
const PiniaStoreHelper = {

    /**
     * =================================================================================================================
     * This function can be used to clear local storage values
     * @param storage pinia storage
     */
    clearLocalStorage(storage){
        console.log("=======================")
        console.log("Clearing local storage")
        console.log("Storage id to clear : "+storage.$id)

        // reset pinia storage
        storage.$reset()

        // clear local storage if it exists by storage id
        if(localStorage.getItem(storage.$id)){

            // remove localstorage
            localStorage.removeItem(storage.$id)
            console.log("local storage cleared")
        }
        else {
            console.warn("no local storage found for the given storage id")
        }
    },


    /**
     * =================================================================================================================
     * This function can be used to get local store values
     * @param storeID pinia storage id
     * @returns {any} serialized data object
     */
    getDataFromLocalStore(storeID){
        return JSON.parse(localStorage.getItem(storeID))
    },

    /**
     * =================================================================================================================
     * This function can be used to sync pina storage values to the local storage
     * @param context
     * @param storeID
     */
    syncDataToLocalStore(context,storeID){
        // listen to changes and update local storage
        context.store.$subscribe((mutation, state) => {
            localStorage.setItem(storeID, JSON.stringify(state));
        });
    }
}

export  {PiniaStoreHelper}