<template>
  <div style="width: auto; margin-left: auto; margin-right: auto !important;">
    <label v-if="label">{{ label }}</label>
    <div>
      <gmap-map
          class="map-styles"
          :center="position"
          :zoom="15"
          map-type-id="terrain"
          :style="styles"
      >
        <gmap-marker
            :position="position"
            :clickable="true"
            :draggable="false"
        />
      </gmap-map>
    </div>
  </div>
</template>

<script>
import {GeoPositionCoordinates} from "../helpers/classes/geoPositionCoordinates";

export default {
  name: "ShowMapLocation",

  props : {
    position : {
      type : GeoPositionCoordinates,
      required : true
    },
    label : {
      type : String,
      required : false
    },
    styles :{
      type: String,
      required : false
    }
  }
}
</script>

<style scoped>
.map-styles{
  width: 100%; height: 300px
}
</style>