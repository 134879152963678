<template>
  
    <div style="height: 80.5vh; overflow: auto !important">
      <!-- <div class="mt-6">
        <SearchBar page=" Hold Applications" />
      </div> -->
      <div class="columns" style="margin-top: 2.8vh; margin-left: 1.1vw;">
        <div class="column">
          <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Hold Applications</label>
        </div>
        <div class="column">
          <b-input
            placeholder="Search..."
            rounded
            type="search"
            icon="magnify"
            icon-clickable
            v-model="searchKeyword"
            style="width:450px">
          </b-input>
        </div>
      </div>
       <TableComp
       :tableData="tableinfo.searchData(tableDetails, searchableFields, searchKeyword)"
        :columns="columnDetails"
        :previousPage="currentPage"
        :statusToAssign="statusToAssign"
        :searchableFields="searchableFields"

       styles="mt-4 mx-6"
      />
         
      
    </div>
  </template>
  
  <script>
  import TableComp from "../../components/TableComp.vue";
  import SearchBar from "../../components/SearchBar.vue";
  import FilterBranch from "../../components/FilterBranch.vue";
  import { filterTableData } from "../../helpers/filterTableData";
  import NetworkManager from "@/network"
  
  export default {
    components: { TableComp, SearchBar, FilterBranch },
    name: "HoldApplicationList",
    data() {
      return {
        ref_no: '',
        nic: '',
        name: '',
        email: '',
        contact: '',
        status_updated_at: '',
        action: '',

        searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','status_updated_at'],
        searchKeyword:"",
        tableDetails: '',
        tableinfo:"",
        applicationList :[],
        currentPage:'HOLD',
        statusToAssign:"",
  
        columnDetails: [
          { id: 1, field: "reference_number", label: "Ref No." ,date:false},
          { id: 2, field: "nic_eic_number", label: "NIC/EIC",date:false },
          { id: 3, field: "full_name", label: "Name" ,date:false},
          { id: 4, field: "gender", label: "Gender" ,date:false},
          { id: 5, field: "email", label: "Email" ,date:false},
          { id: 6, field: "mobile_number", label: "Contact" ,date:false},
          { id: 7, field: "resident_status", label: "Residence" ,date:false},
          { id: 8, field: "status_updated_at", label: "Hold Date",date:true},
          { id: 9, field: "action", label: "Action",date:false },
          
        ],
      };
    },

    created() {
      this.tableinfo = filterTableData
    },
    methods: {
      GetHoldApplicationData: function (){
        const comp= this
        const  data = {
          status:"HOLD"
        }
        console.log("in getassignedapplication")
  
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listAssignedApplicationByStatus',data, function (e){
          console.log("in getassignedapplication",e.data)
          if(e.statusCode === 200){
            comp.tableDetails = e.data.applications
            //console.log("qwertyui", comp.tableDetails)
          }
  
          // comp.hideFullScreenLoader()
        })
      },
    },
    mounted() {
      console.log("in mounted")
      // this.$root.formData.nationality.customer_input = "Sri Lanka";
      // this.$root.formData.nationality.call_agent_input = "Pakistan";
      this.GetHoldApplicationData();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
  };
  </script>
  <style scoped></style>
  