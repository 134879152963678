<template>
  <div class="wrapper">

    <!-- main values   -->
    <div class="columns">
      <div class="column">
        <b-field
            label-position="inside"
            custom-class="custom-float-label" >

          <template #label>Are You or Your Close Relative a Politically Exposed Person?</template>

          <b-input
              v-model="pepDeclaration"
              rounded
              type="text"
              :disabled="true"
              :readonly="true"/>
        </b-field>
      </div>

      <div class="columns" v-for="(pepPerson, index) in PepPersonDetails" :key="index">

        <!-- name -->
        <div class="column">
          <b-field
              label-position="inside"
              custom-class="custom-float-label" >

            <template #label>Name</template>

            <b-input
                v-bind="pepPerson.name"
                rounded
                type="text"
                :disabled="true"
                :readonly="true"/>
          </b-field>
        </div>

        <!-- designation -->
        <div class="column">
          <b-field
              label-position="inside"
              custom-class="custom-float-label" >

            <template #label>Designation</template>

            <b-input
                v-model="pepPerson.designation"
                rounded
                type="text"
                :disabled="true"
                :readonly="true"/>
          </b-field>
        </div>

      </div>
    </div>


    <!-- value changes if any-->
<!-- >
    <div v-if="fieldData.changes.length > 0" class="kyc-change-wrapper">
      <span class="kyc-field-change" v-for="(change, index) in fieldData.changes" :key="index">
        {{ getKycFieldChangesTemplate(change) }}
      </span>
    </div>
<-->

  </div>
</template>

<script>

import {defineComponent} from "vue";


export default defineComponent({

  name : "central-kyc-pep-field",

  props : {
    fieldData: {
      required : true,
      type : Object
    }
  },

  computed:{
    PepPersonDetails(){
      const comp = this

      return comp.fieldData.value.not_politically_exposed ? [] :  comp.fieldData.value.pep_person_details
    }
  },

  data(){
    return {
      pepDeclaration : "",
    }
  },


  methods : {

    getKycFieldChangesTemplate(change) {
      let value = this.type === "date" ? this.getKycFieldChangesTemplate(change.value) : change.value;
      return change.source + " - " + value
    },

    getDisplayValueForBoolean(value){
      return value === true ? "Yes" : "No";
    },

    bindValues(){
      const comp =  this
      console.warn("not politically exposed value ",comp.fieldData.value.not_politically_exposed)
      if(!comp.fieldData.value.not_politically_exposed){
        if(comp.fieldData.value.iam_politically_exposed){
          comp.pepDeclaration = "Yes: I am Politically Exposed";
        }
        else{
          comp.pepDeclaration = "Yes: My Close Relative Politically Exposed";
        }
      }
      else{
        comp.pepDeclaration = "Not Politically Exposed";
      }
    },
  },


  mounted() {
    this.bindValues()
  }

})
</script>

<style scoped>
.wrapper{
  margin-bottom: 20px;
}
.kyc-change-wrapper{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: red;
}
</style>