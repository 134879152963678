<template>
  <section>
    <nav-bar />
    <div class="columns mt-1 mx-1">
      <div class="columns is-one-fifth" v-if="!$root.isSideBarHidden">
        <div class="column">
          <b-button @click="openpanal" icon-right="format-list-bulleted-type" type="is-primary" outlined></b-button>
        </div>
        <div class="column">
          <side-bar style="height: 86vh" v-if="opened"/>  
        </div>
      </div>
      <div class="column" style="max-height:93vh">
        <app-main v-if="!$root.isSideBarHidden" class="card app-main" />
        <app-main v-if="$root.isSideBarHidden" class="card app-main-sec" />
      </div>
    </div>
  </section>
</template>

<script>
import AppMain from "@/layout/components/AppMain";
import NavBar from "@/layout/components/NavBar";
import SideBar from "@/layout/components/SideBar";

export default {
  name: "Layout",
  components: {
    AppMain,
    NavBar,
    SideBar,
  },
  data(){
    return{
      opened:true,
    }
  },
  methods:{
    openpanal(){
      this.opened =!this.opened
    }
  },
  mounted() {
    const self = this;

    // this.startTokenTimer()
    setTimeout(() => {
      self.hideFullScreenLoader();
    }, 500);
  },
};

</script>

<style scoped>
.app-main {
  overflow-x: auto !important;
  height: calc(100vh - 102px) !important;

  margin: 0 0 10px 0;
  max-width: calc(150vw - 350px);
}
.app-main-sec {
  overflow: auto !important;
  /* overflow-x: hidden !important; */
  height: 88vh;
  padding-top: 10px;
  max-width: 100vw;
}
</style>
