import { render, staticRenderFns } from "./AssetsDetails.vue?vue&type=template&id=911dbea6&scoped=true"
import script from "./AssetsDetails.vue?vue&type=script&lang=js"
export * from "./AssetsDetails.vue?vue&type=script&lang=js"
import style0 from "./AssetsDetails.vue?vue&type=style&index=0&id=911dbea6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "911dbea6",
  null
  
)

export default component.exports