import {ExceptionalApproverBaseTableDataItem} from "../exceptionalApproverBaseTableDataItem";

export class GrantedExceptionalApprovalAuthorizerTableDataItem extends  ExceptionalApproverBaseTableDataItem {
    received_date
    granted_date

    constructor(id,refNo,nic,name,gender,email,branch,assignedUserName,status,receivedDate,grantedDate) {
        super(id,receivedDate,nic,name,gender,email,branch,assignedUserName,status);
        this.received_date = receivedDate;
        this.granted_date = grantedDate;
    }
}