<template>
  <b-navbar
    :style="{
      background: '#f7f8f9',
      'box-shadow': '0px 4px 25px rgba(0, 0, 0, 0.28)',
      'backdrop-filter': 'blur(50px)',
    }"
  >
    <template #brand>
      <b-navbar-item >
        <img v-if="displayImageInNavBar" src="../../assets/Group_1000002831.png" style="mix-blend-mode: darken" />
        <img v-else src="../../assets/logo.png" style="mix-blend-mode: darken" />
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <b-dropdown 
          position="is-bottom-left"
          append-to-body 
          aria-role="list">
          <template  #trigger>
            <!-- <b-icon icon="account-circle" size="is-medium" style="cursor: pointer;"></b-icon> -->
            <div class="columns" style="margin-right: 30px; width: max-content;">
              <div class="column" >
                <div class="row" style="width: max-content; text-align: right;">
                  <label> <b>{{ getUsername }}</b>  </label>
                </div>
                <div class="row" style="width: max-content; text-align: right;">
                  <label style="font-size: small;"> {{ getUserRole }} </label>  
                </div>
              </div>
              <div class="column">
                 <b-icon icon="chevron-down" type="is-primary" size="is-medium" style="text-align: right; cursor: pointer; margin-top: 10px;"></b-icon> 
              </div>
            </div>

                        
            <!-- <b-icon icon="account-circle" size="is-large"></b-icon>  -->
          </template>
            <!-- <b-navbar-item >
              <b-icon icon="account-circle" size="is-large"></b-icon> 
              <label>Logged in as: {{ getUsername }} </label>            
            </b-navbar-item> -->
            <b-navbar-item @click="logout()" style="margin-left: 40px;">
              <b-icon icon="exit-to-app" size="is-medium" type="is-info"></b-icon>
              <label style="margin-left: 10px;">Logout</label>  
            </b-navbar-item>
          </b-dropdown>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import JwtUtils from "@/helpers/jwtUtils";
import router from "@/router";


export default {
  name: "NavBar",
  data() {
    return {
      user: null,
      role:null
    };
  },

  computed: {
    getUsername() {
      return this.user !== null ? this.user.username : "User";
    },
    getUserRole() {
      return this.user !== null ? this.user.role : "Role";
    },
    displayImageInNavBar() {
      return this.router().currentRoute.name === 'adminApplicationsList'
    }
  },

  methods: {
    router() {
      return router
    },
    logout: function () {
      this.$root.videoCallManager = null
      JwtUtils.logoutTheUser(true);
    },
    // goToRoot() {
    //   if(this.user.role === 'Detail Reviewer')
    //   {
    //     this.$router.push('/assigned');
    //   }
    //   if(this.user.role === 'Super Admin' || 'Admin')
    //   {
    //     window.open('/applicationList', '_self');
    //   }
    //   if(this.user.role === 'Call Agent')
    //   {
    //     window.open('/schedulevideocall', '_self');
    //   }
    //   if(this.user.role === 'Authorizer')
    //   {
    //     window.open('/applicationsForApproval', '_self');
    //   }
    //   if(this.user.role === 'Exceptional Approver')
    //   {
    //     window.open('/Approvalrequired', '_self');
    //   }

    //   if(this.user.role === 'Branch User')
    //   {
    //     window.open('/RefferToBranchQueue', '_self');
    //   }
    // }

  },

  mounted() {
    this.user = JwtUtils.loadToken().jwtObj;
    console.log("USER DETAILS ",this.user)
  },
};
</script>

<style scoped>
.navbar-title {
  font-size: x-large;
  font-weight: bold;
}
.navbar-item img {
  max-height: 3rem;
}
</style>
