<template>
  <section style="height: 80.5vh; overflow: auto !important">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="" active >
              <label style="font-family:Gotham-Medium; color: gray; font-weight: 400;">Branches</label>
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
         <div class="coloumn is-half" >
          <b-input
          placeholder="Search..."
          rounded
          type="search"
          icon="magnify"
          icon-clickable
          v-model="searchKeyword"
          style="width:450px"

        >
        </b-input>
        </div>
        <div class="column">
          <b-button v-if="hasPermission('branch-create')" type="is-primary is-small" class="is-pulled-right" icon-left="bank-plus" @click="navigateTo('CreateBranch')" rounded>Create Branch</b-button>
        </div>
        </div>
      </div>
    </div>
   
    <div class="card-content" style="overflow: auto; height: 70vh">
   
      <b-table
        
          :data="tableData.searchData(branchList, searchableFields, searchKeyword)"
          :paginated="tableOptions.isPaginated"
          :per-page="tableOptions.perPage"
          :current-page.sync="tableOptions.currentPage"
          :pagination-simple="tableOptions.isPaginationSimple"
          :pagination-position="tableOptions.paginationPosition"
          :default-sort-direction="tableOptions.defaultSortDirection"
          :pagination-rounded="tableOptions.isPaginationRounded"
          :sort-icon="tableOptions.sortIcon"
          :sort-icon-size="tableOptions.sortIconSize"
          default-sort="id"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :page-input="tableOptions.hasInput"
          :pagination-order="tableOptions.paginationOrder"
          :page-input-position="tableOptions.inputPosition"
          :debounce-page-input="tableOptions.inputDebounce"
          :sticky-header="true">

        

        <b-table-column field="branch_code" label="Branch Code" sortable v-slot="props">
          {{ props.row.branch_code}}
        </b-table-column>

        <b-table-column field="branch_name" label="Branch Name" sortable v-slot="props">
          {{ props.row.branch_name }}
        </b-table-column>

        <b-table-column field="address" label="Address" sortable v-slot="props">
          {{ props.row.address }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        

        <b-table-column field="created_at_upm" label="Created At" sortable centered v-slot="props">
                <span >
                    {{ DateTimeManger.getDisplayDateForUPM(props.row.created_at_upm) }}
                </span>
        </b-table-column>

        <b-table-column field="updated_at_upm" label="Updated At" sortable centered v-slot="props">
                <span>
                    {{ DateTimeManger.getDisplayDateForUPM(props.row.updated_at_upm) }}
                </span>
        </b-table-column>

        <b-table-column field="status" label="Status" sortable v-slot="props">
          <span >
            <b-tooltip :label="props.row.status === 'Active' ? 'Active': 'Not Active'" :type="props.row.status === 'Active' ? 'is-success' : 'is-danger'">
               <b-icon
                icon="circle"
                size="is-small"
                :type="props.row.status ==='Active' ? 'is-success' : 'is-danger'"
                rounded/>
            </b-tooltip> 
            </span>
        </b-table-column>
        
        <b-table-column field="action" label="Action" v-slot="props">
          <div class="buttons" v-if="props.row.locked === 0">
            <b-tooltip v-if="hasPermission('branch-edit')" label="Update">
              <b-button rounded
                        style="width:auto; height: auto;"
                        type="is-primary"
                        size="is-small"
                        @click="navigateTo('EditBranch', {id: props.row.id})"
                        icon-left="lead-pencil"></b-button>
            </b-tooltip>
          </div>
          <b-tooltip v-else label="Locked">
            <!-- <b-icon
                icon="lock"
                size="is-medium">
            </b-icon> -->
            <b-button rounded
                      style="width:auto; height: auto;"
                      type="is-dark"
                      size="is-small"
                      icon-left="lock"></b-button>

          </b-tooltip>
        </b-table-column>

      </b-table>
    </div>
    <br />


  </section>
</template>

<script>
import NetworkManager from "@/network"
import {FieldValidator} from "@/helpers/validators/fieldValidator"
import SearchBarVue from '../../components/SearchBar.vue';
// import {DateTimeManger} from "../../helpers/dateTimeManger";
import { filterTableData } from "../../helpers/filterTableData";
import {DateTimeManger} from "../../helpers/dateTimeManger";


export default {
  name: 'BranchList',
  components: {
   'SearchBar':SearchBarVue,
   
  },
  data() {
    return {
      branches: [],
      crData: [],
      crIsLoading: false,
      filterOutFromCR: ['id', 'created_at_upm', 'updated_at_upm', 'locked','district'],

      isPwChangeModalActive: false,
      isPwChangeModalLoading: false,

      selectedUsername: '',
      newPassword: new FieldValidator(),

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: 'is-centered',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,

      },
      searchableFields: ['branch_code', 'branch_name', 'address','email','created_at_upm','updated_at_upm'],
      searchKeyword: "",
      tableData:"",
      branchList:[],

     

    };
  },

  computed: {
    DateTimeManger() {
      return DateTimeManger
    }
  },

  created() {
    this.tableData = filterTableData
  },

  methods: {
    onChangePasswordClick: function (username){
      this.selectedUsername = username
      this.isPwChangeModalActive = true

      this.newPassword.value = ''
    },


    loadData: function (){
      const  self = this

      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/branch/list', {}, function (e){
        if(e.statusCode === 200){
         
          self.branches = e.data.branches

          self.branches.forEach(branch => {
            self.branchList.push({
              'address':branch.address.value,
              'branch_code':branch.branch_code.value,
              'branch_name':branch.branch_name.value,
              'created_at_upm':branch.created_at.value,
              'deleted':branch.deleted.value,
              'district':branch.district.value,
              'edited':branch.edited.value,
              'email':branch.email.value,
              'id':branch.id.value,
              'locked':branch.locked.value,
              'status':branch.status.value,
              'updated_at_upm':branch.updated_at.value
            })
          })
        }
        self.hideFullScreenLoader()

      })
    },


    approve_or_reject: function (data){
      const self = this
      this.crIsLoading = true

      NetworkManager.apiRequest('api/branch/approve', data, function (e){
        if(e.statusCode === 200){
          const params = {
            type: 'is-success',
            message: 'Branch ' + data.actionTaken + ' successfully',
            duration: 5000,
          }
          self.$buefy.toast.open(params)
          location.reload()
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'Branch ' + data.actionTaken + ' failed!',
            duration: 5000,
          }
          self.$buefy.toast.open(params)
        }

        self.crIsLoading = false
      })
    },

    // getFormattedDateTime(date){
    //   return DateTimeManger.formatDateTime(date)
    // },
    //
    filterTableData(branches, searchableFields, searchKeyword) {
    if (!searchKeyword) {
      return branches;
    }

    const keyword = searchKeyword.toLowerCase();

    return branches.filter(branch => {
      return searchableFields.some(field => {
        const value = branch[field].value;
        if (typeof value === "string") {
          return value.toLowerCase().includes(keyword);
        }
        return false;
      });
    });
}

  },

  mounted() {
    this.loadData()
    
  }
};
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}
.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
</style>
