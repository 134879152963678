<template>
  <div class="wrapper">
    <div class="timer">
      <p id="text">Your video call will end in <b>{{displayTime}}</b></p>
    </div>
    <b-icon id="icon" icon="close-circle-outline" size="is-medium" @click.native="close"/>
    <div class="next-call-reminder" v-if="videoKycStore.answeredVideoKycData.nextAssignedVideoCall !== null">
      <p id="text" class="next-call-reminder-header">Next Assigned Video Call Details</p>
      <div class="next-call-details">
        <p class="next-call-detail">Full Name : <span class="emphasise"> {{videoKycStore.answeredVideoKycData.nextAssignedVideoCall.customerFullName}}</span></p>
        <p class="next-call-detail">NIC/EIC Number : <span class="emphasise"> {{videoKycStore.answeredVideoKycData.nextAssignedVideoCall.customerNicEicNumber}}</span></p>
        <br>
        <p class="next-call-detail">Scheduled Time : <span class="emphasise"> {{DateTimeManger.getVideoCallNotificationDateTime(videoKycStore.answeredVideoKycData.nextAssignedVideoCall.videoCallDate,videoKycStore.answeredVideoKycData.nextAssignedVideoCall.videoCallTime)}}</span></p>

      </div>
      <div class="mt-4 mb-4">
        <b-field grouped position="is-centered">
          <b-button id="nextCallerAlert" style=" width: auto" rounded  type="is-primary" :disabled="alertSent === true" @click="alertToNextAssignedCustomer()">Send Alert</b-button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import {GlobalEventManager} from "../helpers/globalEventManager";
import {GlobalEvents} from "../helpers/globalEvents";
import {useVideoKycStore} from "../store/videoKycStore";
import {DateTimeManger} from "../helpers/dateTimeManger";
import NetworkManager from "@/network";
import {BuefyHelper} from "@/helpers/bufeyHelper";
import applications from "@/views/Applications/Applications.vue";

export default {
  name: "AgentVideoCallEndTimeNotification",

  setup(){
    const videoKycStore = useVideoKycStore()
    return {videoKycStore}
  },

  computed :{
    DateTimeManger() {
      return DateTimeManger
    }

  },

  data(){
    return  {
      displayTime : "00 : 00",
      alertSent : false
    }
  },

  methods : {
    close(){
        console.log("hiding notification...")
        GlobalEventManager.$emit(GlobalEvents.agentSide.closeEndCallNotification)
    },

    getDisplayTime(){
      const comp = this

      let remainingSeconds = comp.videoKycStore.expectedCallEndTimeSec - ( Math.floor(performance.now() / 1000) )

      if(remainingSeconds > 0) {

        let minutes = Math.floor(remainingSeconds / 60)
        let seconds = Math.floor(remainingSeconds - minutes * 60)

        minutes = minutes < 10 ? "0" + minutes : minutes
        seconds = seconds < 10 ? "0" + seconds : seconds

        comp.displayTime = minutes + " : " + seconds

        setTimeout(()=>{comp.getDisplayTime()},1000)
      }
    },

    /**
     * =================================================================================================================
     * A function that can be used to send an alert to the next assigned customer
     */

    alertToNextAssignedCustomer() {
      const comp = this;
      const requestBody = {
        kycFormID: comp.videoKycStore.answeredVideoKycData.nextAssignedVideoCall.kycFormId
      }

      console.log("Request Body Here ==========================================================================",requestBody);
      NetworkManager.apiRequest("api/VideoCall/alertToNextAssignedCaller", requestBody, (response) => {

        if (response.statusCode === 200) {

          console.log("Send Alert To User =============================================================")
          comp.alertSent = true
          BuefyHelper.showToastMessage("Successfully Send Alert To Customer", "is-success")

        } else {
          console.log("failed to submit the video call data : " + response.data.message)
        }
      })
    }
  },


  mounted(){
    console.log("end notification component mounted")
    const comp = this
    comp.getDisplayTime()

    console.log("Get next caller===============================",comp.videoKycStore.answeredVideoKycData.nextAssignedVideoCall)
  },
}
</script>

<style scoped>
.wrapper{
  display: flex;
  min-height: 78px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(35, 31, 32, 0.8);
  align-items: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  left: 0;
  right: 0;
  color: white;
  z-index: 100;
}

.wrapper-with-next-call{
  min-height: fit-content;
}

#text {
  color: white;
  font-family: Gotham-Light;
  font-size: medium;
}

#icon{
  position: absolute;
  right: 0;
  margin-right: 25px;
}

.next-call-reminder{
  width: fit-content;
}

.next-call-details{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.next-call-detail{
  font-size: small;
  font-family: 'Gotham-Light';
  color: white;
  margin-right: 20px;
}

.next-call-reminder-header{
  margin-top: 5px;
  text-align: center;
}

.emphasise{
  font-weight: bolder;
}
</style>