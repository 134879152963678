import EKycVideoRecorder from "../videoRecorder/eKycVideoRecorder";
export default function WebRtcBackendInterface(url, iceServers, loggedInUser){
    class SendingEvents {
        STORE_OFFER = 0xfa01
        STORE_CANDIDATE = 0xfa02
        AGENT_CONNECTED = 0xfa06
        CALL_REJECTED = 0xfa08
        ALLOCATE_AGENT = 0xfa09
        CALL_ENDED = 0xfa10
        AGENT_BUSY = 0xfa11
        CALL_RECORD_START=0xfa12
        CALL_RECORD_STOP=0xfa13
    }

    class ReceivingEvents {
        CONNECTED = 0xfb01
        ERROR = 0xfb02
        NEW_CALL = 0xfb03
        CANDIDATE = 0xfb05
        OFFER = 0xfb06
        ANSWER = 0xfb07
        CALL_ENDED = 0xfb08
    }


    // Callback Obj
    class CallBackResponse {
        constructor(status, response) {
            this.status = status
            this.response = response
        }
    }

    // Standard response class for socket communication
    class SocketResponse {
        constructor() {
            this.event = 0
            this.data = {}
        }
    }

    // Standard request class for socket communication
    class SocketRequest {
        event = 0
        data = {}

        constructor(json) {
            Object.assign(this, json)
        }
    }

    const _SendingEvents = new SendingEvents()
    const _ReceivingEvents = new ReceivingEvents()

    let webRtcConn = null
    let peerConn = null
    let recorder = null
    let recordedVideo = null
    let videoUrl = null

    let _localVideo = null
    let _remoteVideo = null

    let customerID = null

    let isStreaming = false
    let isRecording = false

    let videoStartTime = new Date()
    let videoEndTime = new Date()

    let videoDuration = 0.0

    // Callback functions
    let _socketClosedCallback = null
    let _videoStreamReceivedCallback = null
    let _videoCallReceivedCallback = null
    let _videoCallEndedCallback = null

    return {
        subscribe: function (socketClosedCallback, videoStreamReceivedCallback, videoCallReceivedCallback,
                             videoCallEndedCallback){
            _socketClosedCallback = socketClosedCallback
            _videoStreamReceivedCallback = videoStreamReceivedCallback
            _videoCallReceivedCallback = videoCallReceivedCallback
            _videoCallEndedCallback = videoCallEndedCallback
        },

        initiateSocketConnection: function (callBack){
            let callBackObj = new CallBackResponse()

            try {
                webRtcConn = new WebSocket(url)
                webRtcConn.onmessage = websocketOnMessage
                webRtcConn.onclose = webSocketOnClose

                webRtcConn.onopen = (e) => {
                    console.log('Socket opened')
                    callBackObj.status = "success"
                    callBackObj.response = "Socket connection successful"

                    let resp = new SocketResponse()
                    resp.event = _SendingEvents.AGENT_CONNECTED
                    resp.data = {
                        username: loggedInUser
                    }

                    sendDataToSocket(resp)

                    callBack(callBackObj)
                }
            }
            catch (e){
                console.error('Socket connection failed!')
                console.error(e)

                callBackObj.status = "failed"
                callBackObj.response = "Socket connection failed"

                callBack(callBackObj)
            }
        },

        initiateVideoCall: function (localVideo, remoteVideo, stream, customerId, callBack) {
            _localVideo = localVideo
            _remoteVideo = remoteVideo

            //  mirror videos
            // _localVideo.style.transform = "rotateY(180deg)"
            // _localVideo.style.webkitTransform = "rotateY(180deg)" // safari and chrome
            //
            //  _remoteVideo.style.transform = "rotateY(180deg)"
            // _remoteVideo.style.webkitTransform = "rotateY(180deg)" // safari and chrome


            let data = new CallBackResponse()
            customerID = customerId

            let resp = new SocketResponse()
            resp.event = _SendingEvents.ALLOCATE_AGENT
            resp.data = {
                customer: customerID,
                username: loggedInUser
            }

            console.log("allocate agent request ===========")
            console.log(resp)

            sendDataToSocket(resp)

            _localVideo.srcObject = stream

            let configuration = {
                iceServers: iceServers
            }

            try {
                peerConn = new RTCPeerConnection(configuration)
                stream.getTracks().forEach(function (track) {
                    try {
                        peerConn.addTrack(track, stream)
                    } catch (error) {
                        console.error(error)
                    }
                })

                peerConn.ontrack = peerConnOnTrack
                peerConn.onicecandidate = peerConnOnIceCandidate

                createAndSendOffer(callBack)
            }
            catch (err){
                console.error(err)
                data.status = "failed"
                data.response = "Peer connection failed"

                callBack(data)
            }
        },

        muteAudio: function (){
            if(isStreaming) _localVideo.srcObject.getAudioTracks()[0].enabled = false
        },

        unmuteAudio: function (){
            if(isStreaming) _localVideo.srcObject.getAudioTracks()[0].enabled = true
        },

        pauseVideo: function (){
            if(isStreaming) _localVideo.srcObject.getVideoTracks()[0].enabled = false
        },

        resumeVideo: function (){
            if(isStreaming) _localVideo.srcObject.getVideoTracks()[0].enabled = true
        },

        // remote stream controlling
        muteRemoteAudio: function (){
            if(isStreaming) _remoteVideo.srcObject.getAudioTracks()[0].enabled = false
        },

        unmuteRemoteAudio: function (){
            if(isStreaming) _remoteVideo.srcObject.getAudioTracks()[0].enabled = true
        },

        pauseRemoteVideo: function (){
            if(isStreaming) _remoteVideo.srcObject.getVideoTracks()[0].enabled = false
        },

        resumeRemoteVideo: function (){
            if(isStreaming) _remoteVideo.srcObject.getVideoTracks()[0].enabled = true
        },

        rejectVideoCall: function (customer){
            let resp = new SocketResponse()
            resp.event = _SendingEvents.CALL_REJECTED
            resp.data = {
                username: loggedInUser,
                customer: customer
            }

            sendDataToSocket(resp)
        },

        agentBusy: function (client){
            let resp = new SocketResponse()
            resp.event = _SendingEvents.AGENT_BUSY
            resp.data = {
                agent: loggedInUser,
                customer: client
            }

            sendDataToSocket(resp)
        },

        endVideoCall: function (){
            let resp = new SocketResponse()
            resp.event = _SendingEvents.CALL_ENDED
            resp.data = {
                agent: loggedInUser,
                customer: customerID
            }

            sendDataToSocket(resp)

            this.stopVideo()
        },

        stopVideo: function (){
            _localVideo.srcObject.getTracks().forEach(function (track) {
                track.stop()
            })

            _remoteVideo.srcObject.getTracks().forEach(function (track) {
                track.stop()
            })

            peerConn.close()

            isStreaming = false
            videoEndTime = new Date()
            videoDuration = (videoEndTime.getTime() - videoStartTime.getTime()) / 1000

            console.log('Video stopped')
            console.log(`Call duration: ${videoDuration}s`)
        },

        //ns
        endVideoSubmit:function(){
            let resp = new SocketResponse()
            resp.event = _SendingEvents.CALL_RECORD_STOP
            resp.data = {
                agent: loggedInUser,
                customer: customerID
            }

            sendDataToSocket(resp)

        },
        //ns

        startRecording: function (element){
            if(!isStreaming) {
                console.error('Unable to start video before call initiated')
            }
            else {
                //ns
                let resp = new SocketResponse()
                resp.event = _SendingEvents.CALL_RECORD_START
                resp.data = {
                    agent: loggedInUser,
                    customer: customerID
                }

                sendDataToSocket(resp)
                //ns
                recorder = EKycVideoRecorder(element, _localVideo, _remoteVideo)
                recorder.startRecord()

                isRecording = true
            }
        },

        stopRecording: function (callBack, downloadVideo = false){
            isRecording = false

            recorder.stopRecord(function (){
                const url = recorder.getUrl()

                if(downloadVideo === true){
                    const link = document.createElement("a");
                    link.download = "vid.mp4"
                    link.href = url
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }

                recordedVideo = recorder.getBlob()
                videoUrl = url

                //ns
                // let resp = new SocketResponse()
                // resp.event = _SendingEvents.CALL_RECORD_STOP
                // resp.data = {
                //     agent: loggedInUser,
                //     customer: customerID
                // }

                // sendDataToSocket(resp)
                //ns

                callBack()
            })
        },

        getRecordedBlob: function (){
            return recordedVideo
        },

        getVideoUrl: function (){
            return videoUrl
        },

        getCallDuration: function (){
            return videoDuration
        },
    }

    function websocketOnMessage(e){
        try {
            const json = JSON.parse(e.data)
            const request = new SocketRequest(json)
            console.log(request)

            let resp = null

            switch (request.event) {
                case _ReceivingEvents.CONNECTED:
                    const message = request.data.message;
                    console.log(message)
                    break;
                case _ReceivingEvents.ANSWER:
                    peerConn.setRemoteDescription(request.data.answer)
                    break
                case _ReceivingEvents.CANDIDATE:
                    peerConn.addIceCandidate(request.data.candidate)
                    break
                case _ReceivingEvents.CALL_ENDED:
                    resp = new CallBackResponse()
                    resp.status = request.data.reason
                    resp.response = {customer: request.data.username}
                    _videoCallEndedCallback(resp)
                    break;
                case _ReceivingEvents.NEW_CALL:
                    let obj =  {
                        customer : request.data.customer,
                        agent : request.data.agent,
                        location : request.data.location
                    }

                    resp = new CallBackResponse()
                    resp.status = "success"
                    resp.response = obj

                    _videoCallReceivedCallback(resp)
                    break;
            }
        }
        catch (e){
            console.error('Web socket on message exception...')
            console.error(e)
        }
    }

    function webSocketOnClose(e){
        console.error("Socket connection closed...")
        console.error(url)

        let data = new CallBackResponse("failed", "Socket connection closed")

        if(_socketClosedCallback) _socketClosedCallback(data)
    }

    function sendDataToSocket(resp){
        resp.data.username = loggedInUser
        webRtcConn.send(JSON.stringify(resp))
    }

    function createAndSendOffer (callBack) {
        let data = new CallBackResponse()

        peerConn.createOffer((offer) => {
            console.log("createAndSendOffer")
            let resp = new SocketResponse()
            resp.event = _SendingEvents.STORE_OFFER
            resp.data = {
                username: loggedInUser,
                customer: customerID,
                offer: offer
            }

            sendDataToSocket(resp)

            peerConn.setLocalDescription(offer)

            data.status = "success"
            data.response = "Video initiation offer sent"

            console.log("Offer sent to Id: " + customerID)

            videoStartTime = new Date()

            callBack(data)

        }, (error) => {
            console.error('Failed to send offer')
            console.error(error)

            data.status = "failed"
            data.response = "Failed to send offer"

            callBack(data)
        })
    }

    function peerConnOnTrack(e){
        console.log("peerConn Track received")
        console.log(e)

        _remoteVideo.srcObject = e.streams[0];
        isStreaming = true

        let data = new CallBackResponse("success", "Video stream received")

        if(_videoStreamReceivedCallback) _videoStreamReceivedCallback(data)
    }

    function peerConnOnIceCandidate(e){
        console.log('PeerConn ice candidates received')
        if (e.candidate){
            let resp = new SocketResponse()
            resp.event = _SendingEvents.STORE_CANDIDATE
            resp.data = {
                username: loggedInUser,
                customer: customerID,
                candidate: e.candidate
            }

            sendDataToSocket(resp)
        }
    }
}
