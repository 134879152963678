<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">


        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="/users"  >Users</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="" active >Create</b-breadcrumb-item>
          </b-breadcrumb>
        </div>

          
           
        <div class="column">
        </div>
      </div>
    </div>

    <div class="card-content" style="overflow: auto; height: 70vh;" >
      <section style="font-size: 15px !important;margin-top: 10px;" >
        <b-field
            horizontal
            :type="userData.name.class"
            :message="userData.name.message">
          <template slot="label">Name <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="userData.name.blur()" v-model="userData.name.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="userData.username.class"
            :message="userData.username.message">
          <template slot="label">Username <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="userData.username.blur()" v-model="userData.username.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="userData.email.class"
            :message="userData.email.message">
          <template slot="label">Email <span class="has-text-danger">*</span></template>
          <b-input rounded @input="userData.email.blur()" v-model="userData.email.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="userData.contactNo.class"
            :message="userData.contactNo.message">
          <template slot="label">Contact No <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="userData.contactNo.blur()" v-model="userData.contactNo.value" maxlength="12" placeholder="+947XXXXXXXX"></b-input>

        </b-field>

        <!-- <b-field
            horizontal
            :type="userData.userType.class"
            :message="userData.userType.message">
          <template slot="label">User Type <span class="has-text-danger">*</span></template>
          <b-select @blur="userData.userType.blur()" v-model="userData.userType.value" placeholder="Select a Type" >
            <option value=""  disabled selected>Select a Type</option>
            <option value="BACKEND" :key="1">Backend User</option>
          </b-select>
        </b-field> -->

        <b-field
             horizontal
             :type="userData.branch.class"
             :message="userData.branch.message">
          <template slot="label">Branch <span class="has-text-danger">*</span></template>
<!--          <b-select @blur="userData.branch.blur()" v-model="userData.branch.value" placeholder="Select a Branch" >-->
<!--            <option :value="''"  disabled selected>Select a Branch</option>-->
<!--            <option v-for="item in branchList" v-bind:value="item.id" :key="item.id">{{ item.name }}</option>-->
<!--          </b-select>-->
          <p class="control has-icons-right">
            <b-autocomplete
                placeholder="Select a Branch"
                size="is-medium"
                v-model="branchInput"
                open-on-focus
                :data="filterBranches"
                field="name"
                rounded
                @blur="userData.branch.blur()"
                @select="branch => onBranchSelected(branch)"
            >
            </b-autocomplete>
            <span class="icon is-small is-right mr-2 ">
                <img src="@/assets/Images/Icons/Stroke-3.png" alt="Stroke-img" width="15" height="15"/>
              </span>
          </p>
        </b-field>

        <b-field
            horizontal
            :type="userData.department.class"
            :message="userData.department.message">
          <template slot="label">Department <span class="has-text-danger">*</span></template>
          <!--          <b-select @blur="userData.branch.blur()" v-model="userData.branch.value" placeholder="Select a Branch" >-->
          <!--            <option :value="''"  disabled selected>Select a Branch</option>-->
          <!--            <option v-for="item in branchList" v-bind:value="item.id" :key="item.id">{{ item.name }}</option>-->
          <!--          </b-select>-->
          <p class="control has-icons-right">
            <b-autocomplete
                placeholder="Select a Department"
                size="is-medium"
                v-model="departmentInput"
                open-on-focus
                :data="filterDepartments"
                field="name"
                rounded
                @blur="userData.department.blur()"
                @select="department => onDepartmentSelected(department)"
            >
            </b-autocomplete>
            <span class="icon is-small is-right mr-2 ">
                <img src="@/assets/Images/Icons/Stroke-3.png" alt="Stroke-img" width="15" height="15"/>
              </span>
          </p>
        </b-field>

        <b-field
            horizontal
            :type="userData.role.class"
            :message="userData.role.message">
          <template slot="label">Role <span class="has-text-danger">*</span></template>
          <b-select
              @input="onRoleSelected(userData.role.value)"
              @blur="userData.role.blur()"
              rounded
              v-model="userData.role.value"
              placeholder="Select a Role">
            <option :value="''"  disabled selected>Select a Role</option>
            <option
                v-for="role in roleListRef"
                v-bind:value="role.roleId"
                :key="role.roleId">{{ role.roleName }}</option>
          </b-select>


        </b-field>


        <b-field horizontal>
          <div class="block"></div>
        </b-field>


        <b-field horizontal>
          <template slot="label">Permissions<span class="has-text-danger">*</span></template>

          <div class="permissions-container">

            <div class="permission-group-wrapper"
                 v-for="permissionGroup in permissionsListRef"
                 :key="permissionGroup.index">

              <div class="permission-group-title">{{ permissionGroup.type }}</div>
              <div class="permission-group-permission-list">

                <div class="field"
                     v-for="permissionItem in permissionGroup.permissions"
                     :key="permissionItem.index">

                  <label class="b-checkbox checkbox check">
                    <input type="checkbox" autocomplete="on" true-value="true" value="false" :checked="permissionItem.checked" disabled>
                    <span class="check"></span>
                    <span class="control-label">{{ permissionItem.value }}</span>
                  </label>

                </div>
              </div>
            </div>
          </div>
        </b-field>

<!--        <b-field-->
<!--            horizontal-->
<!--            :type="userData.password.class"-->
<!--            :message="userData.password.message">-->
<!--          <template slot="label">Password <span class="has-text-danger">*</span></template>-->
<!--          <b-field :type="userData.password.class"-->
<!--             grouped>-->
<!--            <b-input disabled-->
<!--                @blur="userData.password.blur()"-->
<!--                rounded-->
<!--                maxlength="32"-->
<!--                v-model="userData.password.value"-->
<!--            >-->
<!--            </b-input>-->
<!--            <p class="control">-->
<!--              <button @click="generatePassword" class="button is-primary">Generate</button>-->
<!--            </p>-->

<!--            <p class="control">-->
<!--              <button @click="copyToClipboard" class="button is-primary">Copy</button>-->
<!--            </p>-->

<!--          </b-field>-->

<!--        </b-field>-->

        <b-field horizontal>
          <div class="block"></div>
        </b-field>

        <b-field
            horizontal
            message=" ">
          <template slot="label">Status <span class="has-text-danger">*</span></template>
          <div class="block">
            <b-radio v-model="userData.disabled.value"

                     name="status"
                     native-value="0">
              Active
            </b-radio>
            <b-radio v-model="userData.disabled.value"
                     name="status"
                     native-value="1"
                     type="is-danger"
                     style="margin-left: 20px;">
              Not Active
            </b-radio>

          </div>
        </b-field>

      </section>
    </div>

    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
          <p class="column has-text-danger is-italic is-size-5">Fields marked with <big>*</big> are mandatory</p>
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" style="margin: 5px">
            <b-button @click="validateAndSubmit()" :disabled="disablebutton" type="is-success">Save</b-button>
            <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
    </b-loading>
  </section>
</template>

<script>
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import {PermissionGroup, PermissionItem} from "@/views/User/Support";

export default {
  name: "Create",
  data() {
    return {
      branchList: [],
      departmentList: [],
      roleList: [],
      permissionsList: [],
      exchangeHouseList: [],
      providerList: [],
      branchInput : "",
      departmentInput:"",
      disablebutton :false,
      isLoading:false,

      userData: {
        userType: new FieldValidator(
            /^\w+$/,
            "Please select a User Type"
        ),

        name: new FieldValidator(
            /^([A-Za-z\d]*\s?[A-Za-z\d]+){3,}$/,
            "Name can only have alphanumeric characters and should be at least 3 characters long"
        ),
        username: new FieldValidator(
            /^\w{3,}$/,
            "Username can only have alphanumeric characters with '_' and should be at least 3 characters long"
        ),
        email: new FieldValidator(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
            "Please enter a valid Email"
        ),
        // contactNo: new FieldValidator(
        //   /^\d{4,32}$/,
        //     "Please enter a valid contact number"
        // ),
        contactNo: new FieldValidator(
            // /^\+94\d{9}$/,
            /^(\+94)(7[0-9]{8})$/,
            "Please enter a valid Contact No starting with +94"

        ),
        branch: new FieldValidator(
            /\w+/,
            "Please select a Branch"
        ),
        department: new FieldValidator(
            /\w+/,
            "Please select a Department"
        ),
        role: new FieldValidator(
            /\w+/,
            "Please select a Role"
        ),
        // password: new FieldValidator(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
        //     "Password should contain minimum eight characters,maxmimu thirty two characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)"
        // ),
        disabled: new FieldValidator()
      },

      checkBoxVal: false
    }
  },

  computed: {
    roleListRef(){
      const self = this
      const list = []
      console.log("Role list computed : ")

      self.roleList.forEach(function (item){

        if(item.userType === self.userData.userType.value || item.userType == 'backend'){

          list.push(item)
        }
      })
      return list
    },

    permissionsListRef(){
      const self = this
      const list = []

      self.permissionsList.forEach(function (item){
        if(item.userType === self.userData.userType.value){
          list.push(item)
        }
      })

      return list
    },

    userTypeRef(){
      return this.userData.userType.value
    },

    filterBranches() {
      return this.branchList.filter((branch) => {
        return branch.name.toLowerCase().indexOf(this.branchInput.toLowerCase()) >= 0
      })
    },
    filterDepartments() {
      return this.departmentList.filter((department) => {
        return department.name.toLowerCase().indexOf(this.departmentInput.toLowerCase()) >= 0
      })
    }
  },

  watch: {
    userTypeRef(newVal, oldVal){
      const self = this
      let x = 'a Branch'
      let a = 'a Department'
      // switch (newVal){
      //   case 'BACKEND':
      //     x = "a Branch"
      //     break
      //   case 'EXCHANGEHOUSE':
      //     x = "an exchange house"
      //     break
      //   case 'IPGBANK':
      //     x = "a bank"
      //     break
      // }
      self.userData.department.blur()
      self.userData.department.value = ''
      self.userData.department.errorMessage = 'Please select ' + a

      self.userData.branch.blur()
      self.userData.branch.value = ''
      self.userData.role.value = ''
      self.userData.branch.errorMessage = 'Please select ' + x
    }
  },

  methods: {

    onBranchSelected(branch){
      const comp = this

      console.log("selected branch : ")
      console.log(branch)

      if(branch === null){
        comp.userData.branch.value = "";
      }
      else {
        comp.userData.branch.value = branch.id
      }

    },
    onDepartmentSelected(department){
      const comp = this

      console.log("selected department : ")
      console.log(department)

      if(department === null){
        comp.userData.department.value = "";
      }
      else {
        comp.userData.department.value = department.id
      }
      console.log("Hey here is dep ===========================",comp.userData.department.value)
    },

    // generatePassword: function () {
    //   const self = this
    //
    //   self.userData.password.blur()
    //
    //   const length = Math.floor(Math.random() * 8) + 8
    //
    //   let result = ''
    //   const uc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    //   const lc = 'abcdefghijklmnopqrstuvwxyz'
    //   const num = '0123456789'
    //   const sp = '@$!%*?&'
    //
    //   const characters = uc + lc + num + sp
    //   const charactersLength = characters.length
    //
    //   const x = Math.floor(Math.random() * (uc.length -1)) + 1
    //   const y = Math.floor(Math.random() * (length -1)) + 1
    //   const c1 = uc[x]
    //
    //   const a = Math.floor(Math.random() * (lc.length -1)) + 1
    //   const b = Math.floor(Math.random() * (length -1)) + 1
    //   const c2 = lc[a]
    //
    //   const p = Math.floor(Math.random() * (num.length -1)) + 1
    //   const q = Math.floor(Math.random() * (length -1)) + 1
    //   const c3 = num[p]
    //
    //   const m = Math.floor(Math.random() * (sp.length -1)) + 1
    //   const n = Math.floor(Math.random() * (length -1)) + 1
    //   const c4 = sp[m]
    //   for (let i = 0; i < length; i++ ) {
    //
    //     if(i === y) result += c1
    //     if(i === b) result += c2
    //     if(i === q) result += c3
    //     if(i === n) result += c4
    //
    //     result += characters.charAt(Math.floor(Math.random() *
    //         charactersLength))
    //   }
    //
    //   self.userData.password.value = result
    // },

    copyToClipboard: function(){
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = this.userData.password.value
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      this.$buefy.toast.open({
        message: 'Copied to Clipboard',
        duration: 5000,
      })

    },

    initialData: function (){
      const self = this
      NetworkManager.apiRequest('api/user/initial-create-data', {}, function (e){
        if(e.statusCode === 200){

          console.log(e.data.branchList)
          self.branchList = e.data.branchList
          self.departmentList = e.data.departmentList
          self.roleList = e.data.roleList

          self.exchangeHouseList = e.data.exchangeHouseList
          self.providerList = e.data.providerList

          e.data.permissionsList.forEach(function (item, i){
            const groupItem = new PermissionGroup()
            groupItem.index = i
            groupItem.type = item.type
            groupItem.userType = item.userType

            item.permissions.forEach(function (perm, j){
              const permission = new PermissionItem()
              permission.index = j
              permission.value = perm
              groupItem.permissions.push(permission)
            })

            self.permissionsList.push(groupItem)
          })
        }
      })
    },

    onRoleSelected: function (roleId){
      const self = this
      let role = null

      for (let i = 0; i < self.roleList.length; i++){
        if(self.roleList[i].roleId === roleId){
          role = self.roleList[i]
        }
      }

      if(role !== null){
        self.permissionsList.forEach(function (group){
          group.permissions.forEach(function (perm){
            perm.checked = role.permissions.includes(perm.value);
          })
        })
      }
    },

    validate: function (){
      const self = this
      let isValid = true

      Object.keys(self.userData).forEach(function(key, index) {
        console.log(self.userData[key].validate())
         if(!self.userData[key].validate()) isValid = false
      })

      return isValid
    },

    validateAndSubmit: function (){
      const self = this
      if(self.validate()){
        const data = {
          name : self.userData.name.value,
          email : self.userData.email.value,
          contact : self.userData.contactNo.value,
          username : self.userData.username.value,
          branch_id : self.userData.branch.value,
          department_id:self.userData.department.value,
          role : self.userData.role.value,
          disabled : self.userData.disabled.value
        }
        console.log("Passed data list", data)
        self.disablebutton =true
        self.isLoading = true
        NetworkManager.apiRequest('api/user/create', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'Created user record sent for approval',
              duration: 5000,
            }
            self.$buefy.toast.open(params)
            self.disablebutton =false
            self.isLoading = false
            self.$router.go(-1)
          }
          else if(e.statusCode === 422){
            self.disablebutton =false
            self.isLoading = false
            const params = {
              type: 'is-danger',
              message: e.data.message,
              duration: 5000,
            }
            self.$buefy.toast.open(params)
          }
          else {
            self.disablebutton =false
            const params = {
              type: 'is-danger',
              message: 'User save failed!.',
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
          }
        })
      }
      else
      {
        const params = {
              type: 'is-danger',
              message: "Error with Input data. Please Recheck the fields",
              duration: 5000,
            }
            self.$buefy.toast.open(params)
      }
      self.disablebutton =false
      self.isLoading = false
    }
  },

  mounted() {
    this.userData.disabled.value = 0
    this.userData.userType.value = "BACKEND"
    this.initialData()
  }
}
</script>

<style scoped>
  .permissions-container {
    display: block;
  }

  .permission-group-wrapper {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 2rem;
    vertical-align: top;
  }

  .permission-group-title {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-style: italic;
  }

  .permission-group-permission-list {
    padding-left: 0.1rem;
  }
</style>
