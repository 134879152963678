export class NextAssignedVideoCall{
    kycFormId
    customerFullName
    customerNicEicNumber
    videoCallDate
    videoCallTime

    constructor(kycFormId,fullName,nicEicNumber,videoCallDate,videoCallTime) {
        this.kycFormId = kycFormId;
        this.customerFullName = fullName;
        this.customerNicEicNumber = nicEicNumber;
        this.videoCallDate = videoCallDate;
        this.videoCallTime = videoCallTime;
    }
}