import {ColumnDetail} from "../table/columnDetail";
import {GlobalFunctions} from "../../globalFunctions";
import {DateTimeManger} from "../../dateTimeManger";

export class NicOcrResultDTO{
    constructor(nicType, ocrResult) {
        this.nicType = nicType;
        this.ocrResult = ocrResult;
    }


    /**
     * This method is used to get the JSON representation of the ocr result
     * @returns {any|null}
     */
    getOcrResult(){
        if(this.ocrResult === "") return null;

        return JSON.parse(this.ocrResult)["data"]["ocr_result"];
    }


    /**
     * This method is used to get the display value of the nic type
     * @returns {string}
     */
    getNicType(){
        let nicType = "";

        switch (this.nicType){
            case "old_nic_front":
                nicType = "Old NIC Front";
                break;
            case "old_nic_rear":
                nicType = "Old NIC Rear";
                break;
            case "new_nic_front":
                nicType = "New NIC Front";
                break;
            case "new_nic_rear":
                nicType = "New NIC Rear";
                break;
            default:
                nicType = ""
                break;
        }

        console.log("Formatted NIC Type: " + nicType);
        return nicType;
    }


    /**
     * This method is used to check the identified nic type
     * @param nicType
     * @returns {boolean}
     */
    checkNicType(nicType) {
        return this.nicType === nicType;
    }


    getTableColumns(){
        return [
            new ColumnDetail("two_way_heading", ""),
            new ColumnDetail("ocr_data", "OCR Result"),
            new ColumnDetail("kyc_data", "KYC Form Data")
        ];
    }


    getTableData(kycForm){

        if (this.nicType === "") return [];

        let tableData = [];

        if(this.checkNicType("old_nic_front")){
            tableData.push(
                new NicOcrTableData(
                    "NIC Number",
                    this.getOcrResult().nic_number,
                    GlobalFunctions.getKycFieldVerifiedValue(kycForm.nic_eic_number)
                )
            );

            tableData.push(
                new NicOcrTableData(
                    "NIC Issued Date",
                    this.getOcrResult().nic_issued_date,
                    DateTimeManger.formatCustomerInputDates(GlobalFunctions.getKycFieldVerifiedValue(kycForm.nic_eic_issue_date))
                )
            );

        }
        else if(this.checkNicType("new_nic_front")){
            tableData.push(
                new NicOcrTableData(
                    "NIC Number",
                    this.getOcrResult().nic_number,
                    GlobalFunctions.getKycFieldVerifiedValue(kycForm.nic_eic_number)
                )
            );

            tableData.push(
                new NicOcrTableData(
                    "Name",
                    this.getOcrResult().name,
                   GlobalFunctions.getKycFieldVerifiedValue(kycForm.full_name)
                )
            );

            tableData.push(
                new NicOcrTableData(
                    "Gender",
                    this.getOcrResult().gender,
                    GlobalFunctions.getKycFieldVerifiedValue(kycForm.gender)
                )
            );

            tableData.push(
                new NicOcrTableData(
                    "Date of Birth",
                    this.getOcrResult().date_of_birth,
                    DateTimeManger.formatCustomerInputDates(GlobalFunctions.getKycFieldVerifiedValue(kycForm.date_of_birth))
                )
            );

        }
        else if(this.checkNicType("new_nic_rear")){
            tableData.push(
                new NicOcrTableData(
                    "Address",
                    this.getOcrResult().address,
                    GlobalFunctions.getCustomerPermanentAddress(kycForm)
                )
            );

            tableData.push(
                new NicOcrTableData(
                    "NIC Issued Date",
                    this.getOcrResult().nic_issued_date,
                    DateTimeManger.formatCustomerInputDates(GlobalFunctions.getKycFieldVerifiedValue(kycForm.nic_eic_issue_date))
                )
            );
        }

        return tableData
    }
}


export class NicOcrTableData{

    constructor(twoWayHeading, ocrData, kycData) {
        this.two_way_heading = twoWayHeading;
        this.ocr_data = ocrData;
        this.kyc_data = kycData;

    }
}