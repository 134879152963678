import VideoCallNotification from "./videoCallNotification";
import ImageUploadDetail from "./imageUploadDetail";
import VideoCallAttachment from "./videoCallAttachment";
import PendingActions from "./pendingActions";

export default class AnsweredVideoCall extends VideoCallNotification {
    imageUploadDetails = new ImageUploadDetail();
    dateOfAccountFunding = new Date();
    pendingActions = new PendingActions();
    base64pdfdata = '';
    ekycFormData = null;
    product_details = null;
    constructor() {
        super();
    }

    setImageUploadDetails(images){
        this.imageUploadDetails.face_and_nic_front_side_image = new VideoCallAttachment(images.face_and_nic_front_side_image)
        this.imageUploadDetails.face_and_nic_back_side_image = new VideoCallAttachment(images.face_and_nic_back_side_image)
        this.imageUploadDetails.face_and_passport_bio_page_image = new VideoCallAttachment(images.face_and_passport_bio_page_image)
        this.imageUploadDetails.face_and_passport_alteration_page_image = new VideoCallAttachment(images.face_and_passport_alteration_page_image)
        this.imageUploadDetails.face_and_passport_entry_stamp_page_image = new VideoCallAttachment(images.face_and_passport_entry_stamp_page_image)
        this.imageUploadDetails.face_and_visa_image = new VideoCallAttachment(images.face_and_visa_image)

        this.imageUploadDetails.signature_image = new VideoCallAttachment(images.signature_image)
        this.imageUploadDetails.selfie_image = new VideoCallAttachment(images.selfie_image)
        this.imageUploadDetails.address_proof_document_image = new VideoCallAttachment(images.address_proof_document_image)
    }
}