var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.styles},[_c('ValidationProvider',{attrs:{"name":_vm.fieldName,"rules":_vm.validationRules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{attrs:{"label-position":"inside","custom-class":((_vm.type === `text` && _vm.vModelVaribale.trim() === '' && !_vm.placeholder) ||
            (_vm.type !== `text` && _vm.vModelVaribale === '' && !_vm.placeholder )) &&
          !_vm.isLabelFocus
            ? 'custom-float-label-initial'
            : 'custom-float-label'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('span',{staticClass:"has-text-danger"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true)},[_c('b-input',{attrs:{"icon-right":_vm.copyable? 'content-copy' : '',"icon-right-clickable":"","placeholder":_vm.placeholder,"type":_vm.type ? _vm.type : `text`,"disabled":_vm.disabled,"readonly":_vm.readonly,"rows":_vm.rows,"cols":_vm.cols,"rounded":"","maxlength":_vm.maxLength ? _vm.maxLength : ``},on:{"icon-right-click":function($event){return _vm.copyToClipboard(_vm.vModelVaribale)},"focus":() => {
              _vm.isLabelFocus = true;
            },"blur":() => {
              _vm.isLabelFocus = false;
            }},nativeOn:{"input":function($event){return (() => {
              if (_vm.inputFunc) {
                _vm.inputFunc();
              }
              _vm.sendError(errors[0]);
            }).apply(null, arguments)}},model:{value:(_vm.vModelVaribale),callback:function ($$v) {_vm.vModelVaribale=$$v},expression:"vModelVaribale"}})],1),_c('span',{staticClass:"has-text-danger",staticStyle:{"display":"flex"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }