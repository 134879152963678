<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="/branches">Branches</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="" active >Update</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">

        </div>
      </div>
    </div>

    <div class="card-content" style="overflow: auto; height: 70vh">
      <section>
        <b-field
            horizontal
            :type="branchData.branch_code.class"
            :message="branchData.branch_code.message">
          <template slot="label">Branch Code <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="branchData.branch_code.blur()" v-model="branchData.branch_code.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="branchData.branch_name.class"
            :message="branchData.branch_name.message">
          <template slot="label">Branch Name <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="branchData.branch_name.blur()" v-model="branchData.branch_name.value" maxlength="100" expanded></b-input>
        </b-field>


        <b-field
            horizontal
            :type="branchData.email.class"
            :message="branchData.email.message">
          <template slot="label">Email <span class="has-text-danger">*</span></template>
          <b-input rounded @input="branchData.email.blur()" v-model="branchData.email.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="branchData.address.class"
            :message="branchData.address.message">
          <template slot="label">Address <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="branchData.address.blur()" v-model="branchData.address.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="branchData.district.class"
            :message="branchData.district.message">
          <template slot="label">District <span class="has-text-danger">*</span></template>
          <b-select
              rounded
              @blur="branchData.district.blur()"
              v-model="branchData.district.value"
              placeholder="Select a District">
            <option :value="''"  disabled selected>Select a District</option>
            <option
                v-for="(district,index) in districtList"
                v-bind:value="district"
                :key="index">{{ district }}</option>
          </b-select>
        </b-field>


        <b-field
            horizontal
            message=" ">
          <template slot="label">Status <span class="has-text-danger">*</span></template>
          <div class="block">
            <b-radio v-model="branchData.disabled.value"

                     name="status"
                     native-value="0">
              Active
            </b-radio>
            <b-radio v-model="branchData.disabled.value"
                     name="status"
                     native-value="1"
                     type="is-danger"
                     style="margin-left: 20px;">
              Not Active
            </b-radio>

          </div>
        </b-field>

      </section>
    </div>

    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
          <p class="column has-text-danger is-size-5 is-italic">Fields marked with <big>*</big> are mandatory</p>
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" style="margin: 5px">
            <b-button @click="validateAndSubmit()" :loading="false" type="is-success" :disabled="isButtonDisabled || disablebutton">Update</b-button>
            <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
    </b-loading>
  </section>
</template>

<script>
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import {PermissionGroup, PermissionItem} from "@/views/User/Support";
import {GlobalFunctions} from "../../helpers/globalFunctions";

export default {
  name: "UserEdit",
  data() {
    return {
      disablebutton:false,
      isLoading : false,
      branchList: [],
      providerList: [],
      districtList : [],

      branchData: {
        branch_code: new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Branch Code can only have alphanumeric characters"
        ),

        branch_name: new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Branch Name can only have alphanumeric characters and should be at least 3 characters long"
        ),

        address: new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Address can only have alphanumeric characters"
        ),
        email: new FieldValidator(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
            "Please enter a valid Email"
        ),
        disabled: new FieldValidator(),
        district : new FieldValidator(
            /^[\w\s]+$/,
            "Please select a District"
        )
      },

      oldBranchData: {
        branch_code: new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Branch Code can only have alphanumeric characters"
        ),

        branch_name: new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Branch Name can only have alphanumeric characters and should be at least 3 characters long"
        ),

        address: new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Address can only have alphanumeric characters"
        ),
        email: new FieldValidator(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
            "Please enter a valid Email"
        ),
        disabled: new FieldValidator(),
        district : new FieldValidator(
            /^[\w\s]+$/,
            "Please select a District"
        )
      },

      checkBoxVal: false
    }
  },

  computed: {
    isButtonDisabled() {
      return (
        (this.branchData.branch_code.value === this.oldBranchData.branch_code.value) &&
        (this.branchData.branch_name.value === this.oldBranchData.branch_name.value) &&
        (this.branchData.address.value === this.oldBranchData.address.value) &&
        (this.branchData.email.value === this.oldBranchData.email.value) &&
        (this.branchData.disabled.value === this.oldBranchData.disabled.value) &&
        (this.branchData.district.value === this.oldBranchData.district.value)
      )
    },
  },

  methods: {


    validate: function () {
      const self = this
      let isValid = true

      Object.keys(self.branchData).forEach(function (key, index) {
        console.log(self.branchData[key].validate())
        if (!self.branchData[key].validate()) isValid = false
      })

      return isValid
    },

    validateAndSubmit: function () {
      const self = this
      if (self.validate()) {
        const data = {
          id: self.$route.params.id,
          branch_code : self.branchData.branch_code.value,
          branch_name : self.branchData.branch_name.value,
          address : self.branchData.address.value,
          email : self.branchData.email.value,
          disabled : self.branchData.disabled.value,
          district : self.branchData.district.value
          
        }
        self.disablebutton =true
        self.isLoading = true
        
        NetworkManager.apiRequest('api/branch/edit', data, function (e) {
          if (e.statusCode === 200) {
            const params = {
              type: 'is-success',
              message: 'Updated branch record sent for approval',
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
            self.$router.go(-1)
          } else if (e.statusCode === 422) {
            const params = {
              type: 'is-danger',
              message: e.data.message,
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
          } else {
            const params = {
              type: 'is-danger',
              message: 'Branch edit failed!.',
              duration: 5000,

            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
          }
        })
      }
      self.disablebutton =false
      self.isLoading = false
    },

    getBranchForEdit: function () {
      const self = this

      const data = {
        id: self.$route.params.id
      }

      NetworkManager.apiRequest('api/branch/get-for-edit', data, function (e) {
        if (e.statusCode === 200) {
          const branch = e.data.branch

          self.branchData.branch_code.value = branch.branch_code
          self.branchData.branch_name.value = branch.branch_name
          self.branchData.address.value = branch.address
          self.branchData.email.value = branch.email
          self.branchData.disabled.value = branch.disabled
          self.branchData.district.value = branch.district


          self.oldBranchData.branch_code.value = branch.branch_code
          self.oldBranchData.branch_name.value = branch.branch_name
          self.oldBranchData.address.value = branch.address
          self.oldBranchData.email.value = branch.email
          self.oldBranchData.disabled.value = branch.disabled
          self.oldBranchData.district.value = branch.district

          console.log(JSON.stringify(branch))
        }

        self.hideFullScreenLoader()
      })
    },

    async getDistrictList(){
      const comp = this

      comp.showFullScreenLoader()
      await GlobalFunctions.waitUntilDistrictListLoaded(comp.$root);
      console.log(comp.$root.formDataSupport.districtsArray)
      comp.districtList = comp.$root.formDataSupport.districtsArray
      comp.hideFullScreenLoader()
    },

  },


  mounted() {
    this.getDistrictList()
    this.getBranchForEdit()
  }

}
</script>


<style scoped>

</style>
