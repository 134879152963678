<template>
  <div style="height: 80.5vh; overflow: hidden !important;">
    <div class="columns" style="margin-top: 2.8vh; margin-left: 1.1vw;">
      <div class="column">
       <label
          style="
            font-family: Gotham-Medium;
            color: gray;
            font-weight: 400px;
            font-size: 23px;
          "
        >
          Ongoing Monitoring List
            <b-button rounded
            :style="newCount > 0 ? 'width:auto; height: auto; margin-left: 700px; position: relative; padding-right: 15px;' : 'width:auto; height: auto; margin-left: 700px; position: relative; padding-right: 28px;'"
            type="is-dark"
            size="is-medium"
            @click="navigateTo('PepSanctionNotifications')"
            icon-left="bell"
        >
            <span class="notification-dot" v-if="newCount > 0"></span>
        </b-button>
        </label>
      </div>
    </div>

    <div class="table-comp-wrapper">
      <table>
        <thead class="sticky-header">
          <tr>
            <th class="gray-font" style="border-bottom: 1px solid hsl(0deg, 0%, 86%); border-width: 0 0 1px; padding: 0.5em 0.75em;">
              <span style="color: gray;"> </span>
            </th>
            <th class="gray-font" >Applicant Name</th>
            <th class="gray-font">PEP / Sanction Result Type</th>
            <th class="gray-font">Result</th>
          </tr>
        </thead>
       
       <tbody>
          <tr v-for="(item, index) in monitorList" :key="index" :class="{ 'disabled-row': item.disabled }">
            <td><input type="checkbox" v-model="selectedRows[index]" :disabled="item.disabled"></td>
            <td>{{ item.applicant_name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.type_name }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <TableComp
        :tableData="monitorList"
        :columns="columnDetails"
        :previousPage="currentPage"
        :searchableFields="searchableFields"
        :searchKeyword = "searchKeyword"
        styles="mt-4 mx-6"
      /> -->
    </div>
     <div class="button-container">
        <b-button class="is-primary filter-btn" rounded @click="submitVerdict" style="margin-top: 21px;">Submit Verdict</b-button>
      </div>
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import NetworkManager from "@/network";
import {GlobalFunctions} from "../../helpers/globalFunctions";
import { BuefyHelper } from "../../helpers/bufeyHelper";


export default {
  components: {
    TableComp
  },
 
  data() {
    return {
      statusToAssign : '',
      currentPage : '',
      searchableFields : ["applicant_name","type","type_name"],
      columnDetails : [
        { id: 1, field: 'applicant_name', label: "Applicant Name" ,date:false},
        { id: 2, field: "type", label: "PEP / Sanction Result Type",date:false },
        { id: 3, field: "type_name", label: "Result",date:false },
      ],
      selectedRows: [],
      initialFilterConfigIsLoaded: false,
      selected:'',
      searchnow:false,
      branchName:'',
      branchList :  [],
      filteredBranchList : [],
      showVideoCallModel: false,
      searchKeyword: "",
      tableDetails: [],
      filteredTableData:[],
      tableinfo: "",
      applicationList: [],
      selectedBranch : "",
      monitorList:[],
      newCount:'',
    };
  },
 

  methods: {

    GetNotificationCount() {
        const comp = this;

        NetworkManager.apiRequest('api/WebhookNotifications/getWebhookNotificationCount', {}, function (e){
          if(e.statusCode === 200){
            comp.newCount = e.data.count;
            console.log('comp.newCount', comp.newCount);
            console.log('comp.prevDataCount', comp.prevDataCount);
          }
        });
      },

    GetOngoingMonitorList: function (){
      const comp= this

      comp.showFullScreenLoader()
      
      NetworkManager.apiRequest('api/PepSanctionResult/getOngoingMonitoringList', {}, function (e){
        if(e.statusCode === 200){
          comp.monitorList = e.data
           console.log('comp.monitorListtttttt', comp.monitorList);
           comp.monitorList.forEach(item => {
            
              if (item.verdict_submission === true) {
                  comp.$set(item, 'disabled', true); // Ensure reactivity
              }
          });

          console.log('comp.monitorList', comp.monitorList);
        }

        comp.hideFullScreenLoader()
      })
    }, 

  submitVerdict() {
    const comp = this;

    const selectedVerdicts = {
        remarks: "These are the comments added by analyst!",
        status: "accept",
        search_id: "",
        results: {
            pep_result: [],
            sanction_result: []
        }
    };

    let searchId; 

    console.log('selectedRows', comp.selectedRows);

    comp.selectedRows.forEach((isSelected, index) => {
        if (isSelected) {
            const selectedItem = comp.monitorList[index];
            const verdict = {
              
                id: selectedItem.type_id,
                status: true,
                Remark: "notify in pep change"
            };
            if (selectedItem.type === "PEP Result") {
                selectedVerdicts.results.pep_result.push(verdict);
            } else if (selectedItem.type === "Sanction Result") {
                selectedVerdicts.results.sanction_result.push(verdict);
            }
            
            selectedVerdicts.search_id = selectedItem.search_id;
        }
    });

    console.log('searchId:', searchId); 

   NetworkManager.apiRequest('api/PepSanctionResult/submitVerdict', selectedVerdicts, function (response) {
    if (response.statusCode === 200) {
        if (response.data.msg === "Verdict Submitted") {
            BuefyHelper.showToastMessage("Verdict Submitted Successfully", "is-success");
            location.reload();
        } else {
            BuefyHelper.showToastMessage("Verdict already exist", "is-danger");
            location.reload();
        }
    } else {
        console.log('statusCode', response.statusCode);
        BuefyHelper.showToastMessage("Verdict Submission Failed", "is-danger");
        location.reload();
    }
});
  },
},

  mounted() {
    this.GetOngoingMonitorList();
    this.GetNotificationCount();
  },
};
</script>

<style scoped>
.table-comp-wrapper{
  height:56vh
}
.wrapper {
  margin-left: 20px;
  margin-bottom: 10px;
  overflow-x: scroll; 
  overflow-y: scroll; 
  max-width: 100%; 
  height: 100vh; 
}

table {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0; 
  white-space: nowrap;
  overflow-x: scroll; 
  overflow-y: scroll;
  
}

th.gray-font, td {
  border: none; 
  padding: 8px;
  text-align: left;
  
}

th.gray-font {
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid hsl(0deg, 0%, 86%);
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  font-family: "Gotham-Light" !important;
  color: #a7a9ac;
  height: 4em;
}

tr td {
  border-bottom: 1px solid hsl(0deg, 0%, 86%);
  height: 4em;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #ffffff; 
  z-index: 1;
}

.button-container{
  margin-left : 450px;
  align : center;

}

.disabled-row {
  background-color: #f2f2f2; 
  color: #b0b0b0;
  cursor: not-allowed;
}

.disabled-row input[type="checkbox"] {
  opacity: 0.5; 
  cursor: not-allowed;
}

.notification-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
}

</style>

