<template>
  <div style=" overflow: auto !important;">
    <nav-bar/>
    <full-screen-loader></full-screen-loader>
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label
                style="
                font-family: Gotham-Medium;
                color: gray;
                font-weight: 400;
                font-size: 23px;
              "
            >Applications</label
            >
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                  placeholder="Search..."
                  rounded
                  type="search"
                  icon="magnify"
                  icon-clickable
                  v-model="searchKeyword"
                  style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>-->

    <!---------tabs--------------------------------------->
    <section class="mt-5">
      <!--filter component-------------------------------------------->
      <AdminDataTableFilter
          v-if="initialFilterConfigIsLoaded"
          :date="FilterConfigData.detailReviewer.date"
          :fields="filterDetails"
          :tableData="tableDetails"
          :role-filtering-for="FilterConfigData.detailReviewer.roleFilteringFor"
          :userSelect="FilterConfigData.detailReviewer.userSelect"
          @filterData="applyFilteredData"
      />

      <AdminTableComp
          v-if="!isLoading"
          :tableData="tableDetails"
          :columns="columnDetails"
          :statusToAssign="tabData.detailReviewer.statusToAssign"
          :searchableFields="searchableFields"
          :searchKeyword="searchKeyword"
          :assignableUserRoleName="tabData.detailReviewer.assignableUserRoleName"
          :showApprovedApplicationView="false"
          @refreshTableData="refreshTableData"
      />
    </section>
  </div>
</template>

<script>
import NetworkManager from "../../network";
import AdminTableComp from "../../components/AdminTableComp.vue";
import AdminDataTableFilter from "../../components/AdminDataTableFilter.vue";
import { GlobalAttributes } from "@/helpers/globalAttributes";
import { ApplicationPoolListTabData } from "@/helpers/classes/admin/applicationPoolList/applicationPoolListTabData";
import { BranchFilterOption } from "@/helpers/classes/admin/dataFilterTable/branchFilterOption";
import { FilterDetail } from "@/helpers/classes/admin/dataFilterTable/filterDetail";
import app from "../../App.vue";
import {BuefyHelper} from "@/helpers/bufeyHelper";
import {TableDetailItem} from "@/helpers/classes/admin/applicationPoolList/tableDetailItem";
import {FilterConfigData} from "@/helpers/classes/admin/dataFilterTable/filterConfigData";
import {multiColumnSort} from "buefy";
import FullScreenLoader from "@/components/FullScreenLoader.vue";
import {CommonKycDetails} from "@/helpers/classes/commonKycAdmin/commonKycDetails";
import NavBar from "@/layout/components/NavBar.vue";

export default {
  computed: {
    app() {
      return app;
    },
    GlobalAttributes() {
      return GlobalAttributes;
    },

    tabData() {
      return {
        detailReviewer: new ApplicationPoolListTabData(
            this.columnDetails,
            GlobalAttributes.kycFormStatus.ASSIGNED_DETAIL_REVIEWER,
            this.searchableFields,
            GlobalAttributes.roles.DETAIL_REVIEWER
        ),
        callAgent: new ApplicationPoolListTabData(
            this.columnDetails,
            GlobalAttributes.kycFormStatus.ASSIGNED_CALL_AGENT,
            this.searchableFields,
            GlobalAttributes.roles.CALL_AGENT
        ),
        authorizer: new ApplicationPoolListTabData(
            this.columnDetails,
            GlobalAttributes.kycFormStatus.ASSIGNED_APPROVER,
            this.searchableFields,
            GlobalAttributes.roles.AUTHORIZER
        ),
        exceptionalApprover: new ApplicationPoolListTabData(
            this.columnDetails,
            GlobalAttributes.kycFormStatus.ASSIGNED_EXCEPTIONAL_APPROVER,
            this.searchableFields,
            GlobalAttributes.roles.EXCEPTIONAL_APPROVER
        ),
      };
    },

    FilterConfigData() {
      return {
        detailReviewer: new FilterConfigData(
            true,
            [],
            GlobalAttributes.roles.DETAIL_REVIEWER,
            false
        ),
        callAgent: new FilterConfigData(
            true,
            [],
            GlobalAttributes.roles.CALL_AGENT,
            false
        ),
        authorizer: new FilterConfigData(
            true,
            [],
            GlobalAttributes.roles.AUTHORIZER,
            false
        ),
        exceptionalApprover: new FilterConfigData(
            true,
            [],
            GlobalAttributes.roles.EXCEPTIONAL_APPROVER,
            false
        ),
      };
    },
  },
  components: {
    FullScreenLoader,
    AdminTableComp,
    AdminDataTableFilter,
    NavBar
  },

  data() {
    return {
      initialFilterConfigIsLoaded: false,
      activeTab: 0,
      TableData: "",
      isLoading: true,

      searchKeyword: "",

      searchableFields: [
        "full_name",
        "nic",
        "source",
        "status",
        "received_date",
        "remark",
      ],

      filterDetails: [],

      columnDetails: [
        { id: 1, field: "full_name", label: "Name", date: false, hide: false },
        { id: 2, field: "nic", label: "NIC", date: false, hide: false },
        { id: 3, field: "source", label: "Source" ,date:false, hide: false},
        { id: 4, field: "status", label: "Status" ,date:false, hide: false},
        { id: 4, field: "received_date", label: "KYC Date" ,date:true, hide: false},
        { id: 4, field: "remark", label: "Remark" ,date:false, hide: false},
        { id: 5, field: "common-kyc-action-view", label: "Action", date: false, hide: false,},
      ],

      // NOTE :: column filed name and table Details field 'key' must be same. refer to above columnDetail and below tableDetail
      tableDetails: [],
    };
  },

  methods: {
    /**
     * This function is used to reload the data when application is being assign to a user
     */
    refreshTableData() {
      const comp = this;
      comp.loadInitialData();
    },

    /**
     * This function is used to load applications based on selected filter parameters
     * @param filterParams
     */
    applyFilteredData(filterParams) {
      console.log("filter params... (receiving end) ");
      console.log(filterParams);

      const comp = this;

      // set request parameters
      let applicationPoolStatus = "";

      // find application pool status
      switch (filterParams.role) {
        case GlobalAttributes.roles.DETAIL_REVIEWER:
          applicationPoolStatus = GlobalAttributes.kycFormStatus.NEW;
          break;

        case GlobalAttributes.roles.CALL_AGENT:
          applicationPoolStatus =
              GlobalAttributes.kycFormStatus.SUBMITTED_CALL_AGENT;
          break;

        case GlobalAttributes.roles.AUTHORIZER:
          applicationPoolStatus =
              GlobalAttributes.kycFormStatus.SUBMITTED_APPROVER;
          break;

        case GlobalAttributes.roles.EXCEPTIONAL_APPROVER:
          applicationPoolStatus =
              GlobalAttributes.kycFormStatus.SUBMITTED_EXCEPTIONAL_APPROVER;
          break;
      }

      let endDate = null;
      let branchId = filterParams.branch === null ? 0 : filterParams.branch;

      if (filterParams.endDate != null) {
        let endDateTemp = new Date(filterParams.endDate); // create new date object otherwise may increment the date continuously
        endDate = new Date(endDateTemp.setDate(endDateTemp.getDate() + 1)); // pass +1 date for the end date. otherwise it will not returns the correct end date applications due to timestamp filtering in backend
      }
      console.log("end date : " + endDate);

      // filter relevant tab data
      comp.showFullScreenLoader()
      comp.getFilteredApplications(filterParams.startDate,endDate,branchId,applicationPoolStatus,()=>{
        console.log("filter applied successfully...")
        comp.hideFullScreenLoader()
      })


    },

    /**
     * This function is used to add branch filter options to filterDetails array
     * @returns {Promise<void>}
     */
    async getBranchFilterOptions() {
      const comp = this;
      let branchFilterOptions = [];
      comp.showFullScreenLoader();

      await comp.waitUntilBranchListLoaded();

      console.log("after waiting for branch list...");

      comp.$root.formDataSupport.branchesArray.forEach((branch) =>
          branchFilterOptions.push(
              new BranchFilterOption(branch.id, branch.branch_name)
          )
      );
      comp.FilterConfigData.detailReviewer.fields.push(
          FilterDetail.getFilterDetailObjectForAdminFilter(
              1,
              "Branch",
              null,
              "Select a Branch",
              branchFilterOptions
          )
      );
      comp.FilterConfigData.callAgent.fields.push(
          FilterDetail.getFilterDetailObjectForAdminFilter(
              1,
              "Branch",
              null,
              "Select a Branch",
              branchFilterOptions
          )
      );
      comp.FilterConfigData.authorizer.fields.push(
          FilterDetail.getFilterDetailObjectForAdminFilter(
              1,
              "Branch",
              null,
              "Select a Branch",
              branchFilterOptions
          )
      );
      comp.FilterConfigData.exceptionalApprover.fields.push(
          FilterDetail.getFilterDetailObjectForAdminFilter(
              1,
              "Branch",
              null,
              "Select a Branch",
              branchFilterOptions
          )
      );


      comp.initialFilterConfigIsLoaded = true;
      comp.hideFullScreenLoader();
    },

    /**
     * This function is used to wait until the branch list was loaded completely
     * @returns {Promise<unknown>}
     */
    waitUntilBranchListLoaded() {
      const comp = this;

      return new Promise((resolve) => {
        const checkBranchListLoaded = () => {
          if (comp.$root.formDataSupport.branchesArray.length > 0) {
            console.log("branch list loaded ...");
            resolve();
          } else {
            console.log("waiting for branch list...");
            setTimeout(checkBranchListLoaded, 100);
          }
        };

        checkBranchListLoaded();
      });
    },

    /**
     * This function can be used to get filtered application list
     * @param startDate
     * @param endDate
     * @param branchId
     * @param applicationPoolStatus
     * @param callback
     */
    async getFilteredApplications(
        startDate,
        endDate,
        branchId,
        applicationPoolStatus,
        callback
    ) {
      const comp = this;
      const requestBody = {
        start_date: startDate === null ? startDate : startDate.toISOString(),
        end_date: endDate === null ? endDate : endDate.toISOString(),
        branch_id: branchId,
        application_pool_status: applicationPoolStatus,
      };


      comp.isLoading = true;

      console.warn("request body")
      console.log(requestBody)

      await comp.waitUntilBranchListLoaded()

      NetworkManager.apiRequest("api/central-kyc/get-kyc-list", requestBody,(response)=> {
            console.log("get applications from central kyc")
            console.log(response)
            if (response.statusCode === 200) {

              // add data to the relevant tab
              switch (applicationPoolStatus){

                case GlobalAttributes.kycFormStatus.NEW:
                  console.log("updating : NEW ...")

                  console.log('before loading')
                  console.log(this.tableDetails)
                  console.log('before loading')

                  // comp.tabData.detailReviewer.tableData = [] // make as empty, before add new data
                  comp.tableDetails = []; // make as empty, before add new data
                  response.data.kycRecords.forEach(kycApplication =>{
                    comp.tableDetails.push(comp.getNewTableItemObject(kycApplication))
                    // comp.tabData.detailReviewer.tableData.push(comp.getNewTableItemObject(kycApplication))
                  });

                  console.log('after loading')
                  console.log(this.tableDetails)
                  console.log('after loading')

                  break;

                // case GlobalAttributes.kycFormStatus.SUBMITTED_CALL_AGENT:
                //   console.log("updating : SUBMITTED_CALL_AGENT ...")
                //
                //   comp.tabData.callAgent.tableData = [] // make as empty, before add new data
                //   response.data.kycRecords.forEach((kycApplication) =>{
                //     comp.tabData.callAgent.tableData.push(comp.getNewTableItemObject(kycApplication))
                //   });
                //   break;

                // case GlobalAttributes.kycFormStatus.SUBMITTED_APPROVER:
                //   console.log("updating : SUBMITTED_APPROVER ...")
                //
                //   comp.tabData.authorizer.tableData = [] // make as empty, before add new data
                //   response.data.kycRecords.forEach(kycApplication =>{
                //     comp.tabData.authorizer.tableData.push(comp.getNewTableItemObject(kycApplication))
                //   });
                //   break;

                // case GlobalAttributes.kycFormStatus.SUBMITTED_EXCEPTIONAL_APPROVER:
                //   console.log("updating : SUBMITTED_EXCEPTIONAL_APPROVER ...")
                //
                //   comp.tabData.exceptionalApprover.tableData = [] // make as empty, before add new data
                //   response.data.kycRecords.forEach(kycApplication =>{
                //     comp.tabData.exceptionalApprover.tableData.push(comp.getNewTableItemObject(kycApplication))
                //   });
                //   break;

              }

              console.log('end loading')
              console.log(this.tableDetails)
              console.log('end loading')

              comp.isLoading = false
              callback()
            }

            else {
              //BuefyHelper.showToastMessage("Unable to filter the data","is-danger")

              console.error("Failed to filter data")
              console.error(response.data.message);


              comp.isLoading = false
              callback()
            }
          }
      );
    },


    /**
     * this function can be used to get branch name of a branch filtered by branch id
     * @param id
     * @returns {[]|*|string}
     */
    getBranchNameById(id) {
      const comp = this;
      let filteredBranch = comp.$root.formDataSupport.branchesArray.find(
          (branch) => branch.id === id
      );

      if (filteredBranch !== undefined) {
        return filteredBranch.branch_name;
      } else {
        return "";
      }
    },

    /**
     * This function is used to get new object of TableDetailItem
     * @param kycApplication
     * @returns {CommonKycDetails}
     */
    getNewTableItemObject(kycApplication) {
      const comp = this;

      console.log('TableDetailItem')
      console.log(kycApplication)
      console.log('TableDetailItem')

      return new CommonKycDetails(
          kycApplication.id,
          kycApplication.nic,
          kycApplication.full_name,
          kycApplication.source,
          kycApplication.status,
          kycApplication.received_date,
          kycApplication.remark,
      );
    },

    /**
     * This function can be used to load initial data of the tabs
     */
    loadInitialData() {
      const comp = this;

      comp.showFullScreenLoader();
      // load all tabs data at initial load
      this.getFilteredApplications(null,null,0,GlobalAttributes.kycFormStatus.NEW,()=>{
        this.getFilteredApplications(null,null,0,GlobalAttributes.kycFormStatus.SUBMITTED_CALL_AGENT,()=>{
          this.getFilteredApplications(null,null,0,GlobalAttributes.kycFormStatus.SUBMITTED_APPROVER,()=>{
            this.getFilteredApplications(null,null,0,GlobalAttributes.kycFormStatus.SUBMITTED_EXCEPTIONAL_APPROVER,()=>{
              console.log("initial data loaded")
              comp.hideFullScreenLoader()
            })
          })
        });
      });

    }


  },

  // life cycle hooks ==================================================================================================

  mounted() {
    this.getBranchFilterOptions();
    this.loadInitialData();

    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
  },
};
</script>

<style>
:root {
  --navbar-height: 10vh;
}

html,
body,
#app {
  /* overflow: hidden !important; */
  font-family: "Gotham-Medium";
  height: 100vh;
  /* background: #ecedf0; */
  /* border-radius: 30px; */
}

#main-nav {
  height: var(--navbar-height);
}
.logo {
  mix-blend-mode: darken;
}
@font-face {
  font-family: "Gotham-Black";
  src: url(../../../src/assets/fonts/Gotham-Black.otf);
}
@font-face {
  font-family: "Gotham-Bold";
  src: url(../../../src/assets/fonts/Gotham-Bold.otf);
}
@font-face {
  font-family: "Gotham-Medium";
  src: url(../../../src/assets/fonts/Gotham-Medium.otf);
}
@font-face {
  font-family: "Gotham-Light";
  src: url(../../../src/assets/fonts/Gotham-Light.otf);
}
@font-face {
  font-family: "Gotham-Ultra";
  src: url(../../../src/assets/fonts/Gotham-Ultra.otf);
}
@font-face {
  font-family: "Gotham-Thin";
  src: url(../../../src/assets/fonts/Gotham-Thin.otf);
}
@font-face {
  font-family: "Gotham-Book";
  src: url(../../../src/assets/fonts/Gotham-Book.otf);
}
@font-face {
  font-family: "Gotham-MediumItalic";
  src: url(../../../src/assets/fonts/Gotham-MediumItalic.otf);
}

.message-body .media {
  display: flex;
  align-items: center;
}

.link-style {
  text-decoration: underline;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color : #04c3fc;
}

.link:hover {
  text-decoration: underline;
}

.call-notifications{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  /* hide scroll bar */
  -ms-overflow-style: none !important; /* for IE and Edge */
  scrollbar-width: none !important; /* for Firefox */
}

/* hide scroll bar - Chrome, Safari, Opera */
.call-notifications::-webkit-scrollbar{
  display: none;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

</style>