export class ExceptionalApproverBaseTableDataItem{
    id = 0
    ref_no = ""
    nic = ""
    name = ""
    gender = ""
    email = ""
    branch = ""
    assigned_user_name = ""
    status = ""

    constructor(id, refNo,nic,name,gender,email,branch,assignedUserName,status) {
        this.id = id
        this.ref_no = refNo
        this.nic = nic
        this.name =  name
        this.gender =  gender
        this.email =  email
        this.branch = branch
        this.assigned_user_name = assignedUserName
        this.status = status
    }

}