<template>
  <div>
    <!-- <p>hi</p> -->
    <div v-if="detailReviewerAttachments.length !== 0">
      <label>Image Attachments of Detail Reviewer</label>
      <div v-for="(item, index) in detailReviewerAttachments" :key="index">
        <div style="margin-top: 20px">
          <div class="image-box-wrapper" rounded style="width: 28.2vw; border: 2px solid #000000; border-radius: 25px; padding: 20px;">
              <label>Image Attachment {{ index + 1 }}</label>        
              <div v-if="item.type === 'pdf'">        
                <img
                  :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                  style="height: 53vh"
                  resposive
                  alt="Attachment"
                />
                <button class="download-button"
                  @click="downloadpdf(item.url, 'Attachment')">Download</button>
                </div>
                <div v-else>
                  <img
                    :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                    style="height: 53vh"
                    resposive
                    alt="Attachment"
                  />
                </div>
                <br>
              <label>Comment : </label>
              <label>{{item.comment}}</label>
            <br/>
          </div>
          <br/>
        </div>

      </div>
    </div>
    <div v-if="callAgentAttachments.length !== 0">
      <label>Image Attachments of Call Agent</label>
      <div v-for="(item, index) in callAgentAttachments" :key="item.path">
      <div style="margin-top: 20px">
          <div class="image-box-wrapper" rounded style="width: 28.2vw; border: 2px solid #000000; border-radius: 25px; padding: 20px;">
          <label>Image Attachment {{ index + 1 }}</label>
          <div v-if="item.type === 'pdf'">        
            <img
              :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
              style="height: 53vh"
              resposive
              alt="Attachment"
            />
            <button class="download-button"
              @click="downloadpdf(item.url, 'Attachment')">Download</button>
            </div>
            <div v-else>
              <img
                :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                style="height: 53vh"
                resposive
                alt="Attachment"
              />
            </div>
          <br />
          <label>Comment : </label>
          <label>{{ item.comment }}</label>
          <br />
          </div>
          <br />
      </div>
      </div>
    </div>

    <div v-if="applicationAttachments.length !== 0">
      <label>Application Attachments</label>
      <div v-for="(item, index) in applicationAttachments" :key="item.path">
      <div style="margin-top: 20px">
          <div class="image-box-wrapper" rounded style="width: 28.2vw; border: 2px solid #000000; border-radius: 25px; padding: 20px;">
          <label>Image Attachment {{ index + 1 }}</label>
          <div v-if="item.type === 'pdf'">        
          <img
            :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
            style="height: 53vh"
            resposive
            alt="Attachment"
          />
          <button class="download-button"
            @click="downloadpdf(item.url, 'Attachment')">Download</button>
          </div>
          <div v-else>
            <img
              :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
              style="height: 53vh"
              resposive
              alt="Attachment"
            />
          </div>
          <br />
          <label>Comment : </label>
          <label>{{ item.comment }}</label>
          <br />
          </div>
          <br />
      </div>
      </div>
    </div>
    <!-- <div
      v-else
      class="mt-5 has-text-centered"
      style="margin-top: 100px !important; "
    >
      <span class="icon has-text-grey-lighter is-normal">
        <i class="mdi mdi-information-outline mdi-48px"></i>
      </span>

      <br />
       <span class="ml-2 has-text-grey-lighter" style="font-size: 20px">No Assets Details</span>
      <br />
    </div> -->
  </div>
</template>

<script>
import NetworkManager from "@/network";
import ImageBox from "../../../components/ImageBox.vue";
import {useAuthStore} from "../../../store/authStore";

export default {
  name: "AssetsDetails",
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  components: 
    {
      ImageBox,
    },
  data() {
    return {
      pdfPlaceholder : require("@/assets/Images/pdf.png"),
      detailReviewerAttachments: "",
      callAgentAttachments :"",
      applicationAttachments : "",
      formData : [],
      product :[],
      branch:[],
    };
  },
  methods: {
    getAttachmentInfo:function(){
      const comp = this;
      const data = { kyc_form_id: comp.authStore.kyc_form_id};
      NetworkManager.apiRequest(
        "api/KycApplication/getAgentUploadAttachmentById",
        data,
        function (e) {
          if (e.statusCode === 200) {
              console.log("getAgentUploadAttachmentById data ===============", e.data);
              comp.detailReviewerAttachments = e.data.hold_application_attachments.detailReviewerAttachments;
              comp.callAgentAttachments = e.data.hold_application_attachments.callAgentHoldAttachments;
              comp.applicationAttachments = e.data.additional_attachments.attachment_list;
          } else{
              console.error("FAILED GETTING ATTACHMENTS")
          }
        } 
      );
    },
    GetApprovedApplicationData: function (){
      const comp= this
      const data = {
        id: comp.authStore.kyc_form_id
      }
      console.log("in GetApprovedApplicationData")

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        console.log("in GetApprovedApplicationData",e.data)
        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication  
          comp.product = e.data.productSelection  
          comp.branch=e.data.branch     
        }
      })
    },
  },
  mounted(){
    this.getAttachmentInfo()
    this.GetApprovedApplicationData()
  }
};
</script>

<style lang="scss" scoped>
.image-box-wrapper {
    position: relative; /* Make the wrapper a positioning context */
}

.download-button {
    display: none; /* Initially hide the download button */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top :0vh;
    border-radius: 18px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
    linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
}
.image-box-wrapper:hover .download-button {
    display: block; /* Show the download button on hover */
}
</style>
