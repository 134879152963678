import { extend } from "vee-validate";
import {
  required,
  regex,
  alpha_spaces,
  numeric,
  email,
  image,
  size,
  min,
  alpha,
  min_value,
  alpha_dash,
} from "vee-validate/dist/rules";

let type;

//field should at least particular amount of characters
extend("min", {
  ...min,
  message(fieldName, placeHolder) {
    return `${fieldName} should be at least ${placeHolder.length} characters`;
  },
});

//field can't be empty
extend("required", {
  ...required,
  message: "This field is required",
});

//inserted value should match the regex
extend("regex", {
  ...regex,
  message: "Format is invalid",
});

// Min value
extend("min_value", {
  ...min_value,
  message: (fieldName, placeHolder) => {
    return `${fieldName} value should be greater than or equal to ${placeHolder.min}`;
  },
});

// field should contain char
extend("alpha", {
  ...alpha,
  message: "This field should only contain alphabetic characters",
});

extend("numberRange", {
  validate(value, { max, min }) {
    return value >= min ? (value <= max ? true : false) : false;
  },
  params: ["max", "min"],
  message: (fieldName, placeHolder) => {
    return `${fieldName} should be greater than or equal to ${placeHolder.min} and lesser than or equal to ${placeHolder.max}`;
  },
});

//inserted value should not exceed the length
extend("length", {
  validate(value, { length }) {
    type = typeof value;
    return value.length === length;
  },
  params: ["length"],
  message(fieldName, placeHolder) {
    return type === `object`
      ? `You should only select ${placeHolder.length} ${fieldName}`
      : `Entered ${fieldName} is Invaild`;
  },
});

//inserted value can contain alphabetic characters and spaces
extend("alphaSpaces", {
  ...alpha_spaces,
});

//inserted value should not start with a particular character
extend("notStartsWith", {
  validate(value, { char }) {
    return !value.startsWith(char);
  },
  params: ["char"],
  message: (fieldName, placeHolder) => {
    return `${fieldName} should not start with a "${
      placeHolder.char === " " ? `space` : placeHolder.char
    }"`;
  },
});

//inserted value should not end with a particular character
extend("notEndsWith", {
  validate(value, { char }) {
    return !value.endsWith(char);
  },
  params: ["char"],
  message: (fieldName, placeHolder) => {
    return `${fieldName} should not end with a "${
      placeHolder.char === " " ? `space` : placeHolder.char
    }"`;
  },
});

//inserted value should not contain consecutive particular characters
extend("notConsecutive", {
  validate(value, { char }) {
    return !value.includes(char);
  },
  params: ["char"],
  message: (fieldName, placeHolder) => {
    return `${fieldName} should not contain consecutive "${
      placeHolder.char === "  " ? `spaces` : placeHolder.char
    }"`;
  },
});

//inserted value should contain atleast one particular character
extend("includes", {
  validate(value, { char }) {
    return value.includes(char);
  },
  params: ["char"],
  message: (fieldName, placeHolder) => {
    return `${fieldName} should have at least one "${
      placeHolder.char === " " ? `space` : placeHolder.char
    }"`;
  },
});

//inserted value shoud contain numbers

extend("numeric", {
  ...numeric,
  message: "This field only contains numerics",
});

//email
extend("email", (value) => {
  if (email.validate(value)) {
    return true;
  }

  return "Please enter valid Email ID";
});

/* contain alpha-numeric characters as well as dashes and underscores*/
extend("alpha_dash", {
  ...alpha_dash,
  message:
    "This field is required alpha-numeric characters as well as dashes and underscores",
});

extend("image", {
  ...image,
  message: "Please upload an image",
});

extend("size", {
  ...size,
  params: ["size"],
  message: (fieldName, placeHolder) => {
    return `File size should be less than ${placeHolder.size / 1024} MB`;
  },
});

extend("accountNumbers", {
  valid: true,
  validate(value) {
    const accountNumbers = value.split(",");
    for (const number of accountNumbers) {
      const trimmedNumber = number.trim();
      this.valid =
        trimmedNumber.length !== 12
          ? false
          : trimmedNumber.startsWith("1") || trimmedNumber.startsWith("2")
          ? true
          : false;
      if (!this.valid) {
        break;
      }
    }
    return this.valid;
  },
  message: () => {
    return `Entered account numbers are invalid`;
  },
});

extend("accountNumbersCharacter", {
  valid: true,
  validate(value) {
    const accountNumbers = value.split(",");
    for (const number of accountNumbers) {
      const trimmedNumber = number.trim();
      for (const character of trimmedNumber) {
        this.valid = character.match(/[a-z]/i) ? false : true;
      }
      if (!this.valid) {
        break;
      }
    }
    return this.valid;
  },
  message: () => {
    return `Account number can not contain letters`;
  },
});

extend("numberRange", {
  validate(value, { max, min }) {
    return value >= min ? (value <= max ? true : false) : false;
  },
  params: ["max", "min"],
  message: (fieldName, placeHolder) => {
    return `${fieldName} should be greater than or equal to ${placeHolder.min} and lesser than or equal to ${placeHolder.max}`;
  },
});

extend("noCharacters", {
  valid: true,
  validate(value) {
    for (const character of value) {
      this.valid = character.match(/[a-z]/i) ? false : true;
      if (!this.valid) {
        break;
      }
    }
    return this.valid;
  },
  message: (fieldName) => {
    return `${fieldName} should not contain characters`;
  },
});

extend("onlyNumbers", {
  valid: true,
  validate(value) {
    for (const character of value) {
      this.valid = character.match(/[0-9]/i) ? true : false;
      if (!this.valid) {
        break;
      }
    }
    return this.valid;
  },
  message: (fieldName) => {
    return `${fieldName} should only contain numbers`;
  },
});

//inserted value should start with a particular character
extend("startsWith", {
  validate(value, { char }) {
    return value.startsWith(char);
  },
  params: ["char"],
  message: (fieldName, placeHolder) => {
    return `${fieldName} should start with a "${
      placeHolder.char === " " ? `space` : placeHolder.char
    }"`;
  },
});

extend("noNumbers", {
  valid: true,
  validate(value) {
    for (const character of value) {
      this.valid = character.match(/[0-9]/i) ? false : true;
      if (!this.valid) {
        break;
      }
    }
    return this.valid;
  },
  message: (fieldName) => {
    return `${fieldName} should not contain numbers`;
  },
});

extend("noDuplicates", {
  valid: true,
  validate(value) {
    const accountNumbers = value.split(",");
    const accountNumbers2 = [];
    for (const number of accountNumbers) {
      accountNumbers2.push(number.trim());
    }
    for (const number of accountNumbers2) {
      const index = accountNumbers2.indexOf(number);
      accountNumbers2.splice(index, 1);
      this.valid = accountNumbers2.includes(number) ? false : true;
      if (!this.valid) {
        break;
      }
    }
    return this.valid;
  },
  message: () => {
    return `Duplicates are not allowed`;
  },
});

extend("notMatchRegex", {
  valid: true,
  validate(value, { patterns }) {
    const isArray = Array.isArray(patterns);
    if (isArray) {
      for (const pattern of patterns) {
        this.valid = pattern.test(value) ? false : true;
        if (!this.valid) {
          break;
        }
      }
      return this.valid;
    } else {
      this.valid = patterns.test(value) ? false : true;
      return this.valid;
    }
  },
  params: ["patterns"],
  message: () => {
    return `This field is invalid`;
  },
});

//inserted value should be less than or equal to the length
extend("lessOrEqualLength", {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ["length"],
  message(fieldName, placeHolder) {
    return `${fieldName} can't exceed ${placeHolder.length} characters`;
  },
});

extend("multiRegex", {
  valid: false,
  validate(value, { patterns }) {
    const isArray = Array.isArray(patterns);
    if (isArray) {
      for (const pattern of patterns) {
        this.valid = pattern.test(value) ? true : false;
        if (this.valid) {
          break;
        }
      }
      return this.valid;
    } else {
      this.valid = patterns.test(value) ? true : false;
      return this.valid;
    }
  },
  params: ["patterns"],
  message: () => {
    return `This field is invalid`;
  },
});


// this rule can be used to validate initial deposit amount of LKR account
extend("validateInitialDeposit", {
  valid: true,
  validate(value) {
    if (value.trim() !== "") {
      value = value.replace(",", "");
      const number = Number(value);

      number < 1000 ? (this.valid = false) : (this.valid = true);
      return this.valid;
    } else {
      this.valid = false;
      return this.valid;
    }
  },
  message: () => {
    return "Value should be greater than or equal to 1000";
  },
});

// this can be used to validate the initial deposit amount of FC (Foreign Currency) account
extend("validateInitialDepositFC", {
  valid: true,
  validate(value) {
    if (value.trim() !== "") {
      value = value.replace(",", "");
      const number = Number(value);
      console.log("validation - deposit - value : " + value);
      console.log("validation - deposit - Number : " + number);
      // isNaN(number) || number === 0 ? this.valid = false : this.valid = true
      number === 0 ? (this.valid = false) : (this.valid = true);
      return this.valid;
    } else {
      this.valid = false;
      return this.valid;
    }
  },
  message: () => {
    return "Value should be greater-than or equal to USD 100 or equivalent in other FCY";
  },
});

//will only allow alphabets, numerics, spaces, comma and fullstop.
extend("validatePurpose", {
  valid: true,
  validate(value) {
    for (const character of value) {
      this.valid =
        character.match(/[0-9]/i) ||
        character.match(/[a-z]/i) ||
        character === " " ||
        character === "," ||
        character === "."
          ? true
          : false;
      if (!this.valid) {
        break;
      }
    }
    return this.valid;
  },
  message: (fieldName) => {
    return `${fieldName} should only contain a-z, 0-9, spaces, commas and full stops`;
  },
});

// nic validatio
extend("nicValidate", {
  valid: true,
  validate(value) {
    const length = value.length;
    const firstDigit = value.charAt(0);
    const secondDigit = value.charAt(1);
    const thirdDigit = value.charAt(2);
    const fourthDigit = value.charAt(3);
    const fifthDigit = value.charAt(4);
    const sixthDigit = value.charAt(5);
    const seventhDigit = value.charAt(6);
    if (length !== 10 && length !== 12) {
      this.valid = false;
      return this.valid;
    } else if (length === 10) {
      if (
        firstDigit === "0" ||
        firstDigit === "1" ||
        firstDigit === "2" ||
        firstDigit === "3" ||
        firstDigit === "4"
      ) {
        this.valid = false;
        return this.valid;
      } else if (
        thirdDigit === "0" &&
        fourthDigit === "0" &&
        fifthDigit === "0"
      ) {
        this.valid = false;
        return this.valid;
      } else if (thirdDigit === "9" || thirdDigit === "4") {
        this.valid = false;
        return this.valid;
      } else if (
        (thirdDigit === "8" || thirdDigit === "3") &&
        (fourthDigit === "7" || fourthDigit === "8" || fourthDigit === "9")
      ) {
        this.valid = false;
        return this.valid;
      } else if (
        (thirdDigit === "8" || thirdDigit === "3") &&
        fourthDigit === "6" &&
        (fifthDigit === "7" || fifthDigit === "8" || fifthDigit === "9")
      ) {
        this.valid = false;
        return this.valid;
      } else if (
        thirdDigit === "5" &&
        fourthDigit === "0" &&
        fifthDigit === "0"
      ) {
        this.valid = true;
        return this.valid;
      } else {
        this.valid = true;
        return this.valid;
      }
    } else if (length === 12) {
      if (firstDigit !== "1" && firstDigit !== "2") {
        this.valid = false;
        return this.valid;
      } else if (firstDigit === "1" && secondDigit !== "9") {
        this.valid = false;
        return this.valid;
      } else if (firstDigit === "2" && secondDigit !== "0") {
        this.valid = false;
        return this.valid;
      } else if (
        firstDigit === "1" &&
        secondDigit === "9" &&
        (thirdDigit === "0" ||
          thirdDigit === "1" ||
          thirdDigit === "2" ||
          thirdDigit === "3" ||
          thirdDigit === "4")
      ) {
        this.valid = false;
        return this.valid;
      } else if (
        firstDigit === "2" &&
        secondDigit === "0" &&
        thirdDigit !== "0"
      ) {
        this.valid = false;
        return this.valid;
      } else if (
        fifthDigit === "0" &&
        sixthDigit === "0" &&
        seventhDigit === "0"
      ) {
        this.valid = false;
        return this.valid;
      } else if (fifthDigit === "9" || fifthDigit === "4") {
        this.valid = false;
        return this.valid;
      } else if (
        (fifthDigit === "8" || fifthDigit === "3") &&
        (sixthDigit === "7" || sixthDigit === "8" || sixthDigit === "9")
      ) {
        this.valid = false;
        return this.valid;
      } else if (
        (fifthDigit === "8" || fifthDigit === "3") &&
        sixthDigit === "6" &&
        (seventhDigit === "7" || seventhDigit === "8" || seventhDigit === "9")
      ) {
        this.valid = false;
        return this.valid;
      } else if (
        fifthDigit === "5" &&
        sixthDigit === "0" &&
        seventhDigit === "0"
      ) {
        this.valid = false;
        return this.valid;
      } else {
        this.valid = true;
        return this.valid;
      }
    }
  },
  message: () => {
    return `Please enter a valid NIC number`;
  },
});


extend("validateUsername", {
  valid: true,
  validate(value) {

    this.valid = value.split(" ").length <= 1;

    return this.valid;
  },
  message: (fieldName) => {
    return `${fieldName} cannot contain multiple words and spaces`;
  },
});
