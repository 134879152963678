<template>
  <div style="height: 80.5vh; overflow: auto !important">
    <!-- <div class="mt-6">
      <SearchBar page=" Assigned Applications" />
    </div> -->
    <div class="columns" style="margin-top: 2.8vh; margin-left: 1.1vw;">
      <div class="column">
        <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Assigned Applications</label>
      </div>
      <div class="column">
        <b-input
          placeholder="Search..."
          rounded
          icon-pack="mdi"
          type="search"
          icon="magnify"
          icon-clickable
          icon-color="red"
          v-model="searchKeyword"
          style="width:23.4vw">
        </b-input>
      </div>
    </div>
     <TableComp
      :tableData="tableinfo.searchData(tableDetails, searchableFields, searchKeyword)"
      :columns="columnDetails"
      :searchableFields="searchableFields"
      :previousPage="currentPage"
      :statusToAssign="statusToAssign"

     styles="mt-4 mx-6"
    /> 
  </div>
</template>



<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import FilterBranch from "../../components/FilterBranch.vue";
import { filterTableData } from "../../helpers/filterTableData";
import NetworkManager from "@/network"

export default {
  components: { TableComp, SearchBar, FilterBranch },
  name: "AssignedApplication",
  data() {
    return {
      ref_no: '',
      nic: '',
      name: '',
      email: '',
      contact: '',
      received_date: '',
      action: '',

      searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','created_at'],
      searchKeyword:"",
      tableDetails: "",
      tableinfo:"",
      applicationList :[],
      currentPage:'ASSIGNED-DETAIL-REVIEWER',
      statusToAssign:"ASSIGNED-DETAIL-REVIEWER",

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC",date:false },
        { id: 3, field: "full_name", label: "Name",date:false },
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "mobile_number", label: "Contact" ,date:false},
        { id: 6, field: "created_at", label: "Received Date" ,date:true},
        { id: 7, field: "action", label: "Action" ,date:false},
        
      ],

      

      
      
    };
  },
  created() {
    this.tableinfo = filterTableData
  },
  methods: {
    // buttonClick() {
    //   this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    // },

    GetAssignedApplicationData: function (){
      const comp= this
      const  data = {
        status:"ASSIGNED-DETAIL-REVIEWER"
      }
      console.log("in getassignedapplication")

      // comp.showFullScreenLoader()
      NetworkManager.apiRequest('api/KycApplication/listAssignedApplicationByStatus',data, function (e){
        console.log("in getassignedapplication",e.data)
        if(e.statusCode === 200){
          comp.tableDetails = e.data.applications
        }

        // comp.hideFullScreenLoader()
      })
    },
  },
  mounted() {
    console.log("in mounted")
    // this.$root.formData.nationality.customer_input = "Sri Lanka";
    // this.$root.formData.nationality.call_agent_input = "Pakistan";
    this.GetAssignedApplicationData();
    if(this.$root.isSideBarHidden ){
      this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
    }
  },
};
</script>

  