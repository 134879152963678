import Layout from "@/layout";
import Auth from "@/middlewares/auth";
import Permission from "../middlewares/permission";
import Users from "@/views/User/UserList";
import Applications from "@/views/Applications/Applications";
import AssignedApplicationsReview from "@/views/Applications/AssignedApplicationsReview";
import CreateUser from "@/views/User/UserCreate";
import EditUser from "@/views/User/UserEdit";
import RoleList from "@/views/Role/RoleList";
import RoleCreate from "@/views/Role/RoleCreate";
import RoleEdit from "@/views/Role/RoleEdit";
import User from "@/mixins/modules/authMixins";
import ResetPassword from "@/views/User/ResetPassword";
import BranchList from "@/views/Branch/BranchList";
import BranchCreate from "@/views/Branch/BranchCreate";
import BranchEdit from "@/views/Branch/BranchEdit";
import ApprovedApplicationList from "@/views/ApprovedApplications/ApprovedApplicationList";
import ExceptionalApproval from "@/views/ExceptionalApproval/ExceptionalApproval";
import ScheduleVideoCall from "@/views/ScheduleVideoCall/ScheduleVideoCall";
import AssignedScheduledCalls from "@/views/AssignedScheduledCalls/AssignedScheduledCalls";
import CompletedVideoCalls from "@/views/CompletedVideoCalls/CompletedVideoCalls";
import RejectedAplicationList from "@/views/RejectedApplications/RejectedApplicationList";
import RejectedApplicationListForBranch from "@/views/RejectedApplications/RejectedApplicationListForBranch";
import HoldApplicationList from "@/views/HoldApplications/HoldApplicationList";
import ReturnfromAuthorizerList from "@/views/ReturnfromAuthorizer/ReturnfromAuthorizerList";
import ReturnfromExceptionalApprover from "@/views/ReturnfromExceptionalApprover/ReturnfromExceptionalApprover";
import ReturnedApplicationList from "@/views/ReturnedApplication/ReturnedApplicationList.vue";
import RefertoBranchList from "@/views/RefertoBranch/RefertoBranchList";
import ApprovedApplicationView from "@/views/ApprovedApplications/ApprovedApplicationView";
import AssignedApplication from "@/views/AssignedApplications/AssignedApplication";
import VediocallEkyc from "@/views/Applications/VediocallEkyc";
import List from "@/views/DualAuthentication/List";
import Delete from "@/views/Delete/Delete";
import BranchReffer from "@/views/RefferToBranch/BranchReffer";
import ApplicationStatusList from "@/views/ApplicationStatus/ApplicationStatusList";
import AccountCreationQueue from "../views/Authorizer/AccountCreationQueue";
import OngoingMonitorList from "../views/PepSanctionOngoingMonitorDetails/OngoingMonitorList";
import PepSanctionNotifications from "../views/PepSanctionOngoingMonitorDetails/PepSanctionNotifications";


//super admin

// component for common kyc admin
import CommonKycAdminList from "@/views/CommonKycAdmin/CommonKycAdminList";




import AdminApplicationList from "@/views/Admin/Applications/ApplicationList";
import AdminAssingnedApplication from "@/views/Admin/Applications/AssingnedApplication";
import AdminScheduledVideoCalls from "@/views/Admin/Applications/ScheduledVideoCalls";
import AdminCompletedVideoCalls from "@/views/Admin/Applications/CompletedVideoCalls";
import AdminPendingExceptionalApproval from "@/views/Admin/Applications/PendingExceptionalApproval";
import AdminApprocedApplications from "@/views/Admin/Applications/ApprovedApplications";
import AdminRejectedApplications from "@/views/Admin/Applications/RejectedApplications";
import AdminIncompleteApplications from "@/views/Admin/Applications/IncompleteApplications";
import AdminHoldApplications from "@/views/Admin/Applications/HoldApplications";
import AdminReturnApplications from "@/views/Admin/Applications/ReturnApplications";
import AdminDeleteUserProfile from "@/views/Admin/Applications/DeleteUserProfile";
import AdminRefertoBranch from "@/views/Admin/Applications/RefertoBranch";
import AdminSettings from "@/views/Admin/Settings/Settings";

import ApplicationsForApproval from "@/views/Authorizer/ApplicationsForApproval";
import AssignedApproval from "@/views/Authorizer/AssignedApproval";
import ApprovalRequired from "@/views/ApprovalRequiredView/ApprovalRequired";
import AssinedAppli from "@/views/ApprovalRequiredView/AssinedAppli";
import ApprovedAppli from "@/views/ApprovalRequiredView/ApprovedAppli";
import RejectedAppli from "@/views/ApprovalRequiredView/RejectedAppli";
import ReturnedAppli from "@/views/ApprovalRequiredView/ReturnedAppli";
import DebitCardReport from "@/views/Report/DebitCardReport";
import RefferToBranchQueue from "@/views/Queue/RefferToBranchQueue";
import PendingDebitCardQueue from "@/views/Queue/PendingDebitCardQueue";
import CifOverridesReview from "../views/Authorizer/CifOverridesReview/CifOverridesReview.vue";
import ActivityLog from "../views/ActivityLog/ActivityLog.vue";
import TestView from "../views/TestView.vue";
import ReviewCentralKyc from "@/views/CentralKyc/ReviewCentralKyc.vue";

import NICValidationView from "../views/NICValidationView/NICValidationView";
import KycFormView from "../views/KycFormView/KycFormView"

const routes = [
  {
    path: "/Download",
    name: "ApplicationDetailReview",
    component: () =>
      import("@/views/ApplicationDetailsView/ApplicationDetailReview"),
    hidden: true,
  },
  {
    path: "/DownloadAdvancedpdf",
    name: "ApplicationDetailedReviewcompair",
    component: () =>
      import("@/views/ApplicationDetailsView/ApplicationDetailedReviewcompair"),
    hidden: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
    hidden: true,
  },

    // ******* New login page and other pages for the common kyc admin *******
  {
    path: "/admin/login",
    name: "CommonKycAdminLogin",
    component: () => import("@/views/CommonKycAdminLogin"),
    hidden: true,
  },

  {
    path: "/admin/applicationList",
    name: "adminApplicationsList",
    hidden: true,
    component: CommonKycAdminList,
  },
    // *******
  {
    path: "/CustomerVerification",
    name: "CustomerVerification",
    component: () => import("@/views/Applications/CustomerVerification"),
    hidden: true,
  },

  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    hidden: true,
  },
  {
    path: "/central-kyc",
    name: "CentralKyc",
    component: ReviewCentralKyc,
    hidden: true,
  },

  {
    path: "/test",
    name: "test",
    component: TestView,
    hidden: true,
  },
  {
    path: "/",
    hidden: true,
    meta: { middleware: [Auth] },
    redirect: to => {return '/login'}
  },

  // {
  //   path: "/nic-validation",
  //   name: "NICValidationView",
  //   component: NICValidationView,
  //   meta: { middleware: [Auth] },
  //   // redirect: to => {return '/login'}
  // },

  {
    path: "/kyc-form-view",
    name: "KycFormView",
    component: KycFormView,
    meta: { middleware: [Auth] },
    // redirect: to => {return '/login'}
  },

  {
    path: "/#",
    component: Layout,
    isVisible: () => {
      return (
        User.hasPermission("view-new-application-pool") ||
        User.hasPermission("assign-new-application") ||
        User.hasPermission("schedule-video-call") ||
        User.hasPermission("view-scheduled-application-pool") ||
        User.hasPermission("assign-scheduled-application") ||
        User.hasPermission("view-completed-video-call-list") ||
        User.hasPermission("view-exceptional-approval-application-list") ||
        User.hasPermission("view-rejected-application-list") ||
        User.hasPermission("view-hold-application-list") ||
        User.hasPermission("view-returned-from-authorizer-application-list") ||
        User.hasPermission("view-refer-to-branch-application-list") ||
        User.hasPermission("view-pending-approval-application-pool") ||
        User.hasPermission("approve-application") ||
        User.hasPermission("view-exceptional-approval-application-pool") ||
        User.hasPermission("assign-exceptional-approval-application") ||
        User.hasPermission("view-approved-application-list") ||
        User.hasPermission("review-application-from-branch-pool") ||
        User.hasPermission("account-creation-queue")
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "view-dashboard",
      title: "Application",
      isActive: false,
    },
    children: [
      {
        path: "/applications",
        name: "Applications",
        component: Applications,
        isVisible: () => {
          return User.hasPermission("view-new-application-pool");
        },

        meta: {
          type: "menu-item",
          title: "New Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-new-application-pool"],
        },
      },
      {
        path: "/assigned",
        name: "AssignedApplication",
        component: AssignedApplication,
        isVisible: () => {
          return User.hasPermission("assign-new-application");
        },

        meta: {
          type: "menu-item",
          title: "Assigned Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["assign-new-application"],
        },
      },

      {
        path: "/applications/view/:id",
        name: "AssignedApplicationsReview",
        component: AssignedApplicationsReview,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth],
        },
      },
      {
        path: "/schedulevideocall",
        name: "ScheduleVideoCall",
        isVisible: () => {
          return (
            User.hasPermission("schedule-video-call") ||
            User.hasPermission("view-scheduled-application-pool")
          );
        },
        component: ScheduleVideoCall,
        meta: {
          type: "menu-item",
          title: "Scheduled Video Calls",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: [
            "schedule-video-call",
            "view-scheduled-application-pool",
          ],
        },
      },
      {
        path: "/assignedscheduledcalls",
        name: "AssignedScheduledCalls",
        isVisible: () => {
          return User.hasPermission("assign-scheduled-application");
        },
        component: AssignedScheduledCalls,
        meta: {
          type: "menu-item",
          title: "Assigned Scheduled Calls",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["assign-scheduled-application"],
        },
      },
      {
        path: "/completedvideocalls",
        name: "CompletedVideoCalls",
        isVisible: () => {
          return User.hasPermission("view-completed-video-call-list");
        },
        component: CompletedVideoCalls,
        meta: {
          type: "menu-item",
          title: "Completed Video Calls",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-completed-video-call-list"],
        },
      },
      {
        path: "/applicationsForApproval",
        name: "ApplicationsForApproval",
        isVisible: () => {
          return User.hasPermission("view-pending-approval-application-pool");
        },
        component: ApplicationsForApproval,
        meta: {
          type: "menu-item",
          title: "Applications for Approval",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-pending-approval-application-pool"],
        },
      },
      {
        path: "/assignedApproval",
        name: "AssignedApproval",
        isVisible: () => {
          return User.hasPermission("assign-pending-application-approval");
        },
        component: AssignedApproval,
        meta: {
          type: "menu-item",
          title: "Assigned Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["assign-pending-application-approval"],
        },
      },
      {
        path: "/approvedApplications",
        name: "ApprovedApplications",
        isVisible: () => {
          return User.hasPermission("approve-application");
        },
        component: ApprovedApplicationList,
        meta: {
          type: "menu-item",
          title: "Approved Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["approve-application"],
        },
      },
      {
        path: "/exceptionalapproval",
        name: "ExceptionalApproval",
        isVisible: () => {
          return User.hasPermission(
            "view-exceptional-approval-application-list"
          );
        },
        component: ExceptionalApproval,
        meta: {
          type: "menu-item",
          title: "Exceptional Approval",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-exceptional-approval-application-list"],
        },
      },

      {
        path: "/rejectedApplications",
        name: "RejectedApplication",
        isVisible: () => {
          return (
            User.hasPermission("view-rejected-application-list") &&
            !User.hasPermission("view-exceptional-approval-application-pool")
          );
        },
        component: RejectedAplicationList,
        meta: {
          type: "menu-item",
          title: "Rejected Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: [
            "view-rejected-application-list",
            "view-exceptional-approval-application-pool",
          ],
        },
      },
      {
        path: "/holdApplications",
        name: "HoldApplication",
        isVisible: () => {
          return User.hasPermission("view-hold-application-list");
        },
        component: HoldApplicationList,
        meta: {
          type: "menu-item",
          title: "Hold Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-hold-application-list"],
        },
      },
      {
        path: "/returnfromAuthorizer",
        name: "ReturnfromAuthorizerList",
        isVisible: () => {
          return User.hasPermission(
            "view-returned-from-authorizer-application-list"
          );
        },
        component: ReturnfromAuthorizerList,
        meta: {
          type: "menu-item",
          title: "Return from Authorizer",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-returned-from-authorizer-application-list"],
        },
      },
      {
        path: "/returnfromexceptionalapprover",
        name: "ReturnfromExceptionalApprover",
        isVisible: () => {
          return (
            User.hasPermission("view-exceptional-approval-application-list") ||
            User.hasPermission(
              "view-returned-from-exceptional-approver-application-list"
            )
          );
        },
        component: ReturnfromExceptionalApprover,
        meta: {
          type: "menu-item",
          title: "Returned from Exceptional Approver",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: [
            "view-exceptional-approval-application-list",
            "view-returned-from-exceptional-approver-application-list",
          ],
        },
      },
      {
        path: "/returnedapplications",
        name: "ReturnedApplicationList",
        isVisible: () => {
          return User.hasPermission("view-pending-approval-application-pool");
        },
        component: ReturnedApplicationList,
        meta: {
          type: "menu-item",
          title: "Returned Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-pending-approval-application-pool"],
        },
      },
      {
        path: "/Delete user profile",
        name: "Delete",
        isVisible: () => {
          return User.hasPermission("delete-user-profile");
        },
        component: Delete,
        meta: {
          type: "menu-item",
          title: "Delete User Profile(GDPR)",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["delete-user-profile"],
        },
      },
      {
        path: "/refertobranch",
        name: "BranchReffer",
        isVisible: () => {
          return User.hasPermission("view-refer-to-branch-application-list");
        },
        component: RefertoBranchList,
        meta: {
          type: "menu-item",
          title: "Refer to Branch",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-refer-to-branch-application-list"],
        },
      },
      {
        path: "/approvedApplication/view/:id",
        name: "ViewApprovedApplication",
        component: ApprovedApplicationView,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth],
        },
      },
      //exceptional approver applications
      {
        path: "/Approvalrequired",
        name: "ApprovalRequired",
        isVisible: () => {
          return User.hasPermission(
            "view-exceptional-approval-application-pool"
          );
        },
        component: ApprovalRequired,
        meta: {
          type: "menu-item",
          title: "Approval Required",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-exceptional-approval-application-pool"],
        },
      },
      {
        path: "/AssignedExceptionalApprovalApplications",
        name: "AssinedAppli",
        isVisible: () => {
          return User.hasPermission("assign-exceptional-approval-application");
        },
        component: AssinedAppli,
        meta: {
          type: "menu-item",
          title: "Assigned Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["assign-exceptional-approval-application"],
        },
      },

      {
        path: "/ApprovedApplicationsExceptional",
        name: "ApprovedAppli",
        isVisible: () => {
          return User.hasPermission("assign-exceptional-approval-application");
        },
        component: ApprovedAppli,
        meta: {
          type: "menu-item",
          title: "Approved Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["assign-exceptional-approval-application"],
        },
      },
      {
        path: "/ReturnedapplicationsExceptional",
        name: "ReturnedAppli",
        isVisible: () => {
          return User.hasPermission("assign-exceptional-approval-application");
        },
        component: ReturnedAppli,
        meta: {
          type: "menu-item",
          title: "Returned Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["assign-exceptional-approval-application"],
        },
      },

      {
        path: "/RejectedapplicationsExceptional",
        name: "RejectedAppli",
        isVisible: () => {
          return User.hasPermission("assign-exceptional-approval-application");
        },
        component: RejectedAppli,
        meta: {
          type: "menu-item",
          title: "Rejected Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["assign-exceptional-approval-application"],
        },
      },
      {
        path: "/RefferToBranchQueue",
        name: "RefferToBranchQueue",
        isVisible: () => {
          return User.hasPermission("review-application-from-branch-pool");
        },
        component: RefferToBranchQueue,
        meta: {
          type: "menu-item",
          title: "Refer to Branch",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["review-application-from-branch-pool"],
        },
      },
      {
        path: "/PendingDebitCardQueue",
        name: "PendingDebitCardQueue",
        isVisible: () => {
          return User.hasPermission("review-application-from-branch-pool");
        },
        component: PendingDebitCardQueue,
        meta: {
          type: "menu-item",
          title: "Debit Card",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["review-application-from-branch-pool"],
        },
      },

      {
        path: "/rejectedApplicationsForBranch",
        name: "RejectedApplicationForBranch",
        isVisible: () => {
          return User.hasPermission("view-refer-to-branch-pool");
        },
        component: RejectedApplicationListForBranch,
        meta: {
          type: "menu-item",
          title: "Rejected Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["view-refer-to-branch-pool"],
        },
      },
      {
        path: "/ApplicationStatus",
        name: "ApplicationStatusList",
        isVisible: () => {
          return true;
        },
        component: ApplicationStatusList,
        meta: {
          type: "menu-item",
          title: "Application Status",
          isActive: false,
          middleware: [Auth],
        },
      },
      {
        path: "/accountCreationQueue",
        name: "AccountCreationQueue",
        isVisible: () => {
          return User.hasPermission("approve-application");
        },
        component: AccountCreationQueue,
        meta: {
          type: "menu-item",
          title: "Account Creation Queue",
          isActive: false,
          middleware: [Auth],
        },
      },
      {
        path: "/cifOverrides/view/:id",
        name: "CifOverridesReview",
        component: CifOverridesReview,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth],
        },
      },
    ],
  },

  //admin application routes -----------------------------------------------

  // central kyc
  {
    path: "/#",
    component: Layout,
    isVisible: () => {return (User.hasPermission("delete-user-profile") ); },
    redirect: "",
    meta: {
      type: "menu",
      icon: "locker",
      title: "Central KYC",
      isActive: false,
    },
    children: [
      {
        path: "/nic-validation",
        name: "NICValidationView",
        isVisible: () => {
          return User.hasPermission("delete-user-profile");
        },
        component: NICValidationView,
        meta: {
          type: "menu-item",
          title: "Pull KYC Data",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["delete-user-profile"],
        },
      }
    ],
  },

  {
    path: "/#",
    component: Layout,
    isVisible: () => {
      return (
          User.hasPermission("admin-application-list") ||
          User.hasPermission("admin-assigned-application-list") ||
          User.hasPermission("admin-scheduled-video-call-list") ||
          User.hasPermission("admin-completed-list") ||
          User.hasPermission("admin-exceptional-approval-application-list") ||
          User.hasPermission("admin-approved-application-list") ||
          User.hasPermission("admin-rejected-application-list") ||
          User.hasPermission("admin-incomplete-application-list") ||
          User.hasPermission("admin-hold-application-list") ||
          User.hasPermission("admin-return-application-list") ||
          User.hasPermission("delete-user-profile") ||
          User.hasPermission("admin-refer-to-branch-list")
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "view-dashboard",
      title: "Applications",
      isActive: false,
    },
    children: [
      {
        path: "/applicationList",
        name: "applicationsList",
        isVisible: () => {
          return User.hasPermission("admin-application-list");
        },
        component: AdminApplicationList,
        meta: {
          type: "menu-item",
          title: "Applications",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-application-list"],
        },
      },
      {
        path: "/assignedApplications",
        name: "assignedApplications",
        isVisible: () => {
          return User.hasPermission("admin-assigned-application-list");
        },
        component: AdminAssingnedApplication,
        meta: {
          type: "menu-item",
          title: "Assigned Applications",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-assigned-application-list"],
        },
      },
      {
        path: "/scheduledVideoCall",
        name: "scheduledVideoCall",
        isVisible: () => {
          return User.hasPermission("admin-scheduled-video-call-list");
        },
        component: AdminScheduledVideoCalls,
        meta: {
          type: "menu-item",
          title: "Scheduled Video Calls",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-scheduled-video-call-list"],
        },
      },
      {
        path: "/completedVideoCall",
        name: "completedVideoCall",
        isVisible: () => {
          return User.hasPermission("admin-completed-list");
        },
        component: AdminCompletedVideoCalls,
        meta: {
          type: "menu-item",
          title: "Completed Video Calls",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-completed-list"],
        },
      },
      {
        path: "/pendingExceptionalApproval",
        name: "pendingExceptionalApproval",
        isVisible: () => {
          return User.hasPermission(
              "admin-exceptional-approval-application-list"
          );
        },
        component: AdminPendingExceptionalApproval,
        meta: {
          type: "menu-item",
          title: "Exceptional Approval",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-exceptional-approval-application-list"],
        },
      },
      {
        path: "/approvedApplicationsAdmin",
        name: "approvedApplications",
        isVisible: () => {
          return User.hasPermission("admin-approved-application-list");
        },
        component: AdminApprocedApplications,
        meta: {
          type: "menu-item",
          title: "Approved Applications",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-approved-application-list"],
        },
      },
      {
        path: "/adminRejectedApplications",
        name: "adminRejectedApplications",
        isVisible: () => {
          return User.hasPermission("admin-rejected-application-list");
        },
        component: AdminRejectedApplications,
        meta: {
          type: "menu-item",
          title: "Rejected Applications",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-rejected-application-list"],
        },
      },
      {
        path: "/incompleteApplications",
        name: "incompleteApplications",
        isVisible: () => {
          return User.hasPermission("admin-incomplete-application-list");
        },
        component: AdminIncompleteApplications,
        meta: {
          type: "menu-item",
          title: "Incomplete Applications",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-incomplete-application-list"],
        },
      },
      {
        path: "/adminHoldApplications",
        name: "adminHoldApplications",
        isVisible: () => {
          return User.hasPermission("admin-hold-application-list");
        },
        component: AdminHoldApplications,
        meta: {
          type: "menu-item",
          title: "Hold Applications",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-hold-application-list"],
        },
      },
      {
        path: "/returnApplications",
        name: "returnApplications",
        isVisible: () => {
          return User.hasPermission("admin-return-application-list");
        },
        component: AdminReturnApplications,
        meta: {
          type: "menu-item",
          title: "Return Applications",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-return-application-list"],
        },
      },

      {
        path: "/adminReferToBranch",
        name: "adminReferToBranch",
        isVisible: () => {
          return User.hasPermission("admin-refer-to-branch-list");
        },
        component: AdminRefertoBranch,
        meta: {
          type: "menu-item",
          title: "Refer to Branch",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-refer-to-branch-list"],
        },
      },
      {
        path: "/DebitCardReport",
        name: "DebitCardReport",
        isVisible: () => {
          return User.hasPermission("delete-user-profile");
        },
        component: DebitCardReport,
        meta: {
          type: "menu-item",
          title: "Debit Card Report",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["delete-user-profile"],
        },
      },
      {
        path: "/deleteUserProfile",
        name: "deleteUserProfile",
        isVisible: () => {
          return User.hasPermission("delete-user-profile");
        },
        component: AdminDeleteUserProfile,
        meta: {
          type: "menu-item",
          title: "Delete User Profile (GDPR)",
          icon: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["delete-user-profile"],
        },
      },
      {
        path: "/AdminApplicationStatus",
        name: "AdminApplicationStatusList",
        isVisible: () => {
          return true;
        },
        component: ApplicationStatusList,
        meta: {
          type: "menu-item",
          title: "Application Status",
          isActive: false,
          middleware: [Auth],
        },
      },
      {
        path: "/PepSanctionOngoingMonitorDetails",
        name: "OngoingMonitorList",
        isVisible: () => {
          return true;
        },
        component: OngoingMonitorList,
        meta: {
          type: "menu-item",
          title: "Ongoing Monitor List",
          isActive: false,
          middleware: [Auth],
        },
      },
      {
        path: "/PepSanctionOngoingMonitorDetails/PepSanctionNotifications",
        name: "PepSanctionNotifications",
        component: PepSanctionNotifications,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth],
        },
      },
    ],
  },
  {
    path: "/#",
    component: Layout,
    isVisible: () => {
      return (
        User.hasPermission("user-view") ||
        User.hasPermission("user-create") ||
        User.hasPermission("user-edit") ||
        User.hasPermission("role-view") ||
        User.hasPermission("role-create") ||
        User.hasPermission("role-edit") ||
        User.hasPermission("branch-view") ||
        User.hasPermission("branch-create") ||
        User.hasPermission("branch-edit")
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "account-multiple-outline",
      title: "Management",
      isActive: false,
    },
    children: [
      {
        path: "/users",
        name: "Users",
        isVisible: () => {
          return (
            User.hasPermission("user-view") ||
            User.hasPermission("user-create") ||
            User.hasPermission("user-edit")
          );
        },
        component: Users,
        meta: {
          type: "menu-item",
          title: "Users",
          icon: "account-multiple",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["user-view", "user-create", "user-edit"],
        },
      },
      {
        path: "/users/create",
        name: "CreateUser",
        component: CreateUser,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["user-view", "user-create", "user-edit"],
        },
      },
      {
        path: "/users/edit/:id",
        name: "EditUser",
        component: EditUser,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["user-view", "user-create", "user-edit"],
        },
      },
      {
        path: "/roles",
        name: "Roles",
        isVisible: () => {
          return (
            User.hasPermission("role-view") ||
            User.hasPermission("role-create") ||
            User.hasPermission("role-edit")
          );
        },
        component: RoleList,
        meta: {
          type: "menu-item",
          title: "Roles",
          icon: "human-male-board",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["role-view", "role-create", "role-edit"],
        },
      },
      {
        path: "/roles/create",
        name: "CreateRole",
        component: RoleCreate,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["role-view", "role-create", "role-edit"],
        },
      },
      {
        path: "/roles/edit/:id",
        name: "EditRole",
        component: RoleEdit,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["role-view", "role-create", "role-edit"],
        },
      },
      {
        path: "/branches",
        name: "Branches",
        isVisible: () => {
          return (
            User.hasPermission("branch-view") ||
            User.hasPermission("branch-create") ||
            User.hasPermission("branch-edit")
          );
        },
        component: BranchList,
        meta: {
          type: "menu-item",
          title: "Branches",
          icon: "bank",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["branch-view", "branch-create", "branch-edit"],
        },
      },
      {
        path: "/branches/create",
        name: "CreateBranch",
        component: BranchCreate,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["branch-view", "branch-create", "branch-edit"],
        },
      },
      {
        path: "/branches/edit/:id",
        name: "EditBranch",
        component: BranchEdit,
        meta: {
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["branch-view", "branch-create", "branch-edit"],
        },
      },
    ],
  },
  {
    path: "/#",
    component: Layout,
    isVisible: () => {
      return (
        User.hasPermission("user-approve") ||
        User.hasPermission("role-approve") ||
        User.hasPermission("branch-approve")
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "shield-check",
      title: "Dual Authentication",
      isActive: false,
    },
    children: [
      {
        path: "/dualauthentication",
        name: "dualauthentication",
        isVisible: () => {
          return (
            User.hasPermission("user-approve") ||
            User.hasPermission("role-approve") ||
            User.hasPermission("branch-approve")
          );
        },
        redirect: "",
        component: List,
        meta: {
          type: "menu-item",
          title: "Dual Authentication",
          icon: "account-multiple",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["user-approve", "role-approve", "branch-approve"],
        },
      },
      // {
      //   path: "/Delete user profile",
      //   name: "Delete",
      //   isVisible: () => {
      //     return true;
      //   },
      //   component: Delete,
      //   meta: {
      //     type: "menu-item",
      //     title: "Delete User Profile(GDPR)",
      //     icon: "account-multiple",
      //     isActive: false,
      //     middleware: [Auth],
      //   },
      // },
    ],
  },

  {
    path: "/#",
    component: Layout,
    isVisible: () => {
      return (User.hasPermission("admin-application-list") || User.hasPermission("view-activity-log"));
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "text",
      title: "Activity Log",
      isActive: false,
    },
    children: [
      {
        path: "/activityLog",
        name: "activityLog",
        isVisible: () => {
          return (User.hasPermission("admin-application-list")  || User.hasPermission("view-activity-log"));
        },
        redirect: "",
        component: ActivityLog,
        meta: {
          type: "menu-item",
          title: "Activity Log",
          icon: "text",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["admin-application-list", "view-activity-log"],
        },
      },
    ],
  },
];

export default routes;
