<template>
    <div>
        <div class="container" 
            rounded style="width: 50vw; height: fit-content; max-height:99vh;
            background-color: white;
            border-radius: 25px;
            padding: 8px;">
            <section>
                <!-- <b-progress></b-progress> -->
                <div style="text-align: center;">
                    <label style="font-size: 24px;">Uploading...</label>
                </div>
                <div class="container" 
                    rounded style="width: 48vw; height: fit-content; max-height:99vh;
                    background-color: white;
                    border: 2px solid #000000;
                    border-radius: 25px;
                    padding: 0px;">
                    <b-progress :value="value" show-value format="percent" :max=100 type="is-danger" size="is-large" :locale="undefined "></b-progress>
                </div>
                <div style="margin-left:1vw">
                    <label style="font-size: 12px;">*{{ label }}</label>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

export default {
    name: "ProgressLoader",

    props: {

    value:{
      type: Number,
      required : true
    },

    label :{
      type : String,
      required : false
    }
  },
}
</script>

<style lang="scss" scoped>

</style>