<template>
  <div style="height: 80.5vh; overflow: auto !important;">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">{{ pageName }}</label>
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                  placeholder="Search..."
                  rounded
                  type="search"
                  icon="magnify"
                  icon-clickable
                  v-model="searchKeyword"
                  style="width:450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!---------tabs--------------------------------------->
    <section class="mt-5">
      <b-tabs v-model="activeTab" @input="tabChanged">
        <b-tab-item label="Detail Reviewer">

          <!--filter component-------------------------------------------->
          <AdminDataTableFilter
              v-if="initialFilterConfigIsLoaded"
              :date="FilterConfigData.detailReviewer.date"
              :fields="FilterConfigData.detailReviewer.fields"
              :role-filtering-for="FilterConfigData.detailReviewer.roleFilteringFor"
              :userSelect="FilterConfigData.detailReviewer.userSelect"
              @filterData="applyFilteredData"/>

          <AdminTableComp
              v-if="!isLoading"
              :tableData="TabData.detailReviewer.tableData"
              :columns="TabData.detailReviewer.columns"
              :statusToAssign="TabData.detailReviewer.statusToAssign"
              :searchableFields="TabData.detailReviewer.searchableFields"
              :assignable-user-role-name="TabData.detailReviewer.assignableUserRoleName"
              :searchKeyword="searchKeyword"
              @refreshTableData="refreshTableData"

          />
        </b-tab-item>

        <b-tab-item label="Call Agent">

          <!--filter component-------------------------------------------->
          <AdminDataTableFilter
              v-if="initialFilterConfigIsLoaded"
              :date="FilterConfigData.callAgent.date"
              :fields="FilterConfigData.callAgent.fields"
              :role-filtering-for="FilterConfigData.callAgent.roleFilteringFor"
              :userSelect="FilterConfigData.callAgent.userSelect"
              @filterData="applyFilteredData"/>

          <AdminTableComp
              v-if="!isLoading"
              :tableData="TabData.callAgent.tableData"
              :columns="TabData.callAgent.columns"
              :statusToAssign="TabData.callAgent.statusToAssign"
              :searchableFields="TabData.callAgent.searchableFields"
              :assignable-user-role-name="TabData.callAgent.assignableUserRoleName"
              :searchKeyword="searchKeyword"
              @refreshTableData="refreshTableData"

          />
        </b-tab-item>

        <b-tab-item  label="Authorizer" >

          <!--filter component-------------------------------------------->
          <AdminDataTableFilter
              v-if="initialFilterConfigIsLoaded"
              :date="FilterConfigData.authorizer.date"
              :fields="FilterConfigData.authorizer.fields"
              :role-filtering-for="FilterConfigData.authorizer.roleFilteringFor"
              :userSelect="FilterConfigData.authorizer.userSelect"
              @filterData="applyFilteredData"/>

          <AdminTableComp
              v-if="!isLoading"
              :tableData="TabData.authorizer.tableData"
              :columns="TabData.authorizer.columns"
              :statusToAssign="TabData.authorizer.statusToAssign"
              :searchableFields="TabData.authorizer.searchableFields"
              :assignable-user-role-name="TabData.authorizer.assignableUserRoleName"
              :searchKeyword="searchKeyword"
              @refreshTableData="refreshTableData"

          />
        </b-tab-item>

      </b-tabs>
    </section>
  </div>
</template>

<script>

import NetworkManager from "../../../network";
import AdminTableComp from "../../../components/AdminTableComp.vue";
import AdminDataTableFilter from "../../../components/AdminDataTableFilter.vue";
import {GlobalAttributes} from "../../../helpers/globalAttributes";
import {BranchFilterOption} from "../../../helpers/classes/admin/dataFilterTable/branchFilterOption";
import {FilterDetail} from "../../../helpers/classes/admin/dataFilterTable/filterDetail";
import {UserFilterOption} from "../../../helpers/classes/admin/dataFilterTable/userFilterOption";
import {StatusFilterOption} from "../../../helpers/classes/admin/dataFilterTable/statusFilterOption";
import {GlobalEvents} from "../../../helpers/globalEvents";
import {GlobalEventManager} from "../../../helpers/globalEventManager";
import {FilterConfigData} from "../../../helpers/classes/admin/dataFilterTable/filterConfigData";
import {BuefyHelper} from "../../../helpers/bufeyHelper";
import {
  ExceptionalApprovalApplicationsTabData
} from "../../../helpers/classes/admin/exceptionalApproverApplications/exceptionalApprovalApplicationsTabData";
import {TableDetailItem} from "../../../helpers/classes/admin/applicationPoolList/tableDetailItem";
import {
  PendingExApprovalDetailReviewerTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/pendingExceptionalApproval/pendingExApprovalDetailReviewerTableDataItem";
import {
  GrantedExApprovalDetailReviewerTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/grantedExceptionalApproval/grantedExApprovalDetailReviewerTableDataItem";
import {
  DeniedExApprovalDetailReviewerTabDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/deniedExceptionalApproval/deniedExApprovalDetailReviewerTabDataItem";
import {
  PendingExApprovalCallAgentTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/pendingExceptionalApproval/pendingExApprovalCallAgentTableDataItem";
import {
  GrantedExApprovalCallAgentTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/grantedExceptionalApproval/grantedExApprovalCallAgentTableDataItem";
import {
  DeniedExApprovalCallAgentTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/deniedExceptionalApproval/deniedExApprovalCallAgentTableDataItem";
import {
  PendingExApprovalAuthorizerTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/pendingExceptionalApproval/pendingExApprovalAuthorizerTableDataItem";
import {
  GrantedExceptionalApprovalAuthorizerTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/grantedExceptionalApproval/GrantedExceptionalApprovalAuthorizerTableDataItem";
import {
  DeniedExApprovalAuthorizerTableDataItem
} from "../../../helpers/classes/admin/exceptionalApproverApplications/deniedExceptionalApproval/deniedExApprovalAuthorizerTableDataItem";

export default {
  computed: {
    GlobalAttributes() {
      return GlobalAttributes
    },

    FilterConfigData() {
      return {
        detailReviewer : new FilterConfigData(true,[],GlobalAttributes.roles.DETAIL_REVIEWER,false),
        callAgent : new FilterConfigData(true,[],GlobalAttributes.roles.CALL_AGENT,false),
        authorizer : new FilterConfigData(true,[],GlobalAttributes.roles.AUTHORIZER,false),
      }
    },


    // for all tabs, initially set the pending status columns, once the status was changed, columns also needed to be changed. and also table data needed to reset for that tab
    TabData(){
      return {
        detailReviewer: new ExceptionalApprovalApplicationsTabData(
            this.getTabColumns(GlobalAttributes.roles.DETAIL_REVIEWER,GlobalAttributes.ExceptionalApprovalStatus.Pending),
            GlobalAttributes.kycFormStatus.ASSIGNED_EXCEPTIONAL_APPROVER,
            this.searchableFields,
            GlobalAttributes.roles.EXCEPTIONAL_APPROVER
        ),
        callAgent: new ExceptionalApprovalApplicationsTabData(
            this.getTabColumns(GlobalAttributes.roles.CALL_AGENT,GlobalAttributes.ExceptionalApprovalStatus.Pending),
            GlobalAttributes.kycFormStatus.ASSIGNED_EXCEPTIONAL_APPROVER,
            this.searchableFields,
            GlobalAttributes.roles.EXCEPTIONAL_APPROVER
        ),
        authorizer: new ExceptionalApprovalApplicationsTabData(
            this.getTabColumns(GlobalAttributes.roles.AUTHORIZER,GlobalAttributes.ExceptionalApprovalStatus.Pending),
            GlobalAttributes.kycFormStatus.ASSIGNED_EXCEPTIONAL_APPROVER,
            this.searchableFields,
            GlobalAttributes.roles.EXCEPTIONAL_APPROVER
        ),

      }
    }
  },
  components: {
    // TableComp,
    // SearchBar,
    AdminTableComp,
    AdminDataTableFilter
  },
  data() {
    return {
      isLoading : true,
      initialFilterConfigIsLoaded : false,
      activeTab: 0,
      pageName : "Pending Exceptional Approval",
      searchKeyword:"",

      // TODO :: consider the tab wise searchable fields
      searchableFields: ['ref_no', 'nic', 'name','email','branch','gender','received_date','assigned_user_name','scheduled_time','completed_date','granted_date','denied_date'],

      // column details matrix. These details were depend on the STATUS, and the ROLE
      // TODO :: This can be refactor using new class
      columnDetails :{
        pendingExceptionalApproval:{
          detailReviewer: [
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 8, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 9, field: "status", label: "Status" ,date:false,hide:false},
            { id: 10, field: "action", label: "" ,date:false,hide:false},
            { id: 11, field: "action-view", label: "Action" ,date:false,hide:false},
          ],
          callAgent:[
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "scheduled_time",label: "Scheduled Time",date:false,hide:false},
            { id: 11, field: "completed_date",label: "Completed Date",date:true,hide:false},
            { id: 12, field: "status", label: "Status" ,date:false,hide:false},
            { id: 13, field: "action", label: "" ,date:false,hide:false},
            { id: 14, field: "action-view", label: "Action" ,date:false,hide:false},
          ],
          authorizer:[
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "status", label: "Status" ,date:false,hide:false},
            { id: 11, field: "action", label: "" ,date:false,hide:false},
            { id: 12, field: "action-view", label: "Action" ,date:false,hide:false},
          ]
        },
        grantedExceptionalApproval :{
          detailReviewer: [
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "granted_date", label: "Granted Date" ,date:true,hide:false},
            { id: 11, field: "status", label: "Status" ,date:false,hide:false},
            { id: 12, field: "action-view", label: "Action" ,date:false,hide:false},
          ],
          callAgent:[
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "scheduled_time",label: "Scheduled Time",date:false,hide:false},
            { id: 11, field: "granted_date",label: "Granted Date",date:true,hide:false},
            { id: 12, field: "status", label: "Status" ,date:false,hide:false},
            { id: 13, field: "action-view", label: "Action" ,date:false,hide:false},
          ],
          authorizer:[
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "granted_date", label: "Granted Date" ,date:true,hide:false},
            { id: 11, field: "status", label: "Status" ,date:false,hide:false},
            { id: 12, field: "action-view", label: "Action" ,date:false,hide:false},
          ]
        },
        deniedExceptionalApproval :{
          detailReviewer: [
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "denied_date", label: "Denied Date" ,date:true,hide:false},
            { id: 11, field: "status", label: "Status" ,date:false,hide:false},
            { id: 12, field: "action-view", label: "Action" ,date:false,hide:false},
          ],
          callAgent:[
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "scheduled_time",label: "Scheduled Time",date:false,hide:false},
            { id: 11, field: "denied_date",label: "Denied Date",date:true,hide:false},
            { id: 12, field: "status", label: "Status" ,date:false,hide:false},
            { id: 13, field: "action-view", label: "Action" ,date:false,hide:false},
          ],
          authorizer:[
            { id: 1, field: "ref_no", label: "Ref No.",date:false ,hide:false},
            { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
            { id: 3, field: "name", label: "Name" ,date:false,hide:false},
            { id: 4, field: "gender", label: "Gender" ,date:false,hide:false},
            { id: 5, field: "email", label: "Email" ,date:false,hide:false},
            { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
            { id: 7, field: "resident_status", label: "Residence" ,date:false},
            { id: 8, field: "assigned_user_name",label: "Assigned User",date:false,hide:false},
            { id: 9, field: "received_date", label: "Received Date" ,date:true,hide:false},
            { id: 10, field: "denied_date", label: "Denied Date" ,date:true,hide:false},
            { id: 11, field: "status", label: "Status" ,date:false,hide:false},
            { id: 12, field: "action-view", label: "Action" ,date:false,hide:false},
          ]
        }
      },


    };
  },

  methods: {


    /**
     * This function is used to add branch filter options to filterDetails array
     * @param callback
     * @returns {Promise<void>}
     */
    async getBranchFilterOptions(callback){

      const comp = this
      let branchFilterOptions = []
      // comp.showFullScreenLoader()

      await comp.waitUntilBranchListLoaded()

      console.log("after waiting for branch list...")

      comp.$root.formDataSupport.branchesArray.forEach(branch=>branchFilterOptions.push(new BranchFilterOption(branch.id,branch.branch_name)))

      comp.FilterConfigData.detailReviewer.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(1,"Branch", null,"Select a Branch",branchFilterOptions));
      comp.FilterConfigData.callAgent.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(1,"Branch", null,"Select a Branch",branchFilterOptions));
      comp.FilterConfigData.authorizer.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(1,"Branch", null,"Select a Branch",branchFilterOptions));

      // comp.hideFullScreenLoader()
      callback()
    },


    /**
     * This function is used to wait until the branch list was loaded completely
     * @returns {Promise<unknown>}
     */
    waitUntilBranchListLoaded(){
      const comp = this

      return new Promise(resolve => {
        const checkBranchListLoaded = () =>{
          if(comp.$root.formDataSupport.branchesArray.length > 0){
            console.log("branch list loaded ...")
            resolve();
          }
          else{
            console.log("waiting for branch list...")
            setTimeout(checkBranchListLoaded,100)
          }
        };

        checkBranchListLoaded()
      })
    },


    /**
     * This function can be used to load user list based on role type
     * @param role
     * @param callback optional callback function
     */
    getUserFilterOptions:function (role,callback){
      const comp = this;

      // comp.showFullScreenLoader()
      let userFilterOptions = [];

      const requestBody = {
        role_name: role
      }

      NetworkManager.apiRequest("api/User/getUserListByRoleName",requestBody,(response)=>{
        console.log(response)

        if(response.statusCode  === 200){

          console.log("user list loaded for the role : "+role)
          console.log(response.data.users)

          response.data.users.forEach(user=>userFilterOptions.push(new UserFilterOption(user.id,user.name)))

          switch (role){

            case GlobalAttributes.roles.DETAIL_REVIEWER:
              comp.FilterConfigData.detailReviewer.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(2,"User", null,"All Users",userFilterOptions));
              break

            case GlobalAttributes.roles.CALL_AGENT:
              comp.FilterConfigData.callAgent.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(2,"User", null,"All Users",userFilterOptions));
              break

            case GlobalAttributes.roles.AUTHORIZER:
              comp.FilterConfigData.authorizer.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(2,"User", null,"All Users",userFilterOptions));
              break
          }

          if(typeof callback === "function"){
            callback()
          }
        }
      })
    },


    /***
     * This function is used add status filter options
     */
    getStatusFilterOptions(){
      const comp = this

      let statusFilterOptions = [
        new StatusFilterOption(GlobalAttributes.ExceptionalApprovalStatus.Pending,"Pending"),
        new StatusFilterOption(GlobalAttributes.ExceptionalApprovalStatus.Granted,"Granted"),
        new StatusFilterOption(GlobalAttributes.ExceptionalApprovalStatus.Denied,"Denied"),
      ]

      comp.FilterConfigData.detailReviewer.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(3,"Status", GlobalAttributes.ExceptionalApprovalStatus.Pending,"Select Status",statusFilterOptions));
      comp.FilterConfigData.callAgent.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(3,"Status", GlobalAttributes.ExceptionalApprovalStatus.Pending,"Select Status",statusFilterOptions));
      comp.FilterConfigData.authorizer.fields.push( FilterDetail.getFilterDetailObjectForAdminFilter(3,"Status", GlobalAttributes.ExceptionalApprovalStatus.Pending,"Select Status",statusFilterOptions));

    },



    /**
     *
     * This function is used to load applications based on selected filter parameters
     * @param filterParams
     */
    applyFilteredData(filterParams){
      console.log("filter params... (receiving end) ")
      console.log(filterParams)

      const comp = this

      // set request parameters
      let endDate = null;

      if(filterParams.endDate != null){
        let endDateTemp = new Date(filterParams.endDate); // create new date object otherwise may increment the date continuously
        endDate = new Date(endDateTemp.setDate(endDateTemp.getDate()+1)); // pass +1 date for the end date. otherwise it will not returns the correct end date applications due to timestamp filtering in backend
      }

      let branchId = filterParams.branch === null ? 0 : filterParams.branch;
      let userId =  filterParams.user == null ? 0 : filterParams.user;
      console.log("end date : "+endDate)

      comp.showFullScreenLoader()

      // filter relevant tab data
      comp.getFilteredApplications(filterParams.startDate,endDate,branchId,userId,filterParams.role,filterParams.status,()=>{
        console.log("filter applied successfully...")
        comp.hideFullScreenLoader()
      })

    },


    /***
     * This function is used to load filtered applications
     * @param startDate
     * @param endDate
     * @param branchId
     * @param userId
     * @param roleName
     * @param status
     * @param callback
     */
    async getFilteredApplications (startDate,endDate,branchId,userId,roleName,status,callback) {
      const comp = this;
      const requestBody = {
        start_date: startDate === null ? startDate : startDate.toISOString(),
        end_date: endDate === null ? endDate : endDate.toISOString(),
        branch_id: branchId,
        user_id: userId,
        role_name: roleName,
        status: status
      };

      console.log("get filtered application request body ....")
      console.log(requestBody)


      comp.isLoading = true;

      await comp.waitUntilBranchListLoaded()

      NetworkManager.apiRequest("api/Admin/getExceptionalApprovalFilteredApplications", requestBody,(response)=> {

        console.log("get exceptional approval filter applications response")
          console.log(response)

        if (response.statusCode === 200) {
            // add data to the relevant tab
            // TODO :: consider status selection

            switch (roleName){

              case GlobalAttributes.roles.DETAIL_REVIEWER:

                console.log("updating : DETAIL_REVIEWER ... - status "+ status)

                comp.TabData.detailReviewer.tableData = [] // make as empty, before add new data
                comp.TabData.detailReviewer.columns = comp.getTabColumns(GlobalAttributes.roles.DETAIL_REVIEWER,status) // update columns

                // add new data
                response.data.filtered_exceptional_approval_applications.forEach(kycApplication =>{
                  comp.TabData.detailReviewer.tableData.push(comp.getNewTableItemObject(kycApplication,roleName,status))
                });
                break;

              case GlobalAttributes.roles.CALL_AGENT:

                console.log("updating : CALL_AGENT ... - status "+ status)

                comp.TabData.callAgent.tableData = [] // make as empty, before add new data
                comp.TabData.callAgent.columns = comp.getTabColumns(GlobalAttributes.roles.CALL_AGENT,status) // update columns

                // add new data
                response.data.filtered_exceptional_approval_applications.forEach(kycApplication =>{
                  comp.TabData.callAgent.tableData.push(comp.getNewTableItemObject(kycApplication,roleName,status))
                });
                break;

              case GlobalAttributes.roles.AUTHORIZER:

                console.log("updating : AUTHORIZER ... - status "+ status)

                comp.TabData.authorizer.tableData = [] // make as empty, before add new data
                comp.TabData.authorizer.columns = comp.getTabColumns(GlobalAttributes.roles.AUTHORIZER,status) // update columns

                // add new data
                response.data.filtered_exceptional_approval_applications.forEach(kycApplication =>{
                  comp.TabData.authorizer.tableData.push(comp.getNewTableItemObject(kycApplication,roleName,status))
                });
                break;

            }

            comp.isLoading = false

            if(typeof callback === "function"){
              callback()
            }

          }
          else{
            //BuefyHelper.showToastMessage("Unable to filter the data","is-danger")

            console.error("Failed to filter data")
            console.error(response.data.message);


            comp.isLoading = false

            if(typeof callback === "function"){
              callback()
            }
          }
        }
      );
    },




    /***
     * This function is a factory method, which can be used to get new instance of TableDataItem object which relevant to the given role and status
     * @param kycApplication
     * @param role
     * @param status
     * @returns {PendingExApprovalDetailReviewerTableDataItem|GrantedExApprovalDetailReviewerTableDataItem|DeniedExApprovalDetailReviewerTabDataItem|PendingExApprovalCallAgentTableDataItem|GrantedExApprovalCallAgentTableDataItem|DeniedExApprovalCallAgentTableDataItem|PendingExApprovalAuthorizerTableDataItem|GrantedExceptionalApprovalAuthorizerTableDataItem|DeniedExApprovalAuthorizerTableDataItem}
     */
    getNewTableItemObject(kycApplication,role,status){

      const comp = this
      let returnTableItemObject;

      switch (role){

        // DETAIL REVIEWER
        case GlobalAttributes.roles.DETAIL_REVIEWER:
          switch (status){
            case GlobalAttributes.ExceptionalApprovalStatus.Pending:
              returnTableItemObject = new PendingExApprovalDetailReviewerTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.received_date
              );
              break;

            case GlobalAttributes.ExceptionalApprovalStatus.Granted:
              returnTableItemObject = new GrantedExApprovalDetailReviewerTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.received_date,
                  kycApplication.granted_date,
              );
              break;

            case GlobalAttributes.ExceptionalApprovalStatus.Denied:
              returnTableItemObject = new DeniedExApprovalDetailReviewerTabDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.received_date,
                  kycApplication.denied_date,
              );
              break;
          }
          break;

        // CALL AGENT
        case GlobalAttributes.roles.CALL_AGENT:
          switch (status){
            case GlobalAttributes.ExceptionalApprovalStatus.Pending:
              returnTableItemObject = new PendingExApprovalCallAgentTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.scheduled_time,
                  kycApplication.video_call_completed_date,
                  kycApplication.received_date
              );
              break;

            case GlobalAttributes.ExceptionalApprovalStatus.Granted:
              returnTableItemObject = new GrantedExApprovalCallAgentTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.scheduled_time,
                  kycApplication.granted_date,
                  kycApplication.received_date
              );
              break;

            case GlobalAttributes.ExceptionalApprovalStatus.Denied:
              returnTableItemObject = new DeniedExApprovalCallAgentTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.scheduled_time,
                  kycApplication.denied_date,
                  kycApplication.received_date
              );
              break;
          }
          break;

        // AUTHORIZER
        case GlobalAttributes.roles.AUTHORIZER:
          switch (status){
            case GlobalAttributes.ExceptionalApprovalStatus.Pending:
              returnTableItemObject = new PendingExApprovalAuthorizerTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.received_date
              );
              break;

            case GlobalAttributes.ExceptionalApprovalStatus.Granted:
              returnTableItemObject = new GrantedExceptionalApprovalAuthorizerTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.received_date,
                  kycApplication.granted_date,
              );
              break;

            case GlobalAttributes.ExceptionalApprovalStatus.Denied:
              returnTableItemObject = new DeniedExApprovalAuthorizerTableDataItem(
                  kycApplication.kyc_form_id,
                  kycApplication.reference_number,
                  kycApplication.nic_eic_number,
                  kycApplication.full_name,
                  kycApplication.gender,
                  kycApplication.email,
                  comp.getBranchNameById(kycApplication.branch_id),
                  kycApplication.assigned_user,
                  kycApplication.status,
                  kycApplication.received_date,
                  kycApplication.denied_date,
              );
              break;
          }
          break;
      }
      return returnTableItemObject;
    },



    /**
     * this function can be used to get branch name of a branch filtered by branch id
     * @param id
     * @returns {[]|*|string}
     */
    getBranchNameById(id){
      const comp = this
      let filteredBranch =  comp.$root.formDataSupport.branchesArray.find(branch=>branch.id === id)

      if(filteredBranch !== undefined){
        return filteredBranch.branch_name
      }
      else {
        return ""
      }
    },



    /**
     * This function is used to change the page title once the current active tab changes, based on the selected "status" dropdown value
     * @param tab
     */
    getFilterSelectedStatusByTab(tab){
      const  comp = this
      let selectedExceptionalApprovalStatus ; // pending, granted, or denied

      console.log("requested tab : ",tab)

      switch(tab){
        case 0:
          // detail reviewer
         selectedExceptionalApprovalStatus =  comp.FilterConfigData.detailReviewer.fields.find(field => field.name === "Status")
          break;
        case 1:
          // call agent
          selectedExceptionalApprovalStatus =  comp.FilterConfigData.callAgent.fields.find(field => field.name === "Status")
          break;
        case 2:
          // authorizer
          selectedExceptionalApprovalStatus =  comp.FilterConfigData.authorizer.fields.find(field => field.name === "Status")
          break;
        default:
          console.error("invalid tab identifier : "+tab)
          break;
      }

      console.log("selected tab filter status",selectedExceptionalApprovalStatus.selectedValue)
      comp.changePageTitle(selectedExceptionalApprovalStatus.selectedValue)
    },


    /***
     * This function can be used to change the page title when selected status change
     * @param status
     */
    changePageTitle(status){
      const comp = this

      switch (status){
        case GlobalAttributes.ExceptionalApprovalStatus.Pending:
          comp.pageName = "Pending Exceptional Approval"
          break

        case GlobalAttributes.ExceptionalApprovalStatus.Granted:
          comp.pageName = "Granted Exceptional Approval"
          break

        case GlobalAttributes.ExceptionalApprovalStatus.Denied:
          comp.pageName = "Denied Exceptional Approval"
          break
      }
    },


    /**
     * This function can be used to get table columns with respect to the role and status using for
     * @param role role type
     * @param status status
     * @returns {[{date: boolean, hide: boolean, field: string, id: number, label: string},{date: boolean, hide: boolean, field: string, id: number, label: string},{date: boolean, hide: boolean, field: string, id: number, label: string},{date: boolean, hide: boolean, field: string, id: number, label: string},{date: boolean, hide: boolean, field: string, id: number, label: string},null,null,null,null,null,null]}
     */
    getTabColumns(role,status){
      const comp = this
      let outputColumns;

      switch (status){

        case GlobalAttributes.ExceptionalApprovalStatus.Pending:

          switch (role){
            case GlobalAttributes.roles.DETAIL_REVIEWER:
              outputColumns = comp.columnDetails.pendingExceptionalApproval.detailReviewer;
              break;
            case GlobalAttributes.roles.CALL_AGENT:
              outputColumns = comp.columnDetails.pendingExceptionalApproval.callAgent;
              break;
            case GlobalAttributes.roles.AUTHORIZER:
              outputColumns = comp.columnDetails.pendingExceptionalApproval.authorizer;
              break;
          }
          break;

        case GlobalAttributes.ExceptionalApprovalStatus.Granted:

          switch (role){
            case GlobalAttributes.roles.DETAIL_REVIEWER:
              outputColumns = comp.columnDetails.grantedExceptionalApproval.detailReviewer;
              break;
            case GlobalAttributes.roles.CALL_AGENT:
              outputColumns = comp.columnDetails.grantedExceptionalApproval.callAgent;
              break;
            case GlobalAttributes.roles.AUTHORIZER:
              outputColumns = comp.columnDetails.grantedExceptionalApproval.authorizer;
              break;
          }
          break;

        case GlobalAttributes.ExceptionalApprovalStatus.Denied:

          switch (role){
            case GlobalAttributes.roles.DETAIL_REVIEWER:
              outputColumns = comp.columnDetails.deniedExceptionalApproval.detailReviewer;
              break;
            case GlobalAttributes.roles.CALL_AGENT:
              outputColumns = comp.columnDetails.deniedExceptionalApproval.callAgent;
              break;
            case GlobalAttributes.roles.AUTHORIZER:
              outputColumns = comp.columnDetails.deniedExceptionalApproval.authorizer;
              break;
          }
          break;
      }

      return outputColumns;
    },


    /**
     * This function is used to reload the data when application is being assign to a user
     */
    refreshTableData(){
      const comp = this
      comp.loadInitialData()
    },


    /**
     * This function is used to tack changing tabs
     * @param tab
     */
    tabChanged(tab){
      const comp = this
      comp.getFilterSelectedStatusByTab(tab)

    },

    /***
     * This function is used to load initial filter options for the filter
     * @param callback
     */
    loadInitialFilterOptions(callback){
      const comp = this

      comp.showFullScreenLoader()

      comp.getBranchFilterOptions(()=>{
        comp.getUserFilterOptions(GlobalAttributes.roles.DETAIL_REVIEWER,()=>{
          comp.getUserFilterOptions(GlobalAttributes.roles.CALL_AGENT,()=>{
            comp.getUserFilterOptions(GlobalAttributes.roles.AUTHORIZER,()=>{
              comp.getStatusFilterOptions()
              comp.initialFilterConfigIsLoaded = true
              comp.hideFullScreenLoader()

              callback()
            })
          })
        });
      });
    },


    /**
     * This function is used to load initial table data for each tab. (PENDING status only)
     */
    loadInitialData(){
      const comp = this
      comp.showFullScreenLoader()

      // initially load all pending status applications
      comp.getFilteredApplications(null,null,0,0,GlobalAttributes.roles.DETAIL_REVIEWER,GlobalAttributes.ExceptionalApprovalStatus.Pending,()=>{
        comp.getFilteredApplications(null,null,0,0,GlobalAttributes.roles.CALL_AGENT,GlobalAttributes.ExceptionalApprovalStatus.Pending,()=>{
          comp.getFilteredApplications(null,null,0,0,GlobalAttributes.roles.AUTHORIZER,GlobalAttributes.ExceptionalApprovalStatus.Pending,()=>{
            comp.hideFullScreenLoader()
          })
        })
      })


    },


    
  },

  // Life cycle hooks ==================================================================================================
  created() {
    const comp = this

    // listening to global events

    // this was used to change the page title when current tab's selected "Status" dropdown value changed
    GlobalEventManager.$on(GlobalEvents.admin.adminFilterDropdownChanged,(selectedFilterDetails)=>{
      console.log("selected filter ")
      console.log(selectedFilterDetails)

      // based on selected status, update current page name
      if(selectedFilterDetails.filterOptionName === "Status"){
       comp.changePageTitle(selectedFilterDetails.filterOptionValue)
      }
    })
  },

  mounted() {

    this.loadInitialFilterOptions(()=>{
      this.loadInitialData()
    })


    if(this.$root.isSideBarHidden ){
      this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
    }
  },
};
</script>
