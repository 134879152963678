<template>
  <div style="height: 80.5vh; overflow: hidden !important;">
    <!--    un comment following lines -->
    <!-- <div class="mt-6">
      <SearchBar page="New Applications" />
    </div> -->
    <div class="columns" style="margin-top: 2.8vh; margin-left: 1.1vw;">
      <div class="column">
        <label
          style="
            font-family: Gotham-Medium;
            color: gray;
            font-weight: 400;
            font-size: 23px;
          "
          >Application Status</label
        >
      </div>
      <div class="column">
        <b-input
          placeholder="Search..."
          rounded
          type="search"
          icon="magnify"
          icon-clickable
          v-model="searchKeyword"
          style="width: 23.4vw"
        >
        </b-input>
      </div>
    </div>

    <!-- <div>
      <b-field label="Filter Branch">
        <div class="flex-container" style="margin : auto">
          <b-input rounded @input="searchBranchData(branchName)" v-model="branchName">

          </b-input>
        </div>
        </b-field>
    </div> -->
    <div class="wrapper">
      <label>Branch</label>
      <b-field>
        <div class="flex-container">
          <div>
            <b-autocomplete
                rounded
                v-model="branchName"
                :data="filteredDataArray"
                placeholder="Search or Select a Branch"
                icon="magnify"
                :open-on-focus="true"
                style="width: 25vw;"
                @select="branch => (selectedBranch = branch )"
            >
              <template #empty>No results found</template>
            </b-autocomplete>
          </div>
          <div style="margin-left: 1vw;">
            <b-button type="is-primary" @click="searchBranch()">Filter</b-button>
          </div>
          <div style="margin-left: 1vw;">
            <b-button type="is-primary" outlined @click="clearSearch()">Clear</b-button>
          </div>
        </div>
      </b-field>
    </div>

    <TableComp v-if="initialFilterConfigIsLoaded"
        :tableData="tableinfo.searchData(filteredTableData, searchableFields, searchKeyword)"
        :columns="columnDetails"
        :previousPage="currentPage"
        :statusToAssign="statusToBeAssigned"
        :searchableFields="searchableFields"
        :searchKeyword="searchKeyword"
      styles="mt-4 mx-6"
    />
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import FilterBranch from "../../components/FilterBranch.vue";
import { filterTableData } from "../../helpers/filterTableData";
import NetworkManager from "@/network";
// import { ApiRequestManager } from "../../network/apiRequestManager";
import { BranchFilterOption } from "../../helpers/classes/admin/dataFilterTable/branchFilterOption"; 
import { GlobalEventManager } from "../../helpers/globalEventManager";
import {
  ApplicationStatusListTableDataItem
} from "../../helpers/classes/applicationStatusList/applicationStatusListTableDataItem";
import {GlobalFunctions} from "../../helpers/globalFunctions";

export default {
  components: {
    TableComp,
    SearchBar,
    FilterBranch,
  },
  computed:{
    filteredArray() {
      return this.branchList.map(item => item.value);
    },
    filteredDataArray() {
        return this.filteredArray.filter((option) => {
            return option
                .toLowerCase()
                .indexOf(this.branchName.toLowerCase()) >= 0
        })
    }
  },
  data() {
    return {
      initialFilterConfigIsLoaded: false,
      selected:'',
      searchnow:false,
      branchName:'',
      branchList :  [],
      filteredBranchList : [],
      showVideoCallModel: false,

      searchableFields: [
          "reference_number",
          "nic_eic_number",
          "full_name",
          "email",
          "mobile_number",
          "created_at",
          "current_status",
          "branch",
          "gender",
          "agent"
      ],

      searchKeyword: "",
      tableDetails: [],
      filteredTableData:[],
      tableinfo: "",
      applicationList: [],
      selectedBranch : "",

      currentPage: "APPLICATION-STATUS-CHECK",
      statusToBeAssigned: "",
      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "gender", label: "Gender" ,date:false},
        { id: 5, field: "email", label: "Email", date: false },
        { id: 6, field: "mobile_number", label: "Contact", date: false },
        { id: 7, field: "branch", label: "Branch", date: false },
        { id: 8, field: "agent", label: "Assigned User", date: false },
        { id: 9, field: "current_status", label: "Current State", date: false },
        { id: 10, field: "created_at", label: "Created Date", date: true },
        { id: 11, field: "kyc_approved_at", label: "Approved Date", date: true },
        { id: 12, field: "action", label: "Action",date:false },
      ],
    };
  },
  created() {
    this.tableinfo = filterTableData;
  },

  methods: {
    searchBranch(){
      const comp= this

      console.log("Selected branch : ",comp.selectedBranch)
      comp.searchKeyword = ''

      if(comp.selectedBranch !== ""){
        let filteredForms =[]

        comp.tableDetails.forEach(kycForm => {
          console.log(kycForm)
          if(kycForm.branch === comp.selectedBranch){
            filteredForms.push(kycForm)
          }
        })

        comp.filteredTableData = filteredForms
      }
    },

    clearSearch(){
      const comp= this
      comp.searchKeyword = ''
      comp.branchName =''
      comp.filteredTableData = comp.tableDetails
    },

    searchBranchData(value){
      this.selected = value
    },
    GetApplicationData: function () {
      const comp = this;
     

      comp.showFullScreenLoader();
      NetworkManager.apiRequest(
        "api/KycApplication/getAllApplications",
        {},
        function (e) {

          console.log("all application response")
          console.log(e)

          if (e.statusCode === 200) {
            console.log(e)
            // comp.tableDetails = (e.data.applications);
            comp.tableDetails = comp.getTableDataObjects(e.data.applications)
            comp.filteredTableData = comp.tableDetails
          }

          comp.hideFullScreenLoader();
        }
      );
    },

    /**
     * This function can be used to get the table data items
     * @param applications received kyc applications from the backend
     * @returns {[]} array of table data
     */
    getTableDataObjects(applications){
      const comp = this
      let tableData =[]

      applications.forEach(app=> {
        tableData.push(new ApplicationStatusListTableDataItem(
            app.kyc_form_id,
            app.reference_number,
            app.nic_eic_number,
            app.full_name,
            app.email,
            app.mobile_number,
            app.branch,
            app.agent,
            app.current_status,
            app.created_at,
            GlobalFunctions.checkKycApproved(app.current_status) ? app.status_updated_at : "",
            app.gender
            )
        )
      })

      return tableData;
    },

    /**
     * This function is used to add branch filter options to filterDetails array
     * @returns {Promise<void>}
     */
     async getBranchFilterOptions() {
      const comp = this;
      let branchFilterOptions = [];
      comp.showFullScreenLoader();

      await comp.waitUntilBranchListLoaded();

      console.log("after waiting for branch list...");

      comp.$root.formDataSupport.branchesArray.forEach((branch) =>
        branchFilterOptions.push(
          new BranchFilterOption(branch.id, branch.branch_name)
        )
      );
      comp.branchList = branchFilterOptions
      // console.log("comp.branchList",comp.branchList)

      comp.initialFilterConfigIsLoaded = true;
      comp.hideFullScreenLoader();
    },

    /**
     * This function is used to wait until the branch list was loaded completely
     * @returns {Promise<unknown>}
     */
    waitUntilBranchListLoaded() {
      const comp = this;

      return new Promise((resolve) => {
        const checkBranchListLoaded = () => {
          if (comp.$root.formDataSupport.branchesArray.length > 0) {
            console.log("branch list loaded ...");
            resolve();
          } else {
            console.log("waiting for branch list...");
            setTimeout(checkBranchListLoaded, 100);
          }
        };

        checkBranchListLoaded();
      });
    },

  },
  mounted() {

    this.GetApplicationData()
    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
    this.getBranchFilterOptions();
  },
};
</script>

<style scoped>
.wrapper{
  margin-left: 20px;
  margin-bottom: 10px;
}
</style>
