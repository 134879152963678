
/*
This file can be used to define globally used attributes
* */
const GlobalAttributes = {

    kycFormStatus : {
        DRAFT : "DRAFT",
        NEW : "NEW",
        ASSIGNED_DETAIL_REVIEWER : "ASSIGNED-DETAIL-REVIEWER",
        SUBMITTED_CALL_AGENT : "SUBMITTED-CALL-AGENT",
        REFER_TO_BRANCH_PENDING : "REFER-TO-BRANCH-PENDING",
        REJECTED : "REJECTED",
        HOLD : "HOLD",
        SUBMITTED_EXCEPTIONAL_APPROVER : "SUBMITTED-EXCEPTIONAL-APPROVER",
        ASSIGNED_CALL_AGENT : "ASSIGNED-CALL-AGENT",
        VIDEO_CALL_COMPLETED : "VIDEO-CALL-COMPLETED",
        SUBMITTED_APPROVER : "SUBMITTED-APPROVER",
        ASSIGNED_APPROVER : "ASSIGNED-APPROVER",
        APPROVED : "APPROVED",
        RETURNED_CALL_AGENT : "RETURNED-CALL-AGENT",
        RETURNED_DETAIL_REVIEWER : "RETURNED-DETAIL-REVIEWER",
        ASSIGNED_EXCEPTIONAL_APPROVER : "ASSIGNED-EXCEPTIONAL-APPROVER",
        EXCEPTIONAL_APPROVER_APPROVED : "EXCEPTIONAL-APPROVER-APPROVED",
        EXCEPTIONAL_APPROVER_REJECTED : "EXCEPTIONAL-APPROVER-REJECTED",
        EXCEPTIONAL_APPROVER_RETURNED : "EXCEPTIONAL-APPROVER-RETURNED",
        REFER_TO_BRANCH_REVIEWED : "REFER-TO-BRANCH-REVIEWED",
        DEBIT_CARD_PENDING : "DEBIT-CARD-PENDING",
        DEBIT_CARD_REVIEWED : "DEBIT-CARD-REVIEWED",
        FCM_REJECTED :"FCM-REJECTED",
        T24_CIF_PENDING :"T24-CIF-PENDING",
        T24_CIF_ERROR : "T24-CIF-ERROR",
        T24_SIGN_CREATE_ERROR : "T24-SIGN-CREATE-ERROR",
        T24_SIGN_UPLOAD_ERROR : "T24-SIGN-UPLOAD-ERROR",
        T24_ACC_CREATION_ERROR : "T24-ACC-CREATION-ERROR",
        T24_CIF_OVERRIDES : "T24-CIF-OVERRIDES",
        T24_CORRECTED_OVERRIDES : "T24-CORRECTED-OVERRIDES",
        MANUALLY_REJECTED_FROM_CIF_ACC_FLOW : "MANUALLY-REJECTED-FROM-CIF-ACC-FLOW"
    },

    roles:{
        ADMIN :"Admin",
        DETAIL_REVIEWER :"Detail Reviewer",
        CALL_AGENT :"Call Agent",
        AUTHORIZER :"Authorizer",
        EXCEPTIONAL_APPROVER :"Exceptional Approver",
        BRANCH_USER :"Branch User",
        AUDIT_USER :"Audit User",
    },

    ExceptionalApprovalStatus : {
        Pending : 0,
        Granted : 1,
        Denied : 2
    },
}

export {GlobalAttributes}