<template>
  <div>
    <div class="background">
    </div>
    <div class="nic-ocr-result-wrapper">
      <div class="header">
         AMS Results 
        <b-button
            id="btn-close"
            type="is-primary"
            outlined icon-right="close"
            @click="emitClose"/>
      </div>
      <div class="body">
        <div size="is-small" type="is-toggle-rounded" position="is-centered" class="custom-tabs">
          <div label="AMS Results" icon="card-account-details">
          
            <div class="tab-item-wrapper">
              <div class="not-available"  v-if="!amsData || amsData.length === 0">
                No Data Available
              </div>
              <div v-else>
                <div>NO. of Sources : {{ amsData.length }}</div>
                <br>
                 <!-- Iterate over each object in pepData array -->
              <div v-for="(amsData, index) in amsData" :key="index">
                <div class="table-container" style="margin-bottom: 20px;">
                  <div>Source : {{ index + 1 }}</div>
                <table class="data-table">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{{ amsData.name ? amsData.name : 'N/A'}}</td>
                    </tr>
                     <tr>
                      <td>Tags</td>
                      <td>{{ amsData.tags ? amsData.tags.join(', ') : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Heading</td>
                      <td>{{  amsData.heading ? amsData.heading : 'N/A' }}</td>
                    </tr>
                     <tr>
                      <td>Summary</td>
                      <td>{{ amsData.summary ? amsData.summary :  'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Content</td>
                      <td>{{ amsData.content ? amsData.content : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Source</td>
                      <td>{{ amsData.source ? amsData.source : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Url</td>
                      <td>{{ amsData.url ? amsData.url : 'N/A' }}</td>
                    </tr>
                    <tr>
                    <td>Publish Date</td>
                      <td>{{  amsData.publish_date ? amsData.publish_date : 'N/A' }}</td>
                    </tr>
                  </tbody>
                </table>
                <hr>
              </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>

</template>

<script>
import {NicOcrResultDTO} from "../../helpers/classes/dtos/nicOcrResultDTO";
import TwoWayTable from "../Table/TwoWayTable.vue";
import NetworkManager from "../../network";
import { BuefyHelper } from "../../helpers/bufeyHelper";



export default {
  name: "PepSanctionResults",
    props : ["fullName", "currentDate", "amsData"],

  components:{
    TwoWayTable,
  },

  data(){
    return {
      buttonDisabled: false,

    }
  },

  methods:{
    emitClose(){
      this.$emit("close")
    },

  }
}
</script>


<style scoped>
.nic-ocr-result-wrapper{
  background-color: white;
  z-index: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  height:60vh;
  width:60vw;
  min-height: fit-content;
  border-radius: 22px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}

.background{
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7a7a7a;
  opacity: 0.3;
}

.header{
  height: 50px;
  padding: 30px;
  color: #0a0a0a !important;
  display: flex;
  justify-content: center;
  font-size: 22px;
}

.body{
  display: flex;
  align-items: center;
  justify-content: center;
}

#btn-close{
  position: absolute;
  top: 5px;
  right: 5px;
}

.not-available{
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.tab-item-wrapper{
  margin-top: 5vh;
}

.custom-tabs{
  width: calc(60vw - 10vw)
}

.data-table {
  width: 100%;
}

.data-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.data-table td:first-child {
  font-weight: bold;
  width: 30%;
}

.table-container {
  max-height: 300px; 
  overflow-y: auto; 
}

.filter-item{
  min-width: min-content;
  max-width: 200px;
  margin-top : 1px;
}


</style>