<template>
  <ValidationObserver v-slot="{ invalid }">
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="container" 
      rounded style="width: 39vw;
      background-color: white;
      border: 2px solid #000000;
      border-radius: 25px;
      padding: 20px;">
      <div>
        <div class="columns is-7 is-variable" style="width: 39vw;">
          <div class="column is-three-fifth" style=" text-align: center;">
            <label style="color: #04c3fc; font-size: 22px;">Exceptional Approver</label>
          </div> 
          <div>
            <b-button @click="emitCloss" type="is-primary" outlined>x</b-button>
          </div> 
        </div> 
      </div>
        
      <div class="message-box-body">
        Add Comment/s for internal use
        <ValidationProvider
          name="Internal Comment"
          :rules="validateComment()"
          v-slot="{errors}">
          <b-input class="rounded-input"
            maxlength="400" 
            v-model="EA_internalcomment"
            @input="emitEAinternalcomment"
            type="textarea" 
            placeholder="Specify" 
            rows = 4
            rounded
            style="width: 100%;
            margin-top: 10px;" 
          ></b-input> 
          <span class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <b-field grouped position="is-centered">
        <b-button style=" width: 27vw;" type="is-primary" :disabled="invalid" @click="emitEAexceptional_Approver">Submit</b-button>
      </b-field>
    </div>
  <!-- </b-modal>  -->
  </ValidationObserver>
</template>

<script>
import NetworkManager from "@/network";
export default {
  name: 'ExceptionalApprover',
  props: {
    title: String,
    message: String,
    show: Boolean,
  },

  emits:['close'],

  data(){
    return{
      EA_customercomment :'',
      EA_internalcomment:'',
      isvisible : true,
      EA_isButtonDisabled: false,
      EA_showMessageBox: false,
      tableDetails:[],
    }
  },
  methods: {
    emitEAcustomercomment() {
      this.$emit('update_EA_customer_comment', this.EA_customercomment);
    },
    emitEAinternalcomment() {
      this.$emit('update_EA_internal_comment', this.EA_internalcomment);
    },
    emitEAexceptional_Approver() {
      let comments = {
        commentForCustomer : this.EA_customercomment,
        commentForInternal : this.EA_internalcomment
      }

      this.$emit('exceptional_Approver',comments);
    },
    emitCloss(){
      this.$emit('Closs_EA',this.EA_showMessageBox)
    },
    validateComment: function () {
      const obj = {
        required: false,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
  },
};
</script>

<style>
.rounded-input .input,
.rounded-input textarea {
  border-radius: 25px;
}
</style>
