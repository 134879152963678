<template>
    <div class="container container-background"  rounded >
      <!-- spiral loader -->
      <b-loading :is-full-page="false" v-model="enableLoader" :can-cancel="false"></b-loading>

      <div class="header">
        <label style="color: #04c3fc; font-size: large;">Video Call</label>
          <div class="column" style="margin-left: 86%; position: absolute;">
            <b-button @click="emitRejectCall()" type="is-primary" outlined>x</b-button>
          </div>
      </div>
      <hr id="divider">

        <div class="body-content">
            <div style="margin-bottom: 8px" >You have a new video call request</div>
            <div>Customer Name :  <span class="emphasise">{{customerName}}</span></div>
            <div>Scheduled Time : <span class="emphasise">{{scheduledTime}}</span></div>
        </div>

        <div class="button-area">
          <b-button class="action-btn" @click="emitRejectCall()" type="is-primary" outlined>Reject</b-button>
          <b-button class="action-btn" type="is-primary" @click="emitAnswerCall()">Answer</b-button>
        </div>

        <div class="footer-content">
<!--            Do you want to reschedule the meeting?-->
            <span class="link-style" @click="sendAlertToCustomer" >Send Alert</span>
        </div>
    </div>
</template>

<script>

import {GlobalEventManager} from "../helpers/globalEventManager";
import {GlobalEvents} from "../helpers/globalEvents";
import {BuefyHelper} from "@/helpers/bufeyHelper";
import NetworkManager from "@/network";

export default {
    name:"IncomingCustomerCall",
    props: {
      customerName : String,
      scheduledTime : String,
      customerSessionToken : String,
      kycFormId : Number,
    },
    components :{

    },
    data() {
        return{
            isvisible : true,
            Call_showMessageBox: false,
            tableDetails:[],
            enableLoader:false,
        }
    },
    methods :{

        emitRejectCall() {
          const comp = this
          GlobalEventManager.$emit(GlobalEvents.agentSide.rejectVideoCall,comp.customerSessionToken)
        },

        emitAnswerCall() {
          const comp = this
          GlobalEventManager.$emit(GlobalEvents.agentSide.answerVideoCall,comp.customerSessionToken)
        },

      /**
       * =================================================================================================================
       * A function that can be used to send an alert to the next assigned customer
       */

      sendAlertToCustomer() {
        const comp = this;

        comp.enableLoader = true

        const requestBody = {
          kycFormID: comp.kycFormId
        }

        console.log("Request Body Here ==========================================================================",requestBody);
        NetworkManager.apiRequest("api/VideoCall/alertToNextAssignedCaller", requestBody, (response) => {


          if (response.statusCode === 200) {
            comp.enableLoader = false

            console.log("Send Alert To User =============================================================")
            BuefyHelper.showToastMessage("Successfully Send Alert To Customer", "is-success")

            // reject call after sending the alert
            comp.emitRejectCall()

          } else {
            BuefyHelper.showToastMessage("Failed Send Alert To Customer", "is-danger")
            console.log("failed to submit the video call data : " + response.data.message)
          }
        })
      }

    },



    mounted()  {
    console.log("Notification in mounted")
    console.log("Kyc Form Id : ",this.kycFormId)
  }
}
</script>

<style scoped>
  .container-background{
    width: 450px;
    border:1px solid black;
    background-color: white;
    border-radius: 25px;
    padding: 20px;
    z-index: 1;
    margin-bottom: 20px;
  }

  #divider{
    background-color: #231F20;
    opacity: 0.3;
    border: none;
    display: block;
    height: 2px;
    margin: 1em;
  }

  .header{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-area{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .action-btn{
    width: 160px;
  }

  .footer-content{
    text-align: center;
    font-size: small;
  }

  .body-content{
    text-align: center;
  }

  .emphasise{
    font-weight: bolder;
  }
</style>