<template>
    <div class="background">
     
        <div class="card nic-card">
          
           <div class="nic-heading">Enter customer NIC</div>
        <!-- <ValidationObserver v-slot="invalid"> -->
          <form action="">
            <!-- <ValidationProvider :rules="{ required: true, multiRegex: [/^([0-9]{9}[Xx||Vv])$/, /^[0-9]{12}$/] }" v-slot="{ errors }"> -->
                <div class="nic-field">
                      <b-field custom-class="change-initial-password-input-label" label="Customer NIC">
                        <b-input
                          rounded
                          v-model="customerNic"
                          placeholder="Enter Customer NIC here">
                        </b-input>
                      </b-field>
                      <span class="has-text-danger" style="font-size: 15px;"></span>
                      <!-- <p class="help is-info" v-if="errors.length">Your password must contain at least one lowercase letter, one uppercase letter, one digit, one special character (@$!%*?&), and be 8-32 characters long.</p> -->
                </div>
                
                    <!-- </ValidationProvider> -->
                    <div class="pull-btn-field">
                    <b-button class="nic-validation" type="is-primary" @click="nicValidateHandler" :disabled="pullBtnActivation" >Pull</b-button>
                    </div>
          </form>
        <!-- </ValidationObserver> -->
          <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
           
        </div>

            <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      :can-cancel=false
      aria-modal>
      <div>
        <!-- <ValidationObserver v-slot="invalid2"> -->
          <form action="">
            <div class="card change-initial-password-modal">
                 
                <div>
                  <div class="columns is-7 is-variable">
                    <div class="column i" style="text-align: center;">
                      <label style="font-size: xx-large;">Enter Authorization Code</label>
                    </div> 
                    <div class="column" style="margin-left: 93%; margin-top: 2vh; position: absolute;">
                      <button
                          type="button"
                          class="delete"
                          @click="isComponentModalActive = false"
                        />
                    </div>
                  </div> 
                </div>
                <hr style="background-color: gray; height: 1.8px; opacity: 0.5" />
                <div class="body change-initial-password-modal-body">
                  <!-- <ValidationProvider :rules="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/ }" v-slot="{ errors }"> -->
                      <b-field custom-class="change-initial-password-input-label" label="Authorization Code">
                        <b-input
                          rounded
                          v-model="otpCode"
                          placeholder="Authorization Code">
                        </b-input>
                      </b-field>
                      <span class="has-text-danger" style="font-size: 15px;"></span>
                      <!-- <p class="help is-info" v-if="errors.length">Your password must contain at least one lowercase letter, one uppercase letter, one digit, one special character (@$!%*?&), and be 8-32 characters long.</p> -->
                    <!-- </ValidationProvider> -->
                
                </div>
                <br>
                <b-field grouped position="is-centered" c>
                    <!-- <b-button
                        label="Close"
                        @click="closewindow()" /> -->
                    <b-button
                      class="change-initial-password-btn"
                      type="is-primary" 
                      @click="otpSendHandler"
                      :disbaled="otpBtnActivation">Verify</b-button>
                </b-field>
            </div>
          </form>
        <!-- </ValidationObserver> -->
      </div>
    </b-modal>
    </div>
</template>

<script>
import NetworkManager from "@/network";
import {useAuthStore} from "@/store/authStore";

export default {
    name : "NICValidationView",
    setup(){
      const authStore = useAuthStore()
      return {authStore}
    },

    data(){
        return{
                isComponentModalActive : false,
                customerNic : "",
                regexOne : /^([0-9]{9}[Xx||Vv])$/,
                regexTwo : /^[0-9]{12}$/,
                otpCode : "",
                otpRegEx : /^[a-zA-Z0-9]{8}$/,
                isLoading : false
               
        }
    },

    computed:{
      pullBtnActivation(){
        if(this.customerNic == ""){
          return true
        }else{
          return false
        }

      },

      otpBtnActivation(){
        if(this.otpCode == ""){
          return true
        }
        else{
          return false
        }
      }

    },

    methods:{
    
      nicValidateHandler(){
        console.log("Hello")
        const comp = this
        comp.isLoading = true
        
        

        if(comp.regexOne.test(comp.customerNic) || comp.regexTwo.test(comp.customerNic)){ 
          
          let data = {nic : comp.customerNic}
          // this.navigateTo("KycFormView")
          NetworkManager.apiRequest("api/central-kyc/check-nic-exits", data,
          function(e){
            if(e.statusCode === 200){
                comp.$buefy.toast.open({
                duration: 5000,
                message: e.data.message,
                type: 'is-success',
                })
                comp.isLoading = false
                
                comp.isComponentModalActive = true
                comp.authStore.customer_nic_number = comp.customerNic
                
            }else if(e.statusCode === 400){

              comp.$buefy.toast.open({
                duration: 5000,
                message: e.data.message,
                type: 'is-danger',
                })
                comp.isLoading = false

            }
          })
          
          }else{
              comp.$buefy.toast.open({
                duration: 5000,
                message: "Invalid NIC Number",
                type: 'is-danger',
                })
                comp.isLoading = false
                
          }
          
      },

      otpSendHandler(){
        const comp = this
        if(comp.otpRegEx.test(comp.otpCode)){
          
          
          let data = {
          otp : comp.otpCode,
          customer_nic : comp.customerNic
        }

        NetworkManager.apiRequest("api/central-kyc/validate-otp", data, 
        function(e){
          if(e.statusCode === 200){
              comp.$buefy.toast.open({
                duration: 5000,
                message: "Authorization Code Validation Success",
                type: 'is-success',
                })
                comp.navigateTo("KycFormView")
          }

          else if(e.statusCode === 400){
              comp.$buefy.toast.open({
                duration: 5000,
                message: e.data.message,
                type: 'is-danger',
                })

          }
        })}else{
            comp.$buefy.toast.open({
                duration: 5000,
                message: "Invalid Authorization Code",
                type: 'is-danger',
                })
        }

       

        

      }
    }
}
</script>

<style scoped>
.background {
  background: url("../../assets/Images/Icons/bg-_2_.png") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  min-height: 100vh;

  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
    .nic-card{
        width: 800px;
        height: 300px;
        border-radius: 10px;
        margin: 200px auto;
    }

    .nic-field{
        width: 500px;
        margin: 50px auto 20px auto ;
    }

    .pull-btn-field{
       width: 300px;
        margin: 0px auto;
    }

    .nic-validation{
        /* margin-left: 570px; */
        margin: 0 auto;
        padding: 15px 25px;
        width: 100%;
    }

    .nic-heading{
        font-size: 35px;
        text-align: center;
        width: 100%;
        margin-top: 20px;

    }

    .modal-card-head{
  background-color: white;
}

.change-initial-password-modal {
  width: 35vw;
  background-color: white;
  border: 2px solid #000000;
  border-radius: 25px;
  padding: 30px;
  z-index: 2;
}

.change-initial-password-input-label{
  font-size: small !important;
}

.change-initial-password-btn{
  width: -webkit-fill-available;
  height: 45px;
  /* margin-top: 5px; */
}
</style>