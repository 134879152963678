var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hide)?_c('div',{staticClass:"agent"},[_c('div',{class:_vm.styles},[_c('ValidationProvider',{attrs:{"name":_vm.fieldName,"rules":_vm.validationRules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{attrs:{"label-position":"inside","custom-class":_vm.vModelVaribale.trim() === '' && !_vm.isLabelFocus
            ? 'custom-float-label-initial'
            : 'custom-float-label'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.label))]},proxy:true}],null,true)},[_c('b-input',{attrs:{"icon-right":_vm.copyable? 'content-copy' : '',"icon-right-clickable":"","type":_vm.type ? _vm.type : `text`,"readonly":_vm.readonly,"disabled":_vm.disabled,"rows":_vm.rows,"cols":_vm.cols,"rounded":"","password-reveal":_vm.type === `password` ? true : false,"maxlength":_vm.maxLength ? _vm.maxLength : ``},on:{"icon-right-click":function($event){return _vm.copyToClipboard(_vm.vModelVaribale)},"focus":() => {
              _vm.isLabelFocus = true;
            },"blur":() => {
              _vm.isLabelFocus = false;
            }},model:{value:(_vm.vModelVaribale),callback:function ($$v) {_vm.vModelVaribale=$$v},expression:"vModelVaribale"}})],1),_c('span',{staticClass:"has-text-danger",staticStyle:{"display":"flex"}},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1068110838)})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }