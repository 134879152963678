

<template>
  <div class="table-comp-wrapper">
    <b-table
      style="
        height: auto ;
        max-height: 100vh"
        v-if="tableData.length >= 0"
        :data="filter.searchData(tableData, searchableFields, searchKeyword)" :class="styles"
        :paginated="tableOptions.isPaginated"
        :per-page="tableOptions.perPage"
        :current-page.sync="tableOptions.currentPage"
        :pagination-simple="tableOptions.isPaginationSimple"
        :pagination-position="tableOptions.paginationPosition"
        :default-sort-direction="tableOptions.defaultSortDirection"
        :pagination-rounded="tableOptions.isPaginationRounded"
        :sort-icon="tableOptions.sortIcon"
        :sort-icon-size="tableOptions.sortIconSize"
        default-sort="id"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :sticky-header="true"
        :page-input="tableOptions.hasInput"
        :pagination-order="tableOptions.paginationOrder"
        :page-input-position="tableOptions.inputPosition"
        :debounce-page-input="tableOptions.inputDebounce">


      <b-table-column
          v-for="column in columns"
          :key="column.id"
          :field="column.field"
          :label="column.label"
          v-slot="props"

      >
        <span v-if="column.field !== `action` && (column.date ) && column.field !== 'video_call_date' && !column.hide">
          {{ getFormattedDateTime(props.row[column.field]) }}
        </span>

        <span v-if="column.field !== `action` && (column.date ) && column.field === 'video_call_date' && !column.hide">
          {{ formatDate(props.row[column.field]) }} {{  }}
          {{ props.row['video_call_time'] }}
        </span>

        <span v-if="column.field !== `action` && (!column.date) && column.field !== `status` && !column.hide">
           {{ props.row[column.field] }}
        </span>

        <span v-if="column.field !== `video_call_time`">
        </span>


        <div v-if="column.field === `action` && !column.hide">
          <b-field>
            <b-select  v-model="assignableUserIdForEachRow[props.row.id].assignableUserId" placeholder="Assign to" rounded size="is-small" @input="assignApplicationToUser(props.row.id)">
              <option v-for="assignableUser in assignableUsers" :key="assignableUser.id" :value="assignableUser.id" v-if="assignableUser.id !== props.row.assigned_user_id">{{assignableUser.username}}</option>

            </b-select>
          </b-field>
        </div>

        <div v-if="column.field === `action-view` && !column.hide && !showApprovedApplicationView">
          <b-field>
            <b-tooltip label="View" type="is-primary">
              <b-button type="is-primary" rounded size="is-small" icon-right="eye" @click="viewKycApplication(props.row.id)" style="width: auto; height: auto;"/>
            </b-tooltip>
          </b-field>
        </div>

        <div v-if="column.field === `action-view` && !column.hide && showApprovedApplicationView">
          <b-field>
            <b-tooltip label="View" type="is-primary">
              <b-button type="is-primary" rounded size="is-small" icon-right="eye" @click="approvedViewKycApplication(props.row.id)" style="width: auto; height: auto;"/>
            </b-tooltip>
          </b-field>
        </div>

        <div v-if="column.field === `action-delete` && !column.hide">
          <b-field>
            <b-tooltip label="Delete" type="is-primary">
            <b-button type="is-primary" rounded size="is-small" @click="deleteModalOpen(props.row)"
                      icon-right="delete" style="width: auto; height: auto;"/>
            </b-tooltip>
          </b-field>
        
        </div>

        <div v-if="column.field === `action-approve` && !column.hide">
          <b-field>
            <b-button type="is-primary" rounded size="is-small" @click="approveModalOpen(props.row)"
                      icon-right="delete" style="width: auto; height: auto;"/>
          </b-field>
        </div>

        <!--        New acton column to common kyc applications list view of admin-->
        <div v-if="column.field === `common-kyc-action-view` && !column.hide && !showApprovedApplicationView">
          <b-field>
            <b-tooltip label="View" type="is-primary">
              <b-button type="is-primary" rounded size="is-small" icon-right="eye" @click="viewCommonKycApplication(props.row.id)" style="width: auto; height: auto;"/>
            </b-tooltip>
          </b-field>
        </div>
        <!--        New acton column to common kyc application list view of admin-->


        <div v-if="column.field === `status` && !column.hide">
          <b-field>
            <b-tag v-if="props.row[column.field] === 'Pending'" type="is-warning is-light">Pending</b-tag>
            <b-tag v-if="props.row[column.field] === 'Granted'" type="is-success is-light">Granted</b-tag>
            <b-tag v-if="props.row[column.field] === 'Denied'" type="is-danger is-light">Denied</b-tag>
            <b-tag v-if="props.row[column.field] === 'Altered'" type="is-warning is-light">Altered</b-tag>
            <b-tag v-if="props.row[column.field] === 'Original'" type="is-success is-light">Original</b-tag>
          </b-field>
        </div>

      </b-table-column>

    </b-table>
    <div class="mt-5 has-text-centered" v-if="tableData.length == 0" style="display: flex; justify-content: center; align-items: center; ">
      <div style=" margin-top: -40% ">
          <span class="icon has-text-grey-lighter is-normal">
               <i class="mdi mdi-information-outline mdi-48px"></i>
          </span>
          <br>
          <!-- <span class="ml-2 has-text-grey-lighter" style="font-size: 20px; margin-top: -170px;">No records to show</span> -->
      </div>
          
      <br>
      
      <br>
      <span class="has-text-grey-lighter">{{$props.customText}}</span>
    </div>

    <UserProfileDeleteModal v-if="showModal" :modelVisible="true" @close-modal="closeModal" @reject-click="rejectApplication" @approve-click="approveApplication"/>
    <UserProfileDeleteModalAction v-if="showDeleteModal" :modelVisible="true" @close-modal="closeDeleteModal" @delete="deleteClick" />
    
  </div>
</template>

<script>
import NetworkManager from "../network";
import {DateTimeManger} from "../helpers/dateTimeManger";
import {useAuthStore} from "../store/authStore";
import ScheduleRescheduleMeeting from "./ScheduleRescheduleMeeting.vue";
import {filterTableData} from "../helpers/filterTableData";
import UserProfileDeleteModal from "./UserProfileDeleteModal";
import {FilteredUserByRole} from "../helpers/classes/admin/filteredUserByRole";
import {BuefyHelper} from "../helpers/bufeyHelper";
import UserProfileDeleteModalAction from "./UserProfileDeleteModalAction";

export default {

  components:
      {
        UserProfileDeleteModal,
        ScheduleRescheduleMeeting,
        UserProfileDeleteModalAction
      },

  props: ["tableData", "columns", "styles","previousPage","statusToAssign","searchableFields","searchKeyword","customText" ,"assignableUserRoleName", "showApprovedApplicationView"],
  // props:{
  //   tableData : Array,
  //   columns : Array,
  //   styles : String,
  //   previousPage : String,
  //   statusToAssign : String,
  //   searchableFields : Array,
  //   searchKeyword : String,
  //   customText : String,
  //   assignableUserRoleName : String,
  //   showApprovedApplicationView : Boolean
  // },

  name: "AdminTableComp",

  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },

  data() {
    return {

      type:'',
      Sh_isButtonDisabled: false,
      Sh_showMessageBox:false,
      Schedule_or_Reschedule:"Reschedule Video Call",
      videoCallTime:'',
      selected:'',
      kycid:'',
      filter:'',
      showModal:false,

      // LR
      assignableUsers :[],
      deleteData:[],
      showDeleteModal: false,
      assignableUserIdForEachRow : {},
      approveData:[],

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: 'is-centered',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,   
      },
    };

  },

  methods:{
    approveModalOpen(data){
      console.log(data,"dataaaaa111111")
      const comp =this
       comp.approveData = data
       comp.showModal = true
    },

    approveApplication(){
      const comp= this
        const  data = {
         id:comp.approveData.id,
         reference_number: comp.approveData.ref_no,
         status : "APPROVE",
      }

      NetworkManager.apiRequest('api/Admin/deleteUserApprove',data, (response)=>{
        if(response.statusCode === 200){
          comp.showModal=false
          comp.$emit("refreshTableData")
          BuefyHelper.showToastMessage("User profile is deleted successfully","is-success")
        }
        else {
          BuefyHelper.showToastMessage("Unable to delete the User profile","is-danger")
        }

      })
    },
    rejectApplication(){
      const comp= this
        const  data = {
         id:comp.approveData.id,
         reference_number: comp.approveData.ref_no,
         status : "REJECT",
      }

      console.log("dataaaaaaaa",data)
      NetworkManager.apiRequest('api/Admin/deleteUserApprove',data, (response)=>{
        if(response.statusCode === 200){
          comp.showModal=false
          comp.$emit("refreshTableData")
          BuefyHelper.showToastMessage("User Profile Deletion has been rejected","is-danger")
        }
        else {
          BuefyHelper.showToastMessage("Unable to delete the User Profile","is-danger")
        }

      })
    },
    deleteModalOpen(data){
       const comp =this
       comp.deleteData = data
       comp.showDeleteModal = true
    },
    closeDeleteModal(){
      const comp =this
       this.showDeleteModal =false
    },
    deleteClick(){
      const comp= this
        console.log("const comp =this",comp.deleteData)
        const  data = {
         reference_number:comp.deleteData.ref_no,
         nic_eic_number: comp.deleteData.nic,
         mobile_number : comp.deleteData.contact,
         email :comp.deleteData.email,
         full_name :comp.deleteData.name
      }

      NetworkManager.apiRequest('api/Admin/storeDeleteUserApplication',data, (response)=>{
        if(response.statusCode === 200){
          comp.showDeleteModal = false;
          comp.$emit("refreshTableData")
          BuefyHelper.showToastMessage("User Profile sent for Dual Authentication","is-danger")
        }
        else {
          BuefyHelper.showToastMessage("Unable to delete the User Profile","is-danger")
        }

      })
    },
    closeModal(){
      this.showModal =false
    },



    Closs_Sh(value){
      this.Sh_showMessageBox = value;
    },


    // reviewClick(id) {
    //   const comp= this
    //   comp.authStore.kyc_form_id=id
    //   comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
    //   comp.$router.push('/applications/view/'+id);
    //
    // },
    //

    viewKycApplication(id){
      const comp= this
      console.log("show kyc application for the ID : "+id)
      comp.authStore.kyc_form_id = id
      comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
      comp.$router.push('/applications/view/'+id);
    },

    // New function to view common kyc application
    viewCommonKycApplication(id){
      const comp= this
      console.log("show common kyc application for the ID : "+id)
      comp.authStore.common_kyc_form_id = id
      comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
      comp.$router.push('/central-kyc');
    },
    // New view function to view common kyc application

    approvedViewKycApplication(id){
      const comp= this
      console.log("show kyc application for the ID : "+id)
      comp.authStore.kyc_form_id = id
      comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
      comp.$router.push('/approvedApplication/view/'+id);
    },

    getFormattedDateTime(date){
      return DateTimeManger.formatDateTime(date)
    },

    formatDate(date){
       return DateTimeManger.formatDate(date)
    },

    assignApplicationToUser:function(kycFormId){
      const comp= this

      console.log("assigning the application to user ======================")
      console.log("kyc form ID "+kycFormId)
      console.log("Assignable user id object for the selected record")
      console.log(comp.assignableUserIdForEachRow[kycFormId])
      console.log("user ID "+comp.assignableUserIdForEachRow[kycFormId].assignableUserId)
      console.log("assigning status : "+comp.statusToAssign)

      const  data = {
        status : comp.statusToAssign,
        id : kycFormId,
        user_id : comp.assignableUserIdForEachRow[kycFormId].assignableUserId
      }

      NetworkManager.apiRequest('api/KycApplication/assignApplication',data, (response)=>{
        if(response.statusCode === 200){
          comp.$emit("refreshTableData")
        }
        else {
          BuefyHelper.showToastMessage("Unable to assign the application","is-danger")
        }

      })

    },

    ReSchedule: function (kyc_form_id){
      const comp=this
      comp.authStore.kyc_form_id=kyc_form_id
      const data = {id: kyc_form_id}
      comp.kycid=kyc_form_id
      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e)
      {
        if(e.statusCode === 200){
          comp.videoCallTime = e.data.kycApplication.video_call_time
          var dateStr = e.data.kycApplication.video_call_date
          comp.selected = new Date(dateStr);
        }
      })

      this.Sh_showMessageBox = true
    },

    update_Sh_customer_comment(value) {
      this.Sh_customer_comment = value;
    },

    update_Sh_internal_comment(value) {
      this.Sh_internal_comment = value;
    },

    sendScheduleMeeting: function ()
    {
      const comp= this
      this.Sh_showMessageBox = false

      const data = {
        id: comp.authStore.kyc_form_id,
        "date": comp.selected,
        "time": comp.videoCallTime,
        "comment_for_customer": comp.Sh_customer_comment,
        "comment_for_internal_use": comp.Sh_internal_comment,
        "is_reschedule": true
      }
      NetworkManager.apiRequest('api/KycApplication/scheduleVideoCall',data, function (e){
        //console.log("send Schedule Meeting",e.data)
        if(e.statusCode === 200){
          comp.Sh_isButtonDisabled = true
          comp.Sh_showMessageBox = true
        }
      })
    },


    getAssignableUsers(){
      const comp = this;
      const requestBody = {
        role_name: comp.assignableUserRoleName
      }

      NetworkManager.apiRequest("api/User/getUserListByRoleName",requestBody,(response)=>{
        console.log(response)
        if(response.statusCode  === 200){
          response.data.users.forEach(user => comp.assignableUsers.push(new FilteredUserByRole(user.id,user.username,user.name)))
        }
      })
    },

    /**
     * This function is used to build dynamic variable objects to store each table row assigning agent data
     * @param kycFormId
     */
    setAssignableUserIdVariableForEachRecord(kycFormId){
      const comp = this
      comp.assignableUserIdForEachRow[kycFormId] = {assignableUserId: null};

    },

  },


  // life cycle hooks
  created() {
    const comp = this
    comp.filter = filterTableData


  },

  mounted(){
    const comp = this
    console.log("table component ")
    console.log("table data ")
    console.log(comp.tableData)

    comp.getAssignableUsers()

    // use kyc form id (td.id) as unique identifier for the assignable user object
    comp.tableData.forEach(td=>{
      comp.setAssignableUserIdVariableForEachRecord(td.id)
    })

    console.log("assignable user id object for the role : "+comp.assignableUserRoleName)
    console.log(comp.assignableUserIdForEachRow)
  },
}

</script>
<style>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(88, 88, 88, 0.5);
  display:grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.scrollable-table {
  max-width: 75vw;
  overflow-x: auto;
}
.table-comp-wrapper {
  overflow: auto !important;
}

</style>
