<template>
  <div>
    <div class="columns is-vcentered px-5">
      <div class="column" style="
                font-family: Gotham-Medium;
                color: gray;
                font-weight: 400;
                font-size: 23px;
              "
              >
        {{ page }}
      </div>
      <div class="column mr-2">
        <b-input
          placeholder="Search..."
          rounded
          type="search"
          icon="magnify"
          icon-clickable
          @icon-click="searchIconClick()"
          style="width:450px"
        >
        
        </b-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: ["page"],
};
</script>

<style scoped></style>
