<template>
  <div>
    <div class="background">
    </div>

    <div class="nic-ocr-result-wrapper">
      <div class="header">
        PEP and Sanction Results 
        <b-button
            id="btn-close"
            type="is-primary"
            outlined icon-right="close"
            @click="emitClose"/>
      </div>
      <div class="body">
        <b-tabs size="is-small" type="is-toggle-rounded" position="is-centered" class="custom-tabs">
       
          <!-- PEP -->
          <b-tab-item label="PEP Results" icon="card-account-details">
              <div class="monitor" position="is-centered">
            <b-button class="is-primary monitor" rounded @click="addToOngoingMonitoring" :disabled="buttonDisabled">Add to Ongoing Monitoring</b-button>
        </div>
            <div class="tab-item-wrapper">
              <div class="not-available"  v-if="!pepData || !pepData.length">
                No Data Available
              </div>
              <div v-else>
                <div>NO. of Sources : {{ pepData.length }}</div>
                <br>
                 <!-- Iterate over each object in pepData array -->
              <div v-for="(pepData, index) in pepData" :key="index">
                <div>Source : {{ index + 1 }}</div>
                <div class="table-container" style="margin-bottom: 20px;">
                <table class="data-table">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{{ pepData.name ? pepData.name : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{{  pepData.address ? pepData.address.join(', ') : 'N/A' }}</td>
                    </tr>
                     <tr>
                      <td>Gender</td>
                      <td>{{ pepData.gender ? pepData.gender :  'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Place of Birth</td>
                      <td>{{ pepData.place_of_birth ? pepData.place_of_birth.join(', ') : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>{{ pepData.date_of_birth ? pepData.date_of_birth : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{{ pepData.country ? pepData.country.join(', ') : 'N/A' }}</td>
                    </tr>
                    <tr>
                    <td>Alias Names</td>
                      <td>{{  pepData.alias_names ? pepData.alias_names.join(', ') : 'N/A' }}</td>
                    </tr>
                      <tr>
                      <td>Family Tree</td>
                      <td>
                        <ul>
                          <li v-for="(members, relationship) in pepData.familytree" :key="relationship">
                            <strong>{{ relationship }}</strong>:
                            <ul>
                              <li v-for="member in members" :key="member">{{ member }}</li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Source Name</td>
                      <td>{{ pepData.source_name ? pepData.source_name :  'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Source Link</td>
                      <td><a :href="pepData.source_link" target="_blank">{{ pepData.source_link ? pepData.source_link : 'N/A'}}</a></td>
                    </tr>
                    <tr>
                      <td>Source Description</td>
                      <td>{{ pepData.source_description ? pepData.source_description : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Nationality</td>
                      <td>{{ pepData.nationality ? pepData.nationality.join(', ') : 'N/A' }}</td>
                    </tr>
                    <tr v-if="index >= 0">
                      <td :rowspan="pepData.designation.length">Designation</td>
                      <td>{{ pepData.designation.designation }}</td>
                    </tr>
                      <!-- Display rest of the designations -->
                      <tr v-for="(designation, dIndex) in pepData.designation.slice(1)" :key="'designation_' + dIndex">
                        <td>{{ designation.designation}}</td>
                      </tr>
                      <tr>
                      <td>Designation PEP Type</td>
                      <td>{{  pepData.pep_type ? pepData.pep_type.join(', ') : 'N/A' }}</td>
                    </tr>
                  </tbody>
                </table>
                <hr>
              </div>
            </div>
                
              </div>
            </div>
          </b-tab-item>

            <!-- Sanction Results -->
          <b-tab-item label="Sanction Data" icon="card-bulleted">
            <div class="tab-item-wrapper">
              <div class="not-available" v-if="!sanctionData || !sanctionData.length">
                No Data Available
              </div>
              <div v-else>
                <div>NO. of Sources : {{ sanctionData.length }}</div>
                <br>
              <div v-for="(sanctionData, index) in sanctionData" :key="index">
                <div class="table-container">
                  <div>Source : {{ index + 1 }}</div>
                <table class="data-table">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{{ sanctionData.name ? sanctionData.name : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{{  sanctionData.gender ? sanctionData.gender : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>{{ sanctionData.date_of_birth ? sanctionData.date_of_birth : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Alias Names</td>
                      <td>{{  sanctionData.alias_names ? sanctionData.alias_names.join(', ') : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Associated Country</td>
                      <td>{{ sanctionData.associated_country ? sanctionData.associated_country.join(', ') : 'N/A'  }}</td>
                    </tr>
                    <tr>
                      <td>Sanctioned Country</td>
                      <td>{{ sanctionData.sanctionedCountry ? sanctionData.sanctionedCountry.join(', ') : 'N/A'  }}</td>
                    </tr>
                    <tr>
                      <td>President</td>
                      <td>{{  sanctionData.president ? sanctionData.president : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Aircraft Details</td>
                      <td>{{ sanctionData.aircraft_details ?  sanctionData.aircraft_details :  'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Source Name</td>
                      <td>{{ sanctionData.source_name ? sanctionData.source_name : 'N/A'}}</td>
                    </tr>
                     <tr>
                      <td>Source Type</td>
                      <td>{{ sanctionData.source_type ? sanctionData.source_type : 'N/A' }}</td>
                    </tr>
                     <tr>
                      <td>Source Link</td>
                      <td><a :href="sanctionData.source_link" target="_blank">{{ sanctionData.source_link ? sanctionData.source_link : 'N/A'}}</a></td>
                    </tr>
                      <tr>
                      <td>Source Description</td>
                      <td>{{  sanctionData.source_description ? sanctionData.source_description : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Zipcode</td>
                      <td>{{ sanctionData.zipcode ? sanctionData.zipcode : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Country of Jurisdiction</td>
                      <td>{{ sanctionData.country_of_jurisdiction ? sanctionData.country_of_jurisdiction : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Headquarters</td>
                      <td>{{ sanctionData.headquarters ? sanctionData.headquarters : 'N/A'}}</td>
                    </tr>
                      <tr>
                      <td>Parent Company</td>
                      <td>{{  sanctionData.parent_company ? sanctionData.parent_company : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Flag State</td>
                      <td>{{ sanctionData.flag_state ? sanctionData.flag_state : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Call Sign</td>
                      <td>{{ sanctionData.call_sign ? sanctionData.call_sign : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Tonnage</td>
                      <td>{{ sanctionData.tonnage ?  sanctionData.tonnage : 'N/A' }}</td>
                    </tr>
                      <tr>
                      <td>Model</td>
                      <td>{{  sanctionData.model ? sanctionData.model : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Name Match Score</td>
                      <td>{{ sanctionData.name_match_score ? sanctionData.name_match_score : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>Overall Data Match score</td>
                      <td>{{ sanctionData.sams_score.overall_score ? sanctionData.sams_score.overall_score : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Matched Address</td>
                      <td>{{ sanctionData.matched_address ?  sanctionData.matched_address : 'N/A' }}</td>
                    </tr>
                  </tbody>
                </table>
                <hr>
              </div>
            </div>
                
              </div>
            </div>
            
          </b-tab-item>

           <b-tab-item label="Customer Risk Rating Results" icon="card-bulleted">
            <div class="tab-item-wrapper">
              <div class="not-available" v-if="!customerRiskRatingData || typeof customerRiskRatingData.risk_rating === 'undefined'">
                  No Data Available
              </div>
              <div v-else>
             
                <div class="table-container">
                <table class="data-table">
                  <tbody>
                  <tr>
                      <td>Risk Type</td>
                      <td>{{ customerRiskRatingData.risk_type ? customerRiskRatingData.risk_type : 'N/A'}}</td>
                    </tr>
                    <tr>
                      <td>Risk Rating</td>
                      <td>{{  customerRiskRatingData.risk_rating ? customerRiskRatingData.risk_rating + "%" : 'N/A' }}</td>
                    </tr>
                    <tr>
                   <td>Risk Rating Result</td>
                   <td>
                    <ul v-if="customerRiskRatingData.risk_rating_result && customerRiskRatingData.risk_rating_result.length">
                        <li v-for="result in customerRiskRatingData.risk_rating_result">
                            <!-- Exclude 'ams' -->
                            <template v-if="result.name !== 'ams'">
                                {{ result.name }}: {{ result.classification }}
                            </template>
                        </li>
                    </ul>
                        <span v-else>N/A</span>
                    </td>
                    </tr>
                  </tbody>
                </table>
                <hr>
               </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>

</template>

<script>
import {NicOcrResultDTO} from "../../helpers/classes/dtos/nicOcrResultDTO";
import TwoWayTable from "../Table/TwoWayTable.vue";
import NetworkManager from "../../network";
import { BuefyHelper } from "../../helpers/bufeyHelper";



export default {
  name: "PepSanctionResults",
    props : ["pepData", "sanctionData", "fullName", "currentDate", "customerRiskRatingData"],

  components:{
    TwoWayTable,
  },



  data(){
    return {
      buttonDisabled: false,

    }
  },

  methods:{
    emitClose(){
      this.$emit("close")
    },

    addToOngoingMonitoring: function () {
      console.log('monitoring innnn');
      const comp = this;
      const data = { name: this.fullName,
                     applicant_id: "uhi4hi-3safdsa3-asdfj",
                     screen_type: "ongoing",
                     search_type: "individual",
                     target_date: this.currentDate,
                     application_id : "uhi4hi-3safdsa3-asdfj",
                     sanction_field : true,
                     legal_field : false,
                     corporate_field : true,
                     pep_field : true,
                     risk_rating_field : true,
                     frequency : 1 };

      comp.showFullScreenLoader();

      NetworkManager.apiRequest(
        "api/PepSanctionResult/getSearchResults",
        data,
        function (e) {
          console.log('e.statusCode', e.statusCode);
          if (e.statusCode === 200) {
            comp.pepSanctionData = e.data;
            comp.pepData = comp.pepSanctionData.peP_Result;
            console.log('peppppp', comp.pepData);
            comp.sanctionData = comp.pepSanctionData.sanctions_Result;
            comp.hideFullScreenLoader();
            comp.showPepSanctionResult = true;
            BuefyHelper.showToastMessage("Successfully added to ongoing monitoring list", "is-success")

            console.log("get pep and sanction data ===============", e.data);
            console.log("get pep  data ===============", comp.pepSanctionData.peP_Result);
            console.log("get sanction  data ===============", comp.pepSanctionData.sanctions_Result);

            comp.buttonDisabled = true;
          }
          else {
            comp.hideFullScreenLoader();
            BuefyHelper.showToastMessage("Unable to get the pep sanction data", "is-danger")
          }
        }
      );
    },
  }


}
</script>


<style scoped>
.nic-ocr-result-wrapper{
  background-color: white;
  z-index: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  height:60vh;
  width:60vw;
  min-height: fit-content;
  border-radius: 22px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}

.background{
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //transform: translate(-50%, -50%);
  background-color: #7a7a7a;
  opacity: 0.3;
}

.header{
  height: 50px;
  padding: 10px;
  color: #0a0a0a !important;
  display: flex;
  justify-content: center;
}

.body{
  display: flex;
  align-items: center;
  justify-content: center;
  //width: calc(60vw - 10vw);
}

#btn-close{
  position: absolute;
  top: 5px;
  right: 5px;
}

.not-available{
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.tab-item-wrapper{
  margin-top: 5vh;
}

.custom-tabs{
  width: calc(60vw - 10vw)
}

.data-table {
  width: 100%;
}

.data-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.data-table td:first-child {
  font-weight: bold;
  width: 30%;
}

.table-container {
  max-height: 300px; 
  overflow-y: auto; 
}

.filter-item{
  min-width: min-content;
  max-width: 200px;
  margin-top : 1px;
}





</style>