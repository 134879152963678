<template>
  <div>
    <div class="columns is-7 is-variable" >
      <div class="column">

        <!-- CIF number -->
        <InputLabel copyable="true"
            v-if="customerCifNumber.trim() !== ''"
            label="CIF Number"
            :value="customerCifNumber"
            class="non-interactable-input"
            styles="mt-2"
            readonly="true"
        />

        <!-- AccountSelection -->
        <div v-for="product in products" :key="product.id">
          <p>
            Product Selection -
              <span>{{ product.account_type.customer_input }}</span>
          </p>
          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="formData.current_status != 'REJECTED'"
            label="Onboarding Account Number"
            :value="product.account_number"
            class="non-interactable-input"
            styles="mt-2"
            readonly="true"
          /> 
          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="product.account_type.customer_input"
            label="Account"
            :value="product.account_type.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField 
          label="Account"
          :values="product.account_type.call_agent_input"
          styles="mt-5"
          :hide="!product.account_type.is_changed_by_call_agent"
          readonly="true"
          class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="product.currency_type.customer_input"
            label="Currency"
            :value="product.currency_type.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />

          <!-- purpose of opening account - customer inputs -->
          <DisplayBulletList class="non-interactable-input" label="Purpose of Account Opening" :items="product.purpose_of_opening_customer_inputs" :is-agent="false"/>

          <!-- purpose of opening account - agent inputs -->
          <DisplayBulletList class="non-interactable-input" v-if="product.purpose_of_opening_account.is_changed_by_call_agent" label="Purpose of Account Opening" :items="product.purpose_of_opening_agent_inputs" :is-agent="false"/>


          <InputLabel copyable="true" placeholder=" -- No data -- "
                  v-if="product.purpose_of_opening_other_specify_customer_inputs !== ''"
                  label="Please Specify the Purpose"
                  readonly="true"
                  :value="product.purpose_of_opening_other_specify_customer_inputs"
                  styles="mt-4"
                  class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                  label="Please Specify the Purpose"
                  :value="product.purpose_of_opening_other_specify_agent_inputs"
                  styles="mt-5"
                  :hide="product.purpose_of_opening_other_specify_agent_inputs === ''"
                  readonly="true"
                  class="non-interactable-input"
              />

                        <!-- Anticipated Volumes of Deposits from above Sources -->

                        <InputLabel copyable="true" placeholder=" -- No data -- " v-if="product.anticipated_volume.customer_input"
            label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
            :value="product.anticipated_volume.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
          label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
          :values="product.anticipated_volume.call_agent_input"
          styles="mt-5"
          :hide="!product.anticipated_volume.is_changed_by_call_agent"
          readonly="true"
          class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="product.initial_deposit_amount.customer_input"
            label="Initial Deposit"
            :value="product.initial_deposit_amount.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
          label="Initial Deposit"
          :values="product.initial_deposit_amount.call_agent_input"
          styles="mt-5"
          :hide="!product.initial_deposit_amount.is_changed_by_call_agent"
          readonly="true"
          class="non-interactable-input"
          />
          <br>
        </div>
        <!-- Branch Details -->
        <div class="mt-6">
          <p>Branch Details</p>
          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="customer_branch.branch_name"
            label="Preferred Branch"
            :value="customer_branch.branch_name"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true"  v-if="agent_branch != null"
          label="Preferred Branch"
          :value="agent_branch.branch_name"
          styles="mt-5"
          :hide="!formData.branch_id.is_changed_by_call_agent"
          readonly="true"
          class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="formData.branch_comment.customer_input"
            label="Reason for opening an account in a branch that is located far from the permanent address"
            :value="formData.branch_comment.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
          label="Reason for opening an account in a branch that is located far from the permanent address"
          :value="formData.branch_comment.call_agent_input"
          styles="mt-5"
          :hide="!formData.branch_comment.is_changed_by_call_agent"
          readonly="true"
          class="non-interactable-input"
          />
        </div>
      </div>
      <div class="column">
        <p>Value Added Services</p>
        <InputLabel copyable="true" placeholder=" -- No data -- "
          label="SMS Alerts"
          :value="formData.vas_sms_alert.customer_input ? 'Yes' : 'No'"
          styles="mt-2"
          readonly="true"
          class="non-interactable-input"
        />
        

        <InputLabel copyable="true" placeholder=" -- No data -- "
          label="Online Banking"
          :value="formData.vas_online_banking.customer_input ? 'Yes' : 'No'"
          styles="mt-2"
          readonly="true"
          class="non-interactable-input"
        />
       <div v-if="formData.residentship.customer_input == 'Sri Lanka'">
         <InputLabel copyable="true" 
           label="Virtual Wallet"
           :value="formData.vas_virtual_wallet.customer_input ? 'Yes' : 'No'"
           styles="mt-5"
           readonly="true"
           class="non-interactable-input"
         />
       </div>

        <InputLabel copyable="true" placeholder=" -- No data -- "
          label="E-Statement"
          :value="formData.vas_estatement.customer_input ? 'Yes' : 'No'"
          styles="mt-5"
          readonly="true"
          class="non-interactable-input"
        /> 

        <!-- Debit card section -->
        <div v-if="GlobalFunctions.checkDebitCardSectionVisible(formData.residentship,products)">
          <InputLabel copyable="true" placeholder=" -- No data -- "
              label="Debit Card"
              :value="formData.vas_debit_card.customer_input ? 'Yes' : 'No'"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
          />
          <AgentInputField copyable="true"
              label="Debit Card"
              :value="formData.vas_debit_card.call_agent_input ? 'Yes' : 'No'"
              styles="mt-5"
              :hide="!formData.vas_debit_card.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
          />


          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="formData.vas_debit_card.customer_input"
                      label="POS Required"
                      :value="formData.vas_debit_card_pos_required.customer_input ? 'Yes' : 'No'"
                      styles="mt-5"
                      readonly="true"
                      class="non-interactable-input"
          />
          <AgentInputField copyable="true"
                           label="POS Required"
                           :value="formData.vas_debit_card_pos_required.call_agent_input ? 'Yes' : 'No'"
                           styles="mt-5"
                           :hide="!formData.vas_debit_card_pos_required.is_changed_by_call_agent"
                           readonly="true"
                           class="non-interactable-input"
          />

          <div class="column">
            <InputLabel copyable="true" placeholder=" -- No data -- " v-if="formData.vas_debit_card_pos_account.customer_input"
                label="Account to be Linked for POS Transaction"
                :value="formData.vas_debit_card_pos_account.customer_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
            />
            <AgentInputField copyable="true"
                             label="Account to be Linked for POS Transaction"
                             :value="formData.vas_debit_card_pos_account.call_agent_input"
                             styles="mt-5"
                             :hide="!formData.vas_debit_card_pos_account.is_changed_by_call_agent"
                             readonly="true"
                             class="non-interactable-input"
            />
          </div>


          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="formData.vas_debit_card.customer_input"
                      label="Name to be Printed on Card"
                      :value="formData.vas_debit_card_name.customer_input"
                      styles="mt-5"
                      readonly="true"
                      class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
                           label="Name to be Printed on Card"
                           :value="formData.vas_debit_card_name.call_agent_input"
                           styles="mt-5"
                           :hide="!formData.vas_debit_card_name.is_changed_by_call_agent"
                           readonly="true"
                           class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="formData.vas_debit_card.customer_input"
                      label="Card Delivery Details"
                      :value="formData.vas_debit_card_collection_details.customer_input"
                      styles="mt-5"
                      readonly="true"
                      class="non-interactable-input"
          />
          <AgentInputField copyable="true" v-if="formData.vas_debit_card_collection_details.is_changed_by_call_agent"
                           label="Card Delivery Details"
                           :value="formData.vas_debit_card_collection_details.call_agent_input"
                           styles="mt-5"
                           :hide="!formData.vas_debit_card_collection_details.is_changed_by_call_agent"
                           readonly="true"
                           class="non-interactable-input"
          />

          <InputLabel copyable="true" placeholder=" -- No data -- " v-if="formData.vas_debit_card.customer_input"
                      label="PIN Delivery Details"
                      :value="formData.vas_debit_card_pin_collection_details.customer_input"
                      styles="mt-5"
                      readonly="true"
                      class="non-interactable-input"
          />
          <AgentInputField copyable="true" v-if="formData.vas_debit_card_pin_collection_details.is_changed_by_call_agent"
                           label="PIN Delivery Details"
                           :value="formData.vas_debit_card_pin_collection_details.call_agent_input"
                           styles="mt-5"
                           :hide="!formData.vas_debit_card_pin_collection_details.is_changed_by_call_agent"
                           readonly="true"
                           class="non-interactable-input"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import InputLabel from "../../../components/InputLabel.vue";
import NetworkManager from "@/network";
import {useAuthStore} from "../../../store/authStore";

import AgentInputField from "../../../components/AgentInputField.vue";
import {GlobalFunctions} from "../../../helpers/globalFunctions";
import DisplayBulletList from "../../../components/DisplayBulletList.vue";

export default {
  name: "AccountDetails",
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    }
  },
  components: {
    DisplayBulletList,
    InputLabel,
    AgentInputField
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data() {
    return {
      formData : [],
      products :[],
      customer_branch:[],
      agent_branch:[],
      customerCifNumber:"",
    };
  },
  methods: {
    GetApprovedApplicationData: function (){
      const comp= this
      const data = {
        id:comp.authStore.kyc_form_id
      }
      console.log("in GetApprovedApplicationData")

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        console.log("in GetApprovedApplicationData",e.data)
        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication  
          comp.products= e.data.productSelection  
            comp.agent_branch=e.data.agentBranch   
            comp.customer_branch=e.data.customerBranch
          comp.customerCifNumber = e.data.customerCIFNumber
          
          // comp.products.forEach(product=>{
          //    product.purpose_of_account_opening_customer=''
          //    product.purpose_of_account_opening_agent=''
          //    product.purpose_of_opening_account.customer_input.forEach(purpose=>{
          //     if(product.purpose_of_account_opening_customer == ''){
          //       product.purpose_of_account_opening_customer = purpose.purpose 
          //     }else{
          //       product.purpose_of_account_opening_customer += ","+purpose.purpose 
          //     }
          //    })
          //    if(product.purpose_of_opening_account.call_agent_input != null){
          //     product.purpose_of_opening_account.call_agent_input.forEach(purpose=>{
          //     if(product.purpose_of_account_opening_agent == ''){
          //       product.purpose_of_account_opening_agent = purpose.purpose 
          //     }else{
          //       product.purpose_of_account_opening_agent += ","+purpose.purpose 
          //     }
          //    })
          //    }
           
          // })  
        }
      })
    },
  },
   mounted() {
    this.GetApprovedApplicationData();
   }
   
};
</script>

<style scoped>
p {
  font-family: "Gotham-Medium";
  color: #231f20;
  font-size: 22px;
}
.non-interactable-input {
  pointer-events: none;
}
</style>
