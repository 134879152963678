<template>
    
    <div style="height: 80.5vh; overflow: auto !important">
     
      <div class="columns" style="margin-top: 2.8vh; margin-left: 1.1vw;">
        <div class="column">
          <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Refer to Branch</label>
        </div>
        <div class="column">
          <b-input
            placeholder="Search..."
            rounded
            type="search"
            icon="magnify"
            icon-clickable
            v-model="searchKeyword"
            style="width:450px">
          </b-input>
        </div>
      </div>
       <TableComp
       :tableData="tableinfo.searchData(tableDetails, searchableFields, searchKeyword)"
        :columns="columnDetails"
        :previousPage="currentPage"
        :statusToAssign="statusToAssign"
        :searchableFields="searchableFields"

       styles="mt-4 mx-6"
      />
         
      
    </div>
  </template>
  
  <script>
  import TableComp from "../../components/TableComp.vue";
  import SearchBar from "../../components/SearchBar.vue";
  import FilterBranch from "../../components/FilterBranch.vue";
  import { filterTableData } from "../../helpers/filterTableData";
  import NetworkManager from "@/network"
  
  export default {
    components: { TableComp, SearchBar, FilterBranch },
    name: "RefertoBranchList",
    data() {
      return {

        searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number'],
        searchKeyword:"",
        tableDetails: "",
        tableinfo:"",
        applicationList :[],

        currentPage:'REFER-TO-BRANCH-PENDING',
        statusToAssign:"REFER-TO-BRANCH-REVIEWED",
  
        columnDetails: [
          { id: 1, field: "reference_number", label: "Ref No." ,date:false},
          { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
          { id: 3, field: "full_name", label: "Name" ,date:false},
          { id: 4, field: "gender", label: "Gender" ,date:false},
          { id: 5, field: "email", label: "Email" ,date:false},
          { id: 6, field: "mobile_number", label: "Contact" ,date:false},
          { id: 7, field: "resident_status", label: "Residence" ,date:false},
          { id: 8, field: "created_at", label: "Sent Date" ,date:true},
          { id: 9, field: "action", label: "Action" ,date:false},
          
        ],
      };
    },

    created() {
      this.tableinfo = filterTableData
    },
    
    methods: {
      GetRefertoBranch: function (){
        const comp= this
        const  data = {
          status:"REFER-TO-BRANCH-PENDING"
        }  
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listAssignedApplicationByStatus',data, function (e){
          if(e.statusCode === 200){
            comp.tableDetails = e.data.applications
          }
  
          // comp.hideFullScreenLoader()
        })
      },
    },
    mounted() {
      this.GetRefertoBranch();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
  };
  </script>
  <style scoped></style>
  