<template>
    <div>
      <ValidationObserver v-slot="{ invalid }">
        <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css"> -->
        <br>
        <div class="card"  style="margin-left: auto;  margin-right: auto; width:fit-content;  border-radius: 25px;  padding: 20px;">
        
                    <template  v-for="(question, index) in videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList" >
                            <!-- <ValidationProvider :name="bbb":rules="{required:true}" v-slot="{errors}"> -->
                            <b-message :type="question.status">
                                <section>
                                    <p class="mt-4" style="font-size: 18px">{{question.question}} <span class="has-text-danger" >*</span></p>

                                </section>
                                <section>
                                    <p class="mt-4" style="font-size: 12px">{{question.answer}}</p>
                                </section>
                
                                <section v-if="(question.status == 'is-danger') " class="pt-2">
                                    <ValidationProvider :rules="{ required: (question.status == 'is-danger') }" v-slot="{errors}">
                                        <b-field >

                                            <b-input rounded
                                                v-model="question.correct_answer" placeholder="Correct Answer" 
                                            ></b-input>
                                        </b-field>
                                        <span class="has-text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </section>
                                <section  class="pt-4" >
                                    <b-button  class="is-rounded mr-2" @click="videoQuestionAnswerSuccess(index)" size="is-small" type="is-success" icon-left="check-bold" outlined>Correct</b-button>
                                    
                                    <b-button class="is-rounded ml-2" @click="videoQuestionAnswerFailed(index)" size="is-small" type="is-danger" icon-left="close-thick" outlined>Incorrect</b-button>
                                </section>

                            </b-message>
                        <!-- </ValidationProvider> -->
                    </template>



          <br>

          <!-- <div>
              <b-button
                class="py-5 mt-4"
                type="is-primary"
                expanded
                rounded
                :loading="isSubmitBtnLoading"
                @click="submitAgentChangedQuestions()"
                :disabled=" invalid  || isSubmitDisabled "
                >Submit</b-button
              >
            </div> -->
         </div>
      </ValidationObserver>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  import {useVideoKycStore} from "../store/videoKycStore";
  import {BuefyHelper} from "../helpers/bufeyHelper";
  import {GlobalEventManager} from "../helpers/globalEventManager";
  import {GlobalEvents} from "../helpers/globalEvents";
  import SelectLabelVue from './SelectLabel.vue';
  
  export default {
    name: 'VerifyQuestions',
  
    components: {
      SelectLabelVue,
    },
  
    props:{
     
    },
  
    setup(){
      const videoKycStore = useVideoKycStore()
      console.log("this",videoKycStore.answeredVideoKycData)
      return {videoKycStore}
    },
//    watch:{
//     "videoKycStore.answeredVideoKycData.kycForm.spoken_language":function(){
//       this.consolelog();
//       this.languageChanged = true;
//     }
//    },
   computed : {
    // isSubmitDisabled() {
    //   // Check if any question's validity is false
    //   return this.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList.some(question => !question.validity);
    // }


   },
  
    data(){
      return{
        isSubmitBtnLoading: false,
       
     
      };
    },
    
    methods :{
        videoQuestionAnswerSuccess: function (index) {
                const comp = this;
                // if (comp.isRecording) {
                    comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList[index].status = 'is-success'
                    comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList[index].correct_answer = null
                    comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList[index].validity = true
                // } else {
                //     comp.$buefy.toast.open({
                //         message: 'Wait for recording started before capturing any data',
                //         type: 'is-warning'
                //     })
                // }
            },

            videoQuestionAnswerFailed: function (index) {
                const comp = this;

                // if (comp.isRecording) {
                    comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList[index].status = 'is-danger'
                    comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList[index].correct_answer = null
                    comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList[index].validity = false
                // } else {
                //     comp.$buefy.toast.open({
                //         message: 'Wait for recording  started before capturing any data',
                //         type: 'is-warning'
                //     })
                // }
            },
       
     

  
      /**
       * This function can be used to make a request to capture a image for the required image container
       * @param imageKey requested image key
       */

  
    },
  
    mounted(){
      const comp = this
    }
  }
  </script>
  
  <style scoped>
   .red-title{
      font-family:Gotham-Medium;
      font-weight: 40;
      font-size: 18px;
      color:#04c3fc;
      text-transform: uppercase;
   }
   .black-title{
    font-family:Gotham-Medium;
    font-weight: 500;
    font-size: 18px;
    color:#000000;
   }
   .image{
     width:40vw;
     max-width: 30vw;
     margin-left: auto;
     margin-right: auto;
     border: 2px solid #04c3fc;
     border-radius: 25px;
     /* flex-basis: 40%; */
     padding: 20px;
   }
   .video-kyc-question-container{
    flex-basis: 40%;
    overflow-y: scroll;
    border-radius: 25px;  -webkit-box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
    box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
    margin-top: 10px;
    margin-right: 10px;
  }
  
   .image-wrapper{
     position: relative;
     display: inline-block;
     overflow: hidden;
     margin: 0;
     width: -webkit-fill-available;
   }
  
   .rating-wrapper{
     display: flex;
     align-items: baseline;
     margin-bottom: 5px;
   }
  
   .rating-wrapper label{
     font-size: medium;
   }
  
   .custom-card{
     text-align: center;
     margin-left: auto;
     margin-right: auto;
     width:auto;
     height: fit-content;
     border-radius: 25px;
     padding: 20px;
   }
  
   .custom_float_label_date {
     top: 0.22em !important;
     font-family: "Gotham-Medium";
     letter-spacing: 0.4px;
     font-size: 0.9rem !important;
     transition: ease 0.3s;
   }
  
  </style>
  