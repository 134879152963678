import { render, staticRenderFns } from "./CheckDRPNew.vue?vue&type=template&id=3bd88b09&scoped=true"
import script from "./CheckDRPNew.vue?vue&type=script&lang=js"
export * from "./CheckDRPNew.vue?vue&type=script&lang=js"
import style0 from "./CheckDRPNew.vue?vue&type=style&index=0&id=3bd88b09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd88b09",
  null
  
)

export default component.exports