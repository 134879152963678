/***
 * This class can be used to represent filter details when admin side filter dropdown option changed
 */
export class SelectedFilterDetailWithOption {
    filterOptionName = ""
    filterOptionId = 0
    filterOptionValue = null

    constructor(filterName,filterId,filterOption) {
        this.filterOptionName = filterName
        this.filterOptionId = filterId
        this.filterOptionValue = filterOption
    }
}