import {GlobalFunctions} from "./globalFunctions";

/**
 * =====================================================================================================================
 * This can be use to define date time related functions
 *
 *  */
const DateTimeManger = {

    /**
     * This function can be used to ge formatted date and time
     * @param dateString date string
     * @returns {string} 15 February 2023 16:33:27 this type of output
     */
    formatDate(dateString){
        var date = new Date(dateString)

        var month=date.getMonth() + 1;
        var newDate=date.getDate();
        

        newDate=newDate < 10 ? '0'+newDate : newDate;

        month = month < 10 ? '0'+month : month;

        return newDate+"-"+ (month) +"-"+ date.getFullYear();
    },

    formatDateTime(dateString) {

        if(dateString.trim() === ""){
            return ""
        }
        else{
            var date = new Date(dateString)

            var hours = date.getHours()
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();

            var month=date.getMonth() +1;
            var newDate=date.getDate();


            newDate=newDate < 10 ? '0'+newDate : newDate;

            month = month < 10 ? '0'+month : month;

            minutes = minutes < 10 ? '0'+minutes : minutes;

            seconds = seconds >= 10 ? seconds : "0"+seconds;

            var strTime = hours + ':' + minutes + ':' + seconds+'\n';

            return newDate+"-"+ (month) +"-"+ date.getFullYear() +
                ', ' +strTime;
        }
    },


    getTimestampForActivityLog(dateString) {

        if(dateString.trim() === ""){
            return ""
        }
        else{
            let date = new Date(dateString)

            let hours = date.getHours()
            let minutes = date.getMinutes();
            let seconds = date.getSeconds();

            let month=date.getMonth() +1;
            let newDate=date.getDate();


            newDate=newDate < 10 ? '0'+newDate : newDate;

            month = month < 10 ? '0'+month : month;

            minutes = minutes < 10 ? '0'+minutes : minutes;

            seconds = seconds >= 10 ? seconds : "0"+seconds;

            let strTime = hours + ':' + minutes + ':' + seconds+'\n';

            return newDate+"-"+ (month) +"-"+ date.getFullYear() +
                ' ' +strTime;
        }
    },


    formatCustomerInputDates(dateString){
        var date = new Date(dateString)

        var hours = date.getHours()
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        var month=date.getMonth() + 1 ;
        var newDate=date.getDate();
        

        newDate=newDate < 10 ? '0'+newDate : newDate;

        month = month < 10 ? '0'+month : month;

        minutes = minutes < 10 ? '0'+minutes : minutes;

        seconds = seconds >= 10 ? seconds : "0"+seconds;


        return newDate+"/"+ (month) +"/"+ date.getFullYear() ;
    },


    /**
     * This function can be used to get formatted date and time to display on video call notification
     * @param date date time object as string : 2023-03-24T05:29:38.942Z
     * @param time time as string : 01:15 PM to 01:30 PM
     * @returns {string} 2023-03-24 01:15 PM type of output
     */
    getVideoCallNotificationDateTime(date,time){
        console.log("date : "+date);
        console.log("time : "+time);
        let timeSplit = time.split("to")
        let dateSplit = date.split("T")

        return dateSplit[0]+" "+timeSplit[0].trim()

    },


    /**
     * This function can be used to get given date string in dd-mm-yyyy format
     * @param dateString m/d/yyyy date string
     * @returns {string} dd-mm-yyyy date string
     */
    getLocalDateFormattedDate(dateString){
        let dateSplit= dateString.split('/')

        let day = GlobalFunctions.getNumberInTwoDigits(dateSplit[1])
        let month  = GlobalFunctions.getNumberInTwoDigits(dateSplit[0])

        return day+"-"+month+"-"+dateSplit[2]
    },


    /**
     * A function to get formatted date to display dates in UPM UIs (User,Branch,Role,Dual Auth)
     * @param dateStr
     * @returns {string}
     */
    getDisplayDateForUPM(dateStr){
        let date = new Date(dateStr);

        // date
        let dateArray = [date.getFullYear(),GlobalFunctions.getNumberInTwoDigits(date.getMonth()+1),GlobalFunctions.getNumberInTwoDigits(date.getDate())]

        //time
        let hours = date.getHours();
        let timeArray = [GlobalFunctions.getNumberInTwoDigits(date.getHours()),GlobalFunctions.getNumberInTwoDigits(date.getMinutes()),GlobalFunctions.getNumberInTwoDigits(date.getSeconds())]

        // get AM/PM
        let amPm = "";

        if(hours >= 12){
            amPm = "PM";
        }
        else {
            amPm = "AM"
        }

        let formattedDate = dateArray.join("-")+" "+timeArray.join(":")+" "+amPm;

        console.log("getDisplayDateForUPM() - input date : "+dateStr)
        console.log("getDisplayDateForUPM() - output date : "+formattedDate)

        return formattedDate;
    },
}

export {DateTimeManger}