<template>
  <div style="height: 80.5vh; overflow: auto !important" > 
    <section style="margin-top: 20px; margin-left: 20px;margin-bottom: 40px;">
  <h5>Delete User Profile (GDRP)</h5>
  </section>

  <section style="margin-top:10px; margin-left: 20px;margin-bottom: 40px;"> 
  <div class="columns">
<div class="column">Ref No.
  <b-field > 
          <b-input  v-model="refNoSearch" type="text" rounded></b-input>
  </b-field>
</div>
<div class="column">
  <b-field > NIC/EIC 
          <b-input   v-model="nicNoSearch" type="text" rounded></b-input>
  </b-field>
</div>
<div class="column">
  <b-field > Contact 
          <b-input   v-model="ContactNoSearch" type="text" rounded></b-input>
  </b-field>
</div>
<div class="column">
          <b-field > 
          <b-button type="is-primary"
              icon-left="magnify" 
              @click="search()"
              style="margin-top: 21px;">
              Search
          </b-button>
  </b-field>
</div>
</div>  
  </section>
    <b-tabs v-model="activeTab">
      <b-tab-item label="User Profiles" v-bind="UserProfiles" style="margin-left: 20px;">
        <TableComp
          :tableData=" UserProfiles"
          :columns="userColumnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          
        styles="mt-4 mx-6;"
        />
      </b-tab-item>

      <b-tab-item label="Pending For Dual Authentication" v-bind=" DeleteUserProfiles" style="margin-left: 20px;">
        <TableComp
          :tableData="DeleteUserProfiles"
          :columns="deleteColumnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
         
        styles="mt-4 mx-6"
        />
      </b-tab-item>

    </b-tabs> 
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import NetworkManager from "@/network"

export default {
  components: { TableComp, SearchBar },
  name: "AssignedApplication",
  data() {
    return {
      activeTab: 0,
      callAgent : false,
      authorizer : false,
      deatailReviwer : false,
     
      UserProfiles: [],
      DeleteUserProfiles: [],
      currentPage:'DELETE-USER-PROFILE-GDPR',
      statusToAssign:"",

      userColumnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 3, field: "full_name", label: "Name" ,date:false},
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "mobile_number", label: "Contact" ,date:false},
        { id: 6, field: "action", label: "Action" ,date:false},   
      ],
      deleteColumnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 3, field: "full_name", label: "Name" ,date:false},
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "mobile_number", label: "Contact" ,date:false},
        { id: 6, field: "recived_date", label: "Received Date" ,date:false},
        { id: 7, field: "action", label: "Action" ,date:false},   
      ],

      users: [  {ref_no: '1', nic: '986545676v', name:'Erena',email:'erena@gamil.com',contact:'0779945676',recived_date:'10/03/2023'},
     {ref_no: '2', nic: '678909856v', name:'Dilakshi',email:'himashi5566@gamil.com',contact:'0776674345'},
   
        ],

    

    };
  },
  methods: {
    Get__User_Profile_Details(){

    },
    Get__Pending_For_Dual_Authentication(){

    },

 
  },
  mounted() {     
      
      this. Get__User_Profile_Details();
      this.Get__Pending_For_Dual_Authentication();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
};
</script>
<style scoped></style>
