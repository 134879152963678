<template>
  
    <div style="height: 80.5vh; overflow: auto !important">
      <!-- <div class="mt-6">
        <SearchBar page="Scheduled Video Call" />
      </div> -->
      <div class="columns" style="margin-top: 25px; margin-left: 20px;">
        <div class="column">
          <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Scheduled Video Calls</label>
        </div>
        <div class="column">
          <b-input
            placeholder="Search..."
            rounded
            type="search"
            icon="magnify"
            icon-clickable
            v-model="searchKeyword"
            style="width:450px">
          </b-input>
        </div>
      </div>
       <TableComp
        :tableData="tableinfo.searchData(tableDetails, searchableFields, searchKeyword)"
        :columns="columnDetails"
        :previousPage="currentPage"
        :statusToAssign="statusToAssign"
        :searchableFields="searchableFields"

       styles="mt-4 mx-6"
      />
         
      
    </div>
  </template>
  
  <script>
  import TableComp from "../../components/TableComp.vue";
  import SearchBar from "../../components/SearchBar.vue";
  import NetworkManager from "@/network"
  import FilterBranch from "../../components/FilterBranch.vue";
  import {DateTimeManger} from "../../helpers/dateTimeManger";
  import { filterTableData } from "../../helpers/filterTableData";

  export default {
    components: { TableComp, SearchBar, FilterBranch },
    name: "ScheduleVideoCall",
    data() {
      return {
        ref_no: '',
        nic: '',
        name: '',
        email: '',
        gender:'',
        contact: '',
        scheduled_date: '',
        action: '',

        searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','gender','scheduled_date','created_at'],
        searchKeyword:"",
        tableDetails: "",
        tableinfo:"",
        applicationList :[],

        currentPage:"SUBMITTED-CALL-AGENT",
        statusToAssign:"ASSIGNED-CALL-AGENT",
        columnDetails:[],
       
      };
    },
    methods: {
      getFormattedDateTime(date){
      return DateTimeManger.formatDate(date)
    },
      GetScheduleVideoCall: function (){
        const comp= this
        const  data = {
          status:"SUBMITTED-CALL-AGENT"
        }
  
        NetworkManager.apiRequest('api/KycApplication/listAssignedApplicationByStatus',data, function (e){
          if(e.statusCode === 200){
            comp.tableDetails = e.data.applications
            comp.tableDetails.forEach(tableDetail=>{
              tableDetail.scheduled_date = comp.getFormattedDateTime( tableDetail.video_call_date) +" "+ tableDetail.video_call_time
             })
            comp.columnDetails=[
                                { id: 1, field: "reference_number", label: "Ref No." ,date:false},
                                { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
                                { id: 3, field: "full_name", label: "Name" ,date:false},
                                { id: 4, field: "gender", label: "Gender" ,date:false},
                                { id: 5, field: "email", label: "Email" ,date:false},
                                { id: 6, field: "scheduled_date", label: "Scheduled Time" ,date:false},                               
                                { id: 7, field: "action", label: "Action",date:false },
                              ]
          }
  
          // comp.hideFullScreenLoader()
        })
      },

      GetScheduleVideoCallInPool(){
        const comp= this
        const  data = {
          status:"SUBMITTED-CALL-AGENT"
        }
  
        NetworkManager.apiRequest('api/KycApplication/listByStatus',data, function (e){
          console.log("in Schedulevideocall",e.data)
          if(e.statusCode === 200){
            comp.tableDetails = e.data.applications
            console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaa",comp.tableDetails)
            comp.tableDetails.forEach(tableDetail=>{
              tableDetail.scheduled_date = comp.getFormattedDateTime( tableDetail.video_call_date) +" "+ tableDetail.video_call_time
             })
            comp.columnDetails=[
                                { id: 1, field: "reference_number", label: "Ref No." ,date:false},
                                { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
                                { id: 3, field: "full_name", label: "Name" ,date:false},
                                { id: 4, field: "gender", label: "Gender" ,date:false},
                                { id: 5, field: "email", label: "Email" ,date:false},
                                { id: 6, field: "mobile_number", label: "Contact" ,date:false},
                                { id: 7, field: "resident_status", label: "Residence" ,date:false},
                                { id: 8, field: "created_at", label: "Received Date",date:true},
                                { id: 9, field: "scheduled_date", label: "Scheduled Time" ,date:false},                               
                                { id: 10, field: "action", label: "Action",date:false },
                              ]
          }
  
          // comp.hideFullScreenLoader()
        })
      }
    },
    created() {
      this.tableinfo = filterTableData
    },
    mounted() {

      if(this.hasPermission("assign-scheduled-application")){
        this.GetScheduleVideoCallInPool()
      }else{
        this.GetScheduleVideoCall();
      }
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
  };
  </script>
  <style scoped></style>
  