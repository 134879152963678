<template>
  <div style="height: 80.5vh; overflow: auto !important">
    <b-tabs v-model="activeTab" :indicator="false">
      <b-tab-item ::value="sent">
        <div>
          <!-- <SearchBar page="" /> -->
          <div class="columns" style="margin-left: 1.1vw">
            <div class="column">
              <label
                style="
                  font-family: Gotham-Medium;
                  color: gray;
                  font-weight: 400;
                  font-size: 23px;
                "
                >Pending Exceptional Approval</label
              >
            </div>
            <div class="column">
              <b-input
                placeholder="Search..."
                rounded
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchKeyword"
                style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item v-bind="granted">
        <div>
          <!-- <SearchBar page="Exceptional Approval Granted" /> -->
          <div class="columns" style="margin-left: 10px">
            <div class="column">
              <label
                style="
                  font-family: Gotham-Medium;
                  color: gray;
                  font-weight: 400;
                  font-size: 23px;
                "
                >Granted Exceptional Approval</label
              >
            </div>
            <div class="column">
              <b-input
                placeholder="Search..."
                rounded
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchKeyword"
                style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item v-bind="denied">
        <div>
          <!-- <SearchBar page="Exceptional Approval Denied" /> -->
          <div class="columns" style="margin-left: 10px">
            <div class="column">
              <label
                style="
                  font-family: Gotham-Medium;
                  color: gray;
                  font-weight: 400;
                  font-size: 23px;
                "
                >Denied Exceptional Approval</label
              >
            </div>
            <div class="column">
              <b-input
                placeholder="Search..."
                rounded
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchKeyword"
                style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Pending" v-bind="sent">
        <TableComp
          :tableData="senttableDetails"
          :columns="sentDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Granted" v-bind="granted">
        <TableComp
          :tableData="grantedtableDetails"
          :columns="grantedDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Denied" v-bind="denied">
        <TableComp
          :tableData="deniedtableDetails"
          :columns="deniedDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import NetworkManager from "@/network";

export default {
  components: { TableComp, SearchBar },
  name: "ExceptionalApproval",
  data() {
    return {
      activeTab: 0,
      granted: false,
      denied: false,
      sent: false,
      ref_no: "",
      nic: "",
      name: "",
      email: "",
      contact: "",
      granted_date: "",
      received_date: "",
      denied_date: "",
      sent_date: "",
      action: "",
      grantedtableDetails: [],
      deniedtableDetails: [],
      senttableDetails: [],
      currentPage: "SUBMITTED-EXCEPTIONAL-APPROVER",
      statusToAssign: "",

      grantedDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "gender", label: "Gender", date: false },
        { id: 5, field: "email", label: "Email", date: false },
        { id: 6, field: "mobile_number", label: "Contact", date: false },
        { id: 7, field: "resident_status", label: "Residence", date: false },
        { id: 8, field: "status_updated_at", label: "Granted Date", date: true,},
        { id: 9, field: "created_at", label: "Received Date", date: true },
        { id: 10, field: "action", label: "Action", date: false },
      ],

      deniedDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "email", label: "Email", date: false },
        { id: 5, field: "gender", label: "Gender", date: false },
        { id: 6, field: "mobile_number", label: "Contact", date: false },
        { id: 7, field: "resident_status", label: "Residence", date: false },
        { id: 8, field: "created_at", label: "Sent Date", date: true },
        { id: 9, field: "status_updated_at", label: "Denied Date", date: true },
        { id: 10, field: "action", label: "Action", date: false },
      ],

      sentDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "gender", label: "Gender", date: false },
        { id: 5, field: "email", label: "Email", date: false },
        { id: 6, field: "mobile_number", label: "Contact", date: false },
        { id: 7, field: "resident_status", label: "Residence", date: false },
        { id: 8, field: "created_at", label: "Sent Date", date: true },
        { id: 9, field: "action", label: "Action", date: false },
      ],
      searchableFields: [
        "reference_number",
        "nic_eic_number",
        "full_name",
        "email",
        "mobile_number",
        "created_at",
        "resident_status",
        "status_updated_at",
      ],
      searchKeyword: "",
    };
  },
  methods: {
    GetAssigned_Exceptional_ApproverData: function () {
      const comp = this;
      const data = {
        status: "SUBMITTED-EXCEPTIONAL-APPROVER",
      };
      comp.currentPage = "SUBMITTED-EXCEPTIONAL-APPROVER";
      // comp.showFullScreenLoader()
      NetworkManager.apiRequest(
        "api/KycApplication/listAssignedApplicationByStatus",
        data,
        function (e) {
          console.log("in ExceptionalApproval", e.data);
          if (e.statusCode === 200) {
            comp.senttableDetails = e.data.applications;
          }

          // comp.hideFullScreenLoader()
        }
      );
    },
    GetExceptional_Approver_ApprovedData: function () {
      const comp = this;
      const data = {
        status: "EXCEPTIONAL-APPROVER-APPROVED",
      };
      comp.currentPage = "EXCEPTIONAL-APPROVER-APPROVED";
      // comp.showFullScreenLoader()
      NetworkManager.apiRequest(
        "api/KycApplication/listAssignedApplicationByStatus",
        data,
        function (e) {
          console.log("in EXCEPTIONAL_APPROVER_APPROVED", e.data);
          if (e.statusCode === 200) {
            comp.grantedtableDetails = e.data.applications;
          }

          // comp.hideFullScreenLoader()
        }
      );
    },
    GetExceptional_Approver_RejectedData: function () {
      const comp = this;
      const data = {
        status: "EXCEPTIONAL-APPROVER-REJECTED",
      };
      comp.currentPage = "EXCEPTIONAL-APPROVER-REJECTED";

      // comp.showFullScreenLoader()
      NetworkManager.apiRequest(
        "api/KycApplication/listAssignedApplicationByStatus",
        data,
        function (e) {
          console.log("in EXCEPTIONAL_APPROVER_REJECTED", e.data);
          if (e.statusCode === 200) {
            comp.deniedtableDetails = e.data.applications;
          }

          // comp.hideFullScreenLoader()
        }
      );
    },
  },
  mounted() {
    this.GetAssigned_Exceptional_ApproverData();
    this.GetExceptional_Approver_ApprovedData();
    this.GetExceptional_Approver_RejectedData();
    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
  },
};
</script>
<style scoped></style>
