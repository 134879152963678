import { HubConnectionBuilder } from '@microsoft/signalr';
import { appSettings } from "@/appSettings";

let notificationHubConnection = null;

export default {

    buildClientConnection(clientId,callback){
        console.log("appSettings.baseUrl", appSettings.baseUrl)
        const hubUrl = process.env.VUE_APP_API_BASE_URL + `/kycFormHub?clientId=${clientId}`;
        console.log("hubUrl",hubUrl)
        notificationHubConnection = new HubConnectionBuilder().withUrl(hubUrl).build(); // hub url
        callback();
    },

    connect() {
        if (notificationHubConnection) {
            notificationHubConnection.start().then(() => {
                console.log("SignalR connected");
            }).catch(error => {
                console.error("SignalR connection error:", error);
            });
        } else {
            console.error("SignalR connection not initialized");
        }
    },

    disconnect() {
        if (notificationHubConnection) {
            notificationHubConnection.stop().then(() => {
                console.log("SignalR disconnected");
            }).catch(error => {
                console.error("SignalR disconnection error:", error);
            });
        }
    },

    testNotification(callback){
        console.log('in for test');
        notificationHubConnection.on("pepSanctionUpdateListNotificationReceived",callback)
    },
};
