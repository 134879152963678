<template>
  <div style="height: 80.5vh; overflow: auto !important;">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Delete User Profile (GDPR)</label>
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                  placeholder="Search..."
                  rounded
                  type="search"
                  icon="magnify"
                  icon-clickable
                  v-model="searchKeyword"
                  style="width:450px"

              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    


    <!---------tabs--------------------------------------->
    <section class="mt-5">
      <b-tabs v-model="activeTab">
        <b-tab-item label="User Profiles">
          <!--filter component-------------------------------------------->
          <AdminDataTableFilter :date="false" :fields="filterDetails"  :userSelect="false" @filterData="applyFilteredData" />
          <AdminTableComp
              :tableData="UserTableDetails"
              :columns="columnDetails"
              :searchableFields="searchableFields"
              :searchKeyword="searchKeyword"
              :showApprovedApplicationView =false
              @refreshTableData="refreshTableData"

              

          />
        </b-tab-item>

        <b-tab-item label="Pending For Dual Authentication">
          <AdminTableComp
              :tableData="deleteList"
              :columns="columnDetailsForDeleteList"
              :searchableFields="searchableFields"
              :searchKeyword="searchKeyword"
              :showApprovedApplicationView =false
              @refreshTableData="refreshTableData"
          />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>

import NetworkManager from "@/network";
import AdminTableComp from "@/components/AdminTableComp";
import AdminDataTableFilter from "@/components/AdminDataTableFilter";
import {GlobalAttributes} from "../../../helpers/globalAttributes";
import {ApplicationPoolListTabData} from "../../../helpers/classes/admin/applicationPoolList/applicationPoolListTabData";
import {BranchFilterOption} from "../../../helpers/classes/admin/dataFilterTable/branchFilterOption";
import {FilterDetail} from "../../../helpers/classes/admin/dataFilterTable/filterDetail";
import app from "../../../App.vue";
import {BuefyHelper} from "../../../helpers/bufeyHelper";
import {TableDetailItem} from "../../../helpers/classes/admin/applicationPoolList/tableDetailItem";

export default {
  computed: {
    app() {
      return app
    },
    GlobalAttributes() {
      return GlobalAttributes
    },


  },
  components: {
    // TableComp,
    // SearchBar,
    AdminTableComp,
    AdminDataTableFilter
  },
  data() {
    return {
      activeTab: 0,

      customText:"Please enter customer's Reference Number, NIC and Contact Number To Search",

      currentPage: "NEW",

      searchKeyword:"",
      searchableFields: ['ref_no', 'nic','name','email','contact'],


      columnDetails: [
        { id: 1, field: "ref_no", label: "Ref No.",date:false },
        { id: 2, field: "nic", label: "NIC/EIC" ,date:false},
        { id: 3, field: "name", label: "Name" ,date:false},
        { id: 4, field: "email",label: "Email" ,date:false},
        { id: 5, field: "contact",label: "Contact" ,date:false},
        { id: 6, field: "action-delete", label: "Action" ,date:false},
      ],
      columnDetailsForDeleteList : [
        { id: 1, field: "ref_no", label: "Ref No.",date:false },
        { id: 2, field: "nic", label: "NIC/EIC" ,date:false},
        { id: 3, field: "name", label: "Name" ,date:false},
        { id: 4, field: "email",label: "Email" ,date:false},
        { id: 5, field: "contact",label: "Contact" ,date:false},
        { id: 6, field: "action-approve", label: "Action" ,date:false},
      ],
      UserTableDetails: [],
      deleteList : [],
      filterDetails:[
        {id:1,name:'Ref No.', selectedValue:null },
        {id:2,name:'NIC/EIC',selectedValue:null},
        {id:3,name:'Contact',selectedValue:null},
      ],
      
    };
  },

  methods: {

    loadInitialData(){
      const comp = this;

      // load all tabs data at initial load
      comp.getFilteredApplications(null,null,null);
      comp.getDeleteList();

    },

    refreshTableData(){
      console.log("in refresh....")
      const comp = this
      comp.loadInitialData()
    },

    applyFilteredData(filterParams){
      console.log("filter params... (receiving end) ")
      console.log(filterParams)

      const comp = this
      let Nic = filterParams[1].selectedValue ;
      let Ref_no = filterParams[0].selectedValue;
      let Contact = filterParams[2].selectedValue

      // filter relevant tab data
      comp.getFilteredApplications(Nic,Ref_no,Contact,()=>{
        console.log("filter applied successfully...")
      })
    },

    getFilteredApplications (nic,ref_no,contact) {
      const comp = this;
      const requestBody = {
        nic_number: nic,
        reference_number: ref_no,
        mobile_number: contact
      };

      comp.showFullScreenLoader();

      NetworkManager.apiRequest("api/Admin/getUserApplications", requestBody,(response)=> {
        console.log("get GDPR applications response")
        console.log(response)
            if (response.statusCode === 200) {
              comp.UserTableDetails =[]

              response.data.filtered_kyc_applications.forEach((kycApplication) =>{
                comp.UserTableDetails.push(comp.getNewTableItemObject(kycApplication))
                  }); 
              comp.hideFullScreenLoader();
              console.log("application response",response.data.filtered_kyc_applications)
              callback()
            }
            else{
             // BuefyHelper.showToastMessage("Unable to filter the data","is-danger")
              comp.hideFullScreenLoader();
            }

          }
      );

    },

    getNewTableItemObject(kycApplication){

      const comp = this

      let newTableDetailItem = new TableDetailItem()
      newTableDetailItem.id = kycApplication.kyc_form_id
      newTableDetailItem.ref_no = kycApplication.reference_number
      newTableDetailItem.nic = kycApplication.nic_eic_number
      newTableDetailItem.name = kycApplication.full_name
      newTableDetailItem.email = kycApplication.email
      newTableDetailItem.contact = kycApplication.mobile_number

      return newTableDetailItem;
    },

    getDeleteList(){
            const comp = this
            NetworkManager.apiRequest("api/Admin/getDeleteApplicationsList", {},(response)=> {
              console.log(response)
                  if (response.statusCode === 200) {
                    comp.deleteList=[]
                    response.data.filtered_kyc_applications.forEach((kycApplication) =>{
                      comp.deleteList.push(comp.getNewTableItemObject(kycApplication))
                        }); 
                    comp.hideFullScreenLoader();
                    
                    callback()
                  }
                  else{
                    //BuefyHelper.showToastMessage("Unable to filter the data","is-danger")
                    comp.hideFullScreenLoader();
                  }

                })
    },
    
    

  },
  mounted() {
    this.loadInitialData(); 
        if(this.$root.isSideBarHidden ){
      this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
    }
  },
};
</script>
