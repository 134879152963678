<template>
    <div>
        <div class="container" 
            rounded style="width: 60.5vw;
            
            background-color: white;
            border: 2px solid #000000;
            border-radius: 25px;
            padding: 20px;
            z-index: 2">
            <div>
                <div class="columns is-7 is-variable" style="width: 100%;">
                    <div class="column is-three-fourth" style="text-align: center;">
                        <label style="color: #ED1C24; font-size: 26px;">ATTACHMENTS</label>
                    </div> 
                    <div class="column is-three-fifth" style="margin-left: 54vw; position: absolute;">
                        <b-button @click="emitCloss">x</b-button>
                    </div>
                </div> 
            </div>
            <hr style="width: 90%; margin-left: auto; margin-right: auto;">
            <b-tabs
                    size="is-small"
                    type="is-toggle-rounded"
                    position="is-centered"
                >
                <b-tab-item label="Application Attachments">
                    <div style=" margin-top: 20px;margin-left:10vw; height: 30.5vw; overflow-y:scroll !important;">
                        <div
                        v-for="(item, index) in detailReviewerAttachments"
                        :key="item.path"
                        >
                            <div>
                                <label>Image Attachment {{ index + 1 }}</label> <br />
                                <div class="card"
                                rounded
                                style="
                                    border-radius: 25px;
                                    padding: 18px;
                                    max-width: 35vw;
                                "
                                >
                                    <div class="columns is-variable">
                                        <div class="column">
                                            <img 
                                                :src="((item.type === 'pdf')? pdfPlaceholder : item.path)"
                                                style="max-height: 10vh; "
                                                resposive
                                                alt="Attachment"
                                            />
                                        </div>
                                        <div class="column" style="width: 100%;">
                                            <label>Comment :</label>
                                            <!-- <label>{{ item.comment }}</label> -->
                                            <b-input
                                                class="rounded-input"
                                                rounded
                                                readonly
                                                type="textarea"
                                                v-model="item.comment"
                                                placeholder="Add Comment/s"
                                                rows="2"
                                                style="width: 100%"
                                            ></b-input>
                                        </div>
                                        <div>
                                            <b-icon  style="margin-top: 5vh; cursor: pointer;"
                                                type="is-primary"
                                                icon="tray-arrow-down"
                                                size="is-medium"
                                                @click.native="downloadfile(item.path)">
                                            </b-icon>
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="Detail Reviewer Hold Attachments"  v-if="detailReviewerAttachments != null">
                    <div style=" margin-top: 20px; margin-left:10vw; height: 30.5vw; overflow-y:scroll !important;">
                        <div
                        v-for="(item, index) in detailReviewerAttachments"
                        :key="item.path"
                        >
                            <div>
                                <label>Image Attachment {{ index + 1 }}</label> <br />
                                <div class="card"
                                rounded
                                style="
                                    border-radius: 25px;
                                    padding: 18px;
                                    max-width: 35vw;
                                "
                                >
                                    <div class="columns is-variable">
                                        <div class="column">
                                            <img 
                                                :src="((item.type === 'pdf')? pdfPlaceholder : item.path)"
                                                style="max-height: 10vh; "
                                                resposive
                                                alt="Attachment"
                                            />
                                        </div>
                                        <div class="column" style="width: 100%;">
                                            <label>Comment :</label>
                                            <!-- <label>{{ item.comment }}</label> -->
                                            <b-input
                                                class="rounded-input"
                                                rounded
                                                readonly
                                                type="textarea"
                                                v-model="item.comment"
                                                placeholder="Add Comment/s"
                                                rows="2"
                                                style="width: 100%"
                                            ></b-input>
                                        </div>
                                        <div>
                                            <b-icon  style="margin-top: 5vh; cursor: pointer;"
                                                type="is-primary"
                                                icon="tray-arrow-down"
                                                size="is-medium"
                                                @click.native="downloadfile(item.path)">
                                            </b-icon>
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item label="Call Agent Hold Attachments" v-if="detailReviewerAttachments != null">
                    <div style=" margin-top: 20px;margin-left:10vw; height: 30.5vw; overflow-y:scroll !important;">
                        <div
                        v-for="(item, index) in detailReviewerAttachments"
                        :key="item.path"
                        >
                            <div>
                                <label>Image Attachment {{ index + 1 }}</label> <br />
                                <div class="card"
                                rounded
                                style="
                                    border-radius: 25px;
                                    padding: 18px;
                                    max-width: 35vw;
                                "
                                >
                                    <div class="columns is-variable">
                                        <div class="column">
                                            <img 
                                                :src="((item.type === 'pdf')? pdfPlaceholder : item.path)"
                                                style="max-height: 10vh; "
                                                resposive
                                                alt="Attachment"
                                            />
                                        </div>
                                        <div class="column" style="width: 100%;">
                                            <label>Comment :</label>
                                            <!-- <label>{{ item.comment }}</label> -->
                                            <b-input
                                                class="rounded-input"
                                                rounded
                                                readonly
                                                type="textarea"
                                                v-model="item.comment"
                                                placeholder="Add Comment/s"
                                                rows="2"
                                                style="width: 100%"
                                            ></b-input>
                                        </div>
                                        <div>
                                            <b-icon  style="margin-top: 5vh; cursor: pointer;"
                                                type="is-primary"
                                                icon="tray-arrow-down"
                                                size="is-medium"
                                                @click.native="downloadfile(item.path)">
                                            </b-icon>
                                            <!-- <b-button style="margin-top: 5vh; cursor: pointer;" 
                                                outlined
                                                type="is-primary"
                                                icon-left="tray-arrow-down"
                                                @click="downloadfile(item.path)" /> -->
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </b-tab-item>
            </b-tabs>    
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            detailReviewerAttachments: null,
            callAgentAttachments :null,
            showAttachmenviewer:false,
        },
        data(){
            return {
                pdfPlaceholder : require("@/assets/Images/pdf.png"),
                ShowAttachments :false,
            }
        },
        methods:{
            emitCloss()
            {
                this.$emit('Closs_Viewer',this.showAttachmenviewer)
            },
            downloadfile(url, filename) {
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
        }
    }
</script>

<style lang="scss" scoped>
.coer img {
  margin: 0 auto;
  display: block;
}
</style>