
export class ApplicationPoolListTabData {
    tableData = []
    columns = []
    statusToAssign = ""
    searchableFields = []
    assignableUserRoleName = ""

    constructor(columns,statusToAssign,searchableFields,assignableUserRoleName) {
        this.columns = columns;
        this.statusToAssign = statusToAssign;
        this.searchableFields = searchableFields;
        this.assignableUserRoleName = assignableUserRoleName
    }
}