<template>
  <div>
    <b-loading :is-full-page="true" :can-cancel="false" v-model="isLoading"/>
    <div class="columns is-7 is-variable" v-if="!isLoading">
      <div class="column">
        <ShowMapLocation label="Geo-Location" :position="kycFormCustomerLocation"/>
        <b-message
            style="margin-top: 10px"
            type="is-info"
            has-icon
            v-if="reverseGeocodedAddress !== ''">
          <b>Geo-Location Address: </b>
          {{ reverseGeocodedAddress }}
        </b-message>
      </div>
      <div class="column" >
        <ShowMapLocation label="Geo-Location Video KYC" :position="videoCallCustomerLocation" v-if="videoCallCustomerLocation !== null"/>
        <b-message
            style="margin-top: 10px"
            type="is-info"
            has-icon
            v-if="reverseGeocodedVideoCallAddress !== ''">
          <b>Geo-Location Address: </b>
          {{ reverseGeocodedVideoCallAddress }}
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import {useVideoKycStore} from "../../../store/videoKycStore";
import {useAuthStore} from "../../../store/authStore";
import NetworkManager from "../../../network";
import {GeoPositionCoordinates} from "../../../helpers/classes/geoPositionCoordinates";
import {BuefyHelper} from "../../../helpers/bufeyHelper";
import ShowMapLocation from "../../../components/ShowMapLocation.vue";
import {GlobalFunctions} from "../../../helpers/globalFunctions";
export default {
  components: {
    ShowMapLocation
  },
  setup(){
    const videoKycStore = useVideoKycStore()
    const authStore = useAuthStore()
    return {videoKycStore,authStore}
  },

  data(){
    return{
      isLoading : true,
      kycFormCustomerLocation : new GeoPositionCoordinates(),
      videoCallCustomerLocation : null,
      reverseGeocodedAddress : "",
      reverseGeocodedVideoCallAddress : "",
    }
  },
  methods:{
    GetApplicationData: function (){
      const comp= this
      comp.isLoading = true


      const data = {id: comp.authStore.kyc_form_id}

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (response){
        if(response.statusCode === 200){

          let kycLocation = JSON.parse(response.data.kycApplication.customer_location)
          comp.kycFormCustomerLocation = new GeoPositionCoordinates(kycLocation.latitude,kycLocation.longitude)
          comp.reverseGeocodedAddress = response.data.kycApplication.reverse_geocoded_customer_location

          if(GlobalFunctions.isVideoCallCompleted(response.data.videoCallDetail)){
            let videoKycLocation = JSON.parse(response.data.videoCallDetail.video_call_location)
            comp.videoCallCustomerLocation = new GeoPositionCoordinates(videoKycLocation.latitude, videoKycLocation.longitude)
            comp.reverseGeocodedVideoCallAddress = response.data.videoCallDetail.reverse_geocoded_video_call_location
          }

          // Test logs
          // console.log("location objects")
          // console.log(comp.kycFormCustomerLocation)
          // console.log(comp.videoCallCustomerLocation)
          comp.isLoading = false
        }
        else{
          BuefyHelper.showToastMessage("Unable to get the application data",'is-danger')
          comp.isLoading = false
        }

      })
    },

  },
  mounted(){
    this.GetApplicationData()
  }
};
</script>

<style lang="scss" scoped></style>
