<template>
  <div>
    <div class="p-0" :key="refreshKey">

      <div class="columns" style="margin:10px auto 10px 10px">
        <div style="position:fixed; margin-left: -4vw;">
          <b-button rounded @click="$router.go(-1)" style="border-radius: 20px; height: 40px; width: 40px;" type="is-primary" outlined> <i class="mdi mdi-arrow-left mdi-24px"></i></b-button>
        </div>

        <div v-if="!isReviewOnly">
          <!-- <p v-if="formData.current_status === 'APPROVED' || 'DEBIT-CARD-REVIEWED' || 'DEBIT-CARD-PENDING'" style="color: #aaaaaa; font-size: 25px">Approved Application</p> -->
          <!-- <p v-if="formData.current_status === 'ASSIGNED-APPROVER'" style="color: #aaaaaa; font-size: 25px"> Assigned Approval Application</p>
          <p v-if="formData.current_status === 'APPROVED'" style="color: #aaaaaa; font-size: 25px">Approved Application</p>
          <p v-if="formData.current_status === 'EXCEPTIONAL-APPROVER-RETURNED'" style="color: #aaaaaa; font-size: 25px">Returned Application</p>
          <p v-if="formData.current_status === 'REJECTED'" style="color: #aaaaaa; font-size: 25px">Rejected Application</p>
          <p v-if="formData.current_status === 'ASSIGNED-CALL-AGENT'" style="color: #aaaaaa; font-size: 25px">Call Scheduled Application</p>
          <p v-if="formData.current_status === 'ASSIGNED-DETAIL-REVIEWER'" style="color: #aaaaaa; font-size: 25px">Recommend / Reject Application</p>
          <p v-if="formData.current_status === 'SUBMITTED-CALL-AGENT'" style="color: #aaaaaa; font-size: 25px">Recommend / Reject Application</p> -->
          <p style="color: #aaaaaa; font-size: 25px">
            Recommend / Reject Application
          </p>
          <!-- <p style="color: #aaaaaa; font-size: 25px"> Recommend / Reject Application</p> -->
        </div>

        <!-- Title for application status check module-->
        <div v-if="isReviewOnly">
          <p style="color: #aaaaaa; font-size: 25px">View Application</p>
        </div>

        <div style="margin-left: 42vw" v-if="!isReviewOnly">
          <div
            v-if="
              hasPermission('assign-scheduled-application') &&
              (formData.current_status === 'SUBMITTED-CALL-AGENT' ||
                formData.current_status === 'ASSIGNED-CALL-AGENT')
            "
          >
            <b-button type="is-primary" outlined
              :disabled="Sh_isButtonDisabled"
              @click="Sh_showMessageBox = true"
              style="width: 100%; margin-bottom: 10px"
            >
              Re-Schedule
            </b-button>
            <div v-if="Sh_showMessageBox">
              <div id="loading" class="fullscreen">
                <ScheduleRescheduleMeeting
                  :Schedule_or_Reschedule="Schedule_or_Reschedule"
                  :selected-date="selected"
                  :video-call-time="videoCallTime"
                  @update_Sh_customer_comment="update_Sh_customer_comment"
                  @update_Sh_internal_comment="update_Sh_internal_comment"
                  @update_Sh_email="update_Sh_email"
                  @update_Sh_sms="update_Sh_sms"
                  @Update_Sh_New_Date="Update_Sh_New_Date"
                  @Update_Sh_New_Time="Update_Sh_New_Time"
                  @sendScheduleMeeting="sendScheduleMeeting"
                  @Closs_Sh="Closs_Sh"
                />
              </div>
            </div>
          </div>
          <div
            v-if="
              hasPermission('review-application-from-branch-pool') &&
              formData.current_status === 'REFER-TO-BRANCH-PENDING'
            "
          >
            <!-- <b-button
              @click="goToDownload"
              class="is-danger"
              style="width: 100%; margin-bottom: 10px;"
              >DOWNLOAD AS PDF
              </b-button> -->
          </div>
        </div>
      </div>

      <!-- <DownloadButton
        :navigate_page="`AssignedApplication`"
        applicationName="Recommend / Reject Application"
        :showDownLoadButton="false"
      /> -->
      <ApplicationReviewVue v-if="!isLoading" :review-only="isReviewOnly" />
     
    </div>
    <!-- <button
      @click="
        () => {
          goBack();
          navigateTo('Applications');
        }
      "
    >
      go back
    </button> -->
  </div>
</template>

<script>
import NetworkManager from "../../network";
import { useAuthStore } from "../../store/authStore";
import DownloadButton from "../../components/DownloadButton.vue";
import ApplicationReviewVue from "../../components/ApplicationReview.vue";
import ScheduleRescheduleMeeting from "../../components/ScheduleRescheduleMeeting.vue";
import ApplicationDetailReview from "../ApplicationDetailsView/ApplicationDetailReview.vue";

export default {
  name: "AssignedApplicationsReview",
  components: {
    DownloadButton,
    ApplicationReviewVue,
    ScheduleRescheduleMeeting,
    ApplicationDetailReview,
   
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {
      isLoading: true,
      isReviewOnly: false,
      refreshKey: 0,
      formData: [],
      Sh_isButtonDisabled: false,
      Sh_showMessageBox: false,
      Sh_customer_comment: "",
      Sh_internal_comment: "",
      Sh_email: true,
      Sh_sms: false,
      videoCallTime: "",
      selected: "",
      Schedule_or_Reschedule: "Reschedule a Video Call ",
      schedule_button_name: "Re-Schedule",
    };
  },
  methods: {
    goToDownload(id) {
      const comp = this;
      comp.authStore.kyc_form_id = id;
      //this.$router.push('/Download')
      window.open("/Download", "_blank", "width=1920,height=1080");
      newWindow.focus();
    },
    refresh() {
      this.refreshKey++;
    },
    //Shedule meeting
    update_Sh_customer_comment(value) {
      this.Sh_customer_comment = value;
    },
    update_Sh_internal_comment(value) {
      this.Sh_internal_comment = value;
    },
    update_Sh_email(value) {
      this.Sh_email = value;
    },
    update_Sh_sms(value) {
      this.Sh_sms = value;
    },
    Update_Sh_New_Date(value) {
      this.selected = value;
    },
    Update_Sh_New_Time(value) {
      this.videoCallTime = value;
    },
    Closs_Sh(value) {
      this.Sh_showMessageBox = value;
    },

    sendScheduleMeeting: function () {
      const comp = this;
      this.Sh_showMessageBox = false;

      const data = {
        id: comp.authStore.kyc_form_id,
        date: comp.selected.toISOString(),
        time: comp.videoCallTime,
        comment_for_customer: comp.Sh_customer_comment,
        comment_for_internal_use: comp.Sh_internal_comment,
        is_reschedule:
          comp.Schedule_or_Reschedule ==
          "Schedule a Video Call & Submit to Caller"
            ? false
            : true,
        is_email: comp.Sh_email,
        is_sms: comp.Sh_sms,
      };

      comp.showFullScreenLoader();

      NetworkManager.apiRequest(
        "api/KycApplication/scheduleVideoCall",
        data,
        function (e) {
          //console.log("send Schedule Meeting",e.data)
          if (e.statusCode === 200) {
            comp.hideFullScreenLoader();
            comp.Sh_isButtonDisabled = true;
            comp.Sh_showMessageBox = false;
            if (
              comp.Schedule_or_Reschedule ==
              "Schedule a Video Call & Submit to Caller"
            ) {
              const params = {
                type: "is-success",
                message: "Video Call has been Scheduled successfully.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
              comp.$router.push("/schedulevideocall");
              comp.refresh();
              //comp.GetApplicationData()
            } else {
              comp.Sh_isButtonDisabled = false;
              const params = {
                type: "is-success",
                message: "Video Call has been Rescheduled successfully.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
              comp.refresh();
              //comp.GetApplicationData()
            }
          } else {
            comp.hideFullScreenLoader();
          }
        }
      );
    },

    GetApplicationData: function () {
      const comp = this;

      comp.showFullScreenLoader();
      comp.isLoading = true;

      const data = { id: comp.authStore.kyc_form_id };
      NetworkManager.apiRequest(
        "api/KycApplication/getApplicationById",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.formData = e.data.kycApplication;
            console.log(comp.formData);
            console.log("ID", comp.authStore.kyc_form_id);
            comp.videoCallDetail = e.data.videoCallDetail;
            comp.videoCallTime = e.data.kycApplication.video_call_time;
            var dateStr = e.data.kycApplication.video_call_date;

            comp.selected = new Date(dateStr);
            comp.customer_branch = e.data.customerBranch;
            comp.agent_branch = e.data.agentBranch;

            comp.isLoading = false;
            comp.hideFullScreenLoader();
          } else {
            comp.isLoading = false;
            comp.hideFullScreenLoader();
          }
        }
      );
    },

    goBack() {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    },
    back() {
      router.go(-1);
    },
  },
  mounted() {
    this.GetApplicationData();
    this.isReviewOnly = this.authStore.reviewOnly.basicApplicationView;
  },
};
</script>

<style scoped></style>
