export default class VideoCallAttachment {
    rating = 0
    srcObject = ""
    file = null

    required = Boolean

    constructor(required) {
        this.required = required
    }
}