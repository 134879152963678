import JwtUtils from "@/helpers/jwtUtils";
import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import { appSettings } from "@/appSettings";

class NetworkResponse {
  statusCode;
  data;

  constructor() {
    this.statusCode = 0;
    this.data = null;
  }
}

const NetworkManager = {
  apiRequest: function (endpoint, data, callBack, withToken = true,contentType="application/json",token=null) {
    console.log(appSettings.$api_url);
    const URL = appSettings.$api_url + "/" + endpoint;

    // console.warn("URL : ",URL)

    let config = {
      headers: {},
      timeout: appSettings.timeoutDuration,
    };

    if (withToken) {

      let authHeader = ""

      if(token === null){
        authHeader = `Bearer ${JwtUtils.loadToken().token}`
      }
      else {
        authHeader = `Bearer ${JwtUtils.loadToken(token).token}`
      }

      config.headers = {
        // Authorization: `Bearer ${JwtUtils.loadToken().token}`,
        Authorization: authHeader,
        // "Content-Type": contentType,
      };
    }

    config.headers["Content-Type"] =  contentType

    if(contentType === "multipart/form-data"){
      config.headers["onUploadProgress"] =  progressEvent => console.log(progressEvent.loaded)
    }

    axios
      .post(URL, data, config)
      .then(function (e) {
        callBack(e.data);
      })
      .catch(function (e) {
        let resp = new NetworkResponse();

        if (e.response) {
          resp.statusCode = e.response.status;

          if (e.response.data) {
            resp = e.response.data;
          }

          if (resp.statusCode === 401) {
            JwtUtils.logoutTheUser();
          }
        } else {
          resp.statusCode = 502;
        }

        callBack(resp);
      });
  },
};

export default NetworkManager;
