<template>
   <div style="padding: 50px; background-color: #FFFFFF;" class="page-container" >

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

    <!-- <div class="columns" style="margin-left: 10px; margin-top: 10px;">
        <div>
          <b-button rounded @click="$router.go(-1)"> <i class="mdi mdi-arrow-left mdi-36px"></i>  </b-button>
        </div>
        <div >
          <p style="color: #aaaaaa; font-size: 25px">Download Application Preview</p>
        </div>
        <div style="margin-left: auto;">
            <b-button @click="generatePDF">DOWNLOAD</b-button>
        </div>    
    </div> -->

    <!--PDF DOWNLOAD CONTROLLER -->
    <!--IF DOWNLOAD IS NOT WORKING. MAKE SURE :enable-download= true and in hasDownloaded() this.goback is not commented-->
    <vue-html2pdf 
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="false"
        :htmlToPdfOptions="{
            filename: filetitle+' '+filename,
            margin: 0.3,
            image: {
                type: 'jpeg', 
                quality: 1
            },
        
            enableLinks: false,
        
            html2canvas: {
                scale: 1,
                useCORS: true
            },
        
            jsPDF: {
                unit: 'in',
                format: 'a3',
                orientation: 'portrait',
                putOnlyUsedFonts :true
            }
        }"
        :paginate-elements-by-height="1800"
        :pdf-quality="2"
        
        pdf-orientation="portrait"
        pdf-content-width="90%"
        :manual-pagination="false" 
        @progress="onProgress($event)"
        @startPagination="startPagination()"
        @hasPaginated="hasPaginated()"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf"
    > 
    <section slot="pdf-content">  
        <img src="../../assets/logo.png" style="mix-blend-mode: darken; width: 10vw; margin-left: 3vw;" />
        <div style="align-items: center; text-align: center;">
            <img v-if="ekycFormData.selfie_attachment.attachemnt_type !== `pdf`"
             class="imgAttachment" :src="ekycFormData.selfie_attachment.attachment_url" alt="ID Back Image" style="width:15vw;  height: 26vh;">
            <img v-else
                class="imgAttachment" :src="pdfPlaceholder" alt="ID Back Image" style="width:15vw;  height: 26vh;">
            <br>
            <br>
            <label style="font-size: 32px;">KYC Application Profile</label>
            <br>
            <label class="text">{{ ekycFormData.title.customer_input + " " + ekycFormData.full_name.customer_input }}</label>
        </div>

            <div>
                <p>Personal Details</p>
                <!-- Title -->
                <div class="columns is-7 is-variable" >
                    <div class="column">
                        <InputLabel 
                            label="Title"
                            readonly="true"
                            :value="ekycFormData.title.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Title"
                            :value="ekycFormData.title.call_agent_input"
                            :hide="!ekycFormData.title.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <FullNameView
                            class="mt-1 non-interactable-input"
                            label="Full Name (As per the NIC / EIC / PP)"
                            :value="formData.full_name.customer_input"
                            :is-agent="false"
                        />
                        <!-- <InputLabel 
                            label="Full Name (As per the NIC / EIC / PP)"
                            readonly="true"
                            :value="ekycFormData.full_name.customer_input"
                            class="non-interactable-input"
                        /> -->
                    </div>
                    <div class="column">
                        <FullNameView v-if="formData.full_name.is_changed_by_call_agent"
                            class="mt-1 non-interactable-input"
                            label="Full Name (As per the NIC / EIC / PP)"
                            :value="formData.full_name.call_agent_input"
                            :is-agent="true"
                        />
                        <!-- <AgentInputField
                            label="Full Name (As per the NIC / EIC / PP)"
                            :value="ekycFormData.full_name.call_agent_input"
                            :hide="!ekycFormData.full_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        /> -->
                    </div>
                </div>
                <!-- citizenship -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="Citizenship"
                            readonly="true"
                            :value="ekycFormData.citizenship.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Citizenship"
                        :value="ekycFormData.citizenship.call_agent_input"
                        :hide="!ekycFormData.citizenship.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="Residency"
                            readonly="true"
                            :value="ekycFormData.residentship.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Residency"
                            :value="ekycFormData.residentship.call_agent_input"
                            :hide="!ekycFormData.residentship.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- date of birth -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="Nationality"
                            readonly="true"
                            :value="ekycFormData.nationality.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Nationality"
                            :value="ekycFormData.nationality.call_agent_input"
                            :hide="!ekycFormData.nationality.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column ">
                        <InputLabel
                            label="Date of Birth"
                            readonly="true"
                            :value="getFormattedDateTime(ekycFormData.date_of_birth.customer_input)"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column ">
                        <AgentInputField
                            label="Date of Birth"
                            :value="getFormattedDateTime(ekycFormData.date_of_birth.call_agent_input)"
                            :hide="!ekycFormData.date_of_birth.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- Nic -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="NIC / EIC Number"
                            readonly="true"
                            :value="ekycFormData.nic_eic_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column ">
                        <AgentInputField
                            label="NIC / EIC Number"
                            :value="ekycFormData.nic_eic_number.call_agent_input"
                            :hide="!ekycFormData.nic_eic_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel
                            label="NIC Issue Date"
                            readonly="true"
                            :value="getFormattedDateTime(ekycFormData.nic_eic_issue_date.customer_input)"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="NIC Issue Date"
                            :value="getFormattedDateTime(ekycFormData.nic_eic_issue_date.call_agent_input)"
                            :hide="!ekycFormData.nic_eic_issue_date.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>

                <!-- Passport -->
                <div v-if="ekycFormData.passport_number.customer_input != ''">
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column">
                            <InputLabel
                            label="Passport Number"
                            readonly="true"
                            :value="ekycFormData.passport_number.customer_input"
                            class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                            label="Passport Number"
                            :value="ekycFormData.passport_number.call_agent_input"
                            :hide="!ekycFormData.passport_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                        </div>
                    </div>
                    <div class="columns is-7 is-variable" v-if="ekycFormData.passport_number.customer_input != ''" style="margin-top:-4vh">
                        <div class="column">
                            <InputLabel 
                            label="Passport Issue Date "
                            reaDonly="true"
                            :value="getFormattedDateTime(ekycFormData.passport_issue_date.customer_input)"
                            class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                            label="Passport Issue Date"
                            :value="getFormattedDateTime(ekycFormData.passport_issue_date.call_agent_input)"
                            :hide="!ekycFormData.passport_issue_date.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                        </div>
                    </div>
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column">
                            <InputLabel
                                label="Passport Expire Date"
                                readonly="true"
                                :value="getFormattedDateTime(ekycFormData.passport_expire_date.customer_input)"
                                class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                            label="Passport Expire Date"
                            :value="getFormattedDateTime(ekycFormData.passport_expire_date.call_agent_input)"
                            :hide="!ekycFormData.passport_expire_date.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                        </div>

                    </div>
                </div>

                <!-- gender -->
                <div class="columns is-7 is-variable" style="margin-top:-3vh">
                    <div class="column">
                        <InputLabel 
                            label="Gender"
                            readonly="true"
                            :value="ekycFormData.gender.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Marital Status"
                        :value="ekycFormData.maritial_status.call_agent_input"
                        :hide="!ekycFormData.maritial_status.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel
                        label="Marital Status"
                        readonly="true"
                        :value="ekycFormData.maritial_status.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Gender"
                            :value="ekycFormData.gender.call_agent_input"
                            :hide="!ekycFormData.gender.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
            </div>     

            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            />

            <div>
                <!-- Permanent Address -->
                <p>Permanent Address</p>

                <p style="font-family: Gotham-Light; font-size: 1.2rem"
                    v-if="ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false">
                    Permanent and correspondence addresses are one and the same.
                </p>
                <div class="columns is-7 is-variable">
                    <div class="column">
                        <InputLabel
                            label="Building Number"
                            readonly="true"
                            :value="ekycFormData.building_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Building Number"
                        :value="ekycFormData.building_number.call_agent_input"
                        :hide="!ekycFormData.building_number.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="Building Name"
                            readonly="true"
                            :value="ekycFormData.building_name.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Building Name"
                            :value="ekycFormData.building_name.call_agent_input"
                            :hide="!ekycFormData.building_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                    <InputLabel 
                        label="Street"
                        readonly="true"
                        :value="ekycFormData.street.customer_input"
                        class="non-interactable-input"
                    />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Street"
                        :value="ekycFormData.street.call_agent_input"
                        :hide="!ekycFormData.street.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                    <InputLabel 
                        label="Street 2"
                        readonly="true"
                        :value="ekycFormData.street_2.customer_input"
                        class="non-interactable-input"
                    />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Street 2"
                        :value="ekycFormData.street_2.call_agent_input"
                        :hide="!ekycFormData.street_2.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="Town"
                            readonly="true"
                            :value="ekycFormData.town.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Town"
                            :value="ekycFormData.town.call_agent_input"
                            :hide="!ekycFormData.town.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="Postal Code"
                            readonly="true"
                            :value="ekycFormData.postal_code.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Postal Code"
                        :value="ekycFormData.postal_code.call_agent_input"
                        :hide="!ekycFormData.postal_code.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel
                            label="District"
                            readonly="true"
                            :value="ekycFormData.district.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="District"
                            :value="ekycFormData.district.call_agent_input"
                            :hide="!ekycFormData.district.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel
                            label="Country"
                            readonly="true"
                            :value="ekycFormData.country.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Country"
                        :value="ekycFormData.country.call_agent_input"
                        :hide="!ekycFormData.country.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable"  style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel
                            label="Status of Permanant Residence"
                            readonly="true"
                            :value="GlobalFunctions.getStatusOfResidenceDisplayValue(ekycFormData.state_of_residence.customer_input)"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Status of Permanant Residence"
                            :value="GlobalFunctions.getStatusOfResidenceDisplayValue(ekycFormData.state_of_residence.call_agent_input)"
                            :hide="!ekycFormData.state_of_residence.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>

                <hr style="width:90vw"
                    :style="{
                        'background-color': '#1b1b1b',
                        height: '1.8px',
                        opacity: '0.5',
                        'margin-inline': '4px',
                    }"
                    />
            </div>
            <!-- correspondence  Address -->
            <!-- <div v-if="ekycFormData.is_correspondence_address_differs_with_the_permanent_address"> -->
            <div>
                <p>Correspondence  Address</p>
                <div class="columns is-7 is-variable mt-1">
                    <div class="column">
                        <InputLabel 
                        label="Building Number"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_building_number.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Building Number"
                        :value="ekycFormData.correspondence_address_building_number.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_building_number.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable"  style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                        label="Building Name"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_building_name.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Building Name"
                        :value="ekycFormData.correspondence_address_building_name.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_building_name.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                        label="Street"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_street.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Street"
                        :value="ekycFormData.correspondence_address_street.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_street.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                        label="Street 2"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_street_2.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Street 2"
                        :value="ekycFormData.correspondence_address_street_2.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_street_2.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable"  style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                        label="Town"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_town.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Town"
                        :value="ekycFormData.correspondence_address_town.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_town.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                        label="Postal Code"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_postal_code.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Postal Code"
                        :value="ekycFormData.correspondence_address_postal_code.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_postal_code.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable"  style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                        label="District"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_district.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="District"
                        :value="ekycFormData.correspondence_address_district.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_district.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                        label="Country"
                        readonly="true"
                        :value="ekycFormData.correspondence_address_country.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Country"
                        :value="ekycFormData.correspondence_address_country.call_agent_input"
                        :hide="!ekycFormData.correspondence_address_country.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>

                <hr style="width:90vw"
                    :style="{
                        'background-color': '#1b1b1b',
                        height: '1.8px',
                        opacity: '0.5',
                        'margin-inline': '4px',
                    }"
                    />
            </div>
            <div v-if="ekycFormData.is_correspondence_address_differs_with_the_permanent_address">
                <div class="html2pdf__page-break"/>
            </div>    
            <div>
                <!-- contact -->
                <P>Contact Details</P>
                <div class="columns is-7 is-variable" >
                    <div class="column">
                        <InputLabel 
                            label="Primary Mobile Number"
                            readonly="true"
                            :value="ekycFormData.mobile_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Primary Mobile Number"
                            :value="ekycFormData.mobile_number.call_agent_input"
                            :hide="!ekycFormData.mobile_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh" v-if="ekycFormData.telephone_number.customer_input || ekycFormData.telephone_number.is_changed_by_call_agent">
                    <div class="column">
                        <InputLabel
                            label="Mobile/Telephone Number"
                            readonly="true"
                            :value="ekycFormData.telephone_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Mobile/Telephone Number"
                            :value="ekycFormData.telephone_number.call_agent_input"
                            :hide="!ekycFormData.telephone_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- Email Address -->
                <div class="columns is-7 is-variable" style="margin-top:-2vh">
                    <div class="column">
                        <InputLabel 
                            label="Primary Email ID"
                            readonly="true"
                            :value="ekycFormData.email.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Primary Email ID"
                            :value="ekycFormData.email.call_agent_input"
                            :hide="!ekycFormData.email.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!--Secondary  Email Address -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh" v-if="ekycFormData.secondary_email.customer_input || ekycFormData.secondary_email.is_changed_by_call_agent">
                    <div class="column">
                        <InputLabel 
                            label="Additional Email ID"
                            readonly="true"
                            :value="ekycFormData.secondary_email.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Additional Email ID"
                            :value="ekycFormData.secondary_email.call_agent_input"
                            :hide="!ekycFormData.secondary_email.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- Mother’s Maiden Name -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            label="Mother’s Maiden Name"
                            readonly="true"
                            :value="ekycFormData.mothers_maiden_name.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Mother’s Maiden Name"
                            :value="ekycFormData.mothers_maiden_name.call_agent_input"
                            :hide="!ekycFormData.mothers_maiden_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                
                
                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />
            </div>     

            <div>

            <!-- Employment Details -->
            <p>Employment Details</p>

            <div class="columns is-7 is-variable">
                <div class="column">
                <InputLabel
                    label="Employment Status"
                    readonly="true"
                    :value="ekycFormData.employment_status.customer_input"
                    class="non-interactable-input"
                />
                </div>
                <div class="column">
                <AgentInputField
                    label="Employment Status"
                    :value="ekycFormData.employment_status.call_agent_input"
                    :hide="!ekycFormData.employment_status.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                />
                </div>
            </div>
            <div
            v-if="
              formData.employment_status.is_changed_by_call_agent
                ? formData.employment_status.call_agent_input ===  'Self-Employed' ||
                  formData.employment_status.call_agent_input === 'Freelance'
                : formData.employment_status.customer_input === 'Freelance' ||
                  formData.employment_status.customer_input === 'Self-Employed'"
          >
                <InputLabel
                label="Profession / Nature of Business"
                readonly="true"
                :value="ekycFormData.profession_nature_of_business.customer_input"
                styles="mt-4"
                class="non-interactable-input"
                />
                <AgentInputField 
                label="Profession / Nature of Business"
                :value="ekycFormData.profession_nature_of_business.call_agent_input"
                styles="mt-5"
                :hide="
                    !ekycFormData.profession_nature_of_business.is_changed_by_call_agent
                "
                readonly="true"
                class="non-interactable-input"
                />
            </div>
            <div
                v-if="ekycFormData.employment_status.is_changed_by_call_agent ?
                ekycFormData.employment_status.call_agent_input === 'Full-Time' ||
                ekycFormData.employment_status.call_agent_input === 'Part Time' ||
                ekycFormData.employment_status.call_agent_input === 'Self-Employed' ||
                ekycFormData.employment_status.call_agent_input === 'Probation' : ekycFormData.employment_status.customer_input === 'Full-Time' ||
                ekycFormData.employment_status.customer_input === 'Part Time' ||
                ekycFormData.employment_status.customer_input === 'Self-Employed' ||
                ekycFormData.employment_status.customer_input === 'Probation'
                "
            >
                <InputLabel
                    label="Employer /  Business Name"
                    readonly="true"
                    :value="ekycFormData.employer.customer_input"
                    styles="mt-5"
                    class="non-interactable-input"
                    />
                <AgentInputField
                    label="Employer /  Business Name"
                    :value="ekycFormData.employer.call_agent_input"
                    styles="mt-5"
                    :hide="!ekycFormData.employer.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
            </div>
            <div
            v-if="
              ekycFormData.employment_status.is_changed_by_call_agent ? (ekycFormData.employment_status.call_agent_input === 'Full-Time' ||
              ekycFormData.employment_status.call_agent_input === 'Part Time' ||
              ekycFormData.employment_status.call_agent_input === 'Self-Employed' ||
              ekycFormData.employment_status.call_agent_input === 'Probation' ) : (ekycFormData.employment_status.customer_input === 'Full-Time' ||
              ekycFormData.employment_status.customer_input === 'Part Time' ||
              ekycFormData.employment_status.customer_input === 'Self-Employed' ||
              ekycFormData.employment_status.customer_input === 'Probation' )
            "
          >
            <!-- Employer / Business Address -->
            <p class="mt-4">Employer / Business Address</p>
            <div class="columns is-7 is-variable mt-1">
                <div class="column">
                    <InputLabel 
                    label="Building Number"
                    readonly="true"
                    :value="ekycFormData.emp_building_number.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                <AgentInputField
                  label="Building Number"
                  :value="ekycFormData.emp_building_number.call_agent_input"
                  :hide="!ekycFormData.emp_building_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel 
                    label="Building Name"
                    readonly="true"
                    :value="ekycFormData.emp_building_name.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Building Name"
                    :value="ekycFormData.emp_building_name.call_agent_input"
                    :hide="!ekycFormData.emp_building_name.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel 
                    label="Street"
                    readonly="true"
                    :value="ekycFormData.emp_street.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Street"
                    :value="ekycFormData.emp_street.call_agent_input"
                    :hide="!ekycFormData.emp_street.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel 
                    label="Town"
                    readonly="true"
                    :value="ekycFormData.emp_town.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Town"
                    :value="ekycFormData.emp_town.call_agent_input"

                    :hide="!ekycFormData.emp_town.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel 
                    label="Postal Code"
                    readonly="true"
                    :value="ekycFormData.emp_postal_code.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Postal Code"
                    :value="ekycFormData.emp_postal_code.call_agent_input"
                    :hide="!ekycFormData.emp_postal_code.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel
                    label="District"
                    readonly="true"
                    :value="ekycFormData.emp_district.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="District"
                    :value="ekycFormData.emp_district.call_agent_input"
                    :hide="!ekycFormData.emp_district.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel 
                    label="Country"
                    readonly="true"
                    :value="ekycFormData.emp_country.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Country"
                    :value="ekycFormData.emp_country.call_agent_input"
                    :hide="!ekycFormData.emp_country.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
          </div>
          <div v-if="ekycFormData.employment_status.is_changed_by_call_agent ? ekycFormData.employment_status.call_agent_input === 'Freelance' : ekycFormData.employment_status.customer_input === 'Freelance'">
            

            <InputLabel
              label="Are you working for any other platform's?"
              readonly="true"
              styles="mt-5"
              :value="
                ekycFormData.are_you_work_on_another_platform.customer_input ? 'Yes' : 'No'
              "
              class="non-interactable-input"
            />
            <AgentInputField
              label="Are you working for any other platform's?"
              :value="
                ekycFormData.are_you_work_on_another_platform.call_agent_input ? 'Yes' : 'No'
              "
              styles="mt-5"
              :hide="!ekycFormData.are_you_work_on_another_platform.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <div v-if="ekycFormData.are_you_work_on_another_platform.customer_input || ekycFormData.are_you_work_on_another_platform.call_agent_input ">
              <!-- <InputLabel
                label="What other platforms are you working in?"
                readonly="true"
                :value="ekycFormData.other_platforms_working_on.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField
                v-if="ekycFormData.other_platforms_working_on.is_changed_by_call_agent"
                label="What other platforms are you working in?"
                :value="ekycFormData.other_platforms_working_on.call_agent_input"
                styles="mt-5"
                class="non-interactable-input"
                readonly="true"
              /> -->
              <!-- other_platforms_working - customer inputs -->
              <DisplayBulletList class="non-interactable-input" label="What other platforms are you working in?" :items="ekycFormData.other_platforms_working_on.customer_input" :is-agent="false"/>

              <!-- other_platforms_working - agent inputs -->
             <DisplayBulletList class="non-interactable-input" v-if="ekycFormData.other_platforms_working_on.call_agent_input != null" label="What other platforms are you working in?" :items="ekycFormData.other_platforms_working_on.call_agent_input" :is-agent="true"/>
              
              <br />
              <InputLabel
                label="Please Specify"
                type="textarea"
                v-if="ekycFormData.other_platforms_working_on_other_selected.customer_input"
                readonly="true"
                :value="ekycFormData.other_platforms_working_on_other_description.customer_input"
                class="non-interactable-input"
              />
              <AgentInputField
                label="Please Specify"
                type="textarea"
                v-if="ekycFormData.other_platforms_working_on_other_selected.is_changed_by_call_agent"
                :value="ekycFormData.other_platforms_working_on_other_description.call_agent_input"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>

                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />
            </div>
            <div class="html2pdf__page-break"/>  
            <div>
                <!-- Tax Details -->
                <p>Tax Details</p>
                <div class="columns is-7 is-variable">
                    <div class="column">
                        <InputLabel
                            label="Are You a Taxpayer?"
                            readonly="true"
                            :value="ekycFormData.are_you_a_tax_payer.customer_input ? 'Yes' : 'No'"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Are You a Taxpayer?"
                            :value="
                            ekycFormData.are_you_a_tax_payer.call_agent_input ? 'Yes' : 'No'
                            "
                            :hide="!ekycFormData.are_you_a_tax_payer.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh" v-if="ekycFormData.are_you_a_tax_payer.customer_input || ekycFormData.are_you_a_tax_payer.call_agent_input">
                    <div class="column">
                        <InputLabel
                        label="Tax File Number"
                        readonly="true"
                        :value="ekycFormData.tax_file_numer.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Tax File Number"
                        :value="ekycFormData.tax_file_numer.call_agent_input"
                        :hide="!ekycFormData.tax_file_numer.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />

            </div>
            
            <div>
                <p>PEP Declaration</p> 
                <div class="columns is-7 is-variable">
                    <div class="column">
                        <InputLabel
                        label="Are You or Your Close Relative a Politically Exposed Person?"
                        readonly="true"
                        :value="
                            ekycFormData.pep_declaration.customer_input.not_politically_exposed
                            ? 'No'
                            : 'Yes'
                        "
                        class="non-interactable-input"
                        />
                        <InputLabel v-if="ekycFormData.pep_declaration.customer_input.close_relative_politically_exposed"
                        label="Are You or Your Close Relative a Politically Exposed Person?"
                        readonly="true"
                        :value="ekycFormData.pep_declaration.customer_input.close_relative_politically_exposed ? 'Yes : Close Relative Politically Exposed': 'No'"
                        class="non-interactable-input"
                        />
                        <InputLabel v-if="ekycFormData.pep_declaration.customer_input.iam_politically_exposed"
                        label="Are You or Your Close Relative a Politically Exposed Person?"
                        readonly="true"
                        :value="ekycFormData.pep_declaration.customer_input.iam_politically_exposed ? 'Yes : I am Politically Exposed' : 'No'"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <div v-if="ekycFormData.pep_declaration.is_changed_by_call_agent">
                            <AgentInputField  v-if="ekycFormData.pep_declaration.call_agent_input.not_politically_exposed"
                                label="Are You or Your Close Relative a Politically Exposed Person?"
                                :value="ekycFormData.pep_declaration.call_agent_input.not_politically_exposed ? 'No' : 'Yes'"
                                :hide="!ekycFormData.pep_declaration.is_changed_by_call_agent"
                                readonly="true"
                                class="non-interactable-input"
                            />
                        </div>
                        <div v-if="ekycFormData.pep_declaration.is_changed_by_call_agent">
                        <AgentInputField  v-if="ekycFormData.pep_declaration.call_agent_input.close_relative_politically_exposed"
                            label="Are You or Your Close Relative a Politically Exposed Person?"
                            :value="ekycFormData.pep_declaration.call_agent_input.close_relative_politically_exposed ? 'Yes : Close Relative Politically Exposed' : 'No'"
                            :hide="!ekycFormData.pep_declaration.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                        </div>
                        <div v-if="ekycFormData.pep_declaration.is_changed_by_call_agent">
                        <AgentInputField  v-if="ekycFormData.pep_declaration.call_agent_input.iam_politically_exposed"
                            label="Are You or Your Close Relative a Politically Exposed Person?"
                            :value="ekycFormData.pep_declaration.call_agent_input.iam_politically_exposed ? 'Yes : I am Politically Exposed' : 'No' "
                            :hide="!ekycFormData.pep_declaration.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                        </div>
                    </div>             
                </div>

                <div v-if="!ekycFormData.pep_declaration.customer_input.not_politically_exposed">
                    <div class="columns is-7 is-variable" v-for="pep_detail in ekycFormData.pep_declaration.customer_input.pep_person_details" :key="pep_detail.name">
                    <div class="column">
                        <InputLabel
                        v-if="pep_detail.name != ''"
                        label="Name"
                        readonly="true"
                        :value="pep_detail.name"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <InputLabel
                        v-if="pep_detail.designation != ''"
                        label="Designation"
                        readonly="true"
                        :value="pep_detail.designation"
                        class="non-interactable-input"
                        />
                    </div>
                    </div>
                </div>

                <div v-if="ekycFormData.pep_declaration.is_changed_by_call_agent">
                    <div class="columns is-7 is-variable" v-for="pep_detail in ekycFormData.pep_declaration.call_agent_input.pep_person_details" :key="pep_detail.name">
                    <div class="column">
                        <AgentInputField
                        v-if="pep_detail.name != ''"
                        label="Name"
                        :value="pep_detail.name"
                        :hide="!ekycFormData.pep_declaration.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        v-if="pep_detail.designation != ''"
                        label="Designation"
                        :value="pep_detail.designation"
                        :hide="!ekycFormData.pep_declaration.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                    </div>
                </div>
                
            </div>

            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            /> 

            <div>
                <p>Financial Details</p>
                <div class="columns is-7 is-variable">
                        <div class="column">
                            <DisplayBulletList class="non-interactable-input" label="Source of Funds"  :items="ekycFormData.source_of_funds.customer_input" :is-agent="false"/>
                        </div>
                        <div class="column">
                            <!-- source of funds - agent inputs -->
                            <DisplayBulletList style="color:red" class="non-interactable-input" label="Source of Funds" v-if="ekycFormData.source_of_funds.call_agent_input != null"  :items="ekycFormData.source_of_funds.call_agent_input" :is-agent="true"/>
                        </div>
                </div>

                <br />
                <InputLabel
                    v-if="ekycFormData.source_of_funds_other.customer_input"
                    label="Please Specify"
                    type="textarea"
                    readonly="true"
                    :value="ekycFormData.source_of_funds_other_description.customer_input"
                    class="non-interactable-input"
                />
                <AgentInputField
                    label="Please Specify"
                    type="textarea"
                    :value="ekycFormData.source_of_funds_other_description.call_agent_input"
                    :hide="!ekycFormData.source_of_funds_other_description.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                />
                
                
                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />
            </div>

            <p>Branch Details</p>
            <div class="columns is-7 is-variable">
                <div class="column">
                    <InputLabel v-if="customer_branch"
                        label="Preferred Branch"
                        readonly="true"
                        :value="customer_branch"
                        class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                        v-if="agent_branch != null || agent_branch != -1"
                        label="Preferred Branch"
                        :value="agent_branch"
                        :hide="!ekycFormData.branch_id.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable">
                <div class="column">
                    <InputLabel
                        v-if="ekycFormData.branch_comment.customer_input != '' "
                        label=" Reason for opening an account in a branch that is located far from the permanent address"
                        readonly="true"
                        :value="ekycFormData.branch_comment.customer_input"
                        class="non-interactable-input"
                    />
                </div>
                <div class="column">   
                    <AgentInputField
                        v-if="ekycFormData.branch_comment.is_changed_by_call_agent"
                        label="Reason for opening an account in a branch that is located far from the permanent address"
                        :value="ekycFormData.branch_comment.call_agent_input"
                        readonly="true"
                        class="non-interactable-input"
                    />
                </div>
            </div>
            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            />

            <!-- Product Selection -->
            <div class="html2pdf__page-break"/>

            <div v-for="product in product_selection" :key="product.id">
                <p>Product Selection - 
                    <span v-if="product.account_type.is_changed_by_call_agent">
                        {{ product.account_type.customer_input }}
                    </span>
                    <span v-if="product.account_type.is_changed_by_call_agent == false">
                        {{ product.account_type.customer_input }}
                    </span>
                </p>
                <div class="columns is-7 is-variable">
                    <div class="column ">
                        <InputLabel
                        label="Currency"
                        readonly="true"
                        :value="product.currency_type.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Currency"
                        :value="product.currency_type.call_agent_input"
                        :hide="!product.currency_type.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div>
                    <!-- saving_account_type -->
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <!-- saving_account_type -->
                        <div class="column">
                            <InputLabel
                            label="Account"
                            readonly="true"
                            :value="product.account_type.customer_input"
                            class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                            label="Account"
                            :value="product.account_type.call_agent_input"
                            :hide="!product.account_type.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                        </div>
                    </div>

                    <!-- Purpose of Account Opening -->
                    <!-- purpose of opening account - customer inputs -->
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column">
                            <DisplayBulletList class="non-interactable-input" label="Purpose of Account Opening" :items="product.purpose_of_opening_customer_inputs" :is-agent="false"/>
                        </div>
                        <div class="column">
                            <!-- purpose of opening account - agent inputs -->
                            <DisplayBulletList style="color:red" class="non-interactable-input" v-if="product.purpose_of_opening_account.is_changed_by_call_agent" label="Purpose of Account Opening" :items="product.purpose_of_opening_agent_inputs" :is-agent="true"/>
                        </div>
                    </div>
                    
                    
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        
                        <!-- OTHER SPECIFIED  -->
                        <div class="column">
                            <InputLabel
                                v-if="product.purpose_of_opening_other_specify_customer_inputs !== ''"
                                label="Please Specify the Purpose"
                                type="textarea"
                                readonly="true"
                                :value="product.purpose_of_opening_other_specify_customer_inputs"
                                class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                                label="Please Specify the Purpose"
                                type="textarea"
                                :value="product.purpose_of_opening_other_specify_agent_inputs"
                                :hide="product.purpose_of_opening_other_specify_agent_inputs == ''"
                                readonly="true"
                                class="non-interactable-input"
                            />
                        </div>
                    </div>          
                </div>
                <!-- Anticipated Volumes of Deposits from above Sources -->
                
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">  
                        <InputLabel 
                            label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
                            readonly="true"
                            :value="product.anticipated_volume.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">  
                        <AgentInputField
                            label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
                            :value="product.anticipated_volume.call_agent_input"
                            :hide="!product.anticipated_volume.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- Initial Deposit -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">  
                        <InputLabel 
                            label="Initial Deposit"
                            readonly="true"
                            :value="product.initial_deposit_amount.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">  
                        <AgentInputField
                            label="Initial Deposit"
                            :value="product.initial_deposit_amount.call_agent_input"
                            :hide="!product.initial_deposit_amount.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                
            </div>

            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            />

            <div>
                <!-- Value Added Services -->
                <p>Value Added Services</p>
                <div class="columns is-7 is-variable">
                    <div class="column">
                    <InputLabel
                        label="SMS Alerts"
                        readonly="true"
                        :value="ekycFormData.vas_sms_alert.customer_input ? 'Yes' : 'No'"
                        class="non-interactable-input"
                    />
                    </div>

                    <div class="column">
                    <InputLabel
                        label="Online Banking"
                        readonly="true"
                        :value="ekycFormData.vas_online_banking.customer_input ? 'Yes' : 'No'"
                        class="non-interactable-input"
                    />
                    </div>
                </div>               
                <div>
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column is-half" v-if="ekycFormData.residentship.customer_input == 'Sri Lanka'">
                        <InputLabel
                            label="Virtual Wallet"
                            readonly="true"
                            :value="ekycFormData.vas_virtual_wallet.customer_input ? 'Yes' : 'No'"
                            class="non-interactable-input"
                        />
                        </div>

                        <div class="column is-half">
                        <InputLabel
                            label="E-Statement"
                            readonly="true"
                            :value="ekycFormData.vas_estatement.customer_input ? 'Yes' : 'No'"
                            class="non-interactable-input"
                        />
                        </div>
                    </div>
                    <!--  debit card -->
                    <div v-if="GlobalFunctions.checkDebitCardSectionVisible(ekycFormData.residentship,product_selection)">
                        <div class="columns is-7 is-variable" style="margin-top:-4vh">
                            <div class="column">
                                <InputLabel
                                    label="Debit Card"
                                    readonly="true"
                                    :value="ekycFormData.vas_debit_card.customer_input ? 'Yes' : 'No'"
                                    class="non-interactable-input"
                                />
                            </div>
                            <div class="column">
                                <AgentInputField
                                    label="Debit Card"
                                    :value="ekycFormData.vas_debit_card.call_agent_input ? 'Yes' : 'No'"
                                    styles="mt-5"
                                    :hide="!ekycFormData.vas_debit_card.is_changed_by_call_agent"
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                        </div>
                        <div class="columns is-7 is-variable" style="margin-top:-4vh">
                            <div class="column">
                                <InputLabel
                                    v-if="ekycFormData.vas_debit_card.customer_input"
                                    label="POS Required"
                                    readonly="true"
                                    :value="
                                    ekycFormData.vas_debit_card_pos_required.customer_input
                                    ? 'Yes'
                                    : 'No'
                                "
                                class="non-interactable-input"
                                />
                            </div>
                            <div class="column">
                                <AgentInputField
                                    label="POS Required"
                                    :value="
                                    ekycFormData.vas_debit_card_pos_required.call_agent_input
                                    ? 'Yes'
                                    : 'No'
                                "
                                    :hide="!ekycFormData.vas_debit_card_pos_required.is_changed_by_call_agent"
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                        </div>
                        <div class="columns is-7 is-variable">
                            <!-- If costomer POS REQUIRED TRUE THIS WILL SHOW-->
                            <div class="column">
                                <InputLabel v-if="ekycFormData.vas_debit_card_pos_required.customer_input"
                                    label="Account to be Linked for POS Transaction"
                                    readonly="true"
                                    :value="ekycFormData.vas_debit_card_pos_account.customer_input"
                                    class="non-interactable-input"
                                />
                                <AgentInputField
                                    label="Account to be Linked for POS Transaction"
                                    :value="ekycFormData.vas_debit_card_pos_account.call_agent_input"
                                    :hide="
                                    !ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent
                                "
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                        </div>

                        <InputLabel
                            v-if="ekycFormData.vas_debit_card.customer_input"
                            label="Name to be Printed on Card"
                            readonly="true"
                            :value="ekycFormData.vas_debit_card_name.customer_input"
                            class="non-interactable-input"
                        />
                        <AgentInputField
                            label="Name to be Printed on Card"
                            :value="ekycFormData.vas_debit_card_name.call_agent_input"
                            :hide="!ekycFormData.vas_debit_card_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                        <!-- {{ ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent + "4" }}
                        <br />
                        {{ ekycFormData.vas_debit_card_collection_details.call_agent_input }} -->
                        <div class="columns is-7 is-variable mt-3">
                            <div class="column">
                                <InputLabel
                                    v-if="ekycFormData.vas_debit_card.customer_input"
                                    label="Card Collection Details"
                                    readonly="true"
                                    :value="
                                    ekycFormData.vas_debit_card_collection_details.customer_input
                                "
                                class="non-interactable-input"
                                />
                                <AgentInputField
                                    v-if="ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent"
                                    label="Card Collection Details"
                                    :value="
                                    ekycFormData.vas_debit_card_collection_details.call_agent_input
                                "
                                    :hide="
                                    !ekycFormData.vas_debit_card_collection_details
                                    .is_changed_by_call_agent
                                "
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                            <div class="column">
                                <InputLabel
                                    v-if="ekycFormData.vas_debit_card.customer_input"
                                    label="PIN Collection Details"
                                    readonly="true"
                                    :value="
                                    ekycFormData.vas_debit_card_pin_collection_details.customer_input
                                "
                                class="non-interactable-input"
                                />
                                <AgentInputField
                                    v-if="ekycFormData.vas_debit_card_pin_collection_details.is_changed_by_call_agent"
                                    label="PIN Collection Details"
                                    :value="
                                    ekycFormData.vas_debit_card_pin_collection_details.call_agent_input
                                "
                                    :hide="
                                    !ekycFormData.vas_debit_card_pin_collection_details
                                    .is_changed_by_call_agent
                                "
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            />

            <div>
                <!-- Video Call Details -->
                <p>Video Call Details</p>
                <div>
                    <div class="columns is-7 is-variable mt-1">
                        <div class="column">
                            <InputLabel v-if="ekycFormData.video_call_date"
                                label="Preferred Date"
                                readonly="true"
                                :value="getFormattedDateTime(ekycFormData.video_call_date)"
                                class="non-interactable-input"
                            />
                            </div>
                            <div class="column">
                            <InputLabel
                                label="Preferred Time"
                                readonly="true"
                                :value="ekycFormData.video_call_time"
                                class="non-interactable-input"
                            />
                        </div>
                    </div>
                </div> 

                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />
            </div>

            <!-- image part -->
            <br>              
            <div style="display: flex; width: 56vw;">
            <div v-if="ekycFormData.hold_application_attachments != null" style="width: 28vw;">
                <label style="margin-left:2vw;">Image Attachments of Detail Reviewer</label>
                <div v-for="(item, index) in ekycFormData.hold_application_attachments" :key="index">
                    <div style="margin-top: 20px">
                        <label style="margin-left:3vw"> Image Attachment {{ index + 1 }}</label>        
                        <div class="container"  style="width: 25vw; border: 2px solid #000000; border-radius: 2px; padding: 2px;">
                            <img
                                :src="item.path"
                                style="height: 40vh"
                                resposive
                                alt="Attachment"
                            />
                            <br>
                            <label>Comment : </label>
                            <label>{{item.comment}}</label>
                            <br/>
                        </div>
                    </div> 
                    <div v-if="index === 2">
                        <div class="html2pdf__page-break"/>
                    </div> 
                </div>   
                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />             
            </div>
            <div v-if="ekycFormData.hold_application_attachments_call_agent != null" style="width: 28vw;">
                <label  style="margin-left:2vw">Image Attachments of Call Agent</label>
                <div v-for="(item, index) in ekycFormData.hold_application_attachments_call_agent" :key="item.path">
                    <div style="margin-top: 20px">
                        <label style="margin-left:3vw"> Image Attachment {{ index + 1 }}</label>
                        <div class="container" style="width: 25vw; border: 2px solid #000000; border-radius: 2px; padding: 2px;">
                            <img
                                :src="item.path"
                                style="height:40vh;"
                                resposive
                                alt="Attachment"
                            />
                            <br />
                            <label>Comment : </label>
                            <label>{{ item.comment }}</label>
                            <br />
                        </div>
                    </div>
                    <div v-if="index === 2">
                        <div class="html2pdf__page-break"/>
                    </div>
                </div>
            </div>  
        </div>  
            <div v-if="ekycFormData != null">

                <!-- Face Comparisons -->
                <div  v-if="ekycFormData.selfie_image != null">
                    <p class="has-text-danger">Face Comparisons</p>
                    <div class="columns is-7 is-variable" style="width: 60vw;">
                        <div class="column ">
                            <label><b>Form Selfie image</b></label>
                            <br>
                        <img class="imgAttachment"
                            :src="ekycFormData.selfie_image"
                             
                            resposive
                            alt="Selfie Image"/>
                        </div>
                        <div class="column " >
                            <label><b>Video call Selfie image</b></label>
                            <br>
                            <img class="imgAttachment"
                                :src="videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.srcObject"
                                 
                                resposive
                                alt="Vid call Selfie Image"/>
                        </div>
                    </div>
                    
                    <!-- <hr
                        :style="{
                        'background-color': '#1b1b1b',
                        height: '1.8px',
                        opacity: '0.5',
                        'margin-inline': '4px',
                        }"
                    /> -->

                    <!-- <div class="html2pdf__page-break"/> -->

                </div>
                
                <div v-if="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image != null">
                    <!-- User Uploads and Video Call Data Comparison -->
                    <p class="has-text-danger">User Uploads and Video Call Data Comparison</p>
                    <!-- NIC -->
                    <div v-if="ekycFormData.nic_front_attachment.attachment_url !== ''" class="columns is-7 is-variable" style="width: 60vw;">
                        <div class="column ">
                            <label><b>User uploaded NIC front image</b></label>
                                <br>
                            <img v-if="ekycFormData.nic_front_attachment.attachment_type !== 'pdf'"
                                class="imgAttachment"
                                :src="ekycFormData.nic_front_image"     
                                resposive
                                alt="Form NIC Front Image"/>
                            <img v-else
                                class="imgAttachment"
                                :src="pdfPlaceholder"
                                resposive
                                alt="NIC Front Image"/>
                        </div>
                        <div class="column " >
                            <label><b>NIC front image from video call</b></label>
                                <br>
                            <img class="imgAttachment"
                                :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.srcObject"
                                    
                                resposive
                                alt="Vid call Selfie Image"/>

                        </div>
                    </div>

                    <div class="html2pdf__page-break"/>
                    
                    <!-- NIC rear image from video call -->
                    <div class="columns is-7 is-variable" v-if="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image != null" style="width: 60vw;">
                        <div class="column ">
                            <label><b>User uploaded NIC rear image</b></label>
                                <br>
                            <img v-if="ekycFormData.nic_back_attachment.attachment_type !== 'pdf'"
                                class="imgAttachment"
                                :src="ekycFormData.nic_back_attachment.attachment_url"   
                                resposive
                                alt="User uploaded NIC rear image"/>
                            <img v-else
                                class="imgAttachment"
                                :src="pdfPlaceholder"
                                resposive
                                alt="User uploaded NIC rear image"/>
                        </div>
                        <div class="column " >
                            <label><b>NIC rear image from video call</b></label>
                                <br>
                            <img class="imgAttachment"
                                :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.srcObject"
                                    
                                resposive
                                alt="NIC rear image from video call"/>
                        </div>
                    </div>

                    <br>
                </div>

                    
                    <!-- Passport -->
                    <div v-if="ekycFormData.passport_number.customer_input != '' || ekycFormData.passport_number.call_agent_input != '' ">
                        <div v-if="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image != null"
                            class="columns is-7 is-variable"
                            style="width: 60vw;"
                        >
                            <div class="column " v-if="ekycFormData.passport_bio_page_attachment.attachment_url !== ''">
                                <label><b>User uploaded Passport bio page image</b></label>
                                    <br>
                                <img v-if="ekycFormData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                                    class="imgAttachment"
                                    :src="ekycFormData.passport_bio_page_attachment.attachment_url"
                                    resposive
                                    alt="User uploaded Passport bio page image"/>
                                <img v-else
                                    class="imgAttachment"
                                    :src="pdfPlaceholder"
                                    resposive
                                    alt="User uploaded Passport bio page image"/>
                            </div>
                            
                            <div class="column " >
                                <label><b>Passport bio page  image from video call</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.srcObject"
                                    
                                    resposive
                                    alt="Passport bio page  image from video call"/>
                            </div>
                        </div>

                        <div class="columns is-7 is-variable" v-if="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image != null" style="width: 60vw;">
                            <div class="column " v-if="ekycFormData.passport_alteration_attachment.attachment_url !== ''">
                                <label><b>User uploaded Passport alteration page image</b></label>
                                    <br>
                                <img v-if="ekycFormData.passport_alteration_attachment.attachment_type !== 'pdf'"
                                    class="imgAttachment"
                                    :src="ekycFormData.passport_alteration_attachment.attachment_url"
                                    resposive
                                    alt="User uploaded Passport alteration page image"/>
                                <img v-else
                                    class="imgAttachment"
                                    :src="pdfPlaceholder"
                                    resposive
                                    alt="User uploaded Passport alteration page image"/>
                            </div>
                            <div class="column " >
                                <label><b>Passport alteration page  image from video call</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.srcObject"
                                    style="width:26vw; height:26vw"
                                    resposive
                                    alt="Passport alteration page  image from video call"/>
                            </div>
                        </div>

                        <div v-if="ekycFormData.passport_entry_stamp_attachment.attachment_url !== ''">
                            <div class="html2pdf__page-break"/>
                        </div>

                        <div class="columns is-7 is-variable" v-if="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image != null" style="width: 60vw;">
                            <div class="column " v-if="ekycFormData.passport_entry_stamp_attachment.attachment_url !== ''">
                                <label><b>User uploaded Passport entry stamp page image</b></label>
                                    <br>
                                <img v-if="ekycFormData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                                    class="imgAttachment"
                                    :src="ekycFormData.passport_entry_stamp_attachment.attachment_url"
                                    resposive
                                    alt="User uploaded Passport entry stamp page image"/>
                                <img v-else
                                    class="imgAttachment"
                                    :src="pdfPlaceholder"
                                    resposive
                                    alt="User uploaded Passport entry stamp page image"/>
                            </div>
                            <div class="column " >
                                <label><b>Passport entry stamp page  image from video call</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.srcObject"
                                    
                                    resposive
                                    alt="Passport entry stamp page  image from video call"/>
                            </div>
                        </div>
                    </div>

                    <hr style="width:90vw"
                    :style="{
                        'background-color': '#1b1b1b',
                        height: '1.8px',
                        opacity: '0.5',
                        'margin-inline': '4px',
                    }"
                    />
                    
                    <div v-if="ekycFormData.visa_attachment.attachment_url != ''">
                        <!-- Visa image -->
                        <p class="is-uppercase">Visa image</p>
                        <div class="columns is-7 is-variable" style="width: 60vw;">
                            <div class="column is-three-fifth">
                                <label><b>User uploaded Visa image</b></label>
                                    <br>
                                <img v-if="ekycFormData.visa_attachment.attachment_type !== 'pdf'"
                                    class="imgAttachment"
                                    :src="ekycFormData.visa_attachment.attachment_url"
                                    resposive
                                    alt="User uploaded Visa image"/>
                                <img v-else
                                    class="imgAttachment"
                                    :src="pdfPlaceholder"
                                    resposive
                                    alt="User uploaded Visa image"/>
                            </div>
                            <div class="column ">
                                <label><b>Visa image from video call</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.srcObject"
                                     
                                    resposive
                                    alt="Signature image from video call"/>
                            </div>

                        </div>
                        <hr style="width:90vw"
                            :style="{
                                'background-color': '#1b1b1b',
                                height: '1.8px',
                                opacity: '0.5',
                                'margin-inline': '4px',
                            }"
                                />
                    
                            
                    </div>

                        <div>
                            <div v-if="videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image != null">
                                <!-- address proof document -->
                                <p class="is-uppercase">address proof document</p>
                                <div class="columns is-7 is-variable" style="width: 60vw;">
                                <div class="column ">
                                    <label><b>User uploaded address proof document</b></label>
                                        <br>
                                    <img v-if="ekycFormData.address_proof_attachment.attachment_type !== 'pdf'"
                                        class="imgAttachment"
                                        :src="ekycFormData.address_proof_attachment.attachment_url"
                                        resposive
                                        alt="User uploaded address proof document"/>
                                    <img v-else
                                        class="imgAttachment"
                                        :src="pdfPlaceholder"
                                        resposive
                                        alt="User uploaded address proof document"/>
                                </div>
                                <div class="column " >
                                    <label><b>Address proof document from video call</b></label>
                                        <br>
                                    <img class="imgAttachment"
                                        :src="videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.srcObject"
                                        
                                        resposive
                                        alt="Address proof document from video call"/>
                                </div>
                            </div>
                            <hr style="width:90vw"
                                :style="{
                                    'background-color': '#1b1b1b',
                                    height: '1.8px',
                                    opacity: '0.5',
                                    'margin-inline': '4px',
                                }"
                                />
                        </div>

                        <div v-if="ekycFormData.visa_image != 'sample_image'">
                            <div class="html2pdf__page-break"/>
                        </div>

                        <div v-if="videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image != null">
                            <!-- Signature image -->
                            <p class="is-uppercase">Signature image</p>
                            <div class="columns is-7 is-variable" style="width: 60vw;">
                                <div class="column is-three-fifth">
                                    <label><b>User uploaded Signature image</b></label>
                                        <br>
                                    <img class="imgAttachment"
                                        :src="ekycFormData.signature_image"
                                        
                                        resposive
                                        alt="User uploaded Signature image"/>
                                </div>
                                <div class="column ">
                                    <label><b>Signature image from video call</b></label>
                                        <br>
                                    <img class="imgAttachment"
                                        :src="videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.srcObject"
                                        
                                        resposive
                                        alt="Signature image from video call"/>
                                </div>

                        </div>

                    <hr style="width:90vw"
                            :style="{
                                'background-color': '#1b1b1b',
                                height: '1.8px',
                                opacity: '0.5',
                                'margin-inline': '4px',
                            }"
                            />
                    </div>
                </div>                 
                <div class="columns is-7 is-variable">
                    <div class="column ">
                        <p class="mb-2">Customer Spoken Language</p>
                        <div v-if="formData.spoken_language">
                        <AgentInputField
                            label="Customer Spoken Language"
                            :value="formData.spoken_language"
                            readonly="true"
                            class="non-interactable-input"
                        />
                        </div>
                    </div>
                </div>
            </div> 
    
    </section>
    </vue-html2pdf> 
    </div>
</template>
<script>
import {useAuthStore} from "../../store/authStore"; 
import {useVideoKycStore} from "../../store/videoKycStore";
import VueHtml2pdf from 'vue-html2pdf' 
import ImageBox from "../../components/ImageBox.vue";
import NetworkManager from "../../network";
import {GlobalFunctions} from "../../helpers/globalFunctions";
import {GeoPositionCoordinates} from "../../helpers/classes/geoPositionCoordinates";
import ShowMapLocation from "../../components//ShowMapLocation.vue";
import {DateTimeManger} from "../../helpers/dateTimeManger";
import InputLabel from "../../components/InputLabel.vue";
import AgentInputField from "../../components/AgentInputField.vue";
import DisplayBulletList from "../../components/DisplayBulletList.vue";
import FullNameView from "../../components/FullNameView.vue"

  export default {
    name: "ApplicationDetailReviewcompir",
    components: {
    ImageBox,
    VueHtml2pdf,
    ShowMapLocation,
    InputLabel,
    AgentInputField,
    DisplayBulletList,
    FullNameView,
  },
    computed: {
      GlobalFunctions() {
        return GlobalFunctions
      },
    },
    emits:['close'],
    data() {
        return{
            pdfPlaceholder : require("@/assets/Images/pdf.png"),
            isLoading:true,
            ekycFormData:[],
            product_selection:[],
            branchArray:[],
            videoCallDetail:[],
            filetitle:null,
            filename:null,
            branch:[],
            customer_branch: null,
            agent_branch: null,
            contentRendered: false,
            progress: 0,
            generatingPdf: false,
            pdfDownloaded: false,
            showMessageBox: false,

            showCustomerLocation : false,
            showVideoCallCustomerLocation : false,

            kycFormCustomerLocation: new GeoPositionCoordinates(),
            videoKycCustomerLocation: new GeoPositionCoordinates(),
        }
    },
    setup(){
    const videoKycStore = useVideoKycStore()
    const authStore = useAuthStore()
    return {videoKycStore, authStore}
  },
    methods :{
        goback(){
            this.$router.go(-1);
        },

        disableResize() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        handleResize() {
            const desiredWidth = 1920; // Desired width in pixels
            const desiredHeight = 1080; // Desired height in pixels
            window.resizeTo(desiredWidth, desiredHeight);
        },

        generatePDF() {
            // Get the HTML content of the component
            console.log("Generating PDF")
            this.$refs.html2Pdf.generatePdf() 
        },

        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        startPagination() {
            console.log(`PDF has started pagination`)
        },
        hasPaginated () {
            console.log(`PDF has been paginated`)
        },

      async beforeDownload ({ html2pdf, options, pdfContent }) {
          const comp = this
          console.warn('On before pdf downloading.....')
          await html2pdf().set(options).from(pdfContent).outputPdf().then((pdf) => {
                    // const totalPages = pdf.internal.getNumberOfPages()
                    // for (let i = 1; i <= totalPages; i++) {
                    // 	pdf.setPage(i)
                    // 	pdf.setFontSize(10)
                    // 	pdf.setTextColor(150)
                    // 	pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    // }

            let base64Pdf = btoa(pdf)
            console.log("pdf was generated ....")
            // console.log(base64Pdf)

            comp.videoKycStore.answeredVideoKycData.base64pdfdata = base64Pdf;

            if(comp.videoKycStore.answeredVideoKycData.base64pdfdata !== '' || comp.videoKycStore.answeredVideoKycData.base64pdfdata!== null)
            {
              this.emitCloss()
            }

          })
      },

        hasDownloaded (blobPdf) {
            const comp = this;
            console.warn(`PDF has downloaded............`)
            comp.pdfDownloaded = true
            console.log(blobPdf)

            var reader = new FileReader();
            reader.readAsDataURL(blobPdf);

            reader.onloadend = function() {
                comp.videoKycStore.answeredVideoKycData.base64pdfdata = reader.result;                
                console.log(comp.videoKycStore.answeredVideoKycData.base64pdfdata);
            }
            if(comp.videoKycStore.answeredVideoKycData.base64pdfdata !== '' || comp.videoKycStore.answeredVideoKycData.base64pdfdata!== null)
            {
                this.emitCloss()
            }

        },

        emitCloss(){
            this.$emit('ClossApp',this.showMessageBox)
        },


        getFormattedDateTime(date){
            return DateTimeManger.formatCustomerInputDates(date)
        },

        // GetApplicationData: function (){
        //     const comp= this
        //     const data = { 
        //         id: comp.authStore.kyc_form_id
        //     }
        //     //console.log("ID---------------------",comp.authStore.kyc_form_id)
        //     //console.log("in getapplication")

        //     NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        //         console.log("in getapplicationlll",e.data)
        //         if(e.statusCode === 200){
        //         comp.formData = e.data.kycApplication
        //         //console.log("formData RECEIVED", comp.formData)
        //         comp.products = e.data.productSelection
        //         console.log("products ", comp.products)
        //         comp.videoCallDetail=e.data.videoCallDetail
        //         comp.branch=e.data.customerBranch
        //         comp.customer_branch = e.data.customerBranch;
        //         comp.agent_branch = e.data.agentBranch;

        //         comp.markerPosition = e.data.kycApplication.customer_location;
        //         const splitted = comp.markerPosition.split(',')
        //         const latitude = splitted[1]
        //         const again1 = latitude.split(':')
        //         const longitude = splitted[2]
        //         const again2 = longitude.split(':')
        //         const newvar = {latitude, longitude}
        //         const lattt = parseFloat(again1[1])
        //         const lngggg = parseFloat(again2[1])
        //         comp.markerPosition = {"lat": lattt, "lng": lngggg};       
        //         //console.log("kkkkk",comp.formData.are_you_a_tax_payer.customer_input)

        //         let customerLocation = JSON.parse(e.data.kycApplication.customer_location)
        //         //console.log("customer location")
        //         //console.log(customerLocation)
        //         comp.kycFormCustomerLocation = new GeoPositionCoordinates(customerLocation.latitude,customerLocation.longitude)
        //         comp.showCustomerLocation = true;

        //         // get video call location if available
        //         if(comp.videoCallDetail !== null){
        //             let videoCallCustomerLocation = JSON.parse(comp.videoCallDetail.video_call_location)
        //             //console.log("video call location")
        //             //console.log(videoCallCustomerLocation)
        //             comp.videoKycCustomerLocation = new GeoPositionCoordinates(videoCallCustomerLocation.latitude,videoCallCustomerLocation.longitude)
        //             comp.showVideoCallCustomerLocation = true;
        //         }
                

        //         // comp.products.forEach(product=>{

        //         //     product.perpose_of_account_opening_customer=''
        //         //     product.perpose_of_account_opening_agent=''

        //         //     product.purpose_of_opening_account.customer_input.forEach(purpose=>{
        //         //       if(product.perpose_of_account_opening_customer === ''){
        //         //           product.perpose_of_account_opening_customer = purpose.purpose
        //         //       }else{
        //         //           product.perpose_of_account_opening_customer += ", "+purpose.purpose
        //         //       }
        //         //     })

        //         //     if(product.purpose_of_opening_account.call_agent_input !== null){
        //         //       product.purpose_of_opening_account.call_agent_input.forEach(purpose=>{
        //         //         if(product.perpose_of_account_opening_agent === ''){
        //         //           product.perpose_of_account_opening_agent = purpose.purpose
        //         //         }else{
        //         //           product.perpose_of_account_opening_agent += ", "+purpose.purpose
        //         //         }
        //         //       })
        //         //     }
        //         // }) 
                
        //         }       
        //     })
        // },
        
        
        GetApplicationData(){
            this.ekycFormData = this.videoKycStore.answeredVideoKycData.ekycFormData 
            if(this.ekycFormData.title.is_changed_by_call_agent)
            {
                this.filetitle = this.ekycFormData.title.call_agent_input
            }
            else
            {
                this.filetitle = this.ekycFormData.title.customer_input
            }

            if(this.ekycFormData.full_name.is_changed_by_call_agent)
            {
                this.filename = this.ekycFormData.full_name.call_agent_input
            }
            else
            {
                this.filename = this.ekycFormData.full_name.customer_input
            }
            console.log("ekyc FormData",this.videoKycStore.answeredVideoKycData.ekycFormData )
            this.product_selection = GlobalFunctions.getFormattedProductDetails(this.videoKycStore.answeredVideoKycData.product_details )
            this.branchArray = this.$root.formDataSupport.branchesArray
            console.log("Product details",this.videoKycStore.answeredVideoKycData.product_details )

            const getcustomerbranch = this.branchArray.find((customerbranch) => customerbranch.id === this.ekycFormData.branch_id.customer_input) 
            console.log("getcustomerbranch",getcustomerbranch)
            this.customer_branch = getcustomerbranch.branch_name
            
            if(this.ekycFormData.branch_id.is_changed_by_call_agent)
            {
                const getagentbranch  = this.branchArray.find((agentbranch) => agentbranch.id === this.ekycFormData.branch_id.call_agent_input)
                console.log("getagentbranch",getagentbranch)
                this.agent_branch = getagentbranch.branch_name
            }

        }

        
    },
    // watch:{
    //     "videoKycStore.answeredVideoKycData.ekycFormData" (){
    //         this.GetApplicationData()
    //         setTimeout(() => {
    //             this.generatePDF();
    //         }, 3000);  
    //     }
    // },
    
    mounted(){
      const comp = this
      comp.disableResize();
        // this.ekycFormData = this.videoKycStore.answeredVideoKycData.ekycFormData 
        // this.product_selection = this.videoKycStore.answeredVideoKycData.ekycFormData 
        // this.branchArray = this.videoKycStore.answeredVideoKycData.branchArray

      comp.GetApplicationData()

      if(comp.ekycFormData != null)
      {
          setTimeout(() => {
            comp.generatePDF();
          }, 3000);
      }
    }
};
</script>
<style scoped>
.text{
    font-family: 'Courier New', Courier, monospace
}
.Cutsomertext{
    font-family: 'Courier New', Courier, monospace;
}
.Agenttext{
    font-family: 'Courier New', Courier, monospace;
    color:red;
}
label{
    font-weight: 700;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}
.imgAttachment{
    border: 2px solid #1b1b1b;
    padding: 1px;
}
.page-container {
  width: 70vw; /* Desired width */
}
html {
  /* Disable resizing of the page */
  resize: none;
}
body {
  overflow: hidden;
}
</style>
  