<template>
  <div style="height: 80.5vh; overflow: hidden !important;">
    <div class="columns" style="margin-top: 2.8vh; margin-left: 1.1vw;">
      <div class="column">
       <div style="position:fixed; margin-left: -1vw;">
          <b-button rounded @click="$router.go(-1)" style="border-radius: 20px; height: 40px; width: 40px;" type="is-primary" outlined> <i class="mdi mdi-arrow-left mdi-24px"></i></b-button>
        </div>
        <label
          style="
            font-family: Gotham-Medium;
            color: gray;
            margin-left: 32px;
            font-weight: 400px;
            font-size: 23px;
          "
          >Ongoing Monitoring List Notifications</label
        >
      </div>
    </div>

    <div class="body">
      <b-tabs size="is-small" type="is-toggle-rounded" position="is-centered" class="custom-tabs">
        <b-tab-item label="PEP Results" icon="card-account-details">


          <!-- <TableComp
            :tableData="filteredPepList"
            :columns="filteredPepColumnDetails"
            :searchableFields="pepSearchableFields"
            :previousPage="currentPage"
            styles="mt-4 mx-6"
          />  -->
      <div class="table-comp-wrapper">
        <table>
          <thead class="sticky-header">
            <tr>
              <th class="gray-font" >Applicant Name</th>
              <th class="gray-font">Address</th>
              <th class="gray-font">Gender</th>
              <th class="gray-font">Date of Birth</th>
              <th class="gray-font">Place of Birth</th>
              <th class="gray-font">Alias Names</th>
              <th class="gray-font">Passport Number</th>
              <th class="gray-font">Family Tree</th>
              <th class="gray-font">Country</th>
              <th class="gray-font">Source Name</th>
              <th class="gray-font">Source Link</th>
              <th class="gray-font">Source Description</th>
              <th class="gray-font">Natioanlity</th>
              <th class="gray-font">Designation</th>
              <th class="gray-font">Designation PEP Type</th>
            </tr>
          </thead>

        <tbody>
            <tr v-for="(item, index) in filteredPepList" :key="index" :class="{ 'disabled-row': item.disabled }">
              <td>{{ item.pep_name ? item.pep_name : 'N/A' }}</td>
              <td>
                <template v-if="item.pep_address">
                  <div v-for="(address, addressIndex) in item.pep_address" :key="addressIndex">{{ address }}</div>
              </template>
              <template v-else>N/A</template>
              </td>
              <td>{{ item.pep_gender ?  item.pep_gender : 'N/A' }}</td>
              <td>{{ item.pep_date_of_birth ? item.pep_date_of_birth : 'N/A' }}</td>
              <td>
                <template v-if="item.pep_place_of_birth">
                  <div v-for="(birthPlace, birthPlaceIndex) in item.pep_place_of_birth" :key="birthPlaceIndex">{{ birthPlace }}</div>
              </template>
              <template v-else>N/A</template>
              </td>
              <td>
                <template v-if="item.pep_alias_names">
                  <div v-for="(aliasName, aliasIndex) in item.pep_alias_names" :key="aliasIndex">{{ aliasName }}</div>
              </template>
              <template v-else>N/A</template>
              </td>
              <td>{{ item.pep_passport_number ? item.pep_passport_number : 'N/A'  }}</td>
              <td>
                <div v-if="item.pep_relations">
                    <div v-for="(value, key) in parsedFamilyTree(item.pep_relations)" :key="key">
                        <strong>{{ key }}:</strong> {{ value.join(', ') }}
                    </div>
                </div>
             </td>
              <td>
                <template v-if="item.pep_country">
                  <div v-for="(pepCountry, pepCountryIndex) in item.pep_country" :key="pepCountryIndex">{{ pepCountry }}</div>
              </template>
              <template v-else>N/A</template>
              </td>
              <td>{{ item.pep_source_name ? item.pep_source_name : 'N/A' }}</td>
              <td>{{ item.pep_source_link ? item.pep_source_link : 'N/A' }}</td>
              <td>{{ item.pep_source_description ? item.pep_source_description : 'N/A'  }}</td>
              <td>{{ item.type }}</td>
              <td>
              <div v-if="item.designation_data">
                <div v-for="(designation, index) in JSON.parse(item.designation_data)" :key="index">
                    <div>
                        {{ designation.designation }}
                    </div>
                </div>
            </div>
            <div v-else>N/A</div> </td>
              <td>
              <div v-if="item.designation_data">
                <div v-for="(designation, index) in JSON.parse(item.designation_data)" :key="index">
                    <div>
                        {{ designation.designation_pep_type }}
                    </div>
                </div>
            </div>
            <div v-else>N/A</div> </td>
              <td>{{ item.type_id }}</td>
              
            </tr>
          </tbody>
        </table> 
      </div> 
        </b-tab-item>
                  <b-tab-item label="Sanction Results" icon="card-bulleted">
                  <div class="table-comp-wrapper">
        <table>
          <thead class="sticky-header">
            <tr>
              <th class="gray-font" >Applicant Name</th>
              <th class="gray-font">Gender</th>
              <th class="gray-font">Date of Birth</th>
              <th class="gray-font">Alias Names</th>
              <th class="gray-font" >Associated Country</th>
              <th class="gray-font">Sanctioned Country</th>
              <th class="gray-font">President</th>
              <th class="gray-font">Aircraft Details</th>
              <th class="gray-font">Source Name</th>
              <th class="gray-font">Source Type</th>
              <th class="gray-font">Source Link</th>
              <th class="gray-font">Source Description</th>
              <th class="gray-font">Email</th>
              <th class="gray-font">NIC</th>
              <th class="gray-font">Passport</th>
              <th class="gray-font" >Phone Number</th>
            </tr>
          </thead>

        <tbody>
            <tr v-for="(item, index) in filteredSanctionList" :key="index">
              <td>{{ item.sanction_name ? item.sanction_name : 'N/A'}}</td>
              <td>{{ item.sanction_gender ? item.sanction_gender : 'N/A'}}</td>
              <td>{{ item.sanction_date_of_birth ? item.sanction_date_of_birth : 'N/A'}}</td>
              <td>
                <template v-if="item.sanction_alias_names">
                  <div v-for="(aliasName, aliasIndex) in item.sanction_alias_names" :key="aliasIndex">{{ aliasName }}</div>
                </template>
                <template v-else>N/A</template>
              </td>
               <td>
                <template v-if="item.sanction_associated_country">
                  <div v-for="(associatedCountry, countryIndex) in item.sanction_associated_country" :key="countryIndex">{{ associatedCountry }}</div>
                </template>
                <template v-else>N/A</template>
              </td>
              <td>{{ item.sanctioned_country ?  item.sanctioned_country : 'N/A'}}</td>
              <td>{{ item.type_id }}</td>
              <td>{{ item.type_name }}</td>
              <td>{{ item.sanction_source_name ? item.sanction_source_name : 'N/A' }}</td>
              <td>{{ item.sanction_source_type ? item.sanction_source_type : 'N/A' }}</td>
              <td>{{ item.sanction_source_link ?  item.sanction_source_link : 'N/A'}}</td>
              <td>{{ item.sanction_source_description ? item.sanction_source_description : 'N/A' }}</td>
              <td>{{ item.sanction_email ? item.sanction_email : 'N/A' }}</td>
              <td>{{ item.sanction_national_id ? item.sanction_national_id : 'N/A' }}</td>
              <td>{{ item.sanction_passport ? item.sanction_passport : 'N/A' }}</td>
              <td>{{ item.sanction_phone_number ? item.sanction_phone_number : 'N/A'  }}</td>
            </tr>
          </tbody>
        </table>
      </div>    
      <!-- <TableComp
        :tableData="filteredSanctionList"
        :columns="filteredSanctionColumnDetails"
        :searchableFields="sanctionSearchableFields"
        :previousPage="currentPage"
        styles="mt-4 mx-6"
      />  -->
    </b-tab-item> 
</b-tabs>
</div>
</div>

</template>

<script>
import TableComp from "../../components/TableComp.vue";
import NetworkManager from "@/network";
import {GlobalFunctions} from "../../helpers/globalFunctions";
import { BuefyHelper } from "../../helpers/bufeyHelper";


export default {
  components: {
    TableComp
  },
 
  data() {
    return {
      selectedRows: [],
      initialFilterConfigIsLoaded: false,
      selected:'',
      searchnow:false,
      branchName:'',
      branchList :  [],
      filteredBranchList : [],
      showVideoCallModel: false,
      searchKeyword: "",
      tableDetails: [],
      filteredTableData:[],
      tableinfo: "",
      applicationList: [],
      selectedBranch : "",
      notificationList:[],
      notificationStatusUpdateList:[],
      pepSearchableFields : ["pep_name", "pep_address", "pep_gender", "pep_date_of_birth", "pep_place_of_birth", "pep_alias_names", 
      "pep_passport_number", "pep_relations", "pep_country", "pep_source_name", "pep_source_link", "pep_source_description", "type", "designation_data", "designation_pep_type"],
      currentPage : 'NEW',
      filteredPepColumnDetails : [
        { id: 1, field: "pep_name", label: "Applicant Name" ,date:false},
        { id: 2, field: "pep_address", label: "Address",date:false },
        { id: 3, field: "pep_gender", label: "Gender",date:false },
        { id: 4, field: "pep_date_of_birth", label: "Date of Birth",date:false },
        { id: 5, field: "pep_place_of_birth", label: "Place of Birth",date:true },
        { id: 6, field: "pep_alias_names", label: "Alias Names",date:false },
        { id: 7, field: "pep_passport_number", label: "Passport Number",date:false },
        { id: 8, field: "pep_relations", label: "Family Tree",date:false },
        { id: 9, field: "pep_country", label: "Country",date:false },
        { id: 10, field: "pep_source_name", label: "Source Name",date:false },
        { id: 11, field: "pep_source_link", label: "Source Link",date:false },
        { id: 12, field: "pep_source_description", label: "Source Description",date:false },
        { id: 13, field: "type", label: "Natioanlity",date:false },
        { id: 14, field: "designation_data", label: "Designation",date:false },
        { id: 15, field: "designation_pep_type", label: "Designation PEP Type",date:false },
      ],
      sanctionSearchableFields : ["sanction_name", "sanction_gender", "sanction_date_of_birth", "sanction_alias_names",
      "sanction_associated_country", "sanctioned_country", "type_id", "type_name", "sanction_source_name",
      "sanction_source_type", "sanction_source_link", "sanction_source_description", "sanction_email", "sanction_national_id",
      "sanction_passport", "sanction_phone_number"
      ],
      filteredSanctionColumnDetails : [
        { id: 1, field: "sanction_name", label: "Applicant Name" ,date:false},
        { id: 2, field: "sanction_gender", label: "Gender",date:false },
        { id: 3, field: "sanction_date_of_birth", label: "Date of Birth",date:false },
        { id: 4, field: "sanction_alias_names", label: "Alias Names",date:false },
        { id: 5, field: "sanction_associated_country", label: "Associated Country",date:false },
        { id: 6, field: "sanctioned_country", label: "Sanctioned Country",date:false },
        { id: 7, field: "type_id", label: "President",date:false },
        { id: 8, field: "type_name", label: "Aircraft Details",date:false },
        { id: 9, field: "sanction_source_name", label: "Source Name",date:false },
        { id: 10, field: "sanction_source_type", label: "Source Type",date:false },
        { id: 11, field: "sanction_source_link", label: "Source Link",date:false },
        { id: 12, field: "sanction_source_description", label: "Source Description",date:false },
        { id: 13, field: "sanction_email", label: "Email",date:false },
        { id: 14, field: "sanction_national_id", label: "NIC",date:false },
        { id: 15, field: "sanction_passport", label: "Passport",date:false },
        { id: 16, field: "sanction_phone_number", label: "Phone Number",date:false },
      ],
    };
  },
 
 computed: {
    filteredPepList() {
      console.log("Dataset :",this.notificationList)
      let dataset = []
      for(let i=0; i< this.notificationList.length; i++)
      {
        if(this.notificationList[i].notification_type === "PEP")
        dataset.push(this.notificationList[i])
      }
      return dataset
    },
    filteredSanctionList() {
      // return this.notificationList.filter(item => item.notification_type === "SANCTION");
      console.log("Dataset :",this.notificationList)
      let dataset = []
      for(let i=0; i< this.notificationList.length; i++)
      {
        if(this.notificationList[i].notification_type === "SANCTION")
        dataset.push(this.notificationList[i])
      }
      return dataset
    }
  },

  methods: {

     parsedFamilyTree(familyTreeString) {
            try {
                return JSON.parse(familyTreeString);
            } catch (error) {
                console.error('Error parsing family tree:', error);
                return {};
            }
        },

    GetNotificationList: function (){
      const comp= this

      NetworkManager.apiRequest('api/WebhookNotifications/getWebhookNotificationDataList', {}, function (e){
        if(e.statusCode === 200){
          comp.notificationList = e.data
          console.log('comp.NotificationListtt', comp.notificationList);
        }
      })
    }, 

      UpdateReadNotificationStatus: function (){
      const comp= this
            
      NetworkManager.apiRequest('api/WebhookNotifications/markAllNotificationsAsRead', {}, function (e){
        if(e.statusCode === 200){
          comp.notificationStatusUpdateList = e.data
          console.log('comp.NotificationListtt', comp.notificationList);
        }
      })
    }, 
},

 mounted() {
    this.GetNotificationList();
    this.UpdateReadNotificationStatus();
   
    
}
};
</script>

<style scoped>
.table-comp-wrapper{
  height:56vh;
  width:1100px;
}
.wrapper {
  margin-left: 20px;
  margin-bottom: 10px;
  overflow-x: auto; 
  overflow-y: auto; 
  max-width: 100%; 
  height: 100vh; 
}

table {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0; 
  white-space: nowrap;
  
}

th.gray-font, td {
  border: none; 
  padding: 8px;
  text-align: left;
  
}

th.gray-font {
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid hsl(0deg, 0%, 86%);
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  font-family: "Gotham-Light" !important;
  color: #a7a9ac;
  height: 4em;
}

tr td {
  border-bottom: 1px solid hsl(0deg, 0%, 86%);
  height: 4em;
}

input[type="checkbox"] {
  margin-right: 5px;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #ffffff; 
  z-index: 1;
}

.button-container{
  margin-left : 450px;
  align : center;

}

.disabled-row {
  background-color: #f2f2f2; 
  color: #b0b0b0;
  cursor: not-allowed;
}

.disabled-row input[type="checkbox"] {
  opacity: 0.5; 
  cursor: not-allowed;
}
</style>