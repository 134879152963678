import { render, staticRenderFns } from "./KycFormView.vue?vue&type=template&id=453c260d&scoped=true"
import script from "./KycFormView.vue?vue&type=script&lang=js"
export * from "./KycFormView.vue?vue&type=script&lang=js"
import style0 from "./KycFormView.vue?vue&type=style&index=0&id=453c260d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453c260d",
  null
  
)

export default component.exports