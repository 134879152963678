<template>
  <div class="table-comp-wrapper">
    <b-table
    style="max-height: 100vh"
        v-if="tableData.length > 0"
        class="scrollable-table"
        :sticky-header="true"
        :data="GetFilteredData" :class="styles">

      <b-table-column
          v-for="column in columns"
          :key="column.id"
          :field="column.field"
          :label="column.label"
          v-slot="props"

      >

        <span v-if="column.field !== `action` && (!column.date) && column.field !== `status` && !column.hide">
           {{ props.row[column.field] }}
        </span>
        <span v-if="column.field !== `action` && (column.date ) && column.field !== 'status' && !column.hide">
          {{ getFormattedDateTime(props.row[column.field]) }}
        </span>
        <div v-if="column.field === `action-view` && !column.hide ">
          <b-field>
            <b-tooltip label="View" type="is-primary">
            <b-button type="is-primary" rounded size="is-small" icon-right="eye" @click="approvedViewKycApplication(props.row.id)" />
            </b-tooltip>
          </b-field>
        </div>

      </b-table-column>

    </b-table>

    <div v-else class="empty-indicator mt-5 has-text-centered" style="margin-top: 100px !important; ">
          <span class="icon has-text-grey-lighter is-normal">
               <i class="mdi mdi-information-outline mdi-48px"></i>
          </span>
      <br>
      <!-- <span class="ml-2 has-text-grey-lighter" style="font-size: 20px">No records to show</span> -->
      <br>
      <span class="has-text-grey-lighter">{{$props.customText}}</span>
    </div>

    <UserProfileDeleteModal v-if="showModal" :modelVisible="true" @close-modal="closeModal" />

  </div>
</template>

<script>
import NetworkManager from "../network";
import {DateTimeManger} from "../helpers/dateTimeManger";
import {useAuthStore} from "../store/authStore";
import ScheduleRescheduleMeeting from "./ScheduleRescheduleMeeting.vue";
import {filterTableData} from "../helpers/filterTableData";
import UserProfileDeleteModal from "./UserProfileDeleteModal";
import {FilteredUserByRole} from "../helpers/classes/admin/filteredUserByRole";
import {BuefyHelper} from "../helpers/bufeyHelper";

export default {

  components:
      {
        UserProfileDeleteModal,
        ScheduleRescheduleMeeting,
      },

  // props: ["tableData", "columns", "styles","previousPage","statusToAssign","searchableFields","searchKeyword","customText" ,"assignableUserRoleName"],
  props:{
    tableData : Array,
    columns : Array,
    styles : String,
    searchableFields : Array,
    searchKeyword : String,
    customText : String,
  },


  computed : {
    GetFilteredData(){
      const comp = this

      let filteredData = comp.filter.searchData(comp.tableData, comp.searchableFields, comp.searchKeyword)
      let disableDownloads = !filteredData.length > 0 ;

      comp.$emit("disableDownloadBtn",disableDownloads)

      return filteredData;

    }
  },

  name: "DebitCardTable",

  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },

  data() {
    return {

      type:'',
      filter:'',
      showModal:false,

    };

  },

  methods:{

    closeModal(){
      this.showModal =false
    },



    approvedViewKycApplication(id){
      const comp= this
      console.log("show kyc application for the ID : "+id)
      comp.authStore.kyc_form_id = id
      comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
      comp.$router.push('/approvedApplication/view/'+id);
    },

    getFormattedDateTime(date){
      return DateTimeManger.formatDateTime(date)
    },

    formatDate(date){
       return DateTimeManger.formatDate(date)
    },



  },


  // life cycle hooks
  created() {
    const comp = this
    comp.filter = filterTableData


  },

  mounted(){
    const comp = this
    console.log("table component ")
    console.log("table data "+comp.tableData)
  },
}

</script>
<style>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(88, 88, 88, 0.5);
  display:grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.scrollable-table {
  max-width: 80vw;
  overflow-x: auto;
}

.table-comp-wrapper {
  overflow: auto !important;
  
  
}
</style>
