<template>
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="card" 
      rounded style="width: 55vw; max-height:80vh;
      overflow-y: scroll;

      background-color: white;
      border: 2px solid #000000;
      border-radius: 25px;
      padding: 20px;">
      <div>
        <div class="columns" >
          <div class="column" style="text-align: center;">
            <label style="color: crimson; font-size: large;">DRP Verification Report</label>
            <hr style="margin-left: auto; margin-right: auto;">
          </div>
          <div class="column" style="margin-left: 91%; position: absolute;">
            <b-button @click="emitCloss">x</b-button>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          Data
        </div>
        <div class="column">
          Customer Filled
        </div>
        <div class="column">
          DRP Record
        </div>
        <div class="column">
          Status
        </div>
      </div>

      <div class="columns" style="background-color: rgba(155, 155, 155 ,0.1);">
        <div class="column">
          Date of Birth
        </div>
        <div class="column">
          {{customer_data.dob}}
        </div>
        <div class="column">
          {{drp_data.birth_date}}
        </div>
        <div class="column">
          <img class="status" :src="drpComparedStatus.dob" @error="imageplaceholder">
        </div>
      </div>

      <div class="columns" style="background-color: rgba(155, 155, 155 ,0.1);">
        <div class="column">
          NIC/EIC Number
        </div>
        <div class="column">
          {{customer_data.nic}}
        </div>
        <div class="column">
          {{drp_data.id}}
        </div>
        <div class="column">
          <img class="status" :src="drpComparedStatus.nic" @error="imageplaceholder">
        </div>
      </div>

      <div class="columns" style="background-color: rgba(155, 155, 155 ,0.1);">
        <div class="column">
          Permanent Address
        </div>
        <div class="column">
          {{customer_data.address}}
        </div>
        <div class="column">
          {{drp_data.address}}
        </div>
        <div class="column">
          <img class="status" :src="drpComparedStatus.address" @error="imageplaceholder">
        </div>
      </div>

      <div class="columns" style="background-color: rgba(155, 155, 155 ,0.1);">
        <div class="column">
          Full Name
        </div>
        <div class="column">
          {{customer_data.full_name}}
        </div>
        <div class="column">
          {{drp_data.name}}
        </div>
        <div class="column">
          <img class="status" :src="drpComparedStatus.full_name" @error="imageplaceholder">
        </div>
      </div>

      <div class="columns" style="background-color: rgba(155, 155, 155 ,0.1);">
        <div class="column">
          NIC Issue Date
        </div>
        <div class="column">
          {{customer_data.issue_date}}
        </div>
        <div class="column">
          {{drp_data.issued_date}}
        </div>
        <div class="column">
          <img class="status" :src="drpComparedStatus.issue_date" @error="imageplaceholder">
        </div>
      </div>

      <!-- <div class="columns" style="background-color: rgba(155, 155, 155 ,0.1);">
        <div class="column">
          DRP Reference No. Starts with E: or #
        </div>
        <div class="column">
          -
        </div>
        <div class="column">
          DRP Record
        </div>
        <div class="column">
          Status
        </div>
      </div> -->


      <div>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <label aria-controls="contentIdForA11y1">Add Comment/s for internal use</label>
        </b-collapse>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger="props">
                <label aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"
                      style="color: #ED1C24;">
                      <i class="mdi mdi-plus-circle-outline"></i>
                      Add Comment/s for internal use
                </label>
                  <!-- <b-button
                      label="Add Comment/s for internal use"
                      aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open" /> -->
              </template>
              <!-- <div class="notification"> -->
                  <div class="content">
                      <b-input maxlength="200"
                        v-model="DRP_internalcomment"
                        @input="emitDRPinternalcomment"
                        type="textarea"
                        placeholder="Specify"
                        rows = 5
                        style="width: 100%;"
                      ></b-input>
                  </div>
              <!-- </div> -->
          </b-collapse>

      </div>
      <div style="margin-left: auto; margin-right: auto; text-align: center;">
        <div class="columns">
          <div class="column">
            <b-button style=" width: 80%;"  @click="emitDRP_fail" type="is-primary" outlined>Fail</b-button>
          </div>
          <div class="column">
            <b-button style=" width: 80%;" type="is-primary" @click="emitDRP_success">Success</b-button>
          </div>
        </div>
        
      </div>
    </div>
  <!-- </b-modal>  -->
</template>

<script>
import NetworkManager from "@/network";
import {DateTimeManger} from "../helpers/dateTimeManger";

export default {
  name: 'CheckDRP',
  props: ["customer_data","drp_data","drp_status"],

  emits:['close'],

  data(){
    return{
      check : require("../assets/Images/ok.png"),
      wrong :  require("../assets/Images/wrong.png"),

      DRP_internalcomment:'',
      //DRP_isButtonDisabled: false,
      DRP_showMessageBox: false,
      defaultimg :require("../assets/Images/minus.png"),


      drpComparedStatus : {
        nic : "",
        dob: "",
        address: "",
        full_name: "",
        issue_date: "",
      },
    }
  },
  methods: {
    emitDRPinternalcomment() {
      this.$emit('update_DRP_internal_comment', this.DRP_internalcomment);
    },
    emitDRP_fail() {
      this.$emit('DRP_fail',this.DRP_showMessageBox);
    },
    emitDRP_success() {
      this.$emit('DRP_success',this.DRP_showMessageBox);
    },
    emitDRP() {
      this.$emit('DRP_data',this.DRPdata,);
    },
    emitCloss(){
      this.$emit('Closs_DRP',this.DRP_showMessageBox)
    },

    imageplaceholder(event)
    {
      event.target.src = this.defaultimg;
    },
    getFormattedDateTime(date){
      return DateTimeManger.formatCustomerInputDates(date)
    },


    compareDRPWithCustomerInputs(){
      const comp = this

      comp.customer_data.dob === comp.drp_data.birth_date ?  comp.drpComparedStatus.dob = comp.check : comp.drpComparedStatus.dob = comp.wrong
      comp.customer_data.address === comp.drp_data.address ? comp.drpComparedStatus.address = comp.check : comp.drpComparedStatus.address = comp.wrong
      comp.customer_data.nic === comp.drp_data.id ? comp.drpComparedStatus.nic = comp.check : comp.drpComparedStatus.nic = comp.wrong
      comp.customer_data.issue_date === comp.drp_data.issued_date ?  comp.drpComparedStatus.issue_date = comp.check : comp.drpComparedStatus.issue_date = comp.wrong
      comp.customer_data.full_name.toLowerCase() === comp.drp_data.name.toLowerCase() ? comp.drpComparedStatus.full_name = comp.check : comp.drpComparedStatus.full_name = comp.wrong

    },
    
  },
  mounted(){

    this.compareDRPWithCustomerInputs()
  }
};
</script>

<style>
.status{
  width: 30px;
  margin-left: auto;
  margin-right: auto;
}
</style>
