<template>
  <section>
    <ValidationObserver v-slot="{invalid}">
      <div class="card-header p-3">
        <div class="columns" style="width: 100%">
          <div class="column">
            <b-breadcrumb
                align="is-left"
            >
              <b-breadcrumb-item tag='router-link' to="/branches" >Branches</b-breadcrumb-item>
              <b-breadcrumb-item tag='router-link' to="" active >Create</b-breadcrumb-item>
            </b-breadcrumb>
          </div>
          <div class="column">
          </div>
        </div>
      </div>

      <div class="card-content" style="overflow: auto; height: 70vh">
        <section style="font-size: 15px !important" >
          <b-field
              horizontal
              :type="branchData.branch_code.class"
              :message="branchData.branch_code.message">
            <template slot="label">Branch Code <span class="has-text-danger">*</span></template>
            <ValidationProvider
                    name="Video_Call_Date"
                    :rules="{required:true}"
                    v-slot="{errors}">
              <b-input rounded @blur="branchData.branch_code.blur()" v-model="branchData.branch_code.value" maxlength="100" expanded></b-input>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>

          <b-field
              horizontal
              :type="branchData.branch_name.class"
              :message="branchData.branch_name.message">
            <template slot="label">Branch Name <span class="has-text-danger">*</span></template>
            <ValidationProvider
                    name="Video_Call_Date"
                    :rules="{required:true}"
                    v-slot="{errors}">
              <b-input rounded @blur="branchData.branch_name.blur()" v-model="branchData.branch_name.value" maxlength="100" expanded></b-input>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>

          
          <b-field
              horizontal
              :type="branchData.email.class"
              :message="branchData.email.message">
            <template slot="label">Email <span class="has-text-danger">*</span></template>
            <ValidationProvider
                    name="Video_Call_Date"
                    :rules="{required:true}"
                    v-slot="{errors}">
              <b-input rounded @input="branchData.email.blur()" v-model="branchData.email.value" maxlength="100" expanded></b-input>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          </b-field>

          <b-field
              horizontal
              :type="branchData.address.class"
              :message="branchData.address.message">
            <template slot="label">Address <span class="has-text-danger">*</span></template>
            <ValidationProvider
                    name="Video_Call_Date"
                    :rules="{required:true}"
                    v-slot="{errors}">
            
                <b-input rounded @blur="branchData.address.blur()" v-model="branchData.address.value" maxlength="100" expanded></b-input>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>  
            </b-field>

          <b-field
              horizontal
              :type="branchData.district.class"
              :message="branchData.district.message">
            <template slot="label">District <span class="has-text-danger">*</span></template>
            <b-select
                rounded
                @blur="branchData.district.blur()"
                v-model="branchData.district.value"
                placeholder="Select a District">
              <option :value="''"  disabled selected>Select a District</option>
              <option
                  v-for="(district,index) in districtList"
                  v-bind:value="district"
                  :key="index">{{ district }}</option>
            </b-select>
          </b-field>


          <b-field
              horizontal
              message=" ">
            <template slot="label">Status <span class="has-text-danger">*</span></template>
            <div class="block">
              <ValidationProvider
                    name="Video_Call_Date"
                    :rules="{required:true}"
                    v-slot="{errors}">
                <b-radio v-model="branchData.disabled.value" name="status" native-value="0"> Active</b-radio>

                <b-radio v-model="branchData.disabled.value" name="status" native-value="1" type="is-danger" style="margin-left: 20px;"> Not Active </b-radio>

                <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>  
            </div>
          </b-field>

        </section>
      </div>

      <footer class="card-footer">
        <div class="columns" style="width: 100%">
          <div class="column">
            <p class="column has-text-danger is-size-5 is-italic">Fields marked with  <big>*</big> are mandatory</p>
          </div>
          <div class="column">
            <div class="buttons is-pulled-right" style="margin: 5px">
              <b-button @click="validateAndSubmit()" :loading="false" type="is-success" :disabled="disablebutton">Save</b-button>
              <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
            </div>
          </div>
        </div>
      </footer>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
      </b-loading>
    </ValidationObserver>
  </section>
</template>

<script>
import NetworkManager from "../../network";
import {FieldValidator} from "../../helpers/validators/fieldValidator";
import {GlobalFunctions} from "../../helpers/globalFunctions";

export default {
  name: "RoleCreate",
  data() {
    return {
      disablebutton :false,
      isLoading : false,
      districtList : [],
      branchData :{
        branch_code : new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Branch Code can only have alphanumeric characters"
        ),

        branch_name : new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Branch Name can only have alphanumeric characters and should be at least 3 characters long"
        ),

        address : new FieldValidator(
            /^[a-zA-Z0-9 ,\/.]/,
            "Address can only have alphanumeric characters"
        ),
        email: new FieldValidator(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
            "Please enter a valid Email"
        ),
        disabled: new FieldValidator(),
        district : new FieldValidator(
            /^[\w\s]+$/,
            "Please select a District"
        )
      },

      checkBoxVal: false
    }
  },



  methods: {

    validate: function (){
      const self = this
      let isValid = true

      Object.keys(self.branchData).forEach(function(key, index) {
        if(!self.branchData[key].validate()) isValid = false
      })

      return isValid
    },

    validateAndSubmit: function (){
      const self = this
      
      if(self.validate()){

        const data = {
          branch_code : self.branchData.branch_code.value,
          branch_name : self.branchData.branch_name.value,
          email : self.branchData.email.value,
          address: self.branchData.address.value,
          district : self.branchData.district.value,
          disabled : self.branchData.disabled.value
        }
        self.disablebutton =true
        self.isLoading = true
        self.showFullScreenLoader()

        NetworkManager.apiRequest('api/branch/create', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'Created branch record sent for approval',
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
            self.$router.go(-1)
          }
          else if(e.statusCode === 422){
            console.log(e)
            const params = {
              type: 'is-danger',
              message: e.data.message,
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
          }
          else {
            const params = {
              type: 'is-danger',
              message: 'Branch save failed!.',
              duration: 5000,
            }
            self.$buefy.toast.open(params)
          }
          self.disablebutton =false
          self.isLoading = false
          self.hideFullScreenLoader()
        })
      }
      else
      {
        const params = {
              type: 'is-danger',
              message: "Error with Input data. Please Recheck the fields",
              duration: 5000,
            }
            self.$buefy.toast.open(params)
      }
      self.disablebutton =false
      self.isLoading = false
    },

    async getDistrictList(){
      const comp = this

      comp.showFullScreenLoader()
      await GlobalFunctions.waitUntilDistrictListLoaded(comp.$root);
      console.log(comp.$root.formDataSupport.districtsArray)
      comp.districtList = comp.$root.formDataSupport.districtsArray
      comp.hideFullScreenLoader()
    },
  },

  mounted() {
    this.branchData.disabled.value = 0
    this.getDistrictList()
  }
}
</script>

<style scoped>

</style>
