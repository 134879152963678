<template>
<div class="wrapper">
  <nic-ocr-result
      v-if="showNicOcrResult"
      :nic-rear="nicFrontOcrResult"
      :nic-front="nicFrontOcrResult"
      @close="()=>{showNicOcrResult = false}"/>
  <h5>THIS IS TEST VIEW</h5>

  <b-button label="View NIC OCR Results" @click="()=>{showNicOcrResult = true}" />
</div>

</template>

<script>
import NicOcrResult from "../components/NicOcrResult/NicOcrResult.vue";
import {NicOcrResultDTO} from "../helpers/classes/dtos/nicOcrResultDTO";
export default {
  components: {
    NicOcrResult
  },

  data(){
    return {
      nicFrontType:"old_nic_front",
      nicFrontOcr:"{\u0022data\u0022: {\u0022ocr_result\u0022: {\u0022nic_number\u0022: \u0022970743448 Vv t\u0022, \u0022nic_issued_date\u0022: \u002220131111\u0022}, \u0022nic_image_type\u0022: \u0022old_nic_front\u0022}, \u0022status_code\u0022: 200}",
      nicRearType:"old_nic_rear",
      nicRearOcr: "{\u0022data\u0022: {\u0022ocr_result\u0022: null, \u0022nic_image_type\u0022: \u0022old_nic_rear\u0022}, \u0022status_code\u0022: 200}",

      nicFrontOcrResult : new NicOcrResultDTO(this.nicFrontType, this.nicFrontOcr),
      nicRearOcrResult : new NicOcrResultDTO(this.nicRearType, this.nicRearOcr),
      showNicOcrResult : false,
    }
  },

  methods : {

  },

  mounted() {
  }
}
</script>

<style scoped>


.wrapper{
  margin: 20px;
  text-align: center;
}
</style>