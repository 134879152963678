<template>

  <div style="height: 80.5vh; overflow: auto !important">
    <!-- <div class="mt-6">
      <SearchBar page=" Approved Applications" />
    </div> -->
    <div class="columns" style="margin-top: 25px; margin-left: 20px;">
      <div class="column">
        <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Approved Applications</label>
      </div>
      <div class="column">
        <b-input
          placeholder="Search..."
          rounded
          type="search"
          icon="magnify"
          icon-clickable
          v-model="searchKeyword"
          style="width:450px">
        </b-input>
      </div>
    </div>
     <TableComp
     :tableData="tableinfo.searchData(tableDetails, searchableFields, searchKeyword)"
      :columns="columnDetails"
      :previousPage="currentPage"
      :statusToAssign="statusToAssign"
      :searchableFields="searchableFields"
      :kyc-status-to-get-internal-comment="GlobalAttributes.kycFormStatus.APPROVED"

     styles="mt-4 mx-6"
    />
       
    
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import FilterBranch from "../../components/FilterBranch.vue";
import { filterTableData } from "../../helpers/filterTableData";
import NetworkManager from "@/network"
import {GlobalAttributes} from "../../helpers/globalAttributes";

export default {
  computed: {
    GlobalAttributes() {
      return GlobalAttributes
    }
  },
  components: { TableComp, SearchBar, FilterBranch },
  name: "ApprovedApplicationsList",
  data() {
    return {
      ref_no: '',
      nic: '',
      name: '',
      email: '',
      contact: '',
      received_date: '',
      action: '',

      searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','created_at'],
      searchKeyword:"",
      tableDetails: "",
      tableinfo:"",
      applicationList :[],


      currentPage:'APPROVED',
      statusToAssign:"",

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 3, field: "full_name", label: "Name" ,date:false},
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "mobile_number", label: "Contact" ,date:false},
        { id: 6, field: "created_at", label: "Approved Date" ,date:true},
        { id: 7, field: "action", label: "Action" ,date:false},
        
      ],
    };
  },

  created() {
    this.tableinfo = filterTableData
  },
  
  methods: {
    GetApprovedApplicationData: function (){
      const comp= this
      const  data = {
        status:"APPROVED"
      }

      // comp.showFullScreenLoader()
      NetworkManager.apiRequest('api/KycApplication/listAssignedApplicationByStatus',data, function (e){
        console.log("in getassignedapplication",e.data)
        if(e.statusCode === 200){
          comp.tableDetails = e.data.applications
        }

        // comp.hideFullScreenLoader()
      })
    },
  },
  mounted() {
    console.log("in mounted")
    // this.$root.formData.nationality.customer_input = "Sri Lanka";
    // this.$root.formData.nationality.call_agent_input = "Pakistan";
    this.GetApprovedApplicationData();
    if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
  },
};
</script>
<style scoped></style>
