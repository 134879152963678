<template>
  <div style="height: 87.5vh; overflow: auto !important" > 
    <b-tabs v-model="activeTab" >
      <b-tab-item v-bind="pending" >
        <div>
          <SearchBar page="Pending Debit Card Queue" />
        </div>
      </b-tab-item>
      <b-tab-item v-bind="reviwed">
        <div>
          <SearchBar page="Reviewed Debit Card Queue" />
        </div>  
      </b-tab-item>
    </b-tabs>

    <b-tabs v-model="activeTab">
      <b-tab-item label="Pending" v-bind="pending">
        <TableComp
          :tableData="pendingTableData"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"

        styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Reviewed" v-bind="reviwed">
        <TableComp
          :tableData="reviwedTableData"
          :columns="reviwedcolumnDetails"
          :previousPage="currentpageReview"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"

        styles="mt-4 mx-6"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import NetworkManager from "@/network"

export default {
  components: { TableComp, SearchBar },
  name: "PendingDebitCardQueue",
  data() {
    return {
      activeTab: 0,
      reviwed : false,
      pending : false,
      reviwedtableDetails: [],
      pendingtableDetails: [],
      currentPage:'DEBIT-CARD-PENDING',
      currentpageReview: 'DEBIT-CARD-REVIEWED',
      statusToAssign:"DEBIT-CARD-REVIEWED",

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "acc_number", label: "A/C Number" ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "mobile_number", label: "Contact" ,date:false},
        { id: 6, field: "created_at", label: "Received Date",date:true},
        { id: 7, field: "action", label: "Action" ,date:false},   
      ],
      reviwedcolumnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "acc_number", label: "A/C Number" ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "mobile_number", label: "Contact" ,date:false},
        { id: 6, field: "created_at", label: "Received Date",date:true},
        { id: 7, field: "status_updated_at", label: "Reviewed Date",date:true},
        { id: 8, field: "action", label: "Action" ,date:false},   
      ],
      pendingTableData: [ ],
      reviwedTableData: [],
      searchableFields: ['reference_number', 'acc_number','nic_eic_number', 'email','mobile_number','created_at','status_updated_at'],

    };
  },
  methods: {
    Get_Pending_Details(){
      const comp= this
        const  data = {
          status:"DEBIT-CARD-PENDING"
        }
  
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listByStatus',data, function (e){
          if(e.statusCode === 200){
            comp.pendingTableData = e.data.applications
          }
  
          // comp.hideFullScreenLoader()
        });
    },
    Get_Reviwed_Details(){
      const comp= this
        const  data = {
          status:"DEBIT-CARD-REVIEWED"
        }
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listAssignedApplicationByStatus',data, function (e){
          if(e.statusCode === 200){
            comp.reviwedTableData = e.data.applications
          }
  
          // comp.hideFullScreenLoader()
        })
    },

 
  },
  mounted() {     
      this.Get_Pending_Details();
      this.Get_Reviwed_Details();
     
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
};
</script>
<style scoped></style>
