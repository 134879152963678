<template>
    <div style="height: 80.5vh; overflow: auto !important">
      <!-- <div class="mt-6">
        <SearchBar page="Applications For Approval" />
      </div> -->
      <div class="columns" style="margin-top: 25px; margin-left: 20px;">
      <div class="column">
        <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Applications for Approval</label>
      </div>
      <div class="column">
        <b-input
          placeholder="Search..."
          rounded
          type="search"
          icon="magnify"
          icon-clickable
          v-model="searchKeyword"
          style="width:450px">
        </b-input>
      </div>
    </div>
       <TableComp
        :tableData="tableinfo.searchData(tableDetails, searchableFields, searchKeyword)"
        :columns="columnDetails"
        :previousPage="currentPage"
        :statusToAssign="statusToAssign"
        :searchableFields="searchableFields"
       styles="mt-4 mx-6"
      />
         
      
    </div>
  </template>
  
  <script>
  import TableComp from "../../components/TableComp.vue";
  import SearchBar from "../../components/SearchBar.vue";
  import FilterBranch from "../../components/FilterBranch.vue";
  import { filterTableData } from "../../helpers/filterTableData";
  import NetworkManager from "@/network"

  export default {
    components: { TableComp, SearchBar, FilterBranch },
    name: "ApplicationsForApproval",
    data() {
      return {
        ref_no: '',
        nic: '',
        name: '',
        email: '',
        contact: '',
        scheduled_time: '',
        received_date:'',
        completed_date:'',
        action: '',

        searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','status_updated_at'],
        searchKeyword:"",
        tableDetails: "",
        tableinfo:"",
        applicationList :[],

        currentPage:'SUBMITTED-APPROVER',
        statusToAssign:"ASSIGNED-APPROVER",
  
        columnDetails: [
          { id: 1, field: "reference_number", label: "Ref No." ,date:false},
          { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
          { id: 3, field: "full_name", label: "Name" ,date:false},
          { id: 4, field: "email", label: "Email" ,date:false},
          { id: 5, field: "mobile_number", label: "Contact" ,date:false},
          { id: 6, field: "status_updated_at", label: "Received Date",date:true},
          { id: 7, field: "action", label: "Action",date:false },
          
        ],
      };
    },

    created() {
      this.tableinfo = filterTableData
    },

    methods: {
   
      GetCompletedVideoCall: function (){
        const comp= this
        const  data = {
          status:"SUBMITTED-APPROVER"
        }
        console.log("in CompletedVideoCalls")
  
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listByStatus',data, function (e){
          console.log("in CompletedVideoCalls",e.data)
          if(e.statusCode === 200){
            comp.tableDetails = e.data.applications
           
          }
  
          // comp.hideFullScreenLoader()
        })
      },
    },
    mounted() {
      console.log("in mounted")
      
      this.GetCompletedVideoCall();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
  };
  </script>
  <style scoped></style>
  