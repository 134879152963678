<template>
  <div>
    <b-table :hoverable="true" :data="data" :columns="columns"></b-table>
  </div>
</template>

<script>
export default {
  name: "TwoWayTable",

  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
  }
}
</script>

<style scoped>

</style>