import Auth from "@/mixins/modules/authMixins";
import router from "@/router";
import tokenTimer from "@/mixins/modules/tokenTimer";

const myMixin = {
  name: "GlobalMixin",
  created: function () {},
  data() {
    return {
      regex: {
        passwordValidation:
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        nameValidation: /^([A-Za-z\d]+\s?[A-Za-z\d]*){3,}$/,
        addressValidation: /^[A-Za-z\d@$!%*?&]{3,}[\w\W]+$/,
        contactValidation: /^\d{4,32}$/,
        websiteValidation:
          /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z\d.-]+(:\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z\d.-]+)((?:\/[+~%\/.\w-_]*)?\??#?)?)$/,
        singleWordValidation: /^\w+$/,
        multiWordValidation: /^[\w\s]+$/,
        decimalValidation: /^\d+(.\d+)?$/,
        emptyOrWebsite:
          /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z\d.-]+(:\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z\d.-]+)((?:\/[+~%\/.\w-_]*)?\??#?)?)$|^$/,
      },
    };
  },
  props: {},
  methods: {
    showFullScreenLoader() {
      document.getElementById("fullScreenLoader").style.display = "block";
    },

    hideFullScreenLoader() {
      setTimeout(() => {
        document.getElementById("fullScreenLoader").style.display = "none";
      }, 200);
    },

    hasPermission: function (permission) {
      return Auth.hasPermission(permission);
    },

    isSuperAdmin: function () {
      return Auth.hasSuperPermission();
    },

    authUser: function () {
      return Auth.authUser();
    },

    navigateTo: function (name, params = null) {
      if (router.currentRoute.name !== name) {
        if (params !== null) {
          router.push({ name: name, params: params }).then(null);
        } else {
          router.push({ name: name }).then(null);
        }
      }
    },

    startTokenTimer: function (timeSpan) {
      if (timeSpan) tokenTimer.start(timeSpan);
      else tokenTimer.start();
    },

    stopTokenTimer: function () {
      tokenTimer.stop();
    },

    formatDate: function (date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
};

export default myMixin;
