<template>
  <div style="height: 80.5vh; overflow: auto !important">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label
              style="
                font-family: Gotham-Medium;
                color: gray;
                font-weight: 400;
                font-size: 23px;
              "
              >Completed Video Calls</label
            >
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                placeholder="Search..."
                rounded
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchKeyword"
                style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--filter component-------------------------------------------->
    <AdminDataTableFilter
      :date="true"
      :fields="filterDetails"
      :tableData="tableDetails"
      :role-filtering-for="GlobalAttributes.roles.CALL_AGENT"
      :userSelect="userFilter"
      @filterData="applyFilteredData"
    />

    <!---------tabs--------------------------------------->
    <section class="mt-5 p-2">
      <AdminTableComp
        v-if="!isLoading"
        :tableData="tableDetails"
        :columns="columnDetails"
        :statusToAssign="statusToAssign"
        :searchableFields="searchableFields"
        :searchKeyword="searchKeyword"
        :assignableUserRoleName="assignableUserRoleName"
        :showApprovedApplicationView="false"
        @refreshTableData="refreshTableData"
      />
    </section>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import AdminTableComp from "@/components/AdminTableComp";
import AdminDataTableFilter from "@/components/AdminDataTableFilter";
import { GlobalAttributes } from "../../../helpers/globalAttributes";
import { ApplicationPoolListTabData } from "../../../helpers/classes/admin/applicationPoolList/applicationPoolListTabData";
import { BranchFilterOption } from "../../../helpers/classes/admin/dataFilterTable/branchFilterOption";
import { FilterDetail } from "../../../helpers/classes/admin/dataFilterTable/filterDetail";
import app from "../../../App.vue";
import { BuefyHelper } from "../../../helpers/bufeyHelper";
import { TableDetailItem } from "../../../helpers/classes/admin/applicationPoolList/tableDetailItem";

export default {
  computed: {
    app() {
      return app;
    },
    GlobalAttributes() {
      return GlobalAttributes;
    },
  },
  components: {
    // TableComp,
    // SearchBar,
    AdminTableComp,
    AdminDataTableFilter,
  },
  data() {
    return {
      activeTab: 0,
      isLoading: true,

      statusToAssign: "VIDEO-CALL-COMPLETED",

      searchKeyword: "",
      searchableFields: [
        "ref_no",
        "nic",
        "name",
        "email",
        "branch",
        "assigned_user_name",
        "video_call_date",
        "video_call_time",
        "received_date",
      ],

      filterDetails: [],

      columnDetails: [
        { id: 1, field: "ref_no", label: "Ref No.", date: false, hide: false },
        { id: 2, field: "nic", label: "NIC/EIC", date: false, hide: false },
        { id: 3, field: "name", label: "Name", date: false, hide: false },
        { id: 4, field: "gender", label: "Gender", date: false },
        { id: 5, field: "email", label: "Email", date: false, hide: false },
        { id: 6, field: "branch", label: "Branch", date: false, hide: false },
        { id: 7, field: "resident_status", label: "Residence", date: false },
        {
          id: 8,
          field: "assigned_user_name",
          label: "Assigned User",
          date: false,
          hide: false,
        },
        {
          id: 9,
          field: "video_call_date",
          label: "Scheduled Time",
          date: true,
          hide: false,
        },
        {
          id: 10,
          field: "received_date",
          label: "Completed Date",
          date: true,
          hide: false,
        },
        { id: 11, field: "action", label: "", date: false, hide: false },
        {
          id: 11,
          field: "action-view",
          label: "Action",
          date: false,
          hide: false,
        },
        {
          id: 12,
          field: "video_call_time",
          label: "",
          date: false,
          hide: true,
        },
      ],

      tableDetails: [],
      userFilter: true,
      assignableUserRoleName: GlobalAttributes.roles.CALL_AGENT,
    };
  },

  methods: {
    refreshTableData() {
      const comp = this;
      comp.loadInitialData();
    },

    applyFilteredData(filterParams) {
      console.log("filter params... (receiving end) ");
      console.log(filterParams);

      const comp = this;

      // set request parameters
      let applicationStatus =
        GlobalAttributes.kycFormStatus.VIDEO_CALL_COMPLETED;

      // find application  status

      let branchId = filterParams.branch === null ? 0 : filterParams.branch;
      let userId = filterParams.user === null ? 0 : filterParams.user;
      let endDate = null;

      if (filterParams.endDate != null) {
        let endDateTemp = new Date(filterParams.endDate); // create new date object otherwise may increment the date continuously
        endDate = new Date(endDateTemp.setDate(endDateTemp.getDate() + 1)); // pass +1 date for the end date. otherwise it will not returns the correct end date applications due to timestamp filtering in backend
      }

      console.log("end date : " + endDate);

      // filter relevant tab data
      comp.getFilteredApplications(
        filterParams.startDate,
        endDate,
        branchId,
        userId,
        applicationStatus,
        () => {
          console.log("filter applied successfully...");
        }
      );
    },

    getFilteredApplications(
      startDate,
      endDate,
      branchId,
      userId,
      applicationStatus,
      callback
    ) {
      const comp = this;
      const requestBody = {
        start_date: startDate === null ? startDate : startDate.toISOString(),
        end_date: endDate === null ? endDate : endDate.toISOString(),
        branch_id: branchId,
        user_id: userId,
        application_pool_status: applicationStatus,
      };

      comp.showFullScreenLoader();
      comp.isLoading = true;

      NetworkManager.apiRequest(
        "api/Admin/getAssignedFilteredApplications",
        requestBody,
        (response) => {
          if (response.statusCode === 200) {
            comp.tableDetails = [];
            response.data.filtered_kyc_applications.forEach(
              (kycApplication) => {
                comp.tableDetails.push(
                  comp.getNewTableItemObject(kycApplication)
                );
              }
            );
            comp.hideFullScreenLoader();
            comp.isLoading = false;
            callback();
          } else {
            //BuefyHelper.showToastMessage("Unable to filter the data","is-danger")

            console.error("Failed to filter data");
            console.error(response.data.message);

            comp.hideFullScreenLoader();
            comp.isLoading = false;
          }
        }
      );
    },

    getNewTableItemObject(kycApplication) {
      const comp = this;

      return new TableDetailItem(
        kycApplication.kyc_form_id,
        kycApplication.reference_number,
        kycApplication.nic_eic_number,
        kycApplication.full_name,
        kycApplication.gender,
        kycApplication.resident_status,
        kycApplication.email,
        kycApplication.mobile_number,
        comp.getBranchNameById(kycApplication.branch_id),
        kycApplication.received_date,
        kycApplication.assigned_user_name,
        kycApplication.assigned_user_id,
        kycApplication.video_call_date,
        kycApplication.video_call_time
      );
    },

    getBranchNameById(id) {
      const comp = this;
      let filteredBranch = comp.$root.formDataSupport.branchesArray.find(
        (branch) => branch.id === id
      );

      if (filteredBranch !== undefined) {
        return filteredBranch.branch_name;
      } else {
        return "";
      }
    },

    async getBranchFilterOptions() {
      const comp = this;
      let branchFilterOptions = [];
      comp.showFullScreenLoader();

      await comp.waitUntilBranchListLoaded();

      console.log("after waiting for branch list...");

      comp.$root.formDataSupport.branchesArray.forEach((branch) =>
        branchFilterOptions.push(
          new BranchFilterOption(branch.id, branch.branch_name)
        )
      );
      comp.filterDetails.push(
        FilterDetail.getFilterDetailObjectForAdminFilter(
          1,
          "Branch",
          null,
          "Select a Branch",
          branchFilterOptions
        )
      );

      comp.hideFullScreenLoader();
    },

    waitUntilBranchListLoaded() {
      const comp = this;

      return new Promise((resolve) => {
        const checkBranchListLoaded = () => {
          if (comp.$root.formDataSupport.branchesArray.length > 0) {
            console.log("branch list loaded ...");
            resolve();
          } else {
            console.log("waiting for branch list...");
            setTimeout(checkBranchListLoaded, 100);
          }
        };

        checkBranchListLoaded();
      });
    },

    loadInitialData() {
      const comp = this;

      // load all tabs data at initial load
      this.getFilteredApplications(
        null,
        null,
        0,
        0,
        GlobalAttributes.kycFormStatus.VIDEO_CALL_COMPLETED
      );
    },
  },
  mounted() {
    this.getBranchFilterOptions();
    this.loadInitialData();
    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
  },
};
</script>
