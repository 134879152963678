<template>
  <div>
    <div>
      <ValidationProvider :rules="validationRules" v-slot="{ errors }">
        <b-field
          label-position="inside"
          :custom-class="
            vModelVaribale === '' && !focusVariable
              ? 'custom-float-label-initial'
              : 'custom-float-label'
          "
          class="mb-0"
        >
          <template #label>{{ label }}<span v-if="required" class="has-text-danger">*</span></template>

          <b-select
            expanded
            rounded
            @click.native="
              () => {
                if (selectFun) {
                  selectFun();
                }
              }
            "
            :disabled="disabled"
            v-model="vModelVaribale"
            :class="
              vModelVaribale === '' && !focusVariable
                ? 'arrow-up-before'
                : 'arrow-up'
            "
            @focus="
              () => {
                focusVariable = true;
              }
            "
            @blur="
              () => {
                focusVariable = false;
              }
            "
          >
            <option
              v-for="option in selectOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </b-select>
        </b-field>
        <span class="has-text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectLabel",
  props: [
    "value",
    "selectOptions",
    "validationRules",
    "required",
    "label",
    "disabled",
    "selectFun",
  ],
  data() {
    return {
      focusVariable: false,
    };
  },
  computed: {
    vModelVaribale: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
.custom-float-label-initial {
  top: 0.93em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 1rem !important;
  transition: ease 0.3s;
  pointer-events: none;
}

.custom-float-label {
  top: 0.22em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}
</style>
