import { render, staticRenderFns } from "./ApproveApplication.vue?vue&type=template&id=74fb8c98"
import script from "./ApproveApplication.vue?vue&type=script&lang=js"
export * from "./ApproveApplication.vue?vue&type=script&lang=js"
import style0 from "./ApproveApplication.vue?vue&type=style&index=0&id=74fb8c98&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports