import {defineStore} from "pinia";
import NotificationList from "../helpers/classes/videoCall/notificationList";
import AnsweredVideoCal from "../helpers/classes/videoCall/answeredVideoCall";

export const useVideoKycStore = defineStore("videoKycStore",{
    state : ()=>({
        videoUploadProgress:0,
        videoUploadState:null,
        startRecordingProtectionTimeMs : 2000,
        recordedVideo : null,
        capturedVideo : null,
        callStartTime: 0,
        callEndTime: 0,
        expectedCallEndTimeSec : 0, // remaining video call time as scheduled (time left to end the call)
        showEndVideoCallNotificationThresholdMins : 6,
        openVideoRequestUserID:'',
        answeredVideoKycData :  new  AnsweredVideoCal(),
        isStartedUploadingCallData : false,
        
        videoCallRequestData : new NotificationList(),

        // video call ui button controls
        buttonIcons:{
            micOff : "microphone-off",
            micOn :  "microphone-outline",
            videoOff : "video-off-outline",
            videoOn : "video-outline",
            phoneHangUp : "phone-hangup-outline",
        },

        buttonStates : {
            disabled : {
                videoBtn : true,
                audioBtn : true,
                endCallBtn : true,
            },
        },

        streamStatus:{
            isAudioMuted : false,
            isVideoMuted : false,
        },

        kyc_form_id:null,

    }),

})