var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{key:_vm.refreshKey,staticClass:"p-0"},[_c('div',{staticClass:"columns",staticStyle:{"margin":"10px auto 10px 10px"}},[_c('div',{staticStyle:{"position":"fixed","margin-left":"-4vw"}},[_c('b-button',{staticStyle:{"border-radius":"20px","height":"40px","width":"40px"},attrs:{"rounded":"","type":"is-primary","outlined":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"mdi mdi-arrow-left mdi-24px"})])],1),(!_vm.isReviewOnly)?_c('div',[_c('p',{staticStyle:{"color":"#aaaaaa","font-size":"25px"}},[_vm._v(" Recommend / Reject Application ")])]):_vm._e(),(_vm.isReviewOnly)?_c('div',[_c('p',{staticStyle:{"color":"#aaaaaa","font-size":"25px"}},[_vm._v("View Application")])]):_vm._e(),(!_vm.isReviewOnly)?_c('div',{staticStyle:{"margin-left":"42vw"}},[(
            _vm.hasPermission('assign-scheduled-application') &&
            (_vm.formData.current_status === 'SUBMITTED-CALL-AGENT' ||
              _vm.formData.current_status === 'ASSIGNED-CALL-AGENT')
          )?_c('div',[_c('b-button',{staticStyle:{"width":"100%","margin-bottom":"10px"},attrs:{"type":"is-primary","outlined":"","disabled":_vm.Sh_isButtonDisabled},on:{"click":function($event){_vm.Sh_showMessageBox = true}}},[_vm._v(" Re-Schedule ")]),(_vm.Sh_showMessageBox)?_c('div',[_c('div',{staticClass:"fullscreen",attrs:{"id":"loading"}},[_c('ScheduleRescheduleMeeting',{attrs:{"Schedule_or_Reschedule":_vm.Schedule_or_Reschedule,"selected-date":_vm.selected,"video-call-time":_vm.videoCallTime},on:{"update_Sh_customer_comment":_vm.update_Sh_customer_comment,"update_Sh_internal_comment":_vm.update_Sh_internal_comment,"update_Sh_email":_vm.update_Sh_email,"update_Sh_sms":_vm.update_Sh_sms,"Update_Sh_New_Date":_vm.Update_Sh_New_Date,"Update_Sh_New_Time":_vm.Update_Sh_New_Time,"sendScheduleMeeting":_vm.sendScheduleMeeting,"Closs_Sh":_vm.Closs_Sh}})],1)]):_vm._e()],1):_vm._e(),(
            _vm.hasPermission('review-application-from-branch-pool') &&
            _vm.formData.current_status === 'REFER-TO-BRANCH-PENDING'
          )?_c('div'):_vm._e()]):_vm._e()]),(!_vm.isLoading)?_c('ApplicationReviewVue',{attrs:{"review-only":_vm.isReviewOnly}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }