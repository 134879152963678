<template>
  <div>
    <section style="margin-left: 20px;margin-top: 40px;"> 
      <div class="columns" style="width:250px">
        <div class="column">
          <b-field >Date Range 
            <b-datepicker 
              v-model="StartDateSearch" 
              :max-date="GetMaxStartDate"
              :append-to-body="true"
              :show-week-number="false"
              rounded 
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus
              style="width:150px"
              >
            </b-datepicker>
           
          
          </b-field>
        </div>
        <br><br> To
        <div class="column"><br>
          <b-field >
            <b-datepicker  
              v-model="EndDateSearch" 
              :max-date="maxStartDate"
              :min-date="StartDateSearch"
              :show-week-number="false" 
              rounded 
              :append-to-body="true"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus
              style="width:150px">
            </b-datepicker>
          </b-field>
        </div>
        <div class="column"  >NIC/EIC<br>
          <b-field >
            <b-input  v-model="Nic" type="text" rounded style="width:150px"></b-input>
          </b-field>
        </div>
        <div class="column"> Branch<br>

          <b-select placeholder="Select a Branch" rounded v-model="BranchSearch" >

            <option value=0 disabled>Select a Branch</option>
            <option v-for="(branch,index) in branches" :value="branch.id" :key="index">{{ branch.branch_name }}</option>        
          </b-select>
    
        </div>
        <div class="column">
          <b-field > 
            <b-button type="is-primary "
                rounded
                @click="sendFormData"
                style="margin-top: 21px;" >
                Filter
            </b-button>
          </b-field>
        </div>
        <div class="column">
          <b-field > 
            <b-button type="is-primary" outlined
                rounded 
                @click="clearFormData"
                style="margin-top: 21px;" >
                Clear
            </b-button>
          </b-field>
        </div>
        <div class="column" style="margin-top: 20px; z-index: 0; ">
            <b-tooltip label="Download as Excel"  type="is-dark "  position="is-left">
              <b-button   @click="generateXlsx" type="is-info" icon-right="microsoft-excel" :disabled="disableDownloads"></b-button>
            </b-tooltip>
        </div>
        <div class="column" style="margin-top: 20px; z-index: 2;">
            <b-tooltip label="Download as PDF"  type="is-dark" position="is-left">
              <b-button   @click="generatePdf" type="is-info" icon-right="file-pdf-box" :disabled="disableDownloads" ></b-button>
            </b-tooltip>
        </div>
      </div> 
    </section>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import {ReportExportWizard} from "@/views/Reports/Supports";
import {DateTimeManger} from "../helpers/dateTimeManger";
export default {
  name:"FilterBranch",

  watch :{
    disableDownloads(newVal,oldVal){
      console.log("disable buttons - value : "+newVal)
    }
  },

  computed : {
    GetMaxStartDate(){
      const comp = this
      return comp.EndDateSearch === null ? comp.maxStartDate : comp.EndDateSearch
    },
  },

 props: {
    hideInput: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      required: false
    },

    disableDownloads:{
      type : Boolean,
      required : true,
      default : false,
    }
  },

 data(){
    return{
        //isdisabled:false,
        StartDateSearch:null,
        EndDateSearch:null,
        activityLogs: [],
        maxStartDate : new Date(), // get yesterday as max date
        minEndDate: new Date(),
        exportWizard: new ReportExportWizard("NEW LOG"),
        BranchSearch:0,
        branches:[],
        branch_name:[],
        Nic:'',
        isLoading: true,
        showInput: false,
        tableHeaders: [],
    }
 },
 methods:{
    generateXlsx: function (){
      const formData = this.formatDateData();
      console.log("formdata",formData)
      this.$emit('xlsx-download', formData);
    },

    generatePdf: function (){
      const formData = this.formatDateData();
      console.log("formdata",formData)
      this.$emit('pdf-download', formData);
    },

   formatDateData(){
    let formData = {
        StartDateSearch: this.StartDateSearch,
        EndDateSearch: this.EndDateSearch,
        Nic: this.Nic,
        BranchSearch: this.BranchSearch,
      };
      if(formData.StartDateSearch != null){
        let dateStr1 =new Date(formData.StartDateSearch)
        formData.StartDateSearch =  DateTimeManger.getLocalDateFormattedDate(dateStr1.toLocaleDateString())
        
      }if(formData.EndDateSearch != null){
        let dateStr2 =new Date(formData.EndDateSearch)
        formData.EndDateSearch =  DateTimeManger.getLocalDateFormattedDate(dateStr2.toLocaleDateString())

        
      }
      console.log(formData)
      return formData;
   },

    loadData: function (){
      const  self = this

      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/KycApplication/branchList', {}, function (e){
        if(e.statusCode === 200){
          self.branches = [] = e.data
          self.isLoading = false
         
        }

        self.hideFullScreenLoader()
      },false)
    },

    collectFormData() {
      const formData = {
        StartDateSearch: this.StartDateSearch,
        EndDateSearch: this.EndDateSearch,
        Nic: this.Nic,
        BranchSearch: this.BranchSearch,
      };
      console.log("FORM DATA",formData)
      return formData;
    },

    sendFormData() {
      const formData = this.collectFormData();
      this.$emit('form-submitted', formData);
    },

    emptyFormData() {
      this.StartDateSearch = null
      this.EndDateSearch = null
      this.Nic = null
      this.BranchSearch = null

    },

    clearFormData(){
      this.emptyFormData();
      this.sendFormData();
    },
 },
 mounted(){
    this.loadData();
    if(this.$root.isSideBarHidden ){
      this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
    }
 }

}
</script>

<style>
.b-tooltip:not(:last-child) {
        margin-right: .5em !important;
    }
    .b-tooltip {
        margin-bottom: .5em!important;
    }
</style>