<template>
  <div>
    <b-modal v-model="isModalActive" scroll="keep" :width="700" :can-cancel="false">
      <div class="model_box mt-3">
        <div
            class="has-text-centered"
            style="transition-timing-function: ease-in-out"
        >
          <header class="modal-card-head" style="background-color: white">
            <p class="modal-card-title has-text-centered is-size-3">
              <strong class="has-text-primary is-uppercase"
              >Add Comment</strong
              >
            </p>
            <button
                @click="closeModal"
                type="button"
                class="delete"
            ></button>
          </header>
          <ValidationObserver v-slot="{ invalid }">
            <div style="padding-inline: 10% !important" >
              <hr />

              <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
              >

                <b-field label="Add comments for internal use" style="text-align: left">
                  <b-input maxlength="200" type="textarea" v-model="comments"></b-input>
                </b-field>

                <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>

              </ValidationProvider>
            </div>

            <div class="has-text-centered"  style="transition-timing-function: ease-in-out" >
              <b-button
                  type="is-primary"
                  class="py-5 mt-5"
                  @click="saveComment"
                  :disabled="invalid"
                  style="padding-inline: 100px !important"
                  rounded
              >Save
              </b-button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "AddInternalCommentComponent",
  data() {
    return {
      isModalActive: true,
      comments: ""
    };
  },
  methods: {

    closeModal(){
      const comp = this
      console.log("emit close modal ....")
      comp.$emit('closeInternalCommentModal')
    },

    saveComment(){
      const comp = this
      comp.$emit("saveInternalComment",comp.comments)
    }

  },
  props: [

  ],

};
</script>

<style lang="scss" scoped>
.img_blend {
  mix-blend-mode: darken;
  opacity: 0.4000000059604645;
}
.line {
  position: absolute;
  top: 220px;
}
hr {
  stroke-width: 2px;
  stroke: var(--text, #231f20);
  opacity: 0.30000001192092896;
  margin-bottom: 1px !important;
  text-align: center !important;
}
.model_box {
  border-radius: 20px;
  border: 1px solid #ed1c24 !important;
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center;
}
.boxDesign {
  width: 47.927px;
  height: 47.927px;
  border-radius: 4.985px;
  border: 0.831px solid var(--text, #231f20);
  background: #fff;
  color: var(--red, #ed1c24);
  font-size: 26.587px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-inline: 7px;
}
</style>
