<template>
  <div class="table-comp-wrapper">
    <b-table

      v-if="tableData.length >= 0"
      style="height: auto ;
      max-height: 65vh; width: auto;"
      class="scrollable-table"
      :data="filter.searchData(tableData, searchableFields, searchKeyword)"

      :paginated="tableOptions.isPaginated"
      :per-page="tableOptions.perPage"
      :current-page.sync="tableOptions.currentPage"
      :pagination-simple="tableOptions.isPaginationSimple"
      :pagination-position="tableOptions.paginationPosition"
      :default-sort-direction="tableOptions.defaultSortDirection"
      :pagination-rounded="tableOptions.isPaginationRounded"
      :sort-icon="tableOptions.sortIcon"
      :sort-icon-size="tableOptions.sortIconSize"
      default-sort="id"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :sticky-header="true"
      :page-input="tableOptions.hasInput"
      :pagination-order="tableOptions.paginationOrder"
      :page-input-position="tableOptions.inputPosition"
      :debounce-page-input="tableOptions.inputDebounce"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.id"
        :field="column.field ? 'yes' : 'No'"
        :label="column.label"
        v-slot="props"
      >
        <span v-if="column.field !== `action` && column.date">
          {{ getFormattedDateTime(props.row[column.field]) }}
        </span>

        <span v-if="column.field !== `action` && !column.date">
          {{props.row[column.field]}}
        </span>

        <div v-if="column.field === `action`">

          <div v-if="previousPage === 'APPLICATION-STATUS-CHECK'">
            <!-- for approved/rejected applications -->
            <b-button
                v-if="GlobalFunctions.checkKycApprovedOrRejected(props.row['current_status'])"
                class="is-primary is-rounded action-btn"
                @click="viewClick(props.row.kyc_form_id,true)" >
              View
            </b-button>

            <!-- for other applications -->
            <b-button
                v-else
                class="is-primary is-rounded action-btn"
                @click="reviewClick(props.row.kyc_form_id,true)">
              View
            </b-button>
          </div>

        <!-- CIF Related Actions - START  -->

        <!-- Review btn-->
          <div v-if="ShowActionBtnForCommonCIFStatus" >
            <b-button
                icon-left="pencil"
                class="is-primary is-rounded action-btn"
                @click="reviewClick(props.row.kyc_form_id)"
            >
              Review
            </b-button>
          </div>

          <!-- View btn-->
          <div v-if="ShowActionBtnForFCMAndManuallyRejectCIFStatus" >
            <b-button
                class="is-primary is-rounded action-btn"
                @click="reviewClick(props.row.kyc_form_id)"
            >
              View
            </b-button>
          </div>

          <!-- Overrides btn -->
          <div v-if="ShowActionBtnForT24OverrideStatus" >
            <b-button
                icon-left="pencil"
                class="is-primary is-rounded action-btn"
                @click="viewCifOverrideApplication(props.row.kyc_form_id)"
            >
             Review
            </b-button>
          </div>

        <!-- CIF Related Actions - END  -->

          <div
            v-if="
              (previousPage === 'NEW' &&
                hasPermission('assign-new-application')) ||
              (previousPage === 'SUBMITTED-APPROVER' &&
                hasPermission('assign-pending-application-approval')) ||
              (previousPage === 'SUBMITTED-EXCEPTIONAL-APPROVER' &&
                hasPermission('assign-exceptional-approval-application'))
            "
          >
            <b-button
              class="is-primary is-rounded action-btn"
              @click="assignedApplication(props.row.kyc_form_id)"
              >View & Assign</b-button
            >
          </div>

          <div
            v-if="
              previousPage === 'ASSIGNED-DETAIL-REVIEWER' ||
              previousPage === 'HOLD' ||
              previousPage === 'ASSIGNED-CALL-AGENT' ||
              previousPage === 'ASSIGNED-APPROVER' ||
              previousPage === 'VIDEO-CALL-COMPLETED' ||
              previousPage === 'Returned-Applications' ||
              previousPage === 'RETURNED_DETAIL_REVIEWER' ||
              previousPage === 'ASSIGNED-EXCEPTIONAL-APPROVER'
            "
          >
            <b-button
              class="is-primary is-rounded action-btn"
              @click="reviewClick(props.row.kyc_form_id)"
              icon-left="pencil"
              >Review
            </b-button>

            <b-button
              class="action-btn"
              v-if="
              previousPage === 'ASSIGNED-CALL-AGENT'"
              rounded
              :loading="rescheduleBtnIsLoading"
              @click="ReSchedule(props.row.kyc_form_id)"
              >Re-Schedule</b-button
            >
            <!-- <b-button rounded style="margin-left:3px"  @click="ReSchedule(props.row.kyc_form_id)" :disabled="Sh_isButtonDisabled">Re-Schedule</b-button> -->
          </div>

          <div
            v-if="
              previousPage === 'SUBMITTED-EXCEPTIONAL-APPROVER' &&
              !hasPermission('assign-exceptional-approval-application')
            "
          >
            <b-button
              class="is-primary is-rounded action-btn"
              @click="reviewClick(props.row.kyc_form_id)"
              >View
            </b-button>
          </div>

          <div
            v-if="
              previousPage === 'EXCEPTIONAL-APPROVER-RETURNED' ||
              previousPage === 'EXCEPTIONAL-APPROVER-APPROVED' ||
              previousPage === 'EXCEPTIONAL-APPROVER-REJECTED'
            "
          >
            <b-button
              class="is-primary is-rounded action-btn"
              @click="reviewClick(props.row.kyc_form_id)"
              >View
            </b-button>
          </div>

          <div
            v-if="
              previousPage === 'REJECTED' ||
              previousPage === 'APPROVED'
              
            "
          >
            <b-button
              class="is-primary is-rounded action-btn"
              @click="viewClick(props.row.kyc_form_id)"
              >View</b-button
            >
          </div>

          <div v-if="previousPage === 'REFER-TO-BRANCH-PENDING'">
            <b-button
              class="is-primary is-rounded action-btn"
              @click="reviewClick(props.row.kyc_form_id)"
              >View</b-button
            >
            <b-button type="is-primary" outlined
              v-if="hasPermission('review-application-from-branch-pool')"
              class="is-rounded action-btn"
              @click="goToDownload(props.row.kyc_form_id)"
              >Download</b-button
            >
          </div>

          <div v-if="previousPage === 'DEBIT-CARD-PENDING'">
            <b-button
              class="is-primary is-rounded action-btn"
              @click="viewClick(props.row.kyc_form_id)"
              >Review</b-button
            >
          </div>

          <div v-if="previousPage === 'DEBIT-CARD-REVIEWED'">
            <b-button
              class="is-primary is-rounded action-btn"
              @click="viewClick(props.row.kyc_form_id)"
              >View</b-button
            >
          </div>

          <div v-if="previousPage === 'SUBMITTED-CALL-AGENT'">
            <b-button
              class="is-primary is-rounded action-btn"
              v-if="hasPermission('assign-scheduled-application')"
              @click="assignedApplication(props.row.kyc_form_id)"
              >View & Assign</b-button
            >
            <b-button
              class="is-primary is-rounded action-btn"
              v-else
              @click="reviewClick(props.row.kyc_form_id)"
              >View</b-button
            >
          </div>
        </div>
      </b-table-column>
    </b-table>

    <div class="mt-5 has-text-centered" v-if="tableData.length == 0" style="display: flex; justify-content: center; align-items: center; ">
      <div style=" margin-top: -40% ">
          <span class="icon has-text-grey-lighter is-normal">
               <i class="mdi mdi-information-outline mdi-48px"></i>
          </span>
          <br>
          <!-- <span class="ml-2 has-text-grey-lighter" style="font-size: 20px; margin-top: -170px;">No records to show</span> -->
      </div>
          
      <br>
      
      <br>
      <span class="has-text-grey-lighter">{{$props.customText}}</span>
    </div>

    <div v-if="Sh_showMessageBox">
      <div id="loading" class="fullscreen">
        <ScheduleRescheduleMeeting
          :Schedule_or_Reschedule="Schedule_or_Reschedule"
          :selected-date="selected"
          :video-call-time="videoCallTime"
          @update_Sh_customer_comment="update_Sh_customer_comment"
          @update_Sh_internal_comment="update_Sh_internal_comment"
          @update_Sh_email="update_Sh_email"
          @update_Sh_sms="update_Sh_sms"
          @Update_Sh_New_Date="Update_Sh_New_Date"
          @Update_Sh_New_Time="Update_Sh_New_Time"
          @sendScheduleMeeting="sendScheduleMeeting"
          @Closs_Sh="Closs_Sh"
        ></ScheduleRescheduleMeeting>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "../network";
import { DateTimeManger } from "../helpers/dateTimeManger";
import { useAuthStore } from "../store/authStore";
import ScheduleRescheduleMeeting from "./ScheduleRescheduleMeeting.vue";
import { filterTableData } from "../helpers/filterTableData";
import { BuefyHelper } from "../helpers/bufeyHelper";
import {GlobalFunctions} from "../helpers/globalFunctions";
import {GlobalAttributes} from "../helpers/globalAttributes";

export default {
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    },

    ShowActionBtnForCommonCIFStatus(){
      const comp = this
      return  comp.hasPermission('approve-application') && (comp.previousPage === GlobalAttributes.kycFormStatus.T24_CIF_ERROR
          || comp.previousPage === GlobalAttributes.kycFormStatus.T24_SIGN_CREATE_ERROR
          || comp.previousPage === GlobalAttributes.kycFormStatus.T24_ACC_CREATION_ERROR )
    },

    ShowActionBtnForFCMAndManuallyRejectCIFStatus(){
      const comp = this
      return  comp.hasPermission('approve-application') && (comp.previousPage === GlobalAttributes.kycFormStatus.FCM_REJECTED
          || comp.previousPage === GlobalAttributes.kycFormStatus.MANUALLY_REJECTED_FROM_CIF_ACC_FLOW)
    },

    ShowActionBtnForT24OverrideStatus(){
      const comp = this
      return  comp.hasPermission('approve-application') && (comp.previousPage === GlobalAttributes.kycFormStatus.T24_CIF_OVERRIDES || comp.previousPage === GlobalAttributes.kycFormStatus.T24_CORRECTED_OVERRIDES)
    }
  },
  components: {
    ScheduleRescheduleMeeting,
  },

  props: [
    "tableData",
    "columns",
    "styles",
    "previousPage",
    "statusToAssign",
    "searchKeyword",
    "searchableFields",
    "kycStatusToGetInternalComment"
  ],

  name: "TableComp",

  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  // life cycle hooks
  created() {
    const comp = this;
    comp.filter = filterTableData;
  },
  data() {
    return {
      assignedApplicationStatus: "",
      type: "",
      Sh_isButtonDisabled: false,
      Sh_showMessageBox: false,
      Sh_customer_comment: "",
      Sh_internal_comment: "",
      Sh_email: true,
      Sh_sms: false,
      Schedule_or_Reschedule: "Reschedule Video Call",
      videoCallTime: "",
      selected: "",
      kycid: "",
      filter: "",
      rescheduleBtnIsLoading: false,

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: 'is-centered',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,   
      },
      
    };
  },

  methods: {
    reviewClick(id,reviewOnly=false) {
      const comp = this;
      comp.authStore.kyc_form_id = id;
      comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;

      comp.authStore.reviewOnly.basicApplicationView = reviewOnly;
      comp.$router.push("/applications/view/" + id);
    },

    viewClick(id,reviewOnly=false) {
      const comp = this;
      comp.authStore.kyc_form_id = id;
      comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;

      comp.authStore.reviewOnly.approvedApplicationView = reviewOnly;
      comp.$router.push({path : "/approvedApplication/view/" + id, query : {statusToGetInternalComment : comp.kycStatusToGetInternalComment}});
    },

    viewCifOverrideApplication(id) {
      const comp = this;

      comp.authStore.kyc_form_id = id;
      comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
      comp.$router.push("/cifOverrides/view/" + id);
    },

    goToDownload(id) {
      const comp = this;
      comp.authStore.kyc_form_id = id;
      window.open('/Download', '_blank', 'width=1920,height=1080');
      newWindow.focus();
    },

    getFormattedDateTime(date) {
      return DateTimeManger.formatDateTime(date);
    },

    assignedApplication: function (id) {
      const comp = this;
      const data = {
        status: comp.assignedApplicationStatus,
        id: id,
      };

      NetworkManager.apiRequest(
        "api/KycApplication/assignApplication",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.authStore.kyc_form_id = id;
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            comp.$router.push("/applications/view/" + id);
          }else {
            BuefyHelper.showToastMessage(
              "Something Went Wrong . Try again Later.",
              "is-danger"
            );
          }
        }
      );
    },
    ReSchedule: function (kyc_form_id) {
      const comp = this;
      comp.authStore.kyc_form_id = kyc_form_id;
      const data = { id: kyc_form_id };
      comp.kycid = kyc_form_id;

      comp.rescheduleBtnIsLoading = true;

      NetworkManager.apiRequest(
        "api/KycApplication/getApplicationById",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.videoCallTime = e.data.kycApplication.video_call_time;
            let dateStr = e.data.kycApplication.video_call_date;
            comp.selected = new Date(dateStr);

            comp.rescheduleBtnIsLoading = false;
            comp.Sh_showMessageBox = true;
          } else {
            BuefyHelper.showToastMessage(
              "Unable to get the application data",
              "is-danger"
            );
            comp.rescheduleBtnIsLoading = false;
          }
        }
      );
    },

    update_Sh_customer_comment(value) {
      this.Sh_customer_comment = value;
    },
    update_Sh_internal_comment(value) {
      this.Sh_internal_comment = value;
    },
    update_Sh_email(value) {
      this.Sh_email = value;
    },
    update_Sh_sms(value) {
      this.Sh_sms = value;
    },
    Update_Sh_New_Date(value) {
      this.selected = value;
    },
    Update_Sh_New_Time(value) {
      this.videoCallTime = value;
    },
    Closs_Sh(value) {
      this.Sh_showMessageBox = value;
    },

    refreshPage() {
      this.$router.go(0);
    },
    sendScheduleMeeting: function () {
      const comp = this;
      this.Sh_showMessageBox = false;
      const data = {
        id: comp.authStore.kyc_form_id,
        date: comp.selected.toISOString(),
        time: comp.videoCallTime,
        comment_for_customer: comp.Sh_customer_comment,
        comment_for_internal_use: comp.Sh_internal_comment,
        is_reschedule:
          comp.Schedule_or_Reschedule ===
          "Schedule a Video Call & Submit to Caller"
            ? false
            : true,
        is_email: comp.Sh_email,
        is_sms: comp.Sh_sms,
      };
      NetworkManager.apiRequest(
        "api/KycApplication/scheduleVideoCall",
        data,
        function (e) {
          //console.log("send Schedule Meeting",e.data)
          if (e.statusCode === 200) {
            comp.Sh_isButtonDisabled = true;
            comp.SH_showMessageBox = false;
            if (
              comp.Schedule_or_Reschedule ===
              "Schedule a Video Call & Submit to Caller"
            ) {
              const params = {
                type: "is-success",
                message: "Video Call has been Scheduled successfully.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
              comp.$router.push("/schedulevideocall");
              comp.refreshPage()
            } else {
              comp.Sh_isButtonDisabled = false;
              const params = {
                type: "is-success",
                message: "Video Call has been Rescheduled successfully.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
              comp.refreshPage()
            }
          }
        }
      );
    },
  },
  mounted() {
    this.assignedApplicationStatus = this.statusToAssign;
  },
};
</script>
<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.action-btn {
  margin-bottom: 5px;
}

.table-comp-wrapper {
  overflow: auto !important;
  margin-right: 1.5%;
}

.table-container {
  overflow-y: auto !important;
}

.scrollable-table {
  max-width: 72vw;
  overflow-x: auto;
}
</style>
