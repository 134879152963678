<template>
  <div v-if="!isLoading" style="margin-bottom: 50px">
    <div class="p-0">
      <div class="columns" style="margin-left: 10px; margin-top: 10px;">
        <div>
          <b-button rounded @click="$router.go(-1)"  style="border-radius: 20px; height: 40px; width: 40px;" type="is-primary" outlined> <i class="mdi mdi-arrow-left mdi-36px"></i>  </b-button>
        </div>
        <div v-if="!isReviewOnly">
          <!-- <p style="color: #aaaaaa; font-size: 25px">Recommend / Reject Application</p> -->
          <!-- <p v-if="formData.current_status === 'APPROVED' || 'DEBIT-CARD-REVIEWED' || 'DEBIT-CARD-PENDING'" style="color: #aaaaaa; font-size: 25px">Approved Application</p> -->
          <p v-if="formData.current_status === 'REJECTED'" style="color: #aaaaaa; font-size: 25px">Rejected Application</p>
          <p v-else-if="formData.current_status === 'APPROVED' || formData.current_status === 'DEBIT-CARD-REVIEWED'" style="color: #aaaaaa; font-size: 25px">Approved Application</p>
          <p v-else style="color: #aaaaaa; font-size: 25px">Recommend / Reject Application</p>
          <!-- <p v-if="formData.current_status === 'SUBMITTED-EXCEPTIONAL-APPROVER'" style="color: #aaaaaa; font-size: 25px">Exceptional Approval Pending Application</p> 
          <p v-if="formData.current_status === 'EXCEPTIONAL-APPROVER-APPROVED'" style="color: #aaaaaa; font-size: 25px">Exceptional Approval Granted Application</p> 
          <p v-if="formData.current_status === 'SUBMITTED-EXCEPTIONAL-REJECTED'" style="color: #aaaaaa; font-size: 25px">Exceptional Approval Denied Application</p> -->

        </div>

        <div v-if="isReviewOnly">
          <p style="color: #aaaaaa; font-size: 25px">View Application</p>
        </div>

        <div style="margin-left: auto;" v-if="!isReviewOnly">
          <b-button class="is-primary" @click="goToDownload" >Download as PDF</b-button>
        </div>
      </div>
      <!-- <DownloadButton
        :navigate_page="`ApprovedApplications`"
        applicationName="Approved Application"
        :showDownLoadButton="true"
      ></DownloadButton> -->
      <!-- <div >
        <ApplicationDetailReview/>
      </div> -->
      <!-- <b-button class="is-danger" @click="goToDownload">DOWNLOAD</b-button> -->
        <div class="columns mt-4">
          <div class="column is-one-quarter">
            <CustomerDetail :kyc-form-status-to-get-internal-comment="kycStatusToInternalComment" ></CustomerDetail>
          </div>
          <div class="column ml-4">
            <b-breadcrumb>
              <b-breadcrumb-item @click="isGeneralDetail()"
                >General Details</b-breadcrumb-item
              >
              <b-breadcrumb-item @click="isAccountDetail()"
                >Account Details</b-breadcrumb-item
              >
              <b-breadcrumb-item @click="isAssetsDetail()"
                >Assets Details</b-breadcrumb-item
              >
              <b-breadcrumb-item @click="isImageUpload()"
                >Image Uploads</b-breadcrumb-item
              >
              <b-breadcrumb-item @click="isVedioCallDetail()"
                >Video Call Details</b-breadcrumb-item
              >
              <b-breadcrumb-item @click="isLocationDetail()"
                >Location Details</b-breadcrumb-item
              >
              <b-breadcrumb-item @click="isRiskAnalys()"
                >Risk Analysis</b-breadcrumb-item
              >
              <b-breadcrumb-item @click="isPepSanction()"
                >PEP, Sanction and AMS Results</b-breadcrumb-item
              >
            </b-breadcrumb>
             <RiskAnalysis v-if="isRiskAnalysis" />
            <LocationDetails v-if="isLocationDetails" />
            <ImageUploads v-if="isImageUploads" />
            <AccountDetails v-if="isAccountDetails" />
            <GeneralDetails v-if="isGeneralDetails" />
            <VedioCallDetails v-if="isVedioCallDetails" :review-only="isReviewOnly" />
            <AssetsDetails v-if="isAssetsDetails" />
            <PepSactionDetails v-if="isPepSanctionView" :fullName="fullName"/>
          </div>
      </div>
      <!-- <button
        @click="
          () => {
            goBack();
            navigateTo('ApprovedApplications');
          }
        "
      >
        go back
      </button> -->
    </div>
    <div v-if="!hideProceedButton && !isReviewOnly" id="proceed-btn-wrapper" >
      <b-button type="is-primary" style="width: 100%;"  @click="PDC_showMessageBox = true">Proceed</b-button>
    </div>
    <div v-if="PDC_showMessageBox">
      <div id="loading" class="fullscreen">
        <PendingForDebitCard
            @update_PDC_comment="update_PDC_comment"
            @Closs_PDC="Closs_PDC"
            @reviewed="reviewed"
        ></PendingForDebitCard>
      </div>
    </div>
  </div>
</template>

<script>

// import { from } from "webpack-sources/lib/CompatSource";
import DownloadButton from "../../components/DownloadButton.vue";
import CustomerDetail from "./CustomerDetail.vue";
import VedioCallDetails from "./NavBarComponents/VedioCallDetails.vue";
import ImageUploads from "./NavBarComponents/ImageUploads.vue";
import AccountDetails from "./NavBarComponents/AccountDetails.vue";
import GeneralDetails from "./NavBarComponents/GeneralDetails.vue";
import LocationDetails from "./NavBarComponents/LocationDetails.vue";
import RiskAnalysis from "./NavBarComponents/RiskAnalysis.vue";
import AssetsDetails from "./NavBarComponents/AssetsDetails.vue";
import ApplicationDetailReview from "../ApplicationDetailsView/ApplicationDetailReview.vue";
import PepSactionDetails from './NavBarComponents/PepSactionDetails.vue';
import PendingForDebitCard from "../../components/PendingForDebitCard.vue"; 
import NetworkManager from "@/network";
import {useAuthStore} from "../../store/authStore";
export default {
  name: "ApprovedApplicationView",
  components: {
    DownloadButton,
    CustomerDetail,
    RiskAnalysis,
    LocationDetails,
    VedioCallDetails,
    ImageUploads,
    AccountDetails,
    GeneralDetails,
    AssetsDetails,
    ApplicationDetailReview,
    PendingForDebitCard,
    PepSactionDetails,
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data() {
    return {
      isReviewOnly:false,
      PDC_isButtonShown: true,
      PDC_showMessageBox: false,
      PDC_comment: "",

      isGeneralDetails: false,
      isImageUploads: false,
      isVedioCallDetails: false,
      isRiskAnalysis: false,
      isPepSanctionView: false,
      isAccountDetails: false,
      isAssetsDetails: false,
      isLocationDetails: false,
      formData: [],
      hideProceedButton :true, 
      kycHistoryData:[],
      kycStatusToInternalComment : "",
      isLoading : true,
      fullName : '',
    };
  },
  methods: {
    update_PDC_comment(value) {
      this.PDC_comment = value;
    },
    Closs_PDC(value) {
      this.PDC_showMessageBox = value;
    },

    goToDownload(id) {
      id=this.authStore.kyc_form_id
      //this.$router.push('/Download')
      window.open('/Download', '_blank', 'width=1920,height=1080');
      newWindow.focus();
    },
    goBack() {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    },
    isGeneralDetail() {
      this.isGeneralDetails = true;
      this.isAccountDetails = false;
      this.isImageUploads = false;
      this.isVedioCallDetails = false;
      this.isRiskAnalysis = false;
      this.isAssetsDetails = false;
      this.isLocationDetails = false;
      this.isPepSanctionView = false;
    },
    isAccountDetail() {
      this.isGeneralDetails = false;
      this.isAccountDetails = true;
      this.isImageUploads = false;
      this.isVedioCallDetails = false;
      this.isRiskAnalysis = false;
      this.isAssetsDetails = false;
      this.isLocationDetails = false;
      this.isPepSanctionView = false;
    },
    isAssetsDetail() {
      this.isGeneralDetails = false;
      this.isAccountDetails = false;
      this.isImageUploads = false;
      this.isVedioCallDetails = false;
      this.isRiskAnalysis = false;
      this.isAssetsDetails = true;
      this.isLocationDetails = false;
      this.isPepSanctionView = false;
    },
    isImageUpload() {
      this.isGeneralDetails = false;
      this.isAccountDetails = false;
      this.isImageUploads = true;
      this.isVedioCallDetails = false;
      this.isRiskAnalysis = false;
      this.isAssetsDetails = false;
      this.isLocationDetails = false;
      this.isPepSanctionView = false;
    },
    isVedioCallDetail() {
      this.isGeneralDetails = false;
      this.isAccountDetails = false;
      this.isImageUploads = false;
      this.isVedioCallDetails = true;
      this.isRiskAnalysis = false;
      this.isAssetsDetails = false;
      this.isLocationDetails = false;
      this.isPepSanctionView = false;
    },
    isLocationDetail() {
      this.isGeneralDetails = false;
      this.isAccountDetails = false;
      this.isImageUploads = false;
      this.isVedioCallDetails = false;
      this.isRiskAnalysis = false;
      this.isAssetsDetails = false;
      this.isLocationDetails = true;
      this.isPepSanctionView = false;
    },
    isRiskAnalys() {
      this.isGeneralDetails = false;
      this.isAccountDetails = false;
      this.isImageUploads = false;
      this.isVedioCallDetails = false;
      this.isRiskAnalysis = true;
      this.isAssetsDetails = false;
      this.isLocationDetails = false;
      this.isPepSanctionView = false;
    },
    isPepSanction() {
      this.isGeneralDetails = false;
      this.isAccountDetails = false;
      this.isImageUploads = false;
      this.isVedioCallDetails = false;
      this.isRiskAnalysis = false;
      this.isAssetsDetails = false;
      this.isLocationDetails = false;
      this.isPepSanctionView = true;
    },

    getApplicationData(){
      const comp= this

      comp.isLoading = true;

      comp.showFullScreenLoader()

      // get query parameter value when mounted
      comp.kycStatusToInternalComment = comp.$route.query.statusToGetInternalComment
      console.log("query parameter : ",comp.kycStatusToInternalComment)

      const data = {
         id: comp.authStore.kyc_form_id
      }
      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication
          console.log("APPLICATION DATA --->",e.data) 
          comp.kycHistoryData = e.data.kycFormStatusHistory  
          console.log("full name:", e.data.kycApplication.full_name)  
          comp.fullName = e.data.kycApplication.full_name.customer_input

          if(comp.hasPermission('review-application-from-branch-pool') || comp.hasPermission('review-application-from-debit-card-pool')){
               if(comp.formData.current_status === 'APPROVED' && comp.kycHistoryData[comp.kycHistoryData.length -1].kyc_status === "DEBIT-CARD-PENDING"){
                comp.hideProceedButton = false
               }
          }

          comp.isLoading = false;
          comp.hideFullScreenLoader()
        }
        else{
          comp.isLoading = false;
          comp.hideFullScreenLoader()
        }
      })
    },
    reviewed(){
      const comp= this

      comp.showFullScreenLoader()

      const data = {
         id: comp.authStore.kyc_form_id,
         status : 'DEBIT-CARD-REVIEWED',
         comment_for_internal_use : comp.PDC_comment
      }

      console.log(data)

      comp.PDC_showMessageBox = false

      NetworkManager.apiRequest('api/KycApplication/ApplicationStatusChange',data, function (e){
        if(e.statusCode === 200){

          const params = {
            type: 'is-success',
            message: 'Application Reviewed Successfully',
            duration: 5000,
          }

          comp.$buefy.toast.open(params)
          comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
          comp.hideFullScreenLoader()
          comp.$router.push("/PendingDebitCardQueue");
        }
        else {

          comp.hideFullScreenLoader()

          const params = {
            type: "is-warning",
            message: "Something went wrong!.",
            duration: 5000,
          };

          comp.$buefy.toast.open(params);

        }
      })
    }
  },


  mounted() {
    const comp = this

    comp.isReviewOnly = comp.authStore.reviewOnly.approvedApplicationView

    comp.isGeneralDetails = true;
    comp.getApplicationData();

    if(!comp.$root.isSideBarHidden ){
      comp.$root.isSideBarHidden=!comp.$root.isSideBarHidden;
    }
  },
};
</script>

<style scoped>

#proceed-btn-wrapper{
  margin-bottom: 30px;
  margin-top: 10px;
}

.breadcrumb.is-medium {
  font-size: 0.9rem;
}
</style>
