<template>
  <div>
    <div class="p-0" :key="refreshKey">

      <div class="columns" style="margin:10px auto 10px 10px">
        <div style="position:fixed; margin-left: -4vw;">
          <b-button rounded @click="goToAccountCreationQueue" style="border-radius: 20px; height: 40px; width: 40px;"> <i class="mdi mdi-arrow-left mdi-24px"></i></b-button>
        </div>

        <div>
          <p style="color: #aaaaaa; font-size: 25px">
            Approve / Reject Application
          </p>
        </div>
      </div>


      <!--  Cif Application Review Component -->
      <CifApplicationReview/>
    </div>
  </div>
</template>

<script>
import NetworkManager from "../../../network";
import { useAuthStore } from "../../../store/authStore";
import CifApplicationReview from "../../../components/CifApplicationReview.vue";

export default {
  name: "AssignedApplicationsReview",

  components: {
    CifApplicationReview
  },

  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      isLoading: true,
      refreshKey: 0,
      formData: [],
    };
  },

  methods: {
    goToAccountCreationQueue(){
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 1 }, // overrides tab
      });
    }
  },

  mounted() {

  },
};
</script>

<style scoped></style>
