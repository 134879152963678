<template>
  <div>
    <div class="my-3">
      <div class="is-flex is-justify-content-space-between">
        <div class="is-flex is-align-items-center">
          <b-image
            :src="require(`../assets/Images/Icons/Arrow.png`)"
            responsive
            class="img_size"
            @click="navigatePage"
          >
          </b-image>
          <p class="mb-0 ml-2" style="color: #aaaaaa; font-size: 25px">
            {{ applicationName }}
          </p>
        </div>
        <b-button v-if="showDownLoadButton" class="is-primary is-rounded"
          >Download as PDF</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadButton",
  props: ["navigate_page", "applicationName", "showDownLoadButton"],
  methods: {
    navigatePage() {
      console.log("in navigation");
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
      this.$router.push(this.navigate_page);
    },
  },
};
</script>

<style scoped>
.img_size {
  width: 50px;
}
</style>
