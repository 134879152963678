<!-- have to remove this com-->
<template>
  <div>
    <div class="columns">
      <div class="column">
        <VediocallEkycNew
          :ekycFormData="$root.formData.kycForm"
          :product_selection="$root.formData.kyc_form_product_selection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputLabel from "../../components/InputLabel.vue";
import ImageBox from "../../components/ImageBox.vue";
import AgentInputButtons from "../../components/AgentInputButtons.vue";
import SelectLabel from "../../components/SelectLabel.vue";
import { KycAttribute } from "../../helpers/classes/kycAttribute";
import PurposeOfAccountOpening from "@/helpers/classes/ProductSelection/PurposeOfOpening";
import VediocallEkycNew from "../../components/VideoCallEkyc.vue";
import { BuefyHelper } from "../../helpers/bufeyHelper";
import NetworkManager from "../../network";
import { ApiRequestManager } from "../../network/apiRequestManager";
export default {
  name: "VediocallEkyc",
  components: {
    InputLabel,
    ImageBox,
    AgentInputButtons,
    SelectLabel,
    VediocallEkycNew,
  },
  data() {
    return {
      product_selection: "",
      product_selection_v_model: "",
      overall_score: "58%",
      staff_name: "",

      call_agent_comment: "",
      purpose_of_opening_modals: [],
      other_opening_purpose: [],
      anticipated_volumes_modals: [],
    };
  },
  methods: {},
  computed: {},
};
</script>
