<template>
  <div class="wrapper">
    <!-- current value -->
    <label for="attachment">{{label}}</label>
    <display-attachment :type="kycAttachment.value.attachment_type" :url="kycAttachment.value.attachment_url" :alt="label" />

    <!-- changed attachments -->
    <div v-if="kycAttachment.changes.length > 0">
      <div v-for="(change,index) in kycAttachment.changes" :key="index">
        <label for="attachment" class="has-text-danger">{{getChangedAttachmentLabel(change.source, label)}}</label>
        <display-attachment :type="change.value.attachment_type" :url="change.value.attachment_url" :alt="label" :is-danger="true" />
      </div>
    </div>

  </div>
</template>

<script>

import {defineComponent} from "vue";
import DisplayAttachment from "@/components/CentralKyc/DisplayAttachment.vue";
export default defineComponent({
  name: "central-kyc-attachment",

  components :{
    DisplayAttachment
  },

  props: {
    kycAttachment : {
      required: true,
      type: Object
    },
    label:{
      required: true,
      type: String
    }
  },

  data(){
    return {

    }
  },

  methods : {
    getChangedAttachmentLabel(source, label){
      return   "Previous "+ label + " - "+ source
    }
  },

  mounted(){

  }

})

</script>

<style scoped>

.image-helper {
  height: 56vh;
  width: 28.2vw;
  text-align: center
}
</style>