<template>
  <div class="mt-6 ml-3 wrapper">
    <div class="filter-wrapper" v-if="date && !showLoader">

      <div class="filter-item">
        <label>Date Range</label>
        <b-field class="is-small">
          <b-datepicker
              rounded
              v-model="startDate"
              :max-date="GetMaxStartDate"
              :show-week-number="false"
              :append-to-body="true"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field>
      </div>

      <div class="filter-item" style="margin-bottom: 6px">
        <span class="mt-4"><br>To</span>
      </div>

      <div class="filter-item">
        <b-field>
          <b-datepicker
              rounded
              v-model="endDate"
              :min-date="startDate"
              :max-date="maxStartDate"
              :show-week-number="false"
              :append-to-body="true"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field>
      </div>

      <div class="filter-item filter-item-loop" v-for="field in fields" :key="field.id">
        <label>{{field.name}}</label>

        <b-field >
          <b-select :placeholder="field.placeholder" size="is-small" rounded v-model="field.selectedValue" @change.native="emitChangedFilterOptions(new SelectedFilterDetailWithOption(field.name,field.id,field.selectedValue) )">
            <option v-for="option in field.options" :value="option.id" :key="option.id">{{option.value}}</option>
          </b-select>
        </b-field>
      </div>

      <div class="filter-item" style="align-self: baseline" v-if="userSelect">
        <label>User</label>
        <b-field>
          <b-select  v-model="filterUserId" placeholder="All Users" rounded size="is-small" >
            <option :value=null>All Users</option>
            <option v-for="user in users" :value="user.id" :key="user.id">{{user.username}}</option>
          </b-select>
        </b-field>
      </div>
     

      <!-- filter button -->
      <div class="filter-item">
        <b-button class="is-primary filter-btn" rounded @click="emitFilterData" style="margin-top: 21px;">Filter</b-button>
      </div>

      <!-- clear filters button  -->
      <div class="filter-item">
        <b-button class="clear-filter-btn" rounded outlined @click="clearFilters" style="margin-top: 21px;" type="is-primary">Clear</b-button>

      </div>
      
    </div>

    <b-loading :is-full-page="true" v-model="showLoader" :can-cancel="false"></b-loading>

    <div class="columns" v-if="!date">
      <div class="column" v-for="field in fields" :key="field.id">{{field.name}}<br>

        <b-field >
          <b-input  v-model="field.selectedValue" type="text" rounded  style="width:250px"></b-input>

        </b-field>
      </div>
      <div class="filter-item"> <br>
        <b-button class="is-primary" rounded @click="searchData()" style="margin-top: 11px;">Filter</b-button>
      </div>

      <div class="filter-item"> <br>
        <b-button class="clear-filter-btn" rounded outlined @click="clearFiltersDUP" style="margin-top: 11px;" type="is-primary">Clear</b-button>
      </div>

    </div>
  </div>

</template>

<script>

import {useAuthStore} from "../store/authStore";
import NetworkManager from "../network";
import {GlobalEventManager} from "../helpers/globalEventManager";
import {GlobalEvents} from "../helpers/globalEvents";
import {SelectedFilterDetailWithOption} from "../helpers/classes/admin/dataFilterTable/selectedFilterDetailWithOption";
import {GlobalAttributes} from "../helpers/globalAttributes";


export default {
  computed: {
    SelectedFilterDetailWithOption() {
      return SelectedFilterDetailWithOption
    },
    GetMaxStartDate(){
      const comp = this
      return comp.endDate === null ? comp.maxStartDate : comp.endDate
    },
  },


  components: {

  },

  props:['date','fields','tableData','roleFilteringFor','userSelect'],

  name: "AdminTableFilter",



  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      maxStartDate : new Date(), // get yesterday as max date
      minEndDate: new Date(),
      filterChanged: false,
      users:[],
      filterUserId:null,
      showLoader : false,

    };

  },

  mounted(){
     this.getUsers();
    // console.log("filter fields")
    // console.log(this.fields)
  },

  methods: {

    /***
     * This function is used to clear the selected filter options
     */
     clearFiltersDUP(){
       const comp = this
       comp.fields.forEach(field=>{
        field.selectedValue = null
       });
       comp.$emit('filterData',comp.fields)
     },

    clearFilters(){
      const comp = this
      comp.showLoader = true

      comp.startDate = null
      comp.endDate = null
      comp.filterUserId = null

      comp.fields.forEach(field=>{

        if(field.name === "Status"){
          // default value for the status is pending
          field.selectedValue = GlobalAttributes.ExceptionalApprovalStatus.Pending
        }
        else{
          field.selectedValue = null
        }
        console.warn(field)
      })

      // small delay was added otherwise placeholders will not appeared in the dropdown fields
      setTimeout(()=>{comp.showLoader = false},50)
      this.emitFilterData()
    },

    getUsers:function (){
      const comp = this;

      const requestBody = {
        role_name: comp.roleFilteringFor
      }

      NetworkManager.apiRequest("api/User/getUserListByRoleName",requestBody,(response)=>{
        console.log(response)
        if(response.statusCode  === 200){
          comp.users=response.data.users
          // response.data.users.forEach(user => comp.users.push(new FilteredUserByRole(user.id,user.username,user.name)))
        }
      })
    },

    emitClearData:function (){

      this.startDate= null
      this.endDate= null
      this.filterChanged = true
      this.filterUserId = null

      this.fields.forEach(field => {
        field.selectedValue = null;
      });

      // Emit the filter event with empty filter parameters
      this.emitFilterData();
    },

    emitFilterData:function (){
      const comp = this

      //TODO:: emit event with selected values

      let filterParameters = {}

      // add dynamic properties...
      comp.fields.forEach(field => filterParameters[field.name.toLowerCase()] = field.selectedValue )

      // add date
      if(comp.date){
        filterParameters["startDate"] =comp.startDate
        filterParameters["endDate"] =comp.endDate
      }

      // add role requesting for the filter
      if(comp.roleFilteringFor !== undefined){
        filterParameters["role"] = comp.roleFilteringFor
      }
    
      if(comp.userSelect){
        filterParameters["user"] = comp.filterUserId
      }


      console.log("filter params (emitters end) : ")
      console.log(filterParameters)

      this.$emit('filterData',filterParameters)
    },

    searchData:function (){
      // emit event with selected data
      console.log(this.fields,"searchData")
      this.$emit('filterData',this.fields)

    },


    emitChangedFilterOptions(selectedFilterOption){
      GlobalEventManager.$emit(GlobalEvents.admin.adminFilterDropdownChanged,selectedFilterOption)
    }
  }
}

</script>
<style scoped>
.wrapper{
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(88, 88, 88, 0.5);
  display:grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}


.filter-wrapper{
  display: flex;
  justify-content: left;
  align-items: flex-end;
  flex-wrap: wrap;
}

.filter-item{
  min-width: min-content;
  max-width: 200px;
  margin-right : 10px;
}

.filter-item-loop{
align-self: baseline;
}

.filter-btn{
  min-width: 120px;
}

.clear-filter-btn{
  width: 100px;
}

</style>
