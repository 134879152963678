<template>
    <div style="height: 80.5vh; overflow: auto !important" > 
      <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Refer to Branch </label>
          </b-breadcrumb>

        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                  placeholder="Search..."
                  rounded
                  type="search"
                  icon="magnify"
                  icon-clickable
                  v-model="searchKeyword"
                  style="width:450px"

              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>
        <TableComp
          :tableData="debitcardQueue"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchKeyword="searchKeyword"
          :searchableFields="searchableFields"

           styles="mt-4 mx-6"
        ></TableComp>
    </div>
  </template>
  
  <script>
  import TableComp from "../../components/TableComp.vue";
  import SearchBar from "../../components/SearchBar.vue";
  import NetworkManager from "@/network"
  
  export default {
    components: { TableComp, SearchBar },
    name: "RefferToBranchQueue",
    data() {
      return {
        debitcardQueue: [],
        currentPage:'REFER-TO-BRANCH-PENDING',
        statusToAssign:'',
        searchKeyword:"",

        searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','created_at'],

        columnDetails: [
          { id: 1, field: "reference_number", label: "Ref No." ,date:false},
          { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
          { id: 3, field: "full_name", label: "Name" ,date:false},
          { id: 4, field: "email", label: "Email" ,date:false},
          { id: 5, field: "mobile_number", label: "Contact" ,date:false},
          { id: 6, field: "created_at", label: "Received Date",date:true},
          { id: 7, field: "action", label: "Action" ,date:false},   
        ],
      };
    },
    methods: {
      Get_debitcardQueue_Details(){
        const comp= this
        const  data = {
          status:"REFER-TO-BRANCH-PENDING"
        }
  
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listByStatus',data, function (e){
          if(e.statusCode === 200){
            comp.debitcardQueue = e.data.applications
          }
  
          // comp.hideFullScreenLoader()
        });
      },
     
      
   
    },
    mounted() {     
        this.Get_debitcardQueue_Details();
        if(this.$root.isSideBarHidden ){
          this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
        }
      },
  };
  </script>
  <style scoped></style>
  