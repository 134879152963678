import router from "@/router"
import {ToastProgrammatic as Toast} from 'buefy'
import {useAuthStore} from "../store/authStore";
import NetworkManager from "@/network"
import auth from "../middlewares/auth";
import {PiniaStoreHelper} from "./piniaHelper";
import {useVideoKycStore} from "../store/videoKycStore";
import {GlobalEventManager} from "./globalEventManager";
import {GlobalFunctions} from "./globalFunctions";
import {jwtDecode} from "jwt-decode";

const jwtUtils = {

    status : {
        valid: 'valid',
        invalid: 'invalid',
        expired: 'expired',
        margin: 'margin'
    },

    loadToken: function (customToken=""){
        try {
            let data = null

            const token = customToken === "" ? localStorage.getItem('token') : customToken
            const jwtObj = this.parseJwt(token)

            const status = this.validateJwt(jwtObj)

            switch (status){
                case this.status.valid:
                    data = {token, jwtObj}
                    break
                case this.status.margin:
                    data = {token, jwtObj}
                    setTimeout(()=>{
                        this.refreshJwt().then()
                    }, 5000)
                    break
                case this.status.expired:
                    console.error('Token expired!')
                    this.logoutTheUser()
                    break
                case this.status.invalid:
                    console.error('Token invalid!')
                    this.logoutTheUser()
                    break
            }

            return data
        }
        catch (e){
            console.error(e.message)
            this.logoutTheUser()

            return null
        }
    },

    saveToken: function (token){
        try {
            this.parseJwt(token)

            const authStore= useAuthStore()
            authStore.token = token
            authStore.isTokenRefreshing = false

            localStorage.setItem('token', token)
            localStorage.setItem('isTokenRefreshing', "false")
        }
        catch {
            console.error('Token save failed!')
        }
    },

    validateToken: function (){
        try {
            let isValid = true

            const token = localStorage.getItem('token')
            const jwtObj = this.parseJwt(token)

            const status = this.validateJwt(jwtObj)

            switch (status){
                case this.status.valid:
                    isValid = true
                    break
                case this.status.margin:
                    isValid = true
                    break
                case this.status.expired:
                    console.error('Token expired!')
                    this.logoutTheUser()
                    isValid = false
                    break
                case this.status.invalid:
                    console.error('Token invalid!')
                    this.logoutTheUser()
                    isValid = false
                    break
            }

            return isValid
        }
        catch (e){
            console.error(e.message)
            this.logoutTheUser()

            return false
        }
    },

    parseJwt: function (token){
        try{

            // NOTE: After NODE JS upgrade, JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
            // returns error when parsing JWT
            // Since then, use jwtDecode function from jwt-decode library
            // 05/02/2023 - LR
            let parsedToken = jwtDecode(token)

            // let parsedToken =  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())


            // if(parsedToken.permissions){
            //     parsedToken.permissions = JSON.parse(parsedToken.permissions)
            //     console.warn("list adjustment")
            // }

            return parsedToken

        }
        catch (e){
            console.error("Token parsing failed")
            throw "Token parsing failed!"
        }
    },

    validateJwt: function (jwtObj){
        try {
            let status = this.status.valid

            const createdTime = jwtObj.iat
            const expireTime = jwtObj.exp
            const refreshMargin = jwtObj.rfh
            const currTime = Date.now() / 1000
            const timespan = currTime - createdTime

            //Debug Logs --------------------------------------
            // console.warn("validating JWT token ...")
            // console.log("jwt details")
            // console.log("Expire time",jwtObj.exp)
            // console.log("refresh margin",jwtObj.rfh)
            // console.log("timespan",timespan)
            // console.log("is video call started :",GlobalFunctions.getVideoCallStartedState())

            if(currTime >= expireTime){
                status = this.status.expired
            }

            if(timespan >= refreshMargin / 1000) {
                status = this.status.margin
            }

            return status
        }
        catch {
            return this.status.invalid
        }

    },

    refreshJwt: async function (enforcedRefresh=false){
        const self = this
        const authStore = useAuthStore()

        const isRefreshing = localStorage.getItem('isTokenRefreshing')


        if(enforcedRefresh){
            console.warn('Enforced token refreshing...')
            await NetworkManager.apiRequest('api/auth/token', {}, function (e) {
                if (e.statusCode === 200) {
                    let token = e.data.token
                    self.saveToken(token)
                }
            })
        }
        else{
            if(isRefreshing === "false"){
                console.log('Refreshing token...')
                localStorage.setItem('isTokenRefreshing', 'true')
                authStore.isTokenRefreshing = true

                await NetworkManager.apiRequest('api/auth/token', {}, function (e) {
                    if (e.statusCode === 200) {
                        let token = e.data.token
                        self.saveToken(token)
                    }
                })
            }
        }
    },

    logoutTheUser: function (manualLogOut = false){

        if(!GlobalFunctions.getVideoCallStartedState()){
            
            const authStore = useAuthStore()
            const videoKycStore = useVideoKycStore()

            GlobalEventManager.$emit('hide-video-call-agent-model')
            if(localStorage.getItem('token') != null){
                localStorage.removeItem('token')


                PiniaStoreHelper.clearLocalStorage(authStore)
                PiniaStoreHelper.clearLocalStorage(videoKycStore)
            }

            GlobalFunctions.removeVideoCallStartedState() // remove video call started state from the local storage

            if(router.currentRoute.name !== 'Login'){
                if(manualLogOut){

                    // logout mask for central kyc admins
                    if(router.currentRoute.name === 'CentralKyc' || router.currentRoute.name === 'adminApplicationsList' ){
                        router.replace({path: '/admin/login'}).then(()=>{router.go()})
                    }
                    else{
                        router.replace({path: '/login'}).then(()=>{router.go()})
                    }

                }
                else {
                    const params = {
                        type: 'is-warning',
                        message: 'Session expired!. Please login again'
                    }
                    Toast.open(params)
                    setTimeout(() => {

                        // logout mask for central kyc admins

                        if(router.currentRoute.name === 'CentralKyc' || router.currentRoute.name === 'adminApplicationsList' ){

                            router.replace({path: '/admin/login'}).then(()=>{router.go()})
                        }
                        else{
                            router.replace({path: '/login'}).then(()=>{router.go()})
                        }

                    }, 100)
                }
            }
        }
        else{
            console.warn("Avoiding logout... Video call started ...")
        }
    }
}

export default jwtUtils
