export const GlobalEvents = {
    agentSide :{
        showVideoCallAgentModel : "show-video-call-agent-model",
        hideVideoCallAgentModel : "hide-video-call-agent-model",
        answerVideoCall : "answer-video-call",
        rejectVideoCall : "reject-video-call",
        endCallModal : {
            closeModal : 'close-modal-agent-end-call-modal',
            endWithOutSavingData : 'end-call-without-saving-data-agent-end-call-modal',
            endAndSaveData : 'end-call-and-save-data-agent-end-call-modal',
        },
        captureAttachmentImage : "capture-image-agent-video-call",
        closeEndCallNotification : "close-agent-end-call-notification",
        uploadingLoader : {
            show : "show-uploading-loader-agent-video-call",
            hide : "hide-uploading-loader-agent-video-call",
        }
    },
    customerSide : {
        otpVerifiedSuccess: "otp-verified-success-customer-video-call",
        callEnded : "call-ended-customer-video-call", // by agent
        agentBusy : "agent-busy-customer-video-call",
        socketClosed : "socket-closed-customer-video-call",
        endVideoCall : "end-video-call-customer-video-call", // by customer itself
        startingVideoCall : "starting-video-call-customer-video-call",
        videoTrackReceived : 'video-track-received-customer-video-call',
    },

    admin:{
        adminFilterDropdownChanged : "admin-filter-dropdown-changed",
        adminFilterResetDates : "admin-filter-date-reset",

        kycApplications:{
            showInputIndustryModal : "admin-kyc-show-input-industry-modal",
            hideInputIndustryModal : "admin-kyc-hide-input-industry-modal",
            hideInputIndustryModalAndLoadData : "admin-kyc-hide-input-industry-modal-and-load-data"
        }
    },


}