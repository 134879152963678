<template>
    <div style="height: 80.5vh;overflow: auto !important"> 
      <!-- <div class="mt-6">
        <SearchBar page="Returned Applications" />
      </div> -->
      <div class="columns" style="margin-top: 25px; margin-left: 20px;">
      <div class="column">
        <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Returned Applications</label>
      </div>
      <div class="column">
        <b-input
          placeholder="Search..."
          rounded
          type="search"
          icon="magnify"
          icon-clickable
          v-model="searchKeyword"
          style="width:450px">
        </b-input>
      </div>
    </div>
      <b-tabs v-model="activeTab">
        <b-tab-item label="Detail Reviewer" v-bind="detail_reviewer">
          <TableComp
            :tableData="tableinfo.searchData(detailTableDetails, searchableFields, searchKeyword)"
            :columns="tableDetails"
            :previousPage="currentPage"
            :statusToAssign="statusToAssign"
            :searchableFields="searchableFields"

          styles="mt-4 mx-6"
          />
        </b-tab-item>

        <b-tab-item label="Call Agent" v-bind="call_agent">
          <TableComp
            :tableData="tableinfo.searchData(agentTableDetails, searchableFields, searchKeyword)"
            :columns="tableDetails"
            :previousPage="currentPage"
            :statusToAssign="statusToAssign"
            :searchableFields="searchableFields"

          styles="mt-4 mx-6"
          />
        </b-tab-item>
      </b-tabs> 
    </div>
  </template>
  
  <script>
  import TableComp from "../../components/TableComp.vue";
  import SearchBar from "../../components/SearchBar.vue";
  import FilterBranch from "../../components/FilterBranch.vue";
  import { filterTableData } from "../../helpers/filterTableData";
  import NetworkManager from "@/network"
  
  export default {
    components: { TableComp, SearchBar, FilterBranch },
    name: "ReturnedApplicationList",
    data() {
      return {
        activeTab: 0,
        detail_reviewer : false,
        call_agent : false,

        detailTableDetails: '',
        agentTableDetails: '',
        searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','status_updated_at'],
        searchKeyword:"",
        tableinfo:"",
        applicationList :[],

        currentPage:'Returned-Applications',
        statusToAssign:"",
  
        tableDetails: [
          { id: 1, field: "reference_number", label: "Ref No." ,date:false},
          { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
          { id: 3, field: "full_name", label: "Name" ,date:false},
          { id: 4, field: "email", label: "Email" ,date:false},
          { id: 5, field: "mobile_number", label: "Contact" ,date:false},
          { id: 6, field: "status_updated_at", label: "Returned Date",date:true},
          { id: 7, field: "action", label: "Action" ,date:false},   
        ],
      };
    },

    created() {
      this.tableinfo = filterTableData
    },

    methods: {
      GetDetailReviewerData: function (){
        const comp= this
        const  data = {
          status:"RETURNED-DETAIL-REVIEWER"
        }
  
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listByStatus',data, function (e){
          if(e.statusCode === 200){
            comp.detailTableDetails = e.data.applications
          }
  
          // comp.hideFullScreenLoader()
        })
      },
      GetCallAgentData: function (){
        const comp= this
        const  data = {
          status:"RETURNED-CALL-AGENT"
        }
  
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/listByStatus',data, function (e){
          if(e.statusCode === 200){
            comp.agentTableDetails = e.data.applications
          }
  
          // comp.hideFullScreenLoader()
        })
      },
    
    },
    mounted() {
      console.log("in mounted")
     
      this.GetDetailReviewerData();
      this.GetCallAgentData();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
  };
  </script>
  <style scoped></style>
  