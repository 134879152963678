<template>
  <div>
    <div class="columns">
      <!-- <div class="column"></div> -->
      <div class="column box my-6 px-6">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="">
            <div class="is-flex mb-5">
              <img v-if="ekycFormData.selfie_attachment.attachment_type !== 'pdf'" :src="ekycFormData.selfie_attachment.attachment_url" class="round-image-box" />
              <img v-else :src="pdfPlaceholder" class="round-image-box" />
              <div class="ml-4 mt-6">
                <p
                  :style="{
                    'font-size': '1.5rem',
                    'font-family': 'Gotham-Bold',
                    'letter-spacing': '0.2px',
                  }"
                >
                  {{ ekycFormData.full_name.customer_input || "Charith" }}
                </p>

                <hr
                  :style="{
                    'background-color': '#231F20',
                    height: '0.3px',
                    'margin-top': '0',
                    'padding-top': '0',
                    'margin-bottom': '0',
                    'padding-bottom': '0',
                    opacity: '0.5',
                  }"
                />
                <p style="font-size: 1.02rem" class="mt-2">
                  <img
                    src="../assets/Images/Icons/calll.webp"
                    alt="call.img"
                    width="4%"
                    class="mr-1"
                  /><span class="pr-4 verticalLine">{{
                    ekycFormData.mobile_number.customer_input || "+9475XXXXXXX"
                  }}</span>
                  <img
                    src="../assets/Images/Icons/emaill.webp"
                    alt="email.img"
                    width="4%"
                    class="ml-3 mr-1"
                  />{{
                    ekycFormData.email.customer_input || "yourXXXXX@gmail.com"
                  }}
                </p>
              </div>
            </div>
            <div class="columns mt-2">
              <div class="column is-one-third">
                <SelectLabel
                  label="Title"
                  required="true"
                  v-model="titleText"
                  :validationRules="{ required: true }"
                  :selectOptions="titleList"
                />

                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.title.call_agent_input &&
                      ekycFormData.title.customer_input !== titleText) ||
                    (ekycFormData.title.call_agent_input &&
                      ekycFormData.title.call_agent_input !== titleText)
                  "
                  :save="
                    () => {
                      ekycFormData.title.call_agent_input = titleText;
                      ekycFormData.title.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.title.call_agent_input ===
                        ekycFormData.title.customer_input
                      ) {
                        ekycFormData.title.call_agent_input = ``;
                        ekycFormData.title.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      titleText = ekycFormData.title.call_agent_input
                        ? ekycFormData.title.call_agent_input
                        : ekycFormData.title.customer_input;
                    }
                  "
                />
              </div>
            </div>

            <InputLabel
              label="Full Name (As per the NIC / EIC / PP)"
              type="textarea"
              rounded
              required="true"
              :maxLength="146"
              rows="2"
              cols="48"
              v-model="fullnameText"
              :validationRules="validateName()"
              fieldName="Name"
              @getError="
                (error) => {
                  fullNameValidationProviderError = error;
                }
              "
            />
            <AgentInputButtons
              :show-buttons="
                (!ekycFormData.full_name.call_agent_input &&
                  ekycFormData.full_name.customer_input !== fullnameText) ||
                (ekycFormData.full_name.call_agent_input &&
                  ekycFormData.full_name.call_agent_input !== fullnameText)
              "
              :save="
                () => {
                  ekycFormData.full_name.call_agent_input = fullnameText;
                  ekycFormData.full_name.is_changed_by_call_agent = true;
                  if (
                    ekycFormData.full_name.call_agent_input ===
                    ekycFormData.full_name.customer_input
                  ) {
                    ekycFormData.full_name.call_agent_input = ``;
                    ekycFormData.full_name.is_changed_by_call_agent = false;
                  }
                }
              "
              :cancel="
                () => {
                  fullnameText = ekycFormData.full_name.call_agent_input
                    ? ekycFormData.full_name.call_agent_input
                    : ekycFormData.full_name.customer_input;
                }
              "
              :disable="fullNameValidationProviderError"
            />

            <div class="columns mt-3">
              <div class="column">
                <InputLabel
                  label="NIC / EIC"
                  v-model="customernicText"
                  disabled="true"
                  required="true"
                  :validationRules="{
                    required: true,
                    multiRegex: [/^([0-9]{9}[Xx||Vv])$/, /^[0-9]{12}$/],
                  }"
                  @getError="
                    (error) => {
                      idValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.nic_eic_number.call_agent_input &&
                      ekycFormData.nic_eic_number.customer_input !==
                        customernicText) ||
                    (ekycFormData.nic_eic_number.call_agent_input &&
                      ekycFormData.nic_eic_number.call_agent_input !==
                        customernicText)
                  "
                  :save="
                    () => {
                      ekycFormData.nic_eic_number.call_agent_input =
                        customernicText;
                      ekycFormData.nic_eic_number.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.nic_eic_number.call_agent_input ===
                        ekycFormData.nic_eic_number.customer_input
                      ) {
                        ekycFormData.nic_eic_number.call_agent_input = ``;
                        ekycFormData.nic_eic_number.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      customernicText = ekycFormData.nic_eic_number
                        .call_agent_input
                        ? ekycFormData.nic_eic_number.call_agent_input
                        : ekycFormData.nic_eic_number.customer_input;
                    }
                  "
                  :disable="idValidationProviderError"
                />
              </div>

              <div class="column is-one-third">
                <ValidationProvider
                  name="Issue Date"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label>NIC Issue Date<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-datepicker
                        position="is-bottom-left"
                        rounded
                        :max-date="maxDate"
                        @focus="
                          () => {
                            nicIssueDate = true;
                          }
                        "
                        @blur="
                          () => {
                            nicIssueDate = false;
                          }
                        "
                        v-model="nicIssueDateText"
                      >
                      </b-datepicker>

                      <span class="icon is-small is-right" v-if="nicIssueDate">
                        <img
                          :src=calendarIcon
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <AgentInputButtons
                  :show-buttons="
                    ekycFormData.nic_eic_issue_date.call_agent_input !==
                    `0001-01-01T00:00:00`
                      ? new Date(
                          ekycFormData.nic_eic_issue_date.call_agent_input
                        ).getTime() !== nicIssueDateText.getTime()
                      : nicIssueDateCustomerInput.getTime() !==
                        nicIssueDateText.getTime()
                  "
                  :save="
                    () => {
                      ekycFormData.nic_eic_issue_date.call_agent_input =
                        nicIssueDateText;
                      ekycFormData.nic_eic_issue_date.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.nic_eic_issue_date.call_agent_input ===
                        nicIssueDateCustomerInput
                      ) {
                        ekycFormData.nic_eic_issue_date.call_agent_input = ``;
                        ekycFormData.nic_eic_issue_date.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      nicIssueDateText = ekycFormData.nic_eic_issue_date
                        .call_agent_input
                        ? ekycFormData.nic_eic_issue_date.call_agent_input
                        : nicIssueDateCustomerInput;
                    }
                  "
                />
              </div>
            </div>

            <div class="columns">
              <div
                class="column"
                v-if="ekycFormData.passport_number.customer_input"
              >
                <InputLabel
                  label="Passport Number"
                  required="true"
                  v-model="customerpassportnoText"
                  :validationRules="passportValidateObject()"
                  @getError="
                    (error) => {
                      passportNumValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.passport_number.call_agent_input &&
                      ekycFormData.passport_number.customer_input !==
                        customerpassportnoText) ||
                    (ekycFormData.passport_number.call_agent_input &&
                      ekycFormData.passport_number.call_agent_input !==
                        customerpassportnoText)
                  "
                  :save="
                    () => {
                      ekycFormData.passport_number.call_agent_input =
                        customerpassportnoText;
                      ekycFormData.passport_number.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.passport_number.call_agent_input ===
                        ekycFormData.passport_number.customer_input
                      ) {
                        ekycFormData.passport_number.call_agent_input = ``;
                        ekycFormData.passport_number.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      customerpassportnoText = ekycFormData.passport_number
                        .call_agent_input
                        ? ekycFormData.passport_number.call_agent_input
                        : ekycFormData.passport_number.customer_input;
                    }
                  "
                  :disable="passportNumValidationProviderError"
                />
              </div>
              <div
                class="column is-one-third"
                v-if="ekycFormData.passport_number.customer_input"
              >
                <ValidationProvider
                  name="Issue Date"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label>Issue Date<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-datepicker
                        rounded
                        position="is-bottom-left"
                        :max-date="maxDate"
                        @focus="
                          () => {
                            pIssueDate = true;
                          }
                        "
                        @blur="
                          () => {
                            pIssueDate = false;
                          }
                        "
                        v-model="passportIssueDateText"
                      >
                      </b-datepicker>

                      <span class="icon is-small is-right" v-if="pIssueDate">
                        <img
                          :src=calendarIcon
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <AgentInputButtons
                  :show-buttons="
                    ekycFormData.passport_issue_date.call_agent_input !==
                    `0001-01-01T00:00:00`
                      ? new Date(
                          ekycFormData.passport_issue_date.call_agent_input
                        ).getTime() !== passportIssueDateText.getTime()
                      : passportIssueDateCustomerInput.getTime() !==
                        passportIssueDateText.getTime()
                  "
                  :save="
                    () => {
                      ekycFormData.passport_issue_date.call_agent_input =
                        passportIssueDateText;
                      ekycFormData.passport_issue_date.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.passport_issue_date.call_agent_input ===
                        passportIssueDateCustomerInput
                      ) {
                        ekycFormData.passport_issue_date.call_agent_input = ``;
                        ekycFormData.passport_issue_date.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      passportIssueDateText = ekycFormData.passport_issue_date
                        .call_agent_input
                        ? ekycFormData.passport_issue_date.call_agent_input
                        : passportIssueDateCustomerInput;
                    }
                  "
                />
              </div>
              <div
                class="column is-one-third"
                v-if="ekycFormData.passport_number.customer_input"
              >
                <ValidationProvider
                  name="Expiry Date"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label>Expiry Date<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-datepicker
                        rounded
                        position="is-bottom-left"
                        @focus="
                          () => {
                            pexpiryDate = true;
                          }
                        "
                        @blur="
                          () => {
                            pexpiryDate = false;
                          }
                        "
                        v-model="passportExpiryDateText"
                      >
                      </b-datepicker>

                      <span class="icon is-small is-right" v-if="pexpiryDate">
                        <img
                          :src=calendarIcon
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <AgentInputButtons
                  :show-buttons="
                    ekycFormData.passport_expire_date.call_agent_input !==
                    `0001-01-01T00:00:00`
                      ? new Date(
                          ekycFormData.passport_expire_date.call_agent_input
                        ).getTime() !== passportExpiryDateText.getTime()
                      : passportExpiryDateCustomerInput.getTime() !==
                        passportExpiryDateText.getTime()
                  "
                  :save="
                    () => {
                      ekycFormData.passport_expire_date.call_agent_input =
                        passportExpiryDateText;
                      ekycFormData.passport_expire_date.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.passport_expire_date.call_agent_input ===
                        passportExpiryDateCustomerInput
                      ) {
                        ekycFormData.passport_expire_date.call_agent_input = ``;
                        ekycFormData.passport_expire_date.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      passportExpiryDateText = ekycFormData.passport_expire_date
                        .call_agent_input
                        ? ekycFormData.passport_expire_date.call_agent_input
                        : passportExpiryDateCustomerInput;
                    }
                  "
                />
              </div>
            </div>
            <div class="columns">
              <div
                class="column"
                v-if="ekycFormData.nationality.customer_input"
              >
                <SelectLabel
                  label="Nationality"
                  required="true"
                  v-model="nationalityText"
                  :validationRules="{ required: true }"
                  :selectOptions="nationalityList"
                />

                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.nationality.call_agent_input &&
                      ekycFormData.nationality.customer_input !==
                        nationalityText) ||
                    (ekycFormData.nationality.call_agent_input &&
                      ekycFormData.nationality.call_agent_input !==
                        nationalityText)
                  "
                  :save="
                    () => {
                      ekycFormData.nationality.call_agent_input =
                        nationalityText;
                      ekycFormData.nationality.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.nationality.call_agent_input ===
                        ekycFormData.nationality.customer_input
                      ) {
                        ekycFormData.nationality.call_agent_input = ``;
                        ekycFormData.nationality.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      nationalityText = ekycFormData.nationality
                        .call_agent_input
                        ? ekycFormData.nationality.call_agent_input
                        : ekycFormData.nationality.customer_input;
                    }
                  "
                />
              </div>
              <div class="column is-one-third">
                <ValidationProvider
                  name="Date of Birth"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label>Date of Birth<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-datepicker
                        rounded
                        position="is-bottom-left"
                        :max-date="maxDob"
                        @focus="
                          () => {
                            dob = true;
                          }
                        "
                        @blur="
                          () => {
                            dob = false;
                          }
                        "
                        v-model="dateOfBirthText"
                      >
                      </b-datepicker>

                      <span class="icon is-small is-right" v-if="dob">
                        <img
                          :src=calendarIcon
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <AgentInputButtons
                  :show-buttons="
                    ekycFormData.date_of_birth.call_agent_input !==
                    `0001-01-01T00:00:00`
                      ? new Date(
                          ekycFormData.date_of_birth.call_agent_input
                        ).getTime() !== dateOfBirthText.getTime()
                      : dateOfBirthCustomerInput.getTime() !==
                        dateOfBirthText.getTime()
                  "
                  :save="
                    () => {
                      ekycFormData.date_of_birth.call_agent_input =
                        dateOfBirthText;
                      ekycFormData.date_of_birth.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.date_of_birth.call_agent_input ===
                        dateOfBirthCustomerInput
                      ) {
                        ekycFormData.date_of_birth.call_agent_input = ``;
                        ekycFormData.date_of_birth.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      dateOfBirthText = ekycFormData.date_of_birth
                        .call_agent_input
                        ? ekycFormData.date_of_birth.call_agent_input
                        : dateOfBirthCustomerInput;
                    }
                  "
                />
              </div>
            </div>
            <div class="columns">
              <div
                class="column"
                v-if="ekycFormData.residentship.customer_input"
              >
              <InputLabel
                  label="Residency"
                  v-model="ekycFormData.residentship.customer_input"
                  disabled="true"
                  required="true"
                  @getError="
                    (error) => {
                      idValidationProviderError = error;
                    }
                  "
                />
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <label>Gender<span class="has-text-danger">*</span></label>

                <div>
                  <b-radio class="en-style" disabled checked>
                    {{ ekycFormData.gender.customer_input }}
                  </b-radio>
                </div>
                <!-- @click.native="isGenderModalActive = true" -->
              </div>
              <!-- Gender modal -->
              <!-- <b-modal v-model="isGenderModalActive" :width="540">
                <div class="box">
                  <div class="modal-close-button">
                    <button
                      class="delete mb-2"
                      aria-label="close"
                      @click="
                        () => {
                          isGenderModalActive = false;
                          genderModalText = genderText;
                        }
                      "
                    ></button>
                  </div>
                  <label>Gender</label>

                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns">
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="gender"
                            native-value="Male"
                            v-model="genderModalText"
                          >
                            Male
                          </b-radio>
                        </b-field>
                      </div>
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="gender"
                            native-value="Female"
                            v-model="genderModalText"
                          >
                            Female
                          </b-radio>
                        </b-field>
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      v-if="
                        ekycFormData.gender.call_agent_input
                          ? ekycFormData.gender.call_agent_input !== genderModalText
                          : ekycFormData.gender.customer_input !== genderModalText
                      "
                      class="is-primary is-rounded save_button"
                      @click="
                        () => {
                          if (ekycFormData.gender.customer_input !== genderModalText) {
                            ekycFormData.gender.call_agent_input = genderModalText;
                            genderText = genderModalText;
                            ekycFormData.gender.is_changed_by_call_agent = true;
                          } else {
                            ekycFormData.gender.call_agent_input = ``;
                            genderText = genderModalText;
                            ekycFormData.gender.is_changed_by_call_agent = false;
                          }
                          isGenderModalActive = false;
                        }
                      "
                      >Save
                    </b-button>
                  </div>
                </div>
              </b-modal> -->
              <!-- Gender modal -->
              <div
                class="column"
                v-if="ekycFormData.maritial_status.customer_input"
              >
                <label>Marital Status<span class="has-text-danger">*</span></label>

                <div>
                  <b-radio
                    class="en-style"
                    @click.native="isMaritalstatusModalActive = true"
                    checked
                  >
                    {{ maritalstatusText }}
                  </b-radio>
                </div>
              </div>

              <!-- martal status modal -->
              <b-modal
                v-model="isMaritalstatusModalActive"
                :width="540"
                :can-cancel="false"
              >
                <div class="box">
                  <div class="modal-close-button">
                    <button
                      class="delete mb-2"
                      aria-label="close"
                      @click="
                        () => {
                          isMaritalstatusModalActive = false;
                          maritalstatusModalText = maritalstatusText;
                        }
                      "
                    ></button>
                  </div>
                  <div class="mt-1">
                    <label
                      >Marital Status<span class="has-text-danger"
                        >*</span
                      ></label
                    >

                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <div class="columns">
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="maritial_status"
                              native-value="Married"
                              v-model="maritalstatusModalText"
                            >
                              Married
                            </b-radio>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="maritial_status"
                              native-value="Unmarried"
                              v-model="maritalstatusModalText"
                            >
                              Unmarried
                            </b-radio>
                          </b-field>
                        </div>
                      </div>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      v-if="
                        ekycFormData.maritial_status.call_agent_input
                          ? ekycFormData.maritial_status.call_agent_input !==
                            maritalstatusModalText
                          : ekycFormData.maritial_status.customer_input !==
                            maritalstatusModalText
                      "
                      class="is-primary is-rounded save_button"
                      @click="
                        () => {
                          if (
                            ekycFormData.maritial_status.customer_input !==
                            maritalstatusModalText
                          ) {
                            ekycFormData.maritial_status.call_agent_input =
                              maritalstatusModalText;
                            maritalstatusText = maritalstatusModalText;
                            ekycFormData.maritial_status.is_changed_by_call_agent = true;
                          } else {
                            ekycFormData.maritial_status.call_agent_input = ``;
                            maritalstatusText = maritalstatusModalText;
                            ekycFormData.maritial_status.is_changed_by_call_agent = false;
                          }
                          isMaritalstatusModalActive = false;
                        }
                      "
                      >Save
                    </b-button>
                  </div>
                </div>
              </b-modal>
              <!-- martal status modal -->
            </div>

            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />
            <p class="mt-4" style="font-size: 18px">Permanent Address</p>
            <!-- <p
              style="font-family: Gotham-Light; font-size: 1.2rem"
              v-if="
                ekycFormData.is_correspondence_address_differs_with_the_permanent_address ===
                false
              "
            >
              Permanent and correspondence addresses are one and the same.
            </p> -->
            <div class="columns">
              <div
                class="column is-one-third"
                v-if="ekycFormData.building_number.customer_input"
              >
                <InputLabel
                  label="Building Number"
                  v-model="permanentaddressline1Text"
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      buldingNumValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.building_number.call_agent_input &&
                      ekycFormData.building_number.customer_input !==
                        permanentaddressline1Text) ||
                    (ekycFormData.building_number.call_agent_input &&
                      ekycFormData.building_number.call_agent_input !==
                        permanentaddressline1Text)
                  "
                  :save="
                    () => {
                      ekycFormData.building_number.call_agent_input =
                        permanentaddressline1Text;
                      ekycFormData.building_number.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        ekycFormData.correspondence_address_building_number.is_changed_by_call_agent = true;
                        correspondenceaddressline1Text = permanentaddressline1Text;
                        ekycFormData.correspondence_address_building_number.call_agent_input = permanentaddressline1Text;
                      }
                      if (
                        ekycFormData.building_number.call_agent_input ===
                        ekycFormData.building_number.customer_input
                      ) {
                        ekycFormData.building_number.call_agent_input = ``;
                        ekycFormData.building_number.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddressline1Text = ekycFormData.building_number
                        .call_agent_input
                        ? ekycFormData.building_number.call_agent_input
                        : ekycFormData.building_number.customer_input;
                    }
                  "
                  :disable="buldingNumValidationProviderError"
                />
              </div>
              <div
                class="column"
                v-if="ekycFormData.building_name.customer_input"
              >
                <InputLabel
                  label="Building Name"
                  v-model="permanentaddressline2Text"
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      buldingNameValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.building_name.call_agent_input &&
                      ekycFormData.building_name.customer_input !==
                        permanentaddressline2Text) ||
                    (ekycFormData.building_name.call_agent_input &&
                      ekycFormData.building_name.call_agent_input !==
                        permanentaddressline2Text)
                  "
                  :save="
                    () => {
                      ekycFormData.building_name.call_agent_input =
                        permanentaddressline2Text;
                      ekycFormData.building_name.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        ekycFormData.correspondence_address_building_name.is_changed_by_call_agent = true;
                        correspondenceaddressline2Text = permanentaddressline2Text;
                        ekycFormData.correspondence_address_building_name.call_agent_input = permanentaddressline2Text;
                      }
                      if (
                        ekycFormData.building_name.call_agent_input ===
                        ekycFormData.building_name.customer_input
                      ) {
                        ekycFormData.building_name.call_agent_input = ``;
                        ekycFormData.building_name.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddressline2Text = ekycFormData.building_name
                        .call_agent_input
                        ? ekycFormData.building_name.call_agent_input
                        : ekycFormData.building_name.customer_input;
                    }
                  "
                  :disable="buldingNameValidationProviderError"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="ekycFormData.street.customer_input">
                <InputLabel
                  label="Street"
                  required='true'
                  v-model="permanentaddressline3Text"
                  :validationRules="validateRequired()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      streetValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.street.call_agent_input &&
                      ekycFormData.street.customer_input !==
                        permanentaddressline3Text) ||
                    (ekycFormData.street.call_agent_input &&
                      ekycFormData.street.call_agent_input !==
                        permanentaddressline3Text)
                  "
                  :save="
                    () => {
                      ekycFormData.street.call_agent_input =
                        permanentaddressline3Text;
                      ekycFormData.street.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        ekycFormData.correspondence_address_street.is_changed_by_call_agent = true;
                        correspondenceaddressline3Text = permanentaddressline3Text;
                        ekycFormData.correspondence_address_street.call_agent_input = permanentaddressline3Text;
                      }
                      if (
                        ekycFormData.street.call_agent_input ===
                        ekycFormData.street.customer_input
                      ) {
                        ekycFormData.street.call_agent_input = ``;
                        ekycFormData.street.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddressline3Text = ekycFormData.street
                        .call_agent_input
                        ? ekycFormData.street.call_agent_input
                        : ekycFormData.street.customer_input;
                    }
                  "
                  :disable="streetValidationProviderError"
                />
              </div>
              <!-- street 2 -->
              <div class="column" v-if="ekycFormData.street_2.customer_input">
                <InputLabel
                  label="Street 2"                  
                  v-model="permanentaddressStreetText"
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      secStreetValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.street_2.call_agent_input &&
                      ekycFormData.street_2.customer_input !==
                      permanentaddressStreetText) ||
                    (ekycFormData.street_2.call_agent_input &&
                      ekycFormData.street_2.call_agent_input !==
                      permanentaddressStreetText)
                  "
                  :save="
                    () => {
                      ekycFormData.street_2.call_agent_input =
                      permanentaddressStreetText;
                      ekycFormData.street_2.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        ekycFormData.correspondence_address_street_2.is_changed_by_call_agent = true;
                        correspondenceaddressStreetTwoText = permanentaddressStreetText;
                        ekycFormData.correspondence_address_street_2.call_agent_input = permanentaddressStreetText;
                      }
                      if (
                        ekycFormData.street_2.call_agent_input ===
                        ekycFormData.street_2.customer_input
                      ) {
                        ekycFormData.street_2.call_agent_input = ``;
                        ekycFormData.street_2.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddressStreetText = ekycFormData.street_2.call_agent_input
                        ? ekycFormData.street_2.call_agent_input
                        : ekycFormData.street_2.customer_input;
                    }
                  "
                  :disable="secStreetValidationProviderError"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column" v-if="ekycFormData.town.customer_input">
                <InputLabel
                  label="Town"
                  v-model="permanentaddresstownText"
                  required="true"
                  :validationRules="validateRequired()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      townValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.town.call_agent_input &&
                      ekycFormData.town.customer_input !==
                        permanentaddresstownText) ||
                    (ekycFormData.town.call_agent_input &&
                      ekycFormData.town.call_agent_input !==
                        permanentaddresstownText)
                  "
                  :save="
                    () => {
                      ekycFormData.town.call_agent_input =
                        permanentaddresstownText;
                      ekycFormData.town.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        ekycFormData.correspondence_address_town.is_changed_by_call_agent = true;
                        correspondenceaddresstownText = permanentaddresstownText;
                        ekycFormData.correspondence_address_town.call_agent_input = permanentaddresstownText;
                      }
                      if (
                        ekycFormData.town.call_agent_input ===
                        ekycFormData.town.customer_input
                      ) {
                        ekycFormData.town.call_agent_input = ``;
                        ekycFormData.town.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddresstownText = ekycFormData.town
                        .call_agent_input
                        ? ekycFormData.town.call_agent_input
                        : ekycFormData.town.customer_input;
                    }
                  "
                  :disable="townValidationProviderError"
                />
              </div>
              <div
                class="column"
                v-if="ekycFormData.postal_code.customer_input"
              >
                <InputLabel
                  label="Postal Code"
                  v-model="permanentaddresspostalcodeText"
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      postalCodeValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.postal_code.call_agent_input &&
                      ekycFormData.postal_code.customer_input !==
                        permanentaddresspostalcodeText) ||
                    (ekycFormData.postal_code.call_agent_input &&
                      ekycFormData.postal_code.call_agent_input !==
                        permanentaddresspostalcodeText)
                  "
                  :save="
                    () => {
                      ekycFormData.postal_code.call_agent_input =
                        permanentaddresspostalcodeText;
                      ekycFormData.postal_code.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        ekycFormData.correspondence_address_postal_code.is_changed_by_call_agent = true;
                        correspondenceaddresspostalcodeText = permanentaddresspostalcodeText;
                        ekycFormData.correspondence_address_postal_code.call_agent_input = permanentaddresspostalcodeText;
                      }
                      if (
                        ekycFormData.postal_code.call_agent_input ===
                        ekycFormData.postal_code.customer_input
                      ) {
                        ekycFormData.postal_code.call_agent_input = ``;
                        ekycFormData.postal_code.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddresspostalcodeText = ekycFormData.postal_code
                        .call_agent_input
                        ? ekycFormData.postal_code.call_agent_input
                        : ekycFormData.postal_code.customer_input;
                    }
                  "
                  :disable="postalCodeValidationProviderError"
                />
              </div>              
            </div>

            <div class="columns">
              <!-- district auto complete -->
              <div class="column" v-if="ekycFormData.district.customer_input">
                <div
                  v-if="
                    ekycFormData.residentship.customer_input === `Sri Lanka` ||
                    (ekycFormData.country.is_changed_by_call_agent
                      ? ekycFormData.country.call_agent_input === `Sri Lanka`
                      : ekycFormData.country.customer_input === `Sri Lanka`)
                  "
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        permanentaddressdistrict.trim() === '' &&
                        !isPerAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>District<span class="has-text-danger">*</span></template>

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isPerAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPerAddDistrictFocus = false;
                            }
                          "
                          :data="districtFilteredDataArray"
                          required="true"
                          v-model="permanentaddressdistrict"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                permanentaddressdistrictcount = 0;
                                isPermanentAddressDistrictTouched = false;
                              }
                            }
                          "
                          @input="
                            () => {
                              ++permanentaddressdistrictcount;
                              if (permanentaddressdistrictcount > 1) {
                                isPermanentAddressDistrictTouched = true;
                              }
                              // if (!permanentaddressdistrict) {
                              //   isPermanentAddressDistrictTouched = false;
                              //   district = ``;
                              // } else if (district) {
                              //   if (permanentaddressdistrictcount > 2) {
                              //     isPermanentAddressDistrictTouched = true;
                              //     district = ``;
                              //   }
                              // } else if (permanentaddressdistrictcount > 0) {
                              //   isPermanentAddressDistrictTouched = true;
                              //   district = ``;
                              // }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        permanentaddressdistrict &&
                        isPermanentAddressDistrictTouched
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div
                  v-if="
                    ekycFormData.residentship.customer_input !== `Sri Lanka` &&
                    (ekycFormData.country.is_changed_by_call_agent
                      ? ekycFormData.country.call_agent_input !== `Sri Lanka`
                      : ekycFormData.country.customer_input !== `Sri Lanka`)
                  "
                >
                  <InputLabel
                    label="District"
                    required="true"
                    v-model="permanentaddressdistrict"
                    :validationRules="validateRequired()"
                    fieldName="District"
                    @getError="
                      (error) => {
                        districtValidationProviderError = error;
                      }
                    "
                  />
                </div>
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.district.is_changed_by_call_agent &&
                      ekycFormData.district.customer_input !==
                        permanentaddressdistrict) ||
                    (ekycFormData.district.is_changed_by_call_agent &&
                      ekycFormData.district.call_agent_input !==
                        permanentaddressdistrict)
                  "
                  :save="
                    () => {
                      ekycFormData.district.call_agent_input =
                        permanentaddressdistrict;
                      ekycFormData.district.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        correspondenceaddressdistrictText = permanentaddressdistrict;
                        ekycFormData.correspondence_address_district.is_changed_by_call_agent = true;
                        ekycFormData.correspondence_address_district.call_agent_input = permanentaddressdistrict;
                      }
                      if (
                        ekycFormData.district.call_agent_input ===
                        ekycFormData.district.customer_input
                      ) {
                        ekycFormData.district.call_agent_input = ``;
                        ekycFormData.district.is_changed_by_call_agent = false;
                      }

                      //     ekycFormData.branch_comment.call_agent_input = ``;
                      // reasonforopeninganaccountText = ``
                      // ekycFormData.branch_comment.is_changed_by_call_agent =  ekycFormData.branch_comment.customer_input ? true : false;
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddressdistrict = ekycFormData.district
                        .call_agent_input
                        ? ekycFormData.district.call_agent_input
                        : ekycFormData.district.customer_input;
                    }
                  "
                  :disable="
                    (districtValidationProviderError ? true : false) ||
                    isPermanentAddressDistrictTouched
                  "
                />
              </div>
              <div
                class="column is-half"
                v-if="ekycFormData.country.customer_input"
              >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      permanentaddresscountry.trim() === '' &&
                      !isPerAddCountryFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label>Country<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-autocomplete
                        :disabled="isresidentinSL"
                        rounded
                        v-model="permanentaddresscountry"
                        open-on-focus
                        :data="countryFilteredDataArray"
                        @select="
                          (option) => {
                            if (option) {
                              noEnrolementCountries(option.name)
                              permanentaddresscountryText = option.name;
                              permanentaddresscountry = option.name;
                              permanentaddresscountrycount = -1;
                            } else {
                              permanentaddresscountryText = ``;
                              permanentaddresscountry = ``;
                            }
                          }
                        "
                        @input="
                          () => {
                            ++permanentaddresscountrycount;
                            if (!permanentaddresscountry) {
                              isPermanentAddressCountryTouched = false;
                              permanentaddresscountryText = ``;
                            } else if (countryFilteredDataArray.includes(permanentaddresscountry)) {
                              isPermanentAddressCountryTouched = false;
                              permanentaddresscountryText =
                                permanentaddresscountry;
                            } else if (ekycFormData.country.customer_input) {
                              if (permanentaddresscountrycount > 1) {
                                isPermanentAddressCountryTouched = true;
                                countryError=true;
                                permanentaddresscountryText = ``;
                              }
                            } else if (permanentaddresscountrycount > 0) {
                              isPermanentAddressCountryTouched = true;
                              countryError=true;
                              permanentaddresscountryText = ``;
                            }
                          }
                        "
                        @focus="
                          () => {
                            isPerAddCountryFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPerAddCountryFocus = false;
                          }
                        "
                        placeholder=""
                        icon=""
                        field="name"
                      >
                        <template #empty>No results found</template>
                      </b-autocomplete>
                      <span
                        class="icon is-small is-right mx-2"
                        v-if="isPerAddCountryFocus"
                      >
                        <img
                          :src=autoCompleteDownArrowImage
                          alt="Stroke-img"
                          width="15"
                          height="15"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span
                    class="has-text-danger"
                    v-if="
                      isPermanentAddressCountryTouched &&
                      !permanentaddresscountryText
                    "
                    >You have to select a valid country</span
                  >
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.country.call_agent_input &&
                      ekycFormData.country.customer_input !==
                        permanentaddresscountry) ||
                    (ekycFormData.country.call_agent_input &&
                      ekycFormData.country.call_agent_input !==
                        permanentaddresscountry)
                  "
                  :save="
                    () => {
                      ekycFormData.country.call_agent_input =
                      permanentaddresscountry;
                      ekycFormData.country.is_changed_by_call_agent = true;
                      if(ekycFormData.is_correspondence_address_differs_with_the_permanent_address === false)
                      {
                        isCorrespondenceAddressCountryTouched = false;
                        correspondenceaddresscountrycount = -1
                        correspondenceaddresscountryText = permanentaddresscountry;
                        correspondenceaddresscountry = permanentaddresscountry;
                        ekycFormData.correspondence_address_country.is_changed_by_call_agent = true;
                        ekycFormData.correspondence_address_country.call_agent_input = permanentaddresscountry;
                      }
                      if (
                        ekycFormData.country.call_agent_input ===
                        ekycFormData.country.customer_input
                      ) {
                        ekycFormData.country.call_agent_input = ``;
                        ekycFormData.country.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      permanentaddresscountry = ekycFormData.country
                        .call_agent_input
                        ? ekycFormData.country.call_agent_input
                        : ekycFormData.country.customer_input;
                    }
                  "
                  :disable="
                    !permanentaddresscountryText || countryError
                  "
                  />
              </div>
            </div>
            <!-- Correspondense Address -->
            <p class="mt-4" style="font-size: 18px">Correspondense Address</p>
            <div class="columns">
              <div
                class="column is-one-third"
                v-if="
                  ekycFormData.correspondence_address_building_number
                    .customer_input
                "
              >
                <InputLabel
                  label="Building Number"
                  v-model="correspondenceaddressline1Text"
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      corbuildingnumberValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_building_number
                      .call_agent_input &&
                      ekycFormData.correspondence_address_building_number
                        .customer_input !== correspondenceaddressline1Text) ||
                    (ekycFormData.correspondence_address_building_number
                      .call_agent_input &&
                      ekycFormData.correspondence_address_building_number
                        .call_agent_input !== correspondenceaddressline1Text)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_building_number.call_agent_input =
                        correspondenceaddressline1Text;
                      ekycFormData.correspondence_address_building_number.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_building_number
                          .call_agent_input ===
                        ekycFormData.correspondence_address_building_number
                          .customer_input
                      ) {
                        ekycFormData.correspondence_address_building_number.call_agent_input = ``;
                        ekycFormData.correspondence_address_building_number.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddressline1Text = ekycFormData
                        .correspondence_address_building_number.call_agent_input
                        ? ekycFormData.correspondence_address_building_number
                            .call_agent_input
                        : ekycFormData.correspondence_address_building_number
                            .customer_input;
                    }
                  "
                  :disable="corbuildingnumberValidationProviderError"
                />
              </div>
              <div
                class="column"
                v-if="
                  ekycFormData.correspondence_address_building_name
                    .customer_input
                "
              >
                <InputLabel
                  label="Building Name"
                  v-model="correspondenceaddressline2Text"
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      corBuildingNameValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_building_name
                      .call_agent_input &&
                      ekycFormData.correspondence_address_building_name
                        .customer_input !== correspondenceaddressline2Text) ||
                    (ekycFormData.correspondence_address_building_name
                      .call_agent_input &&
                      ekycFormData.correspondence_address_building_name
                        .call_agent_input !== correspondenceaddressline2Text)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_building_name.call_agent_input =
                        correspondenceaddressline2Text;
                      ekycFormData.correspondence_address_building_name.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_building_name
                          .call_agent_input ===
                        ekycFormData.correspondence_address_building_name
                          .customer_input
                      ) {
                        ekycFormData.correspondence_address_building_name.call_agent_input = ``;
                        ekycFormData.correspondence_address_building_name.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddressline2Text = ekycFormData
                        .correspondence_address_building_name.call_agent_input
                        ? ekycFormData.correspondence_address_building_name
                            .call_agent_input
                        : ekycFormData.correspondence_address_building_name
                            .customer_input;
                    }
                  "
                  :disable="corBuildingNameValidationProviderError"
                />
              </div>
            </div>
            <div class="columns">
              <div
                class="column"
                v-if="ekycFormData.correspondence_address_street.customer_input"
              >
                <InputLabel
                  label="Street"
                  v-model="correspondenceaddressline3Text"
                  required=true
                  :validationRules="validateRequired()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      corStreetValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_street
                      .call_agent_input &&
                      ekycFormData.correspondence_address_street
                        .customer_input !== correspondenceaddressline3Text) ||
                    (ekycFormData.correspondence_address_street
                      .call_agent_input &&
                      ekycFormData.correspondence_address_street
                        .call_agent_input !== correspondenceaddressline3Text)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_street.call_agent_input =
                        correspondenceaddressline3Text;
                      ekycFormData.correspondence_address_street.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_street
                          .call_agent_input ===
                        ekycFormData.correspondence_address_street
                          .customer_input
                      ) {
                        ekycFormData.correspondence_address_street.call_agent_input = ``;
                        ekycFormData.correspondence_address_street.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddressline3Text = ekycFormData
                        .correspondence_address_street.call_agent_input
                        ? ekycFormData.correspondence_address_street
                            .call_agent_input
                        : ekycFormData.correspondence_address_street
                            .customer_input;
                    }
                  "
                  :disable="corStreetValidationProviderError"
                />
              </div>    
              <!-- street 2           -->
              <div
                class="column"
                v-if="ekycFormData.correspondence_address_street_2.customer_input"
              >
                <InputLabel
                  label="Street 2"
                  v-model="correspondenceaddressStreetTwoText"                 
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      corTwoStreetValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_street_2.call_agent_input &&
                      ekycFormData.correspondence_address_street_2
                        .customer_input !== correspondenceaddressStreetTwoText) ||
                    (ekycFormData.correspondence_address_street_2
                      .call_agent_input &&
                      ekycFormData.correspondence_address_street_2
                        .call_agent_input !== correspondenceaddressStreetTwoText)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_street_2.call_agent_input =
                        correspondenceaddressStreetTwoText;
                      ekycFormData.correspondence_address_street_2.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_street_2
                          .call_agent_input ===
                        ekycFormData.correspondence_address_street_2
                          .customer_input
                      ) {
                        ekycFormData.correspondence_address_street_2.call_agent_input = ``;
                        ekycFormData.correspondence_address_street_2.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddressStreetTwoText = ekycFormData
                        .correspondence_address_street_2.call_agent_input
                        ? ekycFormData.correspondence_address_street_2
                            .call_agent_input
                        : ekycFormData.correspondence_address_street_2
                            .customer_input;
                    }
                  "
                  :disable="corTwoStreetValidationProviderError"
                />
              </div> 
            </div>
            <div class="columns">
              <div
                class="column"
                v-if="ekycFormData.correspondence_address_town.customer_input"
              >
                <InputLabel
                  label="Town"
                  v-model="correspondenceaddresstownText"
                  required="true"
                  :validationRules="validateRequired()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      corTownValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_town
                      .call_agent_input &&
                      ekycFormData.correspondence_address_town
                        .customer_input !== correspondenceaddresstownText) ||
                    (ekycFormData.correspondence_address_town
                      .call_agent_input &&
                      ekycFormData.correspondence_address_town
                        .call_agent_input !== correspondenceaddresstownText)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_town.call_agent_input =
                        correspondenceaddresstownText;
                      ekycFormData.correspondence_address_town.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_town
                          .call_agent_input ===
                        ekycFormData.correspondence_address_town.customer_input
                      ) {
                        ekycFormData.correspondence_address_town.call_agent_input = ``;
                        ekycFormData.correspondence_address_town.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddresstownText = ekycFormData
                        .correspondence_address_town.call_agent_input
                        ? ekycFormData.correspondence_address_town
                            .call_agent_input
                        : ekycFormData.correspondence_address_town
                            .customer_input;
                    }
                  "
                  :disable="corTownValidationProviderError"
                />
              </div>
              <div
                class="column"
                v-if="
                  ekycFormData.correspondence_address_postal_code.customer_input
                "
              >
                <InputLabel
                  label="Postal Code"
                  v-model="correspondenceaddresspostalcodeText"
                  :validationRules="validateNonMandatory()"
                  fieldName="This field"
                  @getError="
                    (error) => {
                      corPostalCodeValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_postal_code
                      .call_agent_input &&
                      ekycFormData.correspondence_address_postal_code
                        .customer_input !==
                        correspondenceaddresspostalcodeText) ||
                    (ekycFormData.correspondence_address_postal_code
                      .call_agent_input &&
                      ekycFormData.correspondence_address_postal_code
                        .call_agent_input !==
                        correspondenceaddresspostalcodeText)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_postal_code.call_agent_input =
                        correspondenceaddresspostalcodeText;
                      ekycFormData.correspondence_address_postal_code.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_postal_code
                          .call_agent_input ===
                        ekycFormData.correspondence_address_postal_code
                          .customer_input
                      ) {
                        ekycFormData.correspondence_address_postal_code.call_agent_input = ``;
                        ekycFormData.correspondence_address_postal_code.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddresspostalcodeText = ekycFormData
                        .correspondence_address_postal_code.call_agent_input
                        ? ekycFormData.correspondence_address_postal_code
                            .call_agent_input
                        : ekycFormData.correspondence_address_postal_code
                            .customer_input;
                    }
                  "
                  :disable="corPostalCodeValidationProviderError"
                />
              </div>              
            </div>
            <div class="columns">
              <div
                class="column"
                v-if="
                  ekycFormData.correspondence_address_district.customer_input
                "
              >
                <div
                  v-if="
                    ekycFormData.residentship.customer_input === `Sri Lanka` ||
                    (ekycFormData.correspondence_address_country
                      .is_changed_by_call_agent
                      ? ekycFormData.correspondence_address_country
                          .call_agent_input === `Sri Lanka`
                      : ekycFormData.correspondence_address_country
                          .customer_input === `Sri Lanka`)
                  "
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        correspondenceaddressdistrictText.trim() === '' &&
                        !isCorAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>District<span class="has-text-danger">*</span></template>

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isCorAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCorAddDistrictFocus = false;
                            }
                          "
                          :data="correspondentdistrictFilteredDataArray"
                          v-model="correspondenceaddressdistrictText"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                corresaddressdistrictcount = 0;
                                isCoressAddressDistrictTouched = false;
                              }
                            }
                          "
                          @input="
                            () => {
                              ++corresaddressdistrictcount;
                              if (corresaddressdistrictcount > 1) {
                                isCoressAddressDistrictTouched = true;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        correspondenceaddressdistrictText &&
                        isCoressAddressDistrictTouched
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div
                  v-if="
                    ekycFormData.residentship.customer_input !== `Sri Lanka` &&
                    (ekycFormData.correspondence_address_country
                      .is_changed_by_call_agent
                      ? ekycFormData.correspondence_address_country
                          .call_agent_input !== `Sri Lanka`
                      : ekycFormData.correspondence_address_country
                          .customer_input !== `Sri Lanka`)
                  "
                >
                  <InputLabel
                    label="District"
                    required="true"
                    v-model="correspondenceaddressdistrictText"
                    :validationRules="validateRequired()"
                    fieldName="District"
                    @getError="
                      (error) => {
                        cordistrictValidationProviderError = error;
                      }
                    "
                  />
                </div>
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_district
                      .is_changed_by_call_agent &&
                      ekycFormData.correspondence_address_district
                        .customer_input !==
                        correspondenceaddressdistrictText) ||
                    (ekycFormData.correspondence_address_district
                      .is_changed_by_call_agent &&
                      ekycFormData.correspondence_address_district
                        .call_agent_input !== correspondenceaddressdistrictText)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_district.call_agent_input =
                        correspondenceaddressdistrictText;
                      ekycFormData.correspondence_address_district.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_district
                          .call_agent_input ===
                        ekycFormData.correspondence_address_district
                          .customer_input
                      ) {
                        ekycFormData.correspondence_address_district.call_agent_input = ``;
                        ekycFormData.correspondence_address_district.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddressdistrictText = ekycFormData
                        .correspondence_address_district.call_agent_input
                        ? ekycFormData.correspondence_address_district
                            .call_agent_input
                        : ekycFormData.correspondence_address_district
                            .customer_input;
                    }
                  "
                  :disable="
                    (cordistrictValidationProviderError ? true : false) ||
                    isCoressAddressDistrictTouched
                  "
                />
              </div>
              <div
                class="column is-half"
                v-if="
                  ekycFormData.correspondence_address_country.customer_input
                "
              >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      correspondenceaddresscountry.trim() === '' &&
                      !isCorrespondenceAddCountryFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label>Country<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-autocomplete
                        rounded
                        v-model="correspondenceaddresscountry"
                        open-on-focus
                        :data="correspondentCountryFilteredDataArray"
                        @select="
                          (option) => {
                            if (option) {
                              noEnrolementCountries(option.name)
                              correspondenceaddresscountryText = option.name;
                              correspondenceaddresscountry = option.name;
                              correspondenceaddresscountrycount = -1;
                            } else {
                              correspondenceaddresscountryText = ``;
                              correspondenceaddresscountry = ``;
                            }
                          }
                        "
                        @input="
                          () => {
                            ++correspondenceaddresscountrycount;
                            if (!correspondenceaddresscountry) {
                              isCorrespondenceAddressCountryTouched = false;
                              correspondenceaddresscountryText = ``;
                            } else if (correspondentCountryFilteredDataArray.includes(correspondenceaddresscountry)) {
                              isCorrespondenceAddressCountryTouched = false;
                              correspondenceaddresscountryText =
                                correspondenceaddresscountry;
                            } else if (
                              ekycFormData.correspondence_address_country
                                .customer_input
                            ) {
                              if (correspondenceaddresscountrycount > 1) {
                                isCorrespondenceAddressCountryTouched = true;
                                correspondenceaddresscountryText = ``;
                                countryError=true;
                              }
                            } else if (correspondenceaddresscountrycount > 0) {
                              isCorrespondenceAddressCountryTouched = true;
                              correspondenceaddresscountryText = ``;
                              countryError=true;
                            }
                          }
                        "
                        @focus="
                          () => {
                            isCorrespondenceAddCountryFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isCorrespondenceAddCountryFocus = false;
                          }
                        "
                        placeholder=""
                        icon=""
                        field="name"
                      >
                        <template #empty>No results found</template>
                      </b-autocomplete>
                      <span
                        class="icon is-small is-right mx-2"
                        v-if="isCorrespondenceAddCountryFocus"
                      >
                        <img
                          :src=autoCompleteDownArrowImage
                          alt="Stroke-img"
                          width="15"
                          height="15"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span
                    class="has-text-danger"
                    v-if="
                      isCorrespondenceAddressCountryTouched &&
                      !correspondenceaddresscountryText
                    "
                    >You have to select a valid country</span
                  >
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.correspondence_address_country
                      .call_agent_input &&
                      ekycFormData.correspondence_address_country
                        .customer_input !== correspondenceaddresscountry) ||
                    (ekycFormData.correspondence_address_country
                      .call_agent_input &&
                      ekycFormData.correspondence_address_country
                        .call_agent_input !== correspondenceaddresscountry)
                  "
                  :save="
                    () => {
                      ekycFormData.correspondence_address_country.call_agent_input =
                        correspondenceaddresscountry;
                      ekycFormData.correspondence_address_country.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.correspondence_address_country
                          .call_agent_input ===
                        ekycFormData.correspondence_address_country
                          .customer_input
                      ) {
                        ekycFormData.correspondence_address_country.call_agent_input = ``;
                        ekycFormData.correspondence_address_country.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      correspondenceaddresscountry = ekycFormData
                        .correspondence_address_country.call_agent_input
                        ? ekycFormData.correspondence_address_country
                            .call_agent_input
                        : ekycFormData.correspondence_address_country
                            .customer_input;
                    }
                  "
                  :disable = "!correspondenceaddresscountry || countryError"
                />
              </div>
            </div>
            <div>
              <label>Status of Permanant Residence<span class="has-text-danger">*</span></label>
              <div>
                <b-radio
                  class="en-style"
                  checked
                  @click.native="isStatusOfResidentsModalActive = true"
                >
                  {{ statusofresidenceText }}
                </b-radio>
              </div>
              <b-modal
                v-model="isStatusOfResidentsModalActive"
                :width="600"
                :can-cancel="false"
              >
                <div class="box">
                  <div class="modal-close-button">
                    <button
                      class="delete mb-2"
                      aria-label="close"
                      @click="
                        () => {
                          isStatusOfResidentsModalActive = false;
                          statusofresidenceModalText = statusofresidenceText;
                        }
                      "
                    ></button>
                  </div>
                  <div>
                    <label>Status of Permanant Residence<span class="has-text-danger">*</span></label>
                    <ValidationProvider
                      :rules="{ required: true }"
                      name="This Field"
                      v-slot="{ errors }"
                    >
                      <div class="columns is-7 is-variable">
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="state_of_residence"
                              native-value="House Holder"
                              v-model="statusofresidenceModalText"
                            >
                              House Holder
                            </b-radio>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="state_of_residence"
                              native-value="Living With Parents"
                              v-model="statusofresidenceModalText"
                            >
                              Living With Parents
                            </b-radio>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="state_of_residence"
                              native-value="Renter"
                              v-model="statusofresidenceModalText"
                            >
                              Renter
                            </b-radio>
                          </b-field>
                        </div>
                      </div>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      v-if="
                        ekycFormData.state_of_residence.call_agent_input
                          ? ekycFormData.state_of_residence.call_agent_input !==
                            statusofresidenceModalText
                          : ekycFormData.state_of_residence.customer_input !==
                            statusofresidenceModalText
                      "
                      class="is-primary is-rounded save_button"
                      @click="
                        () => {
                          if (
                            ekycFormData.state_of_residence.customer_input !==
                            statusofresidenceModalText
                          ) {
                            ekycFormData.state_of_residence.call_agent_input =
                              statusofresidenceModalText;
                            statusofresidenceText = statusofresidenceModalText;
                            ekycFormData.state_of_residence.is_changed_by_call_agent = true;
                          } else {
                            ekycFormData.state_of_residence.call_agent_input = ``;
                            statusofresidenceText = statusofresidenceModalText;
                            ekycFormData.state_of_residence.is_changed_by_call_agent = false;
                          }
                          isStatusOfResidentsModalActive = false;
                        }
                      "
                    >
                      Save
                    </b-button>
                  </div>
                </div>
              </b-modal>
            </div>
            <div class="columns mt-4">
              <div class="column is-two-fifths mobile">
                <div>
                  <section>
                    <b-field>
                      <p class="control has-icons-right">
                        <b-autocomplete
                          size="is-medium"
                          v-model="userCodeEdit"
                          icon-pack="fi"
                          :icon="iconStringEdit"
                          open-on-focus
                          :data="filteredDataArrEdit"
                          rounded
                          disabled="true"
                          @focus="
                            () => {
                              mobileFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              mobileFocus = false;
                            }
                          "
                          @input="
                            () => {
                              ++count;
                              if (count === 2) {
                                countryCodeEdit = ``;
                                isCountryCodeTouched = true;
                                isMobileNumberTouched = true;
                              }
                            }
                          "
                          @select="
                            (option) => {
                              if (option) {
                                countryCodeEdit = `+${option.split(`+`)[1]}`;
                                shortNameEdit = option.split(' ')[0];
                              } else {
                                countryCodeEdit = ``;
                              }
                              if (countryCodeEdit) {
                                changeSelectionEdit();
                                calEditPhoneNumber();
                              }
                            }
                          "
                        >
                        </b-autocomplete>
                        <span
                          class="icon is-small is-right mr-2 mt-1"
                          v-if="mobileFocus"
                        >
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                        <span
                          class="has-text-danger"
                          v-if="isCountryCodeTouched && !countryCodeEdit"
                          >You have to select a valid country code</span
                        >
                      </p>
                    </b-field>
                  </section>
                </div>
              </div>
              <div class="column">
                <b-field
                  label-position="inside"
                  :custom-class="
                    mobileNumberEdit.trim() === '' && !isMobileNumberInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label>Primary Mobile Number<span class="has-text-danger">*</span></template>

                  <p class="control has-icons-right">
                    <ValidationProvider
                      name="Primary Mobile Number"
                      :rules="{ required: true, onlyNumbers: true }"
                      v-slot="{ errors }"
                    >
                      <b-input
                        v-model="mobileNumberEdit"
                        disabled="true"
                        @focus="
                          () => {
                            isMobileNumberInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isMobileNumberInputFocus = false;
                          }
                        "
                        @input="
                          () => {
                            isMobileNumberTouched = true;
                            mobileNumEditValidate();
                            calEditPhoneNumber();
                            mobileNumberValidationProviderError = errors[0];
                          }
                        "
                        rounded
                      ></b-input>

                      <span class="has-text-danger">{{ errors[0] }}</span>
                      <span
                        class="has-text-danger"
                        v-if="
                          isMobileNumberTouched &&
                          !isMobileNumberValidEdit &&
                          !errors[0]
                        "
                        >Please enter a valid mobile number.
                      </span>
                      <span
                        class="has-text-danger"
                        v-if="isMobileNumberEditStartsWithZero && !errors[0]"
                        >LK mobile numbers not allowed to start with 0</span
                      >
                    </ValidationProvider>
                  </p>
                </b-field>
              </div>
            </div>
            <!-- mobile number -->
            <div v-if="ekycFormData.telephone_number.customer_input === ``">
              <div
                class="icon-text mt-5"
                :style="{
                  cursor: 'pointer',
                  color: '#F57E82',
                  'line-height': '20px',
                  'letter-spacing': '0.7px',
                }"
              >
                <p
                  v-if="!isShow"
                  @click="visible()"
                  class="is-flex is-flex-direction-row is-align-content-center"
                >
                  <span class="icon has-text-info">
                    <img
                      src="../assets/Images/Icons/Circle-plus.png"
                      alt="Circle_Plus image"
                    />
                  </span>
                  <span
                    :style="{
                      cursor: 'pointer',
                      color: '#04c3fc',
                      'line-height': '20px',
                      'letter-spacing': '0.7px',
                    }"
                    class="is-size-5 mt-1"
                    >Add Mobile Number</span
                  >
                </p>
              </div>
              <div v-if="isShow">
                <div
                  @click="
                    () => {
                      visibleHide();
                      userOfficeCode ='';
                      teleCountryCode = '';
                      noEnrolementCountriesNumber(userOfficeCode)
                    }
                  "
                >
                  <span
                    class="icon has-text-info mr-1"
                    :style="{
                      cursor: 'pointer',
                      color: '#04c3fc',
                      'line-height': '20px',
                      'letter-spacing': '0.7px',
                    }"
                  >
                    <img
                      src="../assets/Images/Icons/CircleMinus.png"
                      alt="CircleMinus.png"
                    />
                  </span>
                  <span
                    :style="{
                      cursor: 'pointer',
                      color: '#04c3fc',
                      'line-height': '20px',
                      'letter-spacing': '0.7px',
                    }"
                    class="is-size-5 mt-1"
                    >Remove Mobile Number</span
                  >
                </div>
                <!-- second_tel_num -->
                <div class="columns mb-3 mt-2">
                  <div class="column is-two-fifths mobile">
                    <div>
                      <section>
                        <b-field>
                          <p class="control has-icons-right">
                            <b-autocomplete
                              size="is-medium"
                              v-model="userOfficeCode"
                              open-on-focus
                              :data="officeFilteredDataArr"
                              rounded
                              icon-pack="fi"
                              :icon="officeIconString"
                              @focus="
                                () => {
                                  telFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  telFocus = false;
                                }
                              "
                              @input="
                                () => {
                                  ++countThird;
                                  if (countThird === 2) {
                                    teleCountryCode = ``;
                                    isTelCountryCodeTouched = true;
                                  }
                                }
                              "
                              @select="
                                (option) => {
                                  if (option) {
                                    noEnrolementCountriesNumber(option)
                                    teleCountryCode = `${option.split(` `)[1]}`;
                                    teleShortNameEdit = option.split(' ')[0];
                                  } else {
                                    teleCountryCode = ``;
                                  }

                                  if (teleCountryCode) {
                                    changeTelSelection();
                                    calTelNumber();
                                  }
                                }
                              "
                            >
                            </b-autocomplete>
                            <span
                              class="icon is-small is-right mr-2 mt-1"
                              v-if="telFocus"
                            >
                              <img
                                :src=autoCompleteDownArrowImage
                                alt="Stroke-img"
                                width="15"
                                height="15"
                              />
                            </span>

                            <span
                              class="has-text-danger"
                              v-if="isTelCountryCodeTouched && !teleCountryCode"
                              >You have to select a valid country code</span
                            >
                          </p>
                        </b-field>
                      </section>
                    </div>
                  </div>
                  <div class="column">
                    <ValidationProvider
                      name="Mobile/Telephone Number"
                      :rules="{ onlyNumbers: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          teleNumber.trim() === '' && !isTeleNumberInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label>Mobile/Telephone Number </template>

                        <b-input
                          type="tel"
                          maxlength="15"
                          v-model="teleNumber"
                          @focus="
                            () => {
                              isTeleNumberInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isTeleNumberInputFocus = false;
                            }
                          "
                          @input="
                            () => {
                              isOfficeNumberTouched = true;
                              officeNumValidate();
                              calTelNumber();
                              telNumberValidationProviderError = errors[0];
                            }
                          "
                          rounded
                        ></b-input>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                      <span
                        class="has-text-danger"
                        v-if="
                          isOfficeNumberTouched &&
                          !isOfficeNumValid &&
                          teleNumber
                        "
                        >Please enter a valid Mobile/Telephone Numbers.
                      </span>
                      <span
                        class="has-text-danger"
                        v-if="isTelNumberStartsWithZero"
                        >LK Mobile/Telephone Numbers not allowed to start with
                        0</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
                <AgentInputButtons
                  :show-buttons="
                    ekycFormData.telephone_number.is_changed_by_call_agent
                      ? ekycFormData.telephone_number.call_agent_input !==
                        agentTeleFullNo
                      : ekycFormData.telephone_number.customer_input !==
                        agentTeleFullNo
                  "
                  :save="
                    () => {
                      ekycFormData.telephone_number.call_agent_input =
                        agentTeleFullNo;
                      ekycFormData.telephone_number.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.telephone_number.call_agent_input ===
                        ekycFormData.telephone_number.customer_input
                      ) {
                        ekycFormData.telephone_number.call_agent_input = ``;
                        ekycFormData.telephone_number.is_changed_by_call_agent = false;
                        ekycFormData.telephone_number_country_code.call_agent_input = ``;
                        ekycFormData.telephone_number_without_country_code.call_agent_input = ``;
                        teleCountryCode =
                          ekycFormData.telephone_number_country_code
                            .customer_input;
                        teleNumber =
                          ekycFormData.telephone_number_without_country_code
                            .customer_input;
                        agentTeleFullNo =
                          ekycFormData.telephone_number.customer_input;
                        userOfficeCode = countryCodeArray.find(
                          (countryCodeItem) =>
                            countryCodeItem.split(` `)[1] === teleCountryCode
                        );
                      } else {
                        ekycFormData.telephone_number_country_code.call_agent_input =
                          teleCountryCode;
                        ekycFormData.telephone_number_without_country_code.call_agent_input =
                          teleNumber;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      teleCountryCode = ekycFormData
                        .telephone_number_country_code.call_agent_input
                        ? ekycFormData.telephone_number_country_code
                            .call_agent_input
                        : ekycFormData.telephone_number_country_code
                            .customer_input;

                      teleNumber = ekycFormData
                        .telephone_number_without_country_code.call_agent_input
                        ? ekycFormData.telephone_number_without_country_code
                            .call_agent_input
                        : ekycFormData.telephone_number_without_country_code
                            .customer_input;
                      agentTeleFullNo = ekycFormData.telephone_number
                        .call_agent_input
                        ? ekycFormData.telephone_number.call_agent_input
                        : ekycFormData.telephone_number.customer_input;
                      userOfficeCode = countryCodeArray.find(
                        (countryCodeItem) =>
                          countryCodeItem.split(` `)[1] === teleCountryCode
                      );
                    }
                  "
                  :disable="
                    telNumberValidationProviderError ||
                    (isTelCountryCodeTouched && !teleCountryCode) ||
                    !isOfficeNumValid ||
                    !teleNumber ||
                    !teleCountryCode || countryError
                  "
                />
              </div>
            </div>

            <!-- <AgentInputButtons
              :show-buttons="
                ekycFormData.mobile_number.call_agent_input
                  ? ekycFormData.mobile_number.call_agent_input !==
                    agentFullMobileNum
                  : ekycFormData.mobile_number.customer_input !==
                    agentFullMobileNum
              "
              :save="
                () => {
                  ekycFormData.mobile_number.call_agent_input =
                    agentFullMobileNum;
                  if (
                    ekycFormData.mobile_number.call_agent_input ===
                    ekycFormData.mobile_number.customer_input
                  ) {
                    ekycFormData.mobile_number.call_agent_input = ``;
                    ekycFormData.mobile_number_country_code.call_agent_input = ``;
                    ekycFormData.mobile_number_without_country_code.call_agent_input = ``;
                    countryCodeEdit =
                      ekycFormData.mobile_number_country_code.customer_input;
                    mobileNumberEdit =
                      ekycFormData.mobile_number_without_country_code.customer_input;
                    agentFullMobileNum =
                      ekycFormData.mobile_number.customer_input;
                    userCodeEdit = countryCodeArray.find(
                      (countryCodeItem) =>
                        countryCodeItem.split(` `)[1] === countryCodeEdit
                    );
                  } else {
                    ekycFormData.mobile_number_country_code.call_agent_input =
                      countryCodeEdit;
                    ekycFormData.mobile_number_without_country_code.call_agent_input =
                      mobileNumberEdit;
                  }
                }
              "
              :cancel="
                () => {
                  countryCodeEdit = ekycFormData.mobile_number_country_code
                    .call_agent_input
                    ? ekycFormData.mobile_number_country_code.call_agent_input
                    : ekycFormData.mobile_number_country_code.customer_input;

                  mobileNumberEdit = ekycFormData.mobile_number_without_country_code.call_agent_input
                    ? ekycFormData.mobile_number_without_country_code.call_agent_input
                    : ekycFormData.mobile_number_without_country_code.customer_input;
                  agentFullMobileNum = ekycFormData.mobile_number
                    .call_agent_input
                    ? ekycFormData.mobile_number.call_agent_input
                    : ekycFormData.mobile_number.customer_input;
                  userCodeEdit = countryCodeArray.find(
                    (countryCodeItem) =>
                      countryCodeItem.split(` `)[1] === countryCodeEdit
                  );
                }
              "
              :disable="
                mobileNumberValidationProviderError ||
                (isCountryCodeTouched && !countryCodeEdit) ||
                (isMobileNumberTouched &&
                  !isMobileNumberValidEdit &&
                  !mobileNumberValidationProviderError) ||
                (isMobileNumberEditStartsWithZero &&
                  !mobileNumberValidationProviderError)
              "
            /> -->
            <!-- TelephoneNumber -->
            <div v-if="ekycFormData.telephone_number.customer_input">
              <div class="columns mb-3 mt-2">
                <div class="column is-two-fifths mobile">
                  <div>
                    <section>
                      <b-field>
                        <p class="control has-icons-right">
                          <b-autocomplete
                            size="is-medium"
                            v-model="userOfficeCode"
                            open-on-focus
                            :data="officeFilteredDataArr"
                            rounded
                            icon-pack="fi"
                            :icon="officeIconString"
                            @focus="
                              () => {
                                telFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                telFocus = false;
                              }
                            "
                            @input="
                              () => {
                                ++countThird;
                                if (countThird === 2) {
                                  teleCountryCode = ``;
                                  isTelCountryCodeTouched = true;
                                }
                              }
                            "
                            @select="
                              (option) => {
                                if (option) {
                                  teleCountryCode = `${option.split(` `)[1]}`;
                                  teleShortNameEdit = option.split(' ')[0];
                                } else {
                                  teleCountryCode = ``;
                                }

                                if (teleCountryCode) {
                                  changeTelSelection();
                                  calTelNumber();
                                }
                              }
                            "
                          >
                          </b-autocomplete>
                          <span
                            class="icon is-small is-right mr-2 mt-1"
                            v-if="telFocus"
                          >
                            <img
                              :src=autoCompleteDownArrowImage
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>

                          <span
                            class="has-text-danger"
                            v-if="isTelCountryCodeTouched && !teleCountryCode"
                            >You have to select a valid country code</span
                          >
                        </p>
                      </b-field>
                    </section>
                  </div>
                </div>
                <div class="column">
                  <ValidationProvider
                    name="Mobile/Telephone Number"
                    :rules="{ onlyNumbers: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        teleNumber.trim() === '' && !isTeleNumberInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label>Mobile/Telephone Number </template>

                      <b-input
                        type="tel"
                        maxlength="15"
                        v-model="teleNumber"
                        @focus="
                          () => {
                            isTeleNumberInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isTeleNumberInputFocus = false;
                          }
                        "
                        @input="
                          () => {
                            isOfficeNumberTouched = true;
                            officeNumValidate();
                            calTelNumber();
                            telNumberValidationProviderError = errors[0];
                          }
                        "
                        rounded
                      ></b-input>
                    </b-field>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                    <span
                      class="has-text-danger"
                      v-if="
                        isOfficeNumberTouched && !isOfficeNumValid && teleNumber
                      "
                      >Please enter a valid Mobile/Telephone Numbers.
                    </span>
                    <span
                      class="has-text-danger"
                      v-if="isTelNumberStartsWithZero"
                      >LK Mobile/Telephone Numbers not allowed to start with
                      0</span
                    >
                  </ValidationProvider>
                </div>
              </div>
              <AgentInputButtons
                :show-buttons="
                  ekycFormData.telephone_number.is_changed_by_call_agent
                    ? ekycFormData.telephone_number.call_agent_input !==
                      agentTeleFullNo
                    : ekycFormData.telephone_number.customer_input !==
                      agentTeleFullNo
                "
                :save="
                  () => {
                    ekycFormData.telephone_number.call_agent_input =
                      agentTeleFullNo;
                    ekycFormData.telephone_number.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.telephone_number.call_agent_input ===
                      ekycFormData.telephone_number.customer_input
                    ) {
                      ekycFormData.telephone_number.call_agent_input = ``;
                      ekycFormData.telephone_number.is_changed_by_call_agent = false;
                      ekycFormData.telephone_number_country_code.call_agent_input = ``;
                      ekycFormData.telephone_number_without_country_code.call_agent_input = ``;
                      teleCountryCode =
                        ekycFormData.telephone_number_country_code
                          .customer_input;
                      teleNumber =
                        ekycFormData.telephone_number_without_country_code
                          .customer_input;
                      agentTeleFullNo =
                        ekycFormData.telephone_number.customer_input;
                      userOfficeCode = countryCodeArray.find(
                        (countryCodeItem) =>
                          countryCodeItem.split(` `)[1] === teleCountryCode
                      );
                    } else {
                      ekycFormData.telephone_number_country_code.call_agent_input =
                        teleCountryCode;
                      ekycFormData.telephone_number_without_country_code.call_agent_input =
                        teleNumber;
                    }
                  }
                "
                :cancel="
                  () => {
                    teleCountryCode = ekycFormData.telephone_number_country_code
                      .call_agent_input
                      ? ekycFormData.telephone_number_country_code
                          .call_agent_input
                      : ekycFormData.telephone_number_country_code
                          .customer_input;

                    teleNumber = ekycFormData
                      .telephone_number_without_country_code.call_agent_input
                      ? ekycFormData.telephone_number_without_country_code
                          .call_agent_input
                      : ekycFormData.telephone_number_without_country_code
                          .customer_input;
                    agentTeleFullNo = ekycFormData.telephone_number
                      .call_agent_input
                      ? ekycFormData.telephone_number.call_agent_input
                      : ekycFormData.telephone_number.customer_input;
                    userOfficeCode = countryCodeArray.find(
                      (countryCodeItem) =>
                        countryCodeItem.split(` `)[1] === teleCountryCode
                    );
                  }
                "
                :disable="
                  telNumberValidationProviderError ||
                  (isTelCountryCodeTouched && !teleCountryCode) ||
                  !isOfficeNumValid ||
                  !teleNumber ||
                  !teleCountryCode
                "
              />
            </div>

            <div>
              <InputLabel
                label="Primary Email ID"
                required="true"
                v-model="emailText"
                disabled="true"
                :validationRules="{
                  email: true,
                  required: true,
                  lessOrEqualLength: 256,
                }"
                fieldName="Primary Email ID"
                styles="mt-5 mb-4"
                @getError="
                  (error) => {
                    mailValidationProviderError = error;
                  }
                "
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.email.call_agent_input &&
                    ekycFormData.email.customer_input !== emailText) ||
                  (ekycFormData.email.call_agent_input &&
                    ekycFormData.email.call_agent_input !== emailText)
                "
                :save="
                  () => {
                    ekycFormData.email.call_agent_input = emailText;
                    ekycFormData.email.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.email.call_agent_input ===
                      ekycFormData.email.customer_input
                    ) {
                      ekycFormData.email.call_agent_input = ``;
                      ekycFormData.email.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    emailText = ekycFormData.email.call_agent_input
                      ? ekycFormData.email.call_agent_input
                      : ekycFormData.email.customer_input;
                  }
                "
                :disable="mailValidationProviderError"
              />
            </div>
            <!-- seconary Email -->
            <div v-if="ekycFormData.secondary_email.customer_input !== ``">
              <InputLabel
                label="Additional Email"
                v-model="emailSecondText"
                :validationRules="{
                  email: true,
                  required: true,
                  lessOrEqualLength: 256,
                }"
                fieldName="Primary Email ID"
                styles="mt-5 mb-4"
                @getError="
                  (error) => {
                    mailSecValidationProviderError = error;
                  }
                "
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.secondary_email.call_agent_input &&
                    ekycFormData.secondary_email.customer_input !==
                      emailSecondText) ||
                  (ekycFormData.secondary_email.call_agent_input &&
                    ekycFormData.secondary_email.call_agent_input !==
                      emailSecondText)
                "
                :save="
                  () => {
                    ekycFormData.secondary_email.call_agent_input =
                      emailSecondText;
                    ekycFormData.secondary_email.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.secondary_email.call_agent_input ===
                      ekycFormData.secondary_email.customer_input
                    ) {
                      ekycFormData.secondary_email.call_agent_input = ``;
                      ekycFormData.secondary_email.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    emailSecondText = ekycFormData.secondary_email
                      .call_agent_input
                      ? ekycFormData.secondary_email.call_agent_input
                      : ekycFormData.secondary_email.customer_input;
                  }
                "
                :disable="mailSecValidationProviderError"
              />
            </div>
            <!-- Sec email -->
            <div v-if="ekycFormData.secondary_email.customer_input === ``">
              <div
                class="icon-text mt-5"
                :style="{
                  cursor: 'pointer',
                  color: '#04c3fc',
                  'line-height': '20px',
                  'letter-spacing': '0.7px',
                }"
              >
                <p
                  v-if="!isEmailShow"
                  @click="visibleEmail()"
                  class="is-flex is-flex-direction-row is-align-content-center"
                >
                  <span class="icon has-text-info">
                    <img
                      src="../assets/Images/Icons/Circle-plus.png"
                      alt="Circle_Plus image"
                    />
                  </span>
                  <span
                    :style="{
                      cursor: 'pointer',
                      color: '#04c3fc',
                      'line-height': '20px',
                      'letter-spacing': '0.7px',
                    }"
                    class="is-size-5 mt-1"
                    >Add Additional Email ID</span
                  >
                </p>
              </div>
              <div v-if="isEmailShow">
                <div
                  @click="
                    () => {
                      emailVisibleHide();
                    }
                  "
                >
                  <span
                    class="icon has-text-info mr-1"
                    :style="{
                      cursor: 'pointer',
                      color: '#F57E82',
                      'line-height': '20px',
                      'letter-spacing': '0.7px',
                    }"
                  >
                    <img
                      src="../assets/Images/Icons/CircleMinus.png"
                      alt="CircleMinus.png"
                    />
                  </span>
                  <span
                    :style="{
                      cursor: 'pointer',
                      color: '#04c3fc',
                      'line-height': '20px',
                      'letter-spacing': '0.7px',
                    }"
                    class="is-size-5 mt-1"
                    >Remove Additional Email ID</span
                  >
                </div>

                <InputLabel
                  label="Additional Email ID"
                  v-model="emailSecondText"
                  :validationRules="{
                    email: true,
                    required: true,
                    lessOrEqualLength: 256,
                  }"
                  fieldName="Email ID"
                  styles="mt-5 mb-4"
                  @getError="
                    (error) => {
                      mailSecValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.secondary_email.is_changed_by_call_agent &&
                      ekycFormData.secondary_email.customer_input !==
                        emailSecondText) ||
                    (ekycFormData.secondary_email.is_changed_by_call_agent &&
                      ekycFormData.secondary_email.call_agent_input !==
                        emailSecondText)
                  "
                  :save="
                    () => {
                      ekycFormData.secondary_email.call_agent_input =
                        emailSecondText;
                      ekycFormData.secondary_email.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.secondary_email.call_agent_input ===
                        ekycFormData.secondary_email.customer_input
                      ) {
                        ekycFormData.secondary_email.call_agent_input = ``;
                        ekycFormData.secondary_email.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      emailSecondText = ekycFormData.secondary_email
                        .call_agent_input
                        ? ekycFormData.secondary_email.call_agent_input
                        : ekycFormData.secondary_email.customer_input;
                    }
                  "
                  :disable="mailSecValidationProviderError"
                />
              </div>
            </div>
            <div class="mt-5">
              <InputLabel
                label="Mother’s Maiden Name"
                required="true"
                v-model="maidennameText"
                :validationRules="validateName()"
                fieldName="Mother's Maiden Name"
                styles="mt-5"
                @getError="
                  (error) => {
                    motherNameValidationProviderError = error;
                  }
                "
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.mothers_maiden_name.call_agent_input &&
                    ekycFormData.mothers_maiden_name.customer_input !==
                      maidennameText) ||
                  (ekycFormData.mothers_maiden_name.call_agent_input &&
                    ekycFormData.mothers_maiden_name.call_agent_input !==
                      maidennameText)
                "
                :save="
                  () => {
                    ekycFormData.mothers_maiden_name.call_agent_input =
                      maidennameText;
                    ekycFormData.mothers_maiden_name.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.mothers_maiden_name.call_agent_input ===
                      ekycFormData.mothers_maiden_name.customer_input
                    ) {
                      ekycFormData.mothers_maiden_name.call_agent_input = ``;
                      ekycFormData.mothers_maiden_name.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    maidennameText = ekycFormData.mothers_maiden_name
                      .call_agent_input
                      ? ekycFormData.mothers_maiden_name.call_agent_input
                      : ekycFormData.mothers_maiden_name.customer_input;
                  }
                "
                :disable="motherNameValidationProviderError"
              />
            </div>
            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />

            <!-- industry -->
            <div class="mb-5">
              <ValidationProvider
                :rules="{ required: true }"
                v-slot="{ errors }"
                ><b-field
                  label-position="inside"
                  :custom-class="
                    industry.trim() === '' && !isindustryFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                  class="mb-0"
                >
                  <template #label>Industry<span class="has-text-danger">*</span></template>

                  <p class="control has-icons-right">
                    <b-autocomplete
                      rounded
                      v-model="industry"
                      open-on-focus
                      :data="industryFilteredDataArray"
                      @select="
                        (option) => {
                          if (option) {
                            industrySelected = { ...option };
                            isIndustryTouched = false;
                          } else {
                            industrySelected = ``;
                          }
                        }
                      "
                      @input="
                        () => {
                          if (
                            industrySelected &&
                            industrySelected.description &&
                            industrySelected.description !== industry
                          ) {
                            isIndustryTouched = true;
                            industrySelected = ``;
                          }
                        }
                      "
                      @focus="
                        () => {
                          isindustryFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isindustryFocus = false;
                        }
                      "
                      placeholder=""
                      icon=""
                      field="description"
                    >
                      <template #empty>No results found</template>
                    </b-autocomplete>
                    <span
                      class="icon is-small is-right mx-2"
                      v-if="isindustryFocus"
                    >
                      <img
                        :src=autoCompleteDownArrowImage
                        alt="Stroke-img"
                        width="15"
                        height="15"
                      />
                    </span>
                  </p>
                </b-field>

                <span
                  v-if="industry && isIndustryTouched"
                  class="has-text-danger"
                  >{{ `Please select a valid industry` }}</span
                >

                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <!-- here -->
            <AgentInputButtons
              :show-buttons="
                ekycFormData.customer_industry
                  ? industrySelected &&
                    ekycFormData.customer_industry.description !==
                      industrySelected.description
                  : industrySelected
              "
              :save="
                () => {
                  ekycFormData.customer_industry = {
                    ...industrySelected,
                  };
                }
              "
              :cancel="
                () => {
                  industry = ekycFormData.customer_industry.description;
                  industrySelected = { ...ekycFormData.customer_industry };
                  test(industrySelected);
                }
              "
              :disable=isIndustryTouched
            />
            <!-- industry -->
            <!-- occupation -->
            <div
              v-if="
                ekycFormData.employment_status.is_changed_by_call_agent
                  ? ekycFormData.employment_status.call_agent_input ===
                      `Full-Time` ||
                    ekycFormData.employment_status.call_agent_input ===
                      `Part Time` ||
                    ekycFormData.employment_status.call_agent_input ===
                      `Probation`
                  : ekycFormData.employment_status.customer_input ===
                      `Full-Time` ||
                    ekycFormData.employment_status.customer_input ===
                      `Part Time` ||
                    ekycFormData.employment_status.customer_input ===
                      `Probation`
              "
            >
              <div class="mb-5">
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  ><b-field
                    label-position="inside"
                    :custom-class="
                      occupation.trim() === '' && !isoccupationFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label>Occupation<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-autocomplete
                        rounded
                        v-model="occupation"
                        open-on-focus
                        :data="occupationFilteredDataArray"
                        @select="
                          (option) => {
                            if (option) {
                              occupationSelected = option;
                              isoccupationTouched = false;
                              occupationValidationError = false;
                            } else {
                              occupationSelected = ``;
                              occupationValidationError = true;
                            }
                          }
                        "
                        @input="
                          () => {
                            if (occupationSelected !== occupation) {
                              test('here');
                              isoccupationTouched = true;
                              occupationSelected = ``;
                            }
                          }
                        "
                        @focus="
                          () => {
                            isoccupationFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isoccupationFocus = false;
                          }
                        "
                        placeholder=""
                        icon=""
                        field=""
                      >
                        <template #empty>No results found</template>
                      </b-autocomplete>
                      <span
                        class="icon is-small is-right mx-2"
                        v-if="isoccupationFocus"
                      >
                        <img
                          :src=autoCompleteDownArrowImage
                          alt="Stroke-img"
                          width="15"
                          height="15"
                        />
                      </span>
                    </p>
                  </b-field>

                  <span
                    v-if="occupation && isoccupationTouched"
                    class="has-text-danger"
                    >{{ `Please select a valid occupation` }}</span
                  >

                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <AgentInputButtons
                :show-buttons="
                  ekycFormData.customer_occupation
                    ? ekycFormData.customer_occupation !== occupation
                    : occupation
                "
                :save="
                  () => {
                    ekycFormData.customer_occupation = occupationSelected;
                  }
                "
                :cancel="
                  () => {
                    occupationSelected = ekycFormData.customer_occupation;
                    occupation = ekycFormData.customer_occupation;
                  }
                "
                :disable="
                  !occupationSelected && occupation && isoccupationTouched
                "
              />
            </div>
            <!-- occupation -->
            <div>
              <SelectLabel
                v-model="employmentstatusText"
                required="true"
                :selectOptions="employmentStatusList"
                :validationRules="{ required: true }"
                label="Employment Status"
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.employment_status.call_agent_input &&
                    ekycFormData.employment_status.customer_input !==
                      employmentstatusText) ||
                  (ekycFormData.employment_status.call_agent_input &&
                    ekycFormData.employment_status.call_agent_input !==
                      employmentstatusText)
                "
                :save="
                  () => {
                    ekycFormData.customer_occupation = ``;
                    occupation = ``;
                    occupationSelected = ``;
                    if (
                      ekycFormData.other_platforms_working_on_other_selected
                        .customer_input
                    ) {
                      ekycFormData.other_platforms_working_on_other_selected.call_agent_input = false;
                      ekycFormData.other_platforms_working_on_other_selected.is_changed_by_call_agent = true;
                    } else {
                      ekycFormData.other_platforms_working_on_other_selected.call_agent_input = false;
                      ekycFormData.other_platforms_working_on_other_selected.is_changed_by_call_agent = false;
                    }
                    ekycFormData.profession_nature_of_business.call_agent_input = ``;
                    natureofbusinessText = ``;
                    ekycFormData.profession_nature_of_business.is_changed_by_call_agent = true;

                    ekycFormData.employer.call_agent_input = ``;
                    employernameText = ``;
                    ekycFormData.employer.is_changed_by_call_agent = true;

                    ekycFormData.employment_status.call_agent_input =
                      employmentstatusText;
                    ekycFormData.employment_status.is_changed_by_call_agent = true;

                    if (
                      ekycFormData.employment_status.call_agent_input ===
                      `Freelance`
                    ) {
                      tempfreelancer = ``;
                      areyouafreelancerText = ``;
                      areyouaFreelancerModalText = ``;
                      ekycFormData.are_you_work_on_another_platform.call_agent_input = ``;
                      ekycFormData.are_you_work_on_another_platform.is_changed_by_call_agent = true;
                      workingPlatformText = [];
                      tempWorkingPlatform = [];
                      workingPlatformTextModal = [];
                      ekycFormData.other_platforms_working_on.call_agent_input =
                        [];
                      ekycFormData.other_platforms_working_on.is_changed_by_call_agent = true;
                      specifiedworkplatformText = ``;
                      ekycFormData.other_platforms_working_on_other_description.call_agent_input = ``;
                      ekycFormData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                    } else {
                      tempfreelancer = ``;
                      areyouafreelancerText = ``;
                      areyouaFreelancerModalText = ``;
                      ekycFormData.are_you_work_on_another_platform.call_agent_input = false;
                      ekycFormData.are_you_work_on_another_platform.is_changed_by_call_agent = true;
                      workingPlatformText = null;
                      workingPlatformTextModal = [];
                      tempWorkingPlatform = [];
                      ekycFormData.other_platforms_working_on.call_agent_input =
                        null;
                      ekycFormData.other_platforms_working_on.is_changed_by_call_agent = true;
                      specifiedworkplatformText = null;
                      ekycFormData.other_platforms_working_on_other_description.call_agent_input =
                        null;
                      ekycFormData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                    }

                    if (
                      ekycFormData.employment_status.call_agent_input ===
                      ekycFormData.employment_status.customer_input
                    ) {
                      ekycFormData.employment_status.call_agent_input = ``;
                      ekycFormData.employment_status.is_changed_by_call_agent = false;
                    }

                    employeraddressline1Text = ``;
                    ekycFormData.emp_building_number.call_agent_input = ``;
                    ekycFormData.emp_building_number.is_changed_by_call_agent =
                      ekycFormData.employment_status.call_agent_input ===
                        `Full-Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Part Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Self-Employed` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Probation`
                        ? true
                        : ekycFormData.emp_building_number.customer_input
                        ? true
                        : false;

                    employeraddressline2Text = ``;
                    ekycFormData.emp_building_name.call_agent_input = ``;
                    ekycFormData.emp_building_name.is_changed_by_call_agent =
                      ekycFormData.employment_status.call_agent_input ===
                        `Full-Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Part Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Self-Employed` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Probation`
                        ? true
                        : ekycFormData.emp_building_name.customer_input
                        ? true
                        : false;

                    employeraddressline3Text = ``;
                    ekycFormData.emp_street.call_agent_input = ``;
                    ekycFormData.emp_street.is_changed_by_call_agent =
                      ekycFormData.employment_status.call_agent_input ===
                        `Full-Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Part Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Self-Employed` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Probation`
                        ? true
                        : ekycFormData.emp_street.customer_input
                        ? true
                        : false;

                    employeraddresstownText = ``;
                    ekycFormData.emp_town.call_agent_input = ``;
                    ekycFormData.emp_town.is_changed_by_call_agent =
                      ekycFormData.employment_status.call_agent_input ===
                        `Full-Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Part Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Self-Employed` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Probation`
                        ? true
                        : ekycFormData.emp_town.customer_input
                        ? true
                        : false;

                    employeraddresspostalcodeText = ``;
                    ekycFormData.emp_postal_code.call_agent_input = ``;
                    ekycFormData.emp_postal_code.is_changed_by_call_agent =
                      ekycFormData.employment_status.call_agent_input ===
                        `Full-Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Part Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Self-Employed` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Probation`
                        ? true
                        : ekycFormData.emp_postal_code.customer_input
                        ? true
                        : false;

                    employeraddressdistrictText = ``;
                    ekycFormData.emp_district.call_agent_input = ``;
                    ekycFormData.emp_district.is_changed_by_call_agent =
                      ekycFormData.employment_status.call_agent_input ===
                        `Full-Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Part Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Self-Employed` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Probation`
                        ? true
                        : ekycFormData.emp_district.customer_input
                        ? true
                        : false;

                    employeraddresscountry = ``;
                    ekycFormData.emp_country.call_agent_input = ``;
                    ekycFormData.emp_country.is_changed_by_call_agent =
                      ekycFormData.employment_status.call_agent_input ===
                        `Full-Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Part Time` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Self-Employed` ||
                      ekycFormData.employment_status.call_agent_input ===
                        `Probation`
                        ? true
                        : ekycFormData.emp_country.customer_input
                        ? true
                        : false;
                  }
                "
                :cancel="
                  () => {
                    employmentstatusText = ekycFormData.employment_status
                      .call_agent_input
                      ? ekycFormData.employment_status.call_agent_input
                      : ekycFormData.employment_status.customer_input;
                  }
                "
              />
            </div>

            <div
              v-if="
                ekycFormData.employment_status.call_agent_input
                  ? ekycFormData.employment_status.call_agent_input !==
                      `Unemployed` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Retired` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Housewife` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Student` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Full-Time` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Part Time` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Probation` &&
                    (ekycFormData.profession_nature_of_business
                      .is_changed_by_call_agent
                      ? true
                      : ekycFormData.profession_nature_of_business
                          .customer_input !== ``)
                  : ekycFormData.employment_status.customer_input !==
                      `Unemployed` &&
                    ekycFormData.employment_status.customer_input !==
                      `Retired` &&
                    ekycFormData.employment_status.customer_input !==
                      `Housewife` &&
                    ekycFormData.employment_status.customer_input !==
                      `Student` &&
                    ekycFormData.employment_status.customer_input !==
                      `Full-Time` &&
                    ekycFormData.employment_status.customer_input !==
                      `Part Time` &&
                    ekycFormData.employment_status.customer_input !==
                      `Probation` &&
                    (ekycFormData.profession_nature_of_business
                      .is_changed_by_call_agent
                      ? true
                      : ekycFormData.profession_nature_of_business
                          .customer_input !== ``)
              "
            >
              <InputLabel
                label="Profession / Nature of Business"
                v-model="natureofbusinessText"
                styles="mt-5"
                :validationRules="{
                  required: ekycFormData.employment_status.call_agent_input
                    ? ekycFormData.employment_status.call_agent_input ===
                      `Freelance`
                      ? true
                      : false
                    : ekycFormData.employment_status.customer_input ===
                      `Freelance`
                    ? true
                    : false,
                }"
              />
              <AgentInputButtons
                :show-buttons="
                  (ekycFormData.profession_nature_of_business
                    .is_changed_by_call_agent &&
                    ekycFormData.profession_nature_of_business
                      .call_agent_input !== natureofbusinessText) ||
                  (!ekycFormData.profession_nature_of_business
                    .is_changed_by_call_agent &&
                    ekycFormData.profession_nature_of_business
                      .customer_input !== natureofbusinessText)
                "
                :save="
                  () => {
                    ekycFormData.profession_nature_of_business.call_agent_input =
                      natureofbusinessText;
                    ekycFormData.profession_nature_of_business.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.profession_nature_of_business
                        .call_agent_input ===
                      ekycFormData.profession_nature_of_business.customer_input
                    ) {
                      ekycFormData.profession_nature_of_business.call_agent_input = ``;
                      ekycFormData.profession_nature_of_business.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    natureofbusinessText = ekycFormData
                      .profession_nature_of_business.call_agent_input
                      ? ekycFormData.profession_nature_of_business
                          .call_agent_input
                      : ekycFormData.profession_nature_of_business
                          .customer_input;
                  }
                "
              />
            </div>
            <div
              v-if="
                ekycFormData.employment_status.call_agent_input
                  ? ekycFormData.employment_status.call_agent_input !==
                      `Unemployed` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Retired` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Housewife` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Student` &&
                    ekycFormData.employment_status.call_agent_input !==
                      `Freelance` &&
                    (ekycFormData.employer.is_changed_by_call_agent
                      ? true
                      : ekycFormData.employer.customer_input !== ``)
                  : ekycFormData.employment_status.customer_input !==
                      `Unemployed` &&
                    ekycFormData.employment_status.customer_input !==
                      `Retired` &&
                    ekycFormData.employment_status.customer_input !==
                      `Housewife` &&
                    ekycFormData.employment_status.customer_input !==
                      `Student` &&
                    ekycFormData.employment_status.customer_input !==
                      `Freelance` &&
                    (ekycFormData.employer.is_changed_by_call_agent
                      ? true
                      : ekycFormData.employer.customer_input !== ``)
              "
            >
              <InputLabel
                label="Employer /  Business Name"
                required="true"
                v-model="employernameText"
                styles="mt-5"
                :validationRules="validateRequired()"
                @error = "(error) => {
                  employerNameValidationProviderError = error;
                }"
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.employer.is_changed_by_call_agent &&
                    ekycFormData.employer.customer_input !==
                      employernameText) ||
                  (ekycFormData.employer.is_changed_by_call_agent &&
                    ekycFormData.employer.call_agent_input !== employernameText)
                "
                :save="
                  () => {
                    ekycFormData.employer.call_agent_input = employernameText;
                    ekycFormData.employer.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.employer.call_agent_input ===
                      ekycFormData.employer.customer_input
                    ) {
                      ekycFormData.employer.call_agent_input = ``;
                      ekycFormData.employer.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    employernameText = ekycFormData.employer.call_agent_input
                      ? ekycFormData.employer.call_agent_input
                      : ekycFormData.employer.customer_input;
                  }
                "
                :disable = "employerNameValidationProviderError"
              />
            </div>
            <div
              v-if="
                ekycFormData.employment_status.is_changed_by_call_agent
                  ? ekycFormData.employment_status.call_agent_input ===
                    `Freelance`
                  : ekycFormData.employment_status.customer_input ===
                    `Freelance`
              "
              class="mt-5"
            >
              <label>Are you working for any other platform/s?<span class="has-text-danger">*</span></label>
              <div v-if="areyouafreelancerText === true">
                <b-radio
                  class="en-style"
                  checked
                  @click.native="isFreelancerModalActive = true"
                >
                  Yes
                </b-radio>
              </div>
              <div v-if="areyouafreelancerText === false">
                <b-radio
                  class="en-style"
                  checked
                  @click.native="isFreelancerModalActive = true"
                >
                  No
                </b-radio>
              </div>
              <div v-if="areyouafreelancerText === ``">
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="columns">
                    <div class="column is-one-two">
                      <b-field>
                        <b-radio
                          class="en-style"
                          name="are_you_work_on_another_platform"
                          :native-value="true"
                          @click.native="isFreelancerModalActive = true"
                          v-model="tempfreelancer"
                        >
                          Yes
                        </b-radio>
                      </b-field>
                    </div>
                    <div class="column is-one-two">
                      <b-field>
                        <b-radio
                          class="en-style"
                          name="are_you_work_on_another_platform"
                          :native-value="false"
                          @click.native="isFreelancerModalActive = true"
                          v-model="tempfreelancer"
                        >
                          No
                        </b-radio>
                      </b-field>
                    </div>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <b-modal
                v-model="isFreelancerModalActive"
                :width="540"
                :can-cancel="false"
              >
                <div class="box">
                  <div class="modal-close-button">
                    <button
                      class="delete mb-2"
                      aria-label="close"
                      @click="
                        () => {
                          isFreelancerModalActive = false;
                          areyouaFreelancerModalText = areyouafreelancerText;
                          tempfreelancer = areyouafreelancerText;
                        }
                      "
                    ></button>
                  </div>
                  <div>
                    <label>Are you working for any other platform/s?<span class="has-text-danger">*</span></label>

                    <div class="columns">
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="are_you_work_on_another_platform"
                            :native-value="true"
                            v-model="areyouaFreelancerModalText"
                          >
                            Yes
                          </b-radio>
                        </b-field>
                      </div>
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="are_you_work_on_another_platform"
                            :native-value="false"
                            v-model="areyouaFreelancerModalText"
                          >
                            No
                          </b-radio>
                        </b-field>
                      </div>
                    </div>
                  </div>

                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      v-if="
                        ekycFormData.are_you_work_on_another_platform
                          .is_changed_by_call_agent
                          ? ekycFormData.are_you_work_on_another_platform
                              .call_agent_input !== areyouaFreelancerModalText
                          : ekycFormData.are_you_work_on_another_platform
                              .customer_input !== areyouaFreelancerModalText
                      "
                      class="is-primary is-rounded save_button"
                      @click="
                        () => {
                          if (
                            ekycFormData
                              .other_platforms_working_on_other_selected
                              .customer_input
                          ) {
                            ekycFormData.other_platforms_working_on_other_selected.call_agent_input = false;
                            ekycFormData.other_platforms_working_on_other_selected.is_changed_by_call_agent = true;
                          } else {
                            ekycFormData.other_platforms_working_on_other_selected.call_agent_input = false;
                            ekycFormData.other_platforms_working_on_other_selected.is_changed_by_call_agent = false;
                          }
                          if (!areyouaFreelancerModalText) {
                            workingPlatformText = [];
                            workingPlatformTextModal = [];
                            tempWorkingPlatform = [];
                            ekycFormData.other_platforms_working_on.call_agent_input =
                              [];
                            ekycFormData.other_platforms_working_on.is_changed_by_call_agent = true;
                            specifiedworkplatformText = null;
                            ekycFormData.other_platforms_working_on_other_description.call_agent_input =
                              null;
                            ekycFormData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                          }

                          if (
                            ekycFormData.are_you_work_on_another_platform
                              .customer_input !== areyouaFreelancerModalText
                          ) {
                            ekycFormData.are_you_work_on_another_platform.call_agent_input =
                              areyouaFreelancerModalText;
                            areyouafreelancerText = areyouaFreelancerModalText;
                            ekycFormData.are_you_work_on_another_platform.is_changed_by_call_agent = true;
                          } else {
                            ekycFormData.are_you_work_on_another_platform.call_agent_input = false;
                            areyouafreelancerText = areyouaFreelancerModalText;
                            ekycFormData.are_you_work_on_another_platform.is_changed_by_call_agent = false;
                          }
                          isFreelancerModalActive = false;
                        }
                      "
                      >Save
                    </b-button>
                  </div>
                </div>
              </b-modal>
            </div>
            <!-- {{ ekycFormData.other_platforms_working_on.is_changed_by_call_agent + "other_platforms_working_on" }} -->
            <!-- What other platforms are you working in? -->
            <div
              @click="isworkingPlatformModalActive = true"
              class="mb-5 mt-4"
              v-if="
                ekycFormData.are_you_work_on_another_platform
                  .is_changed_by_call_agent
                  ? ekycFormData.are_you_work_on_another_platform
                      .call_agent_input
                  : ekycFormData.are_you_work_on_another_platform.customer_input
              "
            >
              <label>What other platforms are you working in?<span class="has-text-danger">*</span></label>
              <div
                v-if="
                  ekycFormData.other_platforms_working_on
                    .is_changed_by_call_agent
                    ? ekycFormData.other_platforms_working_on
                        .call_agent_input &&
                      ekycFormData.other_platforms_working_on.call_agent_input
                        .length > 0
                    : ekycFormData.other_platforms_working_on.customer_input
                        .length > 0
                "
              >
                <div v-for="(work, index) in workingPlatformText">
                  <b-checkbox
                    :value="true"
                    class="en-style"
                    @click.native="preventDefault"
                  >
                    {{ work }}
                  </b-checkbox>
                </div>
              </div>

              <div
                v-if="
                  ekycFormData.other_platforms_working_on
                    .is_changed_by_call_agent &&
                  ekycFormData.other_platforms_working_on.call_agent_input
                    .length === 0
                "
              >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="columns source-list">
                    <div
                      class="source-list-item"
                      v-for="(work, index) in workeList"
                      :key="index"
                    >
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          :native-value="work"
                          v-model="tempWorkingPlatform"
                        >
                          {{ work }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <b-modal
              v-model="isworkingPlatformModalActive"
              :width="540"
              :can-cancel="false"
            >
              <div class="box">
                <div class="modal-close-button">
                  <button
                    class="delete mb-2"
                    aria-label="close"
                    @click="
                      () => {
                        isworkingPlatformModalActive = false;
                        workingPlatformTextModal = workingPlatformText;
                        tempWorkingPlatform = workingPlatformText;
                      }
                    "
                  ></button>
                </div>
                <div>
                  <label>What other platforms are you working in?<span class="has-text-danger">*</span></label>
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns source-list">
                      <div
                        class="source-list-item"
                        v-for="(work, index) in workeList"
                        :key="index"
                      >
                        <b-field>
                          <b-checkbox
                            class="en-style"
                            :native-value="work"
                            v-model="workingPlatformTextModal"
                          >
                            {{ work }}
                          </b-checkbox>
                        </b-field>
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div
                  class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                >
                  <b-button
                    class="is-primary is-rounded save_button"
                    @click="
                      () => {
                        ekycFormData.other_platforms_working_on.call_agent_input =
                          workingPlatformTextModal;
                        workingPlatformText = workingPlatformTextModal;
                        ekycFormData.other_platforms_working_on.is_changed_by_call_agent = true;

                        if (
                          !ekycFormData.other_platforms_working_on.call_agent_input.includes(
                            `Other`
                          )
                        ) {
                          specifiedworkplatformText = null;
                          ekycFormData.other_platforms_working_on_other_description.call_agent_input =
                            null;
                          ekycFormData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                        } else {
                          specifiedworkplatformText = ``;
                          ekycFormData.other_platforms_working_on_other_description.call_agent_input = ``;
                          ekycFormData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                          if (
                            !ekycFormData
                              .other_platforms_working_on_other_selected
                              .customer_input
                          ) {
                            ekycFormData.other_platforms_working_on_other_selected.call_agent_input = true;
                            ekycFormData.other_platforms_working_on_other_selected.is_changed_by_call_agent = true;
                          } else {
                            ekycFormData.other_platforms_working_on_other_selected.call_agent_input = false;
                            ekycFormData.other_platforms_working_on_other_selected.is_changed_by_call_agent = false;
                          }
                        }
                        if (
                          ekycFormData.other_platforms_working_on
                            .call_agent_input ===
                          ekycFormData.other_platforms_working_on.customer_input
                        ) {
                          ekycFormData.other_platforms_working_on.call_agent_input = ``;
                          workingPlatformText = workingPlatformTextModal;
                          ekycFormData.other_platforms_working_on.is_changed_by_call_agent = false;
                        }
                        isworkingPlatformModalActive = false;
                      }
                    "
                    >Save
                  </b-button>
                </div>
              </div>
            </b-modal>
            <!-- specified platform -->
            <!-- {{ ekycFormData.other_platforms_working_on.is_changed_by_call_agent + "other_platforms_working_on" }}
{{  ekycFormData.other_platforms_working_on.call_agent_input + "other_platforms_working_on" }} -->
            <div
              v-if="
                ekycFormData.other_platforms_working_on.is_changed_by_call_agent
                  ? ekycFormData.other_platforms_working_on.call_agent_input &&
                    ekycFormData.other_platforms_working_on.call_agent_input.includes(
                      `Other`
                    )
                  : ekycFormData.other_platforms_working_on.customer_input.includes(
                      `Other`
                    )
              "
            >
              <InputLabel
                required="true"
                label="Please specify"
                v-model="specifiedworkplatformText"
                :validationRules="{ required: true }"
                fieldName="This field"
                @getError="
                  (error) => {
                    specifieworkPlatformValidationProviderError = error;
                  }
                "
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.other_platforms_working_on_other_description
                    .is_changed_by_call_agent &&
                    ekycFormData.other_platforms_working_on_other_description
                      .customer_input !== specifiedworkplatformText) ||
                  (ekycFormData.other_platforms_working_on_other_description
                    .is_changed_by_call_agent &&
                    ekycFormData.other_platforms_working_on_other_description
                      .call_agent_input !== specifiedworkplatformText)
                "
                :save="
                  () => {
                    ekycFormData.other_platforms_working_on_other_description.call_agent_input =
                      specifiedworkplatformText;
                    ekycFormData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.other_platforms_working_on_other_description
                        .call_agent_input ===
                      ekycFormData.other_platforms_working_on_other_description
                        .customer_input
                    ) {
                      ekycFormData.other_platforms_working_on_other_description.call_agent_input = ``;
                      ekycFormData.other_platforms_working_on_other_description.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    specifiedworkplatformText = ekycFormData
                      .other_platforms_working_on_other_description
                      .call_agent_input
                      ? ekycFormData
                          .other_platforms_working_on_other_description
                          .call_agent_input
                      : ekycFormData
                          .other_platforms_working_on_other_description
                          .customer_input;
                  }
                "
                :disable="specifieworkPlatformValidationProviderError"
              />
            </div>
            <div
              v-if="
                ekycFormData.employment_status.is_changed_by_call_agent
                  ? ekycFormData.employment_status.call_agent_input ===
                      `Full-Time` ||
                    ekycFormData.employment_status.call_agent_input ===
                      `Part Time` ||
                    ekycFormData.employment_status.call_agent_input ===
                      `Self-Employed` ||
                    ekycFormData.employment_status.call_agent_input ===
                      `Probation`
                  : ekycFormData.employment_status.customer_input ===
                      `Full-Time` ||
                    ekycFormData.employment_status.customer_input ===
                      `Part Time` ||
                    ekycFormData.employment_status.customer_input ===
                      `Self-Employed` ||
                    ekycFormData.employment_status.customer_input ===
                      `Probation`
              "
            >
              <p
                class="mt-4"
                style="font-size: 18px"
                v-if="
                  ekycFormData.emp_building_number.is_changed_by_call_agent ||
                  ekycFormData.emp_building_number.customer_input ||
                  ekycFormData.emp_building_name.is_changed_by_call_agent ||
                  ekycFormData.emp_building_name.customer_input ||
                  ekycFormData.emp_street.is_changed_by_call_agent ||
                  ekycFormData.emp_street.customer_input ||
                  ekycFormData.emp_town.is_changed_by_call_agent ||
                  ekycFormData.emp_town.customer_input ||
                  ekycFormData.emp_postal_code.is_changed_by_call_agent ||
                  ekycFormData.emp_postal_code.customer_input ||
                  ekycFormData.emp_district.is_changed_by_call_agent ||
                  ekycFormData.emp_district.customer_input
                "
              >
                Employer / Business Address
              </p>
              <div class="columns">
                <div
                  class="column is-one-third"
                  v-if="
                    ekycFormData.emp_building_number.is_changed_by_call_agent ||
                    ekycFormData.emp_building_number.customer_input
                  "
                >
                  <InputLabel
                    label="Building Number"
                    v-model="employeraddressline1Text"
                  />
                  <AgentInputButtons
                    :show-buttons="
                      (!ekycFormData.emp_building_number
                        .is_changed_by_call_agent &&
                        ekycFormData.emp_building_number.customer_input !==
                          employeraddressline1Text) ||
                      (ekycFormData.emp_building_number
                        .is_changed_by_call_agent &&
                        ekycFormData.emp_building_number.call_agent_input !==
                          employeraddressline1Text)
                    "
                    :save="
                      () => {
                        ekycFormData.emp_building_number.call_agent_input =
                          employeraddressline1Text;
                        ekycFormData.emp_building_number.is_changed_by_call_agent = true;
                        if (
                          ekycFormData.emp_building_number.call_agent_input ===
                          ekycFormData.emp_building_number.customer_input
                        ) {
                          ekycFormData.emp_building_number.call_agent_input = ``;
                          ekycFormData.emp_building_number.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        employeraddressline1Text = ekycFormData
                          .emp_building_number.call_agent_input
                          ? ekycFormData.emp_building_number.call_agent_input
                          : ekycFormData.emp_building_number.customer_input;
                      }
                    "
                  />
                </div>
                <div
                  class="column"
                  v-if="
                    ekycFormData.emp_building_name.is_changed_by_call_agent ||
                    ekycFormData.emp_building_name.customer_input
                  "
                >
                  <InputLabel
                    label="Building Name"
                    v-model="employeraddressline2Text"
                  />
                  <AgentInputButtons
                    :show-buttons="
                      (!ekycFormData.emp_building_name
                        .is_changed_by_call_agent &&
                        ekycFormData.emp_building_name.customer_input !==
                          employeraddressline2Text) ||
                      (ekycFormData.emp_building_name
                        .is_changed_by_call_agent &&
                        ekycFormData.emp_building_name.call_agent_input !==
                          employeraddressline2Text)
                    "
                    :save="
                      () => {
                        ekycFormData.emp_building_name.call_agent_input =
                          employeraddressline2Text;
                        ekycFormData.emp_building_name.is_changed_by_call_agent = true;
                        if (
                          ekycFormData.emp_building_name.call_agent_input ===
                          ekycFormData.emp_building_name.customer_input
                        ) {
                          ekycFormData.emp_building_name.call_agent_input = ``;
                          ekycFormData.emp_building_name.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        employeraddressline2Text = ekycFormData
                          .emp_building_name.call_agent_input
                          ? ekycFormData.emp_building_name.call_agent_input
                          : ekycFormData.emp_building_name.customer_input;
                      }
                    "
                  />
                </div>
              </div>
              <div class="columns">
                <div
                  class="column"
                  v-if="
                    ekycFormData.emp_street.is_changed_by_call_agent ||
                    ekycFormData.emp_street.customer_input
                  "
                >
                  <InputLabel
                    label="Street"
                    required="true"
                    v-model="employeraddressline3Text"
                    :validationRules="validateRequired()"
                    fieldName="This field"
                    @getError="
                    (error) => {
                      streetValidationProviderError = error;
                    }
                  "
                  />
                  <AgentInputButtons
                    :show-buttons="
                      (!ekycFormData.emp_street.is_changed_by_call_agent &&
                        ekycFormData.emp_street.customer_input !==
                          employeraddressline3Text) ||
                      (ekycFormData.emp_street.is_changed_by_call_agent &&
                        ekycFormData.emp_street.call_agent_input !==
                          employeraddressline3Text)
                    "
                    :save="
                      () => {
                        ekycFormData.emp_street.call_agent_input =
                          employeraddressline3Text;
                        ekycFormData.emp_street.is_changed_by_call_agent = true;
                        if (
                          ekycFormData.emp_street.call_agent_input ===
                          ekycFormData.emp_street.customer_input
                        ) {
                          ekycFormData.emp_street.call_agent_input = ``;
                          ekycFormData.emp_street.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        employeraddressline3Text = ekycFormData.emp_street
                          .call_agent_input
                          ? ekycFormData.emp_street.call_agent_input
                          : ekycFormData.emp_street.customer_input;
                      }
                    "
                    :disable="streetValidationProviderError"
                  />
                </div>
                <div
                  class="column"
                  v-if="
                    ekycFormData.emp_town.is_changed_by_call_agent ||
                    ekycFormData.emp_town.customer_input
                  "
                >
                  <InputLabel
                      label="Town"
                      required=true
                      v-model="employeraddresstownText"
                      :validationRules="validateRequired()"
                      fieldName="This field"
                      @getError="
                    (error) => {
                      townValidationProviderError = error;
                    }
                  "
                  />
                  <AgentInputButtons
                    :show-buttons="
                      (!ekycFormData.emp_town.is_changed_by_call_agent &&
                        ekycFormData.emp_town.customer_input !==
                          employeraddresstownText) ||
                      (ekycFormData.emp_town.is_changed_by_call_agent &&
                        ekycFormData.emp_town.call_agent_input !==
                          employeraddresstownText)
                    "
                    :save="
                      () => {
                        ekycFormData.emp_town.call_agent_input =
                          employeraddresstownText;
                        ekycFormData.emp_town.is_changed_by_call_agent = true;
                        if (
                          ekycFormData.emp_town.call_agent_input ===
                          ekycFormData.emp_town.customer_input
                        ) {
                          ekycFormData.emp_town.call_agent_input = ``;
                          ekycFormData.emp_town.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        employeraddresstownText = ekycFormData.emp_town
                          .call_agent_input
                          ? ekycFormData.emp_town.call_agent_input
                          : ekycFormData.emp_town.customer_input;
                      }
                    "
                    :disable= townValidationProviderError
                  />
                </div>
              </div>
              <div class="columns">
                <div
                  class="column"
                  v-if="
                    ekycFormData.emp_postal_code.is_changed_by_call_agent ||
                    ekycFormData.emp_postal_code.customer_input
                  "
                >
                  <InputLabel
                    label="Postal Code"
                    v-model="employeraddresspostalcodeText"
                  />
                  <AgentInputButtons
                    :show-buttons="
                      (!ekycFormData.emp_postal_code.is_changed_by_call_agent &&
                        ekycFormData.emp_postal_code.customer_input !==
                          employeraddresspostalcodeText) ||
                      (ekycFormData.emp_postal_code.is_changed_by_call_agent &&
                        ekycFormData.emp_postal_code.call_agent_input !==
                          employeraddresspostalcodeText)
                    "
                    :save="
                      () => {
                        ekycFormData.emp_postal_code.call_agent_input =
                          employeraddresspostalcodeText;
                        ekycFormData.emp_postal_code.is_changed_by_call_agent = true;
                        if (
                          ekycFormData.emp_postal_code.call_agent_input ===
                          ekycFormData.emp_postal_code.customer_input
                        ) {
                          ekycFormData.emp_postal_code.call_agent_input = ``;
                          ekycFormData.emp_postal_code.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        employeraddresspostalcodeText = ekycFormData
                          .emp_postal_code.call_agent_input
                          ? ekycFormData.emp_postal_code.call_agent_input
                          : ekycFormData.emp_postal_code.customer_input;
                      }
                    "
                  />
                </div>
                <div
                  class="column"
                  v-if="
                    ekycFormData.emp_district.is_changed_by_call_agent ||
                    ekycFormData.emp_district.customer_input
                  "
                >
                  <div v-if="employeraddresscountry === `Sri Lanka`">
                    <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                    >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        employeraddressdistrictText.trim() === '' &&
                        !isEmplAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>District<span class="has-text-danger">*</span></template>

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isEmplAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmplAddDistrictFocus = false;
                            }
                          "
                          :data="employeedistrictFilteredDataArray"
                          v-model="employeraddressdistrictText"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                empladdressdistrictcount = 0;
                                isEmplAddressDistrictTouched = false;
                              }
                            }
                          "
                          @input="
                            () => {
                              ++empladdressdistrictcount;
                              if (empladdressdistrictcount > 1) {
                                isEmplAddressDistrictTouched = true;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-if="employeraddresscountry !== `Sri Lanka`">
                    <InputLabel
                      label="District"
                      required="true"
                      :validationRules="validateRequired()"
                      v-model="employeraddressdistrictText"
                    />
                  </div>
                  <AgentInputButtons
                    :show-buttons="
                      (!ekycFormData.emp_district.is_changed_by_call_agent &&
                        ekycFormData.emp_district.customer_input !==
                          employeraddressdistrictText) ||
                      (ekycFormData.emp_district.is_changed_by_call_agent &&
                        ekycFormData.emp_district.call_agent_input !==
                          employeraddressdistrictText)
                    "
                    :save="
                      () => {
                        ekycFormData.emp_district.call_agent_input =
                          employeraddressdistrictText;
                        ekycFormData.emp_district.is_changed_by_call_agent = true;
                        if (
                          ekycFormData.emp_district.call_agent_input ===
                          ekycFormData.emp_district.customer_input
                        ) {
                          ekycFormData.emp_district.call_agent_input = ``;
                          ekycFormData.emp_district.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        employeraddressdistrictText = ekycFormData.emp_district
                          .call_agent_input
                          ? emp_district.call_agent_input
                          : emp_district.customer_input;
                      }
                    "
                  />
                </div>
              </div>

              <div
                class="columns"
                v-if="
                  ekycFormData.emp_building_number.is_changed_by_call_agent ||
                  ekycFormData.emp_building_number.customer_input ||
                  ekycFormData.emp_building_name.is_changed_by_call_agent ||
                  ekycFormData.emp_building_name.customer_input ||
                  ekycFormData.emp_street.is_changed_by_call_agent ||
                  ekycFormData.emp_street.customer_input ||
                  ekycFormData.emp_town.is_changed_by_call_agent ||
                  ekycFormData.emp_town.customer_input ||
                  ekycFormData.emp_postal_code.is_changed_by_call_agent ||
                  ekycFormData.emp_postal_code.customer_input ||
                  ekycFormData.emp_district.is_changed_by_call_agent ||
                  ekycFormData.emp_district.customer_input
                "
              >
                <div class="column is-half">
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      employeraddresscountry.trim() === '' &&
                      !isEmployerAddressCountryFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label>Country<span class="has-text-danger">*</span></template>

                    <p class="control has-icons-right">
                      <b-autocomplete
                        rounded
                        v-model="employeraddresscountry"
                        open-on-focus
                        :data="empCountryFilteredDataArray"
                        @select="
                          (option) => {
                            if (option) {
                              noEnrolementCountries(option.name)
                              employeraddresscountryText = option.name;
                              employeraddresscountry = option.name;
                              employeraddresscountrycount = -1;
                            } else {
                              employeraddresscountryText = ``;
                              employeraddresscountry = ``;
                            }
                          }
                        "
                        @input="
                          () => {
                            ++employeraddresscountrycount;
                            if (!employeraddresscountry) {
                              isEmployerAddressCountryTouched = false;
                              employeraddresscountryText = ``;
                            } else if (empCountryFilteredDataArray.includes(employeraddresscountry)) {
                              isEmployerAddressCountryTouched = false;
                              employeraddresscountryText =
                                employeraddresscountry;
                            } else if (
                              ekycFormData.emp_country.customer_input
                            ) {
                              if (employeraddresscountrycount > 1) {
                                isEmployerAddressCountryTouched = true;
                                employeraddresscountryText = ``;
                                countryError=true;
                              }
                            } else if (employeraddresscountrycount > 0) {
                              isEmployerAddressCountryTouched = true;
                              employeraddresscountryText = ``;
                              countryError=true;
                            }
                          }
                        "
                        @focus="
                          () => {
                            isEmployerAddressCountryFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmployerAddressCountryFocus = false;
                          }
                        "
                        placeholder=""
                        icon=""
                        field="name"
                      >
                        <template #empty>No results found</template>
                      </b-autocomplete>
                      <span
                        class="icon is-small is-right mx-2"
                        v-if="isEmployerAddressCountryFocus"
                      >
                        <img
                          :src=autoCompleteDownArrowImage
                          alt="Stroke-img"
                          width="15"
                          height="15"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span
                    class="has-text-danger"
                    v-if="
                      isEmployerAddressCountryTouched &&
                      !employeraddresscountryText
                    "
                    >You have to select a valid country</span
                  >
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
                  <AgentInputButtons
                    :show-buttons="
                      (!ekycFormData.emp_country.is_changed_by_call_agent &&
                        ekycFormData.emp_country.customer_input !==
                          employeraddresscountry) ||
                      (ekycFormData.emp_country.is_changed_by_call_agent &&
                        ekycFormData.emp_country.call_agent_input !==
                          employeraddresscountry)
                    "
                    :save="
                      () => {
                        ekycFormData.emp_country.call_agent_input =
                          employeraddresscountry;
                        ekycFormData.emp_country.is_changed_by_call_agent = true;
                        if (
                          ekycFormData.emp_country.call_agent_input ===
                          ekycFormData.emp_country.customer_input
                        ) {
                          ekycFormData.emp_country.call_agent_input = ``;
                          ekycFormData.emp_country.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        employeraddresscountry = ekycFormData.emp_country
                          .call_agent_input
                          ? ekycFormData.emp_country.call_agent_input
                          : ekycFormData.emp_country.customer_input;
                      }
                    "
                    :disable="
                      isEmployerAddressCountryTouched &&
                      !employeraddresscountryText || countryError
                    "
                  />
                </div>
              </div>
            </div>
            <div>
              <hr
                style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
              />

              <div>
                <label>Are You a Taxpayer?<span class="has-text-danger">*</span></label>
                <div v-if="areyouataxpayerText">
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="isTaxpayerModalActive = true"
                  >
                    Yes
                  </b-radio>
                </div>
                <div v-if="!areyouataxpayerText">
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="isTaxpayerModalActive = true"
                  >
                    No
                  </b-radio>
                </div>
                <b-modal
                  v-model="isTaxpayerModalActive"
                  :width="540"
                  :can-cancel="false"
                >
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isTaxpayerModalActive = false;
                            areyouataxpayerModalText = areyouataxpayerText;
                          }
                        "
                      ></button>
                    </div>
                    <div>
                      <label>Are You a Taxpayer?<span class="has-text-danger">*</span></label>

                      <div class="columns">
                        <div class="column is-one-two">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="are_you_a_tax_payer"
                              :native-value="true"
                              v-model="areyouataxpayerModalText"
                            >
                              Yes
                            </b-radio>
                          </b-field>
                        </div>
                        <div class="column is-one-two">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="are_you_a_tax_payer"
                              :native-value="false"
                              v-model="areyouataxpayerModalText"
                            >
                              No
                            </b-radio>
                          </b-field>
                        </div>
                      </div>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        v-if="
                          ekycFormData.are_you_a_tax_payer
                            .is_changed_by_call_agent
                            ? ekycFormData.are_you_a_tax_payer
                                .call_agent_input !== areyouataxpayerModalText
                            : ekycFormData.are_you_a_tax_payer
                                .customer_input !== areyouataxpayerModalText
                        "
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            if (
                              ekycFormData.are_you_a_tax_payer
                                .customer_input !== areyouataxpayerModalText
                            ) {
                              ekycFormData.are_you_a_tax_payer.call_agent_input =
                                areyouataxpayerModalText;
                              areyouataxpayerText = areyouataxpayerModalText;
                              ekycFormData.are_you_a_tax_payer.is_changed_by_call_agent = true;

                              ekycFormData.tax_file_numer.call_agent_input = ``;
                              ekycFormData.tax_file_numer.is_changed_by_call_agent = true;
                              taxfilenumText = ``;
                            } else {
                              ekycFormData.are_you_a_tax_payer.call_agent_input =
                                areyouataxpayerModalText;
                              areyouataxpayerText = areyouataxpayerModalText;
                              ekycFormData.are_you_a_tax_payer.is_changed_by_call_agent = true;

                              if (!areyouataxpayerModalText) {
                                ekycFormData.tax_file_numer.call_agent_input = ``;
                                ekycFormData.tax_file_numer.is_changed_by_call_agent = false;
                                taxfilenumText = ``;
                              }
                            }
                            isTaxpayerModalActive = false;
                          }
                        "
                        >Save
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>

              <div
                v-if="
                  ekycFormData.are_you_a_tax_payer.is_changed_by_call_agent
                    ? ekycFormData.are_you_a_tax_payer.call_agent_input
                    : ekycFormData.are_you_a_tax_payer.customer_input &&
                      !ekycFormData.tax_file_numer.is_changed_by_call_agent &&
                      ekycFormData.tax_file_numer.customer_input
                "
              >
                <InputLabel
                  label="Tax File Number"
                  v-model="taxfilenumText"
                  required="true"
                  type="number"
                  :validationRules="validateTaxRequired()"
                  fieldName="This field"
                  styles="mt-4"
                  @getError="
                    (error) => {
                      taxNumValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (ekycFormData.tax_file_numer.is_changed_by_call_agent ?
                      ekycFormData.tax_file_numer.call_agent_input !==
                        taxfilenumText : ekycFormData.tax_file_numer.customer_input !==
                        taxfilenumText)
                  "
                  :save="
                    () => {
                      ekycFormData.tax_file_numer.call_agent_input =
                        taxfilenumText;
                      ekycFormData.tax_file_numer.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.tax_file_numer.call_agent_input ===
                        ekycFormData.tax_file_numer.customer_input
                      ) {
                        ekycFormData.tax_file_numer.call_agent_input = ``;
                        ekycFormData.tax_file_numer.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      taxfilenumText = ekycFormData.tax_file_numer
                        .call_agent_input
                        ? ekycFormData.tax_file_numer.call_agent_input
                        : ekycFormData.tax_file_numer.customer_input;
                    }
                  "
                  :disable="taxNumValidationProviderError || !taxfilenumText.trim()"
                />
              </div>
            </div>

            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />
            <div>
              <label
                >Are You or Your close Relative a Politically Exposed
                Person?<span class="has-text-danger">*</span></label
              >
              <div>
                <b-radio
                  class="en-style"
                  checked
                  @click.native="isPoliticallyexposedModalActive = true"
                >
                  {{ politicallyexposedText }}
                </b-radio>
              </div>
              <b-modal
                v-model="isPoliticallyexposedModalActive"
                :width="540"
                :can-cancel="false"
              >
                <div class="box">
                  <div class="modal-close-button">
                    <button
                      class="delete mb-2"
                      aria-label="close"
                      @click="
                        () => {
                          isPoliticallyexposedModalActive = false;
                          politicallyexposedModalText = politicallyexposedText;
                        }
                      "
                    ></button>
                  </div>
                  <div>
                    <label
                      >Are You or Your close Relative a Politically Exposed
                      Person?<span class="has-text-danger">*</span></label
                    >
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <div class="columns two-column-parent">
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="Are You or Your Close Relative a Politically Exposed Person"
                              native-value="Yes, I am politically exposed"
                              v-model="politicallyexposedModalText"
                              @input="saveToPepObject"
                            >
                              Yes, I am politically exposed
                            </b-radio>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="Are You or Your Close Relative a Politically Exposed Person"
                              native-value="Yes, my Close relative is politically exposed"
                              v-model="politicallyexposedModalText"
                              @input="saveToPepObject"
                            >
                              Yes, my close relative is politically exposed
                            </b-radio>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-radio
                              class="en-style"
                              name="Are You or Your Close Relative a Politically Exposed Person"
                              native-value="No"
                              v-model="politicallyexposedModalText"
                              @input="saveToPepObject"
                            >
                              No
                            </b-radio>
                          </b-field>
                        </div>
                      </div>

                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      v-if="
                        !ekycFormData.pep_declaration
                          .is_changed_by_call_agent &&
                        (ekycFormData.pep_declaration.customer_input
                          .iam_politically_exposed !==
                          pep_object.iam_politically_exposed ||
                          ekycFormData.pep_declaration.customer_input
                            .close_relative_politically_exposed !==
                            pep_object.close_relative_politically_exposed ||
                          ekycFormData.pep_declaration.customer_input
                            .not_politically_exposed !==
                            pep_object.not_politically_exposed)
                          ? true
                          : ekycFormData.pep_declaration
                              .is_changed_by_call_agent &&
                            (ekycFormData.pep_declaration.call_agent_input
                              .iam_politically_exposed !==
                              pep_object.iam_politically_exposed ||
                              ekycFormData.pep_declaration.call_agent_input
                                .close_relative_politically_exposed !==
                                pep_object.close_relative_politically_exposed ||
                              ekycFormData.pep_declaration.call_agent_input
                                .not_politically_exposed !==
                                pep_object.not_politically_exposed)
                          ? true
                          : false
                      "
                      class="is-primary is-rounded save_button"
                      @click="
                        () => {
                          const tempArray = pepDeclarationTextName.map(
                            (name, pepTempindex) => {
                              return {
                                name: pepDeclarationTextName[pepTempindex],
                                designation:
                                  pepDeclarationTextDesignation[pepTempindex],
                              };
                            }
                          );
                          if (
                            ekycFormData.pep_declaration.customer_input
                              .iam_politically_exposed ===
                              pep_object.iam_politically_exposed &&
                            ekycFormData.pep_declaration.customer_input
                              .close_relative_politically_exposed ===
                              pep_object.close_relative_politically_exposed &&
                            ekycFormData.pep_declaration.customer_input
                              .not_politically_exposed ===
                              pep_object.not_politically_exposed &&
                            JSON.stringify(tempArray) ===
                              JSON.stringify(
                                ekycFormData.pep_declaration.customer_input
                                  .pep_person_details
                              )
                          ) {
                            ekycFormData.pep_declaration.call_agent_input =
                              null;
                            ekycFormData.pep_declaration.is_changed_by_call_agent = false;
                            ekycFormData.pep_declaration.customer_input.pep_person_details.map(
                              (pep) => {
                                pepDeclarationTextName.push(pep.name);
                                pepDeclarationTextDesignation.push(
                                  pep.designation
                                );
                              }
                            );
                          } else {
                            if (
                              (!ekycFormData.pep_declaration
                                .is_changed_by_call_agent &&
                                (ekycFormData.pep_declaration.customer_input
                                  .iam_politically_exposed !==
                                  pep_object.iam_politically_exposed ||
                                  ekycFormData.pep_declaration.customer_input
                                    .close_relative_politically_exposed !==
                                    pep_object.close_relative_politically_exposed ||
                                  ekycFormData.pep_declaration.customer_input
                                    .not_politically_exposed !==
                                    pep_object.not_politically_exposed)) ||
                              (ekycFormData.pep_declaration
                                .is_changed_by_call_agent &&
                                (ekycFormData.pep_declaration.call_agent_input
                                  .iam_politically_exposed !==
                                  pep_object.iam_politically_exposed ||
                                  ekycFormData.pep_declaration.call_agent_input
                                    .close_relative_politically_exposed !==
                                    pep_object.close_relative_politically_exposed ||
                                  ekycFormData.pep_declaration.call_agent_input
                                    .not_politically_exposed !==
                                    pep_object.not_politically_exposed))
                            ) {
                              const sampleArray = [1, 2];

                              const tempArray = sampleArray.map((pep) => {
                                return { name: ``, designation: `` };
                              });

                              const tempArrayName = sampleArray.map((pep) => {
                                return ``;
                              });

                              const tempArrayDesignation = sampleArray.map(
                                (pep) => {
                                  return ``;
                                }
                              );

                              pep_object.pep_person_details = tempArray.map(
                                (pep) => {
                                  return {
                                    ...pep,
                                  };
                                }
                              );

                              ekycFormData.pep_declaration.call_agent_input = {
                                ...pep_object,
                              };

                              ekycFormData.pep_declaration.call_agent_input.pep_person_details =
                                tempArray.map((pep) => {
                                  return {
                                    ...pep,
                                  };
                                });

                              ekycFormData.pep_declaration.is_changed_by_call_agent = true;

                              pepDeclarationTextName = [...tempArrayName];
                              pepDeclarationTextDesignation = [
                                ...tempArrayDesignation,
                              ];
                            }
                          }
                          politicallyexposedText = politicallyexposedModalText;
                          isPoliticallyexposedModalActive = false;
                        }
                      "
                      >Save
                    </b-button>
                  </div>
                </div>
              </b-modal>
            </div>

            <div
              v-if="
                ekycFormData.pep_declaration.call_agent_input
                  ? !ekycFormData.pep_declaration.call_agent_input
                      .not_politically_exposed &&
                    ekycFormData.pep_declaration.call_agent_input
                      .pep_person_details.length > 0
                    ? true
                    : false
                  : ekycFormData.pep_declaration.customer_input &&
                    !ekycFormData.pep_declaration.customer_input
                      .not_politically_exposed &&
                    ekycFormData.pep_declaration.customer_input
                      .pep_person_details.length > 0
                  ? true
                  : false
              "
            >
              <div
                v-for="(
                  pep_person, pep_person_index
                ) in pep_object.pep_person_details"
                :key="pep_person_index"
              >
                <div>
                  <InputLabel
                    label="Name of PEP"
                    required="true"
                    v-model="pepDeclarationTextName[pep_person_index]"
                    styles="mt-5"
                    :validationRules="validateRequiredName()"
                    fieldName="This field"
                    @getError="
                    (error) => {
                      nameOfPepValidationProviderError = error;
                    }
                  "
                  />
                  <AgentInputButtons
                    :showButtons="
                      ekycFormData.pep_declaration.call_agent_input &&
                      ekycFormData.pep_declaration.call_agent_input
                        .pep_person_details.length > 0
                        ? ekycFormData.pep_declaration.call_agent_input
                            .pep_person_details[pep_person_index].name !==
                          pepDeclarationTextName[pep_person_index]
                          ? true
                          : false
                        : ekycFormData.pep_declaration.customer_input
                            .pep_person_details[pep_person_index].name !==
                          pepDeclarationTextName[pep_person_index]
                        ? true
                        : false
                    "
                    :save="
                      () => {
                        if (!ekycFormData.pep_declaration.call_agent_input) {
                          ekycFormData.pep_declaration.call_agent_input = {
                            ...ekycFormData.pep_declaration.customer_input,
                          };
                          ekycFormData.pep_declaration.call_agent_input.pep_person_details =
                            ekycFormData.pep_declaration.customer_input.pep_person_details.map(
                              (pep) => {
                                return {
                                  name: pep.name,
                                  designation: pep.designation,
                                };
                              }
                            );

                          ekycFormData.pep_declaration.call_agent_input.pep_person_details[
                            pep_person_index
                          ].name = pepDeclarationTextName[pep_person_index];

                          ekycFormData.pep_declaration.is_changed_by_call_agent = true;
                        } else if (
                          ekycFormData.pep_declaration.call_agent_input
                            .pep_person_details.length === 0
                        ) {
                          ekycFormData.pep_declaration.call_agent_input.pep_person_details.push(
                            {
                              ...ekycFormData.pep_declaration.customer_input
                                .pep_person_details[pep_person_index],
                            }
                          );

                          ekycFormData.pep_declaration.call_agent_input.pep_person_details[
                            pep_person_index
                          ].name = pepDeclarationTextName[pep_person_index];

                          ekycFormData.pep_declaration.is_changed_by_call_agent = true;
                        } else {
                          ekycFormData.pep_declaration.call_agent_input.pep_person_details[
                            pep_person_index
                          ].name = pepDeclarationTextName[pep_person_index];

                          ekycFormData.pep_declaration.is_changed_by_call_agent = true;
                        }

                        if (
                          ekycFormData.pep_declaration.customer_input
                            .iam_politically_exposed ===
                            ekycFormData.pep_declaration.call_agent_input
                              .iam_politically_exposed &&
                          ekycFormData.pep_declaration.customer_input
                            .close_relative_politically_exposed ===
                            ekycFormData.pep_declaration.call_agent_input
                              .close_relative_politically_exposed &&
                          ekycFormData.pep_declaration.customer_input
                            .not_politically_exposed ===
                            ekycFormData.pep_declaration.call_agent_input
                              .not_politically_exposed &&
                          JSON.stringify(
                            ekycFormData.pep_declaration.customer_input
                              .pep_person_details
                          ) ===
                            JSON.stringify(
                              ekycFormData.pep_declaration.call_agent_input
                                .pep_person_details
                            )
                        ) {
                          ekycFormData.pep_declaration.call_agent_input = null;
                          ekycFormData.pep_declaration.is_changed_by_call_agent = false;
                          pepDeclarationTextName[pep_person_index] =
                            ekycFormData.pep_declaration.customer_input.pep_person_details[
                              pep_person_index
                            ].name;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        pepDeclarationTextName[pep_person_index] = ekycFormData
                          .pep_declaration.call_agent_input
                          ? ekycFormData.pep_declaration.call_agent_input
                              .pep_person_details.length > 0
                            ? ekycFormData.pep_declaration.call_agent_input
                                .pep_person_details[pep_person_index].name
                            : ekycFormData.pep_declaration.customer_input
                                .pep_person_details[pep_person_index].name
                          : ekycFormData.pep_declaration.customer_input
                              .pep_person_details[pep_person_index].name;
                      }
                    "
                    :disable=nameOfPepValidationProviderError
                  />
                </div>

                <div>
                  <InputLabel
                    label="Designation of PEP"
                    required="true"
                    v-model="pepDeclarationTextDesignation[pep_person_index]"
                    styles="mt-5"
                    :validationRules="validateRequiredName()"
                    fieldName="This field"
                    @getError="
                    (error) => {
                      designationOfPepValidationProviderError = error;
                    }
                  "
                  />
                  <AgentInputButtons
                    :show-buttons="
                      ekycFormData.pep_declaration.call_agent_input &&
                      ekycFormData.pep_declaration.call_agent_input
                        .pep_person_details.length > 0
                        ? ekycFormData.pep_declaration.call_agent_input
                            .pep_person_details[pep_person_index]
                            .designation !==
                          pepDeclarationTextDesignation[pep_person_index]
                          ? true
                          : false
                        : ekycFormData.pep_declaration.customer_input
                            .pep_person_details[pep_person_index]
                            .designation !==
                          pepDeclarationTextDesignation[pep_person_index]
                        ? true
                        : false
                    "
                    :save="
                      () => {
                        if (!ekycFormData.pep_declaration.call_agent_input) {
                          ekycFormData.pep_declaration.call_agent_input = {
                            ...ekycFormData.pep_declaration.customer_input,
                          };
                          ekycFormData.pep_declaration.call_agent_input.pep_person_details =
                            ekycFormData.pep_declaration.customer_input.pep_person_details.map(
                              (pep) => {
                                return {
                                  name: pep.name,
                                  designation: pep.designation,
                                };
                              }
                            );

                          ekycFormData.pep_declaration.call_agent_input.pep_person_details[
                            pep_person_index
                          ].designation =
                            pepDeclarationTextDesignation[pep_person_index];

                          ekycFormData.pep_declaration.is_changed_by_call_agent = true;
                        } else if (
                          ekycFormData.pep_declaration.call_agent_input
                            .pep_person_details.length === 0
                        ) {
                          ekycFormData.pep_declaration.call_agent_input.pep_person_details.push(
                            {
                              ...ekycFormData.pep_declaration.customer_input
                                .pep_person_details[pep_person_index],
                            }
                          );

                          ekycFormData.pep_declaration.call_agent_input.pep_person_details[
                            pep_person_index
                          ].designation =
                            pepDeclarationTextDesignation[pep_person_index];

                          ekycFormData.pep_declaration.is_changed_by_call_agent = true;
                        } else {
                          ekycFormData.pep_declaration.call_agent_input.pep_person_details[
                            pep_person_index
                          ].designation =
                            pepDeclarationTextDesignation[pep_person_index];

                          ekycFormData.pep_declaration.is_changed_by_call_agent = true;
                        }

                        if (
                          ekycFormData.pep_declaration.customer_input
                            .iam_politically_exposed ===
                            ekycFormData.pep_declaration.call_agent_input
                              .iam_politically_exposed &&
                          ekycFormData.pep_declaration.customer_input
                            .close_relative_politically_exposed ===
                            ekycFormData.pep_declaration.call_agent_input
                              .close_relative_politically_exposed &&
                          ekycFormData.pep_declaration.customer_input
                            .not_politically_exposed ===
                            ekycFormData.pep_declaration.call_agent_input
                              .not_politically_exposed &&
                          JSON.stringify(
                            ekycFormData.pep_declaration.customer_input
                              .pep_person_details
                          ) ===
                            JSON.stringify(
                              ekycFormData.pep_declaration.call_agent_input
                                .pep_person_details
                            )
                        ) {
                          ekycFormData.pep_declaration.call_agent_input = null;
                          ekycFormData.pep_declaration.is_changed_by_call_agent = false;
                          pepDeclarationTextDesignation[pep_person_index] =
                            ekycFormData.pep_declaration.customer_input.pep_person_details[
                              pep_person_index
                            ].designation;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        pepDeclarationTextDesignation[pep_person_index] =
                          ekycFormData.pep_declaration.call_agent_input
                            ? ekycFormData.pep_declaration.call_agent_input
                                .pep_person_details.length > 0
                              ? ekycFormData.pep_declaration.call_agent_input
                                  .pep_person_details[pep_person_index]
                                  .designation
                              : ekycFormData.pep_declaration.customer_input
                                  .pep_person_details[pep_person_index]
                                  .designation
                            : ekycFormData.pep_declaration.customer_input
                                .pep_person_details[pep_person_index]
                                .designation;
                      }
                    "
                    :disable=designationOfPepValidationProviderError
                  />
                </div>
              </div>
            </div>

            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />
            <ValidationProvider
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <div @click="isSourceofincomeModalActive = true" class="mb-5">
                <label>Source of Funds<span class="has-text-danger">*</span></label>
                  <div v-for="(sourceofincome, index) in sourceofincomeText">
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      @click.native="preventDefault"
                    >
                      {{ sourceofincome }}
                    </b-checkbox>
                  </div>
              </div>
              <span class="has-text-danger">{{ errors[0] }}</span>
              <span v-if="sourceofincomeText.length === 0" class="has-text-danger">You Have to Select Source of Funds</span>
            </ValidationProvider>
            <b-modal
              v-model="isSourceofincomeModalActive"
              :width="540"
              :can-cancel="false"
            >
              <div class="box">
                <div class="modal-close-button">
                  <button
                    class="delete mb-2"
                    aria-label="close"
                    @click="
                      () => {
                        isSourceofincomeModalActive = false;
                        sourceofincomeTextModal = sourceofincomeText;
                      }
                    "
                  ></button>
                </div>
                <div>
                  <label>Source of Funds<span class="has-text-danger">*</span></label>
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns source-list">
                      <div
                        class="source-list-item"
                        v-for="(source, index) in sourceOfFundList"
                        :key="index"
                      >
                        <b-field>
                          <b-checkbox
                            class="en-style"
                            :native-value="source"
                            v-model="sourceofincomeTextModal"
                          >
                            {{ source }}
                          </b-checkbox>
                        </b-field>
                      </div>
                    </div>
                    <br>
                    <span v-if="sourceofincomeTextModal.length === 0" class="has-text-danger">You Have to Select Source of Funds</span>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div
                  class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  v-if="
                    (!ekycFormData.source_of_funds.call_agent_input &&
                      ekycFormData.source_of_funds.customer_input !==
                        sourceofincomeTextModal) ||
                    (ekycFormData.source_of_funds.call_agent_input &&
                      ekycFormData.source_of_funds.call_agent_input !==
                        sourceofincomeTextModal)
                  "
                >
                  <b-button
                    class="is-primary is-rounded save_button"
                    :disabled="sourceofincomeTextModal.length === 0"
                    @click="
                      () => {
                        ekycFormData.source_of_funds.call_agent_input =
                          sourceofincomeTextModal;
                        sourceofincomeText = sourceofincomeTextModal;
                        ekycFormData.source_of_funds.is_changed_by_call_agent = true;
                        if (
                          !ekycFormData.source_of_funds.call_agent_input.includes(
                            `Other`
                          )
                        ) {
                          specifiedsourceofincomeText = ``;
                          ekycFormData.source_of_funds_other_description.call_agent_input = ``;
                          ekycFormData.source_of_funds_other_description.is_changed_by_call_agent =
                            ekycFormData.source_of_funds_other_description
                              .customer_input
                              ? true
                              : false;
                        }

                        if (
                          ekycFormData.source_of_funds.call_agent_input ===
                          ekycFormData.source_of_funds.customer_input
                        ) {
                          ekycFormData.source_of_funds.call_agent_input = ``;
                          sourceofincomeText = sourceofincomeTextModal;
                          ekycFormData.source_of_funds.is_changed_by_call_agent = false;
                        }
                        isSourceofincomeModalActive = false;
                      }
                    "
                    >Save
                  </b-button>
                </div>
              </div>
            </b-modal>

            <div
              v-if="
                ekycFormData.source_of_funds_other_description.customer_input ||
                (ekycFormData.source_of_funds.call_agent_input &&
                ekycFormData.source_of_funds.call_agent_input.length > 0
                  ? ekycFormData.source_of_funds.call_agent_input.includes(
                      `Other`
                    )
                  : ekycFormData.source_of_funds.customer_input.includes(
                      `Other`
                    ))
              "
            >
              <InputLabel
                required="true"
                label="Please specify"
                v-model="specifiedsourceofincomeText"
                :validationRules="{ required: true }"
                fieldName="This field"
                @getError="
                  (error) => {
                    specifiedsourceofincomeValidationProviderError = error;
                  }
                "
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.source_of_funds_other_description
                    .is_changed_by_call_agent &&
                    ekycFormData.source_of_funds_other_description
                      .customer_input !== specifiedsourceofincomeText) ||
                  (ekycFormData.source_of_funds_other_description
                    .is_changed_by_call_agent &&
                    ekycFormData.source_of_funds_other_description
                      .call_agent_input !== specifiedsourceofincomeText)
                "
                :save="
                  () => {
                    ekycFormData.source_of_funds_other_description.call_agent_input =
                      specifiedsourceofincomeText;
                    ekycFormData.source_of_funds_other_description.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.source_of_funds_other_description
                        .call_agent_input ===
                      ekycFormData.source_of_funds_other_description
                        .customer_input
                    ) {
                      ekycFormData.source_of_funds_other_description.call_agent_input = ``;
                      ekycFormData.source_of_funds_other_description.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    specifiedsourceofincomeText = ekycFormData
                      .source_of_funds_other_description.call_agent_input
                      ? ekycFormData.source_of_funds_other_description
                          .call_agent_input
                      : ekycFormData.source_of_funds_other_description
                          .customer_input;
                  }
                "
                :disable="specifiedsourceofincomeValidationProviderError"
              />
            </div>
            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />

            <div>
              <div>
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      branchText === '' && !isBranchInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label>Preferred Branch<span class="has-text-danger">*</span></template>

                    <b-select
                      expanded
                      rounded
                      v-model="branchText"
                      :class="
                        branchText === '' && !isBranchInputFocus
                          ? 'arrow-up-before'
                          : 'arrow-up'
                      "
                      @focus="
                        () => {
                          isBranchInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isBranchInputFocus = false;
                        }
                      "
                    >
                      <option
                        v-for="branch in $root.formDataSupport.branchesArray"
                        :key="branch.id"
                        :value="branch.id"
                      >
                        {{ branch.branch_name }}
                      </option>
                    </b-select>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <AgentInputButtons
                :show-buttons="
                  (ekycFormData.branch_id.call_agent_input === -1 &&
                    ekycFormData.branch_id.customer_input !== branchText) ||
                  (ekycFormData.branch_id.call_agent_input !== -1 &&
                    ekycFormData.branch_id.call_agent_input !== branchText)
                "
                :save="
                  () => {
                    ekycFormData.branch_id.call_agent_input = branchText;
                    ekycFormData.branch_id.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.branch_id.call_agent_input ===
                      ekycFormData.branch_id.customer_input
                    ) {
                      ekycFormData.branch_id.call_agent_input = -1;
                      ekycFormData.branch_id.is_changed_by_call_agent = false;
                    }

                    ekycFormData.branch_comment.call_agent_input = ``;
                    reasonforopeninganaccountText = ``;
                    ekycFormData.branch_comment.is_changed_by_call_agent =
                      ekycFormData.branch_comment.customer_input ? true : false;
                  }
                "
                :cancel="
                  () => {
                    branchText =
                      ekycFormData.branch_id.call_agent_input !== -1
                        ? ekycFormData.branch_id.call_agent_input
                        : ekycFormData.branch_id.customer_input;
                  }
                "
              />
            </div>

            <div
              v-if="
                (ekycFormData.district.is_changed_by_call_agent
                  ? ekycFormData.branch_id.is_changed_by_call_agent
                    ? $root.formDataSupport.branchesArray.find(
                        (search) =>
                          search.id === ekycFormData.branch_id.call_agent_input
                      ) &&
                      $root.formDataSupport.branchesArray.find(
                        (search) =>
                          search.id === ekycFormData.branch_id.call_agent_input
                      ).district !== ekycFormData.district.call_agent_input
                    : $root.formDataSupport.branchesArray.find(
                        (search) =>
                          search.id === ekycFormData.branch_id.customer_input
                      ) &&
                      $root.formDataSupport.branchesArray.find(
                        (search) =>
                          search.id === ekycFormData.branch_id.customer_input
                      ).district !== ekycFormData.district.call_agent_input
                  : ekycFormData.branch_id.is_changed_by_call_agent
                  ? $root.formDataSupport.branchesArray.find(
                      (search) =>
                        search.id === ekycFormData.branch_id.call_agent_input
                    ) &&
                    $root.formDataSupport.branchesArray.find(
                      (search) =>
                        search.id === ekycFormData.branch_id.call_agent_input
                    ).district !== ekycFormData.district.customer_input
                  : $root.formDataSupport.branchesArray.find(
                      (search) =>
                        search.id === ekycFormData.branch_id.customer_input
                    ) &&
                    $root.formDataSupport.branchesArray.find(
                      (search) =>
                        search.id === ekycFormData.branch_id.customer_input
                    ).district !== ekycFormData.district.customer_input) &&
                (ekycFormData.country.is_changed_by_call_agent
                  ? ekycFormData.country.call_agent_input === `Sri Lanka`
                  : ekycFormData.country.customer_input === `Sri Lanka`)
              "
            >
              <InputLabel
                label="Reason for opening an account in a branch that is located far from the permanent address"
                required=true
                v-model="reasonforopeninganaccountText"
                :validationRules="validateReason()"
                fieldName="This field"
                styles="mt-5"
                @getError="
                  (error) => {
                    reasonValidationProviderError = error;
                  }
                "
              />
              <AgentInputButtons
                :show-buttons="
                  (!ekycFormData.branch_comment.is_changed_by_call_agent &&
                    ekycFormData.branch_comment.customer_input !==
                      reasonforopeninganaccountText) ||
                  (ekycFormData.branch_comment.is_changed_by_call_agent &&
                    ekycFormData.branch_comment.call_agent_input !==
                      reasonforopeninganaccountText)
                "
                :save="
                  () => {
                    ekycFormData.branch_comment.call_agent_input =
                      reasonforopeninganaccountText;
                    ekycFormData.branch_comment.is_changed_by_call_agent = true;
                    if (
                      ekycFormData.branch_comment.call_agent_input ===
                      ekycFormData.branch_comment.customer_input
                    ) {
                      ekycFormData.branch_comment.call_agent_input = ``;
                      ekycFormData.branch_comment.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    reasonforopeninganaccountText = ekycFormData.branch_comment
                      .call_agent_input
                      ? ekycFormData.branch_comment.call_agent_input
                      : ekycFormData.branch_comment.customer_input;
                  }
                "
                :disable="reasonValidationProviderError"
              />
            </div>
            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />

            <div
              v-for="(product, mainIndex) in product_selection_v_model"
              :key="`${product.id}${mainIndex}`"
            >
              <div>
                <SelectLabel
                  label="Currency"
                  required="true"
                  :disabled="true"
                  v-model="product.currency_type"
                  :validationRules="{ required: true }"
                  :selectOptions="currencyList"
                />

                <AgentInputButtons
                  :show-buttons="
                    !product_selection[mainIndex].currency_type.call_agent_input
                      ? product_selection[mainIndex].currency_type
                          .customer_input !== product.currency_type
                      : product_selection[mainIndex].currency_type
                          .call_agent_input !== product.currency_type
                  "
                  :save="
                    () => {
                      product_selection[
                        mainIndex
                      ].currency_type.call_agent_input = product.currency_type;
                      product_selection[
                        mainIndex
                      ].currency_type.is_changed_by_call_agent = true;
                      if (
                        product_selection[mainIndex].currency_type
                          .call_agent_input ===
                        product_selection[mainIndex].currency_type
                          .customer_input
                      ) {
                        product_selection[
                          mainIndex
                        ].currency_type.call_agent_input = ``;
                        product_selection[
                          mainIndex
                        ].currency_type.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      product.currency_type = product_selection[mainIndex]
                        .currency_type.call_agent_input
                        ? product_selection[mainIndex].currency_type
                            .call_agent_input
                        : product_selection[mainIndex].currency_type
                            .customer_input;
                    }
                  "
                />
              </div>

              <div class="mt-4">
                <label>Account<span class="has-text-danger">*</span></label>

                <div>
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="isProductModalActive[mainIndex].value = true"
                  >
                    {{ product.account_type }}<span class="has-text-danger">*</span>
                  </b-radio>
                </div>
                <b-modal
                  v-model="isProductModalActive[mainIndex].value"
                  :width="600"
                  :can-cancel="false"
                >
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isProductModalActive[mainIndex].value = false;
                            account_modals[mainIndex] = product.account_type;
                          }
                        "
                      ></button>
                    </div>
                    <div class="mt-5">
                      <label>Account<span class="has-text-danger">*</span></label>
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div
                          class="columns"
                          style="flex-wrap: wrap; align-items: baseline"
                        >
                          <div
                            class="product-list-item"
                            v-for="(
                              accountProduct, accountProductIndex
                            ) in getFilteredProductList(
                              product_selection[mainIndex].currency_type
                                .customer_input
                            )"
                            :key="accountProductIndex"
                          >
                            <b-field>
                              <b-radio
                                class="en-style"
                                :name="`product_items${mainIndex}`"
                                :native-value="accountProduct.name"
                                v-model="account_modals[mainIndex]"
                              >
                                {{ accountProduct.name }}
                              </b-radio>
                            </b-field>
                          </div>
                        </div>

                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        v-if="
                          product_selection[mainIndex].account_type
                            .call_agent_input
                            ? product_selection[mainIndex].account_type
                                .call_agent_input !== account_modals[mainIndex]
                            : product_selection[mainIndex].account_type
                                .customer_input !== account_modals[mainIndex]
                        "
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            if (
                              product_selection[mainIndex].account_type
                                .customer_input !== account_modals[mainIndex]
                            ) {
                              product_selection[
                                mainIndex
                              ].account_type.call_agent_input =
                                account_modals[mainIndex];
                              product.account_type = account_modals[mainIndex];
                              product_selection[
                                mainIndex
                              ].account_type.is_changed_by_call_agent = true;
                            } else {
                              product_selection[
                                mainIndex
                              ].account_type.call_agent_input = ``;
                              product.account_type = account_modals[mainIndex];
                              product_selection[
                                mainIndex
                              ].account_type.is_changed_by_call_agent = false;
                            }

                            getPosOptions();
                            isProductModalActive[mainIndex].value = false;
                            throwAccountSelectionError();
                          }
                        "
                        >Save
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>
              <div class="mt-4">
                <label>Purpose of Account Opening<span class="has-text-danger">*</span></label>
                <div
                  v-for="(
                    purposeofopening, indexPurposeOfOpening
                  ) in product.purpose_of_opening_account"
                  @click="
                    () => {
                      isPurposeofopeningModalActive[mainIndex].value = true;
                    }
                  "
                  :key="`${mainIndex}${indexPurposeOfOpening}${product.id}`"
                >
                  <b-checkbox
                    v-if="purposeofopening.key !== `purpose_other_specified`"
                    :value="true"
                    class="en-style"
                    @click.native="preventDefault"
                  >
                    {{ purposeofopening.purpose }}
                  </b-checkbox>
                </div>
                <b-modal
                  v-model="isPurposeofopeningModalActive[mainIndex].value"
                  :width="600"
                  :can-cancel="false"
                >
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isPurposeofopeningModalActive[
                              mainIndex
                            ].value = false;
                            purpose_of_opening_modals[mainIndex] = [
                              ...product.purpose_of_opening_account,
                            ];
                          }
                        "
                      ></button>
                    </div>
                    <div>
                      <div>
                        <label>Purpose of account opening<span class="has-text-danger">*</span></label>
                        <ValidationProvider
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                        >
                          <div class="columns purposes-list">
                            <div
                              class="purpose-list-item"
                              v-for="(purpose, indexPurposeList) in purposeList"
                              :key="`${product.id}${indexPurposeList}${mainIndex}`"
                            >
                              <b-field
                                v-if="purpose.key !== `purpose_other_specified`"
                              >
                                <b-checkbox
                                  class="en-style"
                                  v-model="purpose_of_opening_modals[mainIndex]"
                                  :native-value="
                                    new PurposeOfAccountOpening(
                                      purpose.key,
                                      purpose.value
                                    )
                                  "
                                >
                                  {{ purpose.value + "" }}
                                </b-checkbox>
                              </b-field>
                            </div>
                          </div>
                          <!-- <span v-if="" class="has-text-danger">You have to select at least one purpose</span> -->
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        :disabled = invalid
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            product.purpose_of_opening_account = [
                              ...purpose_of_opening_modals[mainIndex],
                            ];
                            if (
                              JSON.stringify(
                                product.purpose_of_opening_account
                              ) ===
                              JSON.stringify(
                                product_selection[mainIndex]
                                  .purpose_of_opening_account.customer_input
                              )
                            ) {
                              product_selection[
                                mainIndex
                              ].purpose_of_opening_account.call_agent_input = null;
                              product_selection[
                                mainIndex
                              ].purpose_of_opening_account.is_changed_by_call_agent = false;
                            } else {
                              product_selection[
                                mainIndex
                              ].purpose_of_opening_account.call_agent_input = [
                                ...product.purpose_of_opening_account,
                              ];
                              product_selection[
                                mainIndex
                              ].purpose_of_opening_account.is_changed_by_call_agent = true;

                              let checking;
                              if (
                                product_selection[mainIndex]
                                  .purpose_of_opening_account.call_agent_input
                              ) {
                                checking = product_selection[
                                  mainIndex
                                ].purpose_of_opening_account.call_agent_input.find(
                                  (purposeTest) =>
                                    purposeTest.key === `purpose_other`
                                );
                              }

                              if (checking === undefined) {
                                product_selection[
                                  mainIndex
                                ].purpose_of_opening_account.call_agent_input =
                                  [
                                    ...product_selection[
                                      mainIndex
                                    ].purpose_of_opening_account.call_agent_input.filter(
                                      (purposeTest) =>
                                        purposeTest.key !==
                                        `purpose_other_specified`
                                    ),
                                  ];

                                purpose_of_opening_modals[mainIndex] = [
                                  ...purpose_of_opening_modals[
                                    mainIndex
                                  ].filter(
                                    (purposeTest) =>
                                      purposeTest.key !==
                                      `purpose_other_specified`
                                  ),
                                ];
                              } else {
                                const temp = product_selection[
                                  mainIndex
                                ].purpose_of_opening_account.call_agent_input.find(
                                  (purposeTest) =>
                                    purposeTest.key ===
                                    `purpose_other_specified`
                                );

                                if (temp === undefined) {
                                  product_selection[
                                    mainIndex
                                  ].purpose_of_opening_account.call_agent_input =
                                    [
                                      ...product_selection[mainIndex]
                                        .purpose_of_opening_account
                                        .call_agent_input,
                                      {
                                        key: `purpose_other_specified`,
                                        purpose: ``,
                                      },
                                    ];
                                  purpose_of_opening_modals[mainIndex] = [
                                    ...purpose_of_opening_modals[mainIndex],
                                    {
                                      key: `purpose_other_specified`,
                                      purpose: ``,
                                    },
                                  ];
                                  other_opening_purpose[mainIndex] = ``;
                                }
                              }
                            }
                            isPurposeofopeningModalActive[
                              mainIndex
                            ].value = false;
                          }
                        "
                        >Save
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>

              <div
                v-if="
                  product_selection[mainIndex].purpose_of_opening_account
                    .call_agent_input &&
                  product_selection[mainIndex].purpose_of_opening_account
                    .call_agent_input.length !== 0
                    ? product_selection[
                        mainIndex
                      ].purpose_of_opening_account.call_agent_input.find(
                        (purpose) => purpose.key === `purpose_other_specified`
                      )
                    : product_selection[
                        mainIndex
                      ].purpose_of_opening_account.customer_input.find(
                        (purpose) => purpose.key === `purpose_other_specified`
                      )
                "
              >
                <InputLabel
                  required="true"
                  label="Please specify"
                  :validationRules="{ required: true }"
                  fieldName="This field"
                  v-model="other_opening_purpose[mainIndex]"
                  styles="mt-5"
                /><AgentInputButtons
                  :show-buttons="
                    product_selection[mainIndex].purpose_of_opening_account
                      .call_agent_input &&
                    product_selection[
                      mainIndex
                    ].purpose_of_opening_account.call_agent_input.find(
                      (purposeTest) =>
                        purposeTest.key === `purpose_other_specified`
                    )
                      ? product_selection[
                          mainIndex
                        ].purpose_of_opening_account.call_agent_input.find(
                          (purposeTest) =>
                            purposeTest.key === `purpose_other_specified`
                        ).purpose !== other_opening_purpose[mainIndex]
                      : product_selection[
                          mainIndex
                        ].purpose_of_opening_account.customer_input.find(
                          (purposeTest) =>
                            purposeTest.key === `purpose_other_specified`
                        ).purpose !== other_opening_purpose[mainIndex]
                  "
                  :save="
                    () => {
                      const item = product_selection[
                        mainIndex
                      ].purpose_of_opening_account.customer_input.find(
                        (purposeTest) =>
                          purposeTest.key === `purpose_other_specified`
                      );

                      if (
                        item &&
                        other_opening_purpose[mainIndex] === item.purpose
                      ) {
                        product_selection[
                          mainIndex
                        ].purpose_of_opening_account.call_agent_input =
                          product_selection[
                            mainIndex
                          ].purpose_of_opening_account.customer_input;

                        product_selection[
                          mainIndex
                        ].purpose_of_opening_account.is_changed_by_call_agent = false;
                      } else {
                        const filteredArray = product_selection[mainIndex]
                          .purpose_of_opening_account.call_agent_input
                          ? product_selection[
                              mainIndex
                            ].purpose_of_opening_account.call_agent_input.filter(
                              (purposeTest) =>
                                purposeTest.key !== `purpose_other_specified`
                            )
                          : product_selection[
                              mainIndex
                            ].purpose_of_opening_account.customer_input.filter(
                              (purposeTest) =>
                                purposeTest.key !== `purpose_other_specified`
                            );

                        filteredArray.push(
                          new PurposeOfAccountOpening(
                            `purpose_other_specified`,
                            other_opening_purpose[mainIndex]
                          )
                        );
                        product_selection[
                          mainIndex
                        ].purpose_of_opening_account.call_agent_input = [
                          ...filteredArray,
                        ];

                        product.purpose_of_opening_account = [...filteredArray];

                        purpose_of_opening_modals[mainIndex] = [
                          ...filteredArray,
                        ];

                        product_selection[
                          mainIndex
                        ].purpose_of_opening_account.is_changed_by_call_agent = true;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      let isCallAgent = false;
                      if (
                        product_selection[mainIndex].purpose_of_opening_account
                          .call_agent_input
                      ) {
                        isCallAgent = true;
                      }

                      other_opening_purpose[mainIndex] =
                        isCallAgent &&
                        product_selection[
                          mainIndex
                        ].purpose_of_opening_account.call_agent_input.find(
                          (purposeTest) =>
                            purposeTest.key === `purpose_other_specified`
                        )
                          ? product_selection[
                              mainIndex
                            ].purpose_of_opening_account.call_agent_input.find(
                              (purposeTest) =>
                                purposeTest.key === `purpose_other_specified`
                            ).purpose
                          : product_selection[
                              mainIndex
                            ].purpose_of_opening_account.customer_input.find(
                              (purposeTest) =>
                                purposeTest.key === `purpose_other_specified`
                            ).purpose;
                    }
                  "
                />
              </div>
              <div class="mt-4">
                <label
                  >Anticipated Volumes of Deposits from declared Sources (Per Month)<span class="has-text-danger">*</span></label
                >

                <div>
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="
                      isAnticipatedVolModalActive[mainIndex].value = true
                    "
                  >
                    {{ product.anticipated_volume }}
                  </b-radio>
                </div>
                <b-modal
                  v-model="isAnticipatedVolModalActive[mainIndex].value"
                  :width="600"
                  :can-cancel="false"
                >
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isAnticipatedVolModalActive[
                              mainIndex
                            ].value = false;
                            anticipated_volumes_modals[mainIndex] =
                              product.anticipated_volume;
                          }
                        "
                      ></button>
                    </div>
                    <div class="mt-5">
                      <label
                        >Anticipated Volumes of Deposits from declared Sources (Per Month)<span class="has-text-danger">*</span></label
                      >
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div
                          class="columns"
                          style="flex-wrap: wrap; align-items: baseline"
                        >
                          <div
                            class="volume-list-item"
                            v-for="(volume, index) in anticipatedVolumesList"
                            :key="index"
                          >
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="anticipated_volumes"
                                :native-value="volume.value"
                                v-model="anticipated_volumes_modals[mainIndex]"
                              >
                                {{ volume.value }}
                              </b-radio>
                            </b-field>
                          </div>
                        </div>

                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        v-if="
                          product_selection[mainIndex].anticipated_volume
                            .call_agent_input
                            ? product_selection[mainIndex].anticipated_volume
                                .call_agent_input !==
                              anticipated_volumes_modals[mainIndex]
                            : product_selection[mainIndex].anticipated_volume
                                .customer_input !==
                              anticipated_volumes_modals[mainIndex]
                        "
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            if (
                              product_selection[mainIndex].anticipated_volume
                                .customer_input !==
                              anticipated_volumes_modals[mainIndex]
                            ) {
                              product_selection[
                                mainIndex
                              ].anticipated_volume.call_agent_input =
                                anticipated_volumes_modals[mainIndex];
                              product.anticipated_volume =
                                anticipated_volumes_modals[mainIndex];
                              product_selection[
                                mainIndex
                              ].anticipated_volume.is_changed_by_call_agent = true;
                            } else {
                              product_selection[
                                mainIndex
                              ].anticipated_volume.call_agent_input = ``;
                              product.anticipated_volume =
                                anticipated_volumes_modals[mainIndex];
                              product_selection[
                                mainIndex
                              ].anticipated_volume.is_changed_by_call_agent = false;
                            }
                            isAnticipatedVolModalActive[
                              mainIndex
                            ].value = false;
                          }
                        "
                        >Save
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>

              <div
                v-if="
                  product_selection[mainIndex].initial_deposit_amount
                    .customer_input
                "
              >
                <ValidationProvider
                    :rules="{
                      required:true,
                      validateInitialDeposit: true,
                    }"
                    v-slot="{ errors }"
                  >
                  <InputLabel v-if="product.currency_type === 'LKR'"
                    label="Initial Deposit"
                    required=true
                    v-model="product.initial_deposit_amount"
                    styles="mt-5"
                    @input="
                      () => {
                        if (product.initial_deposit_amount.trim() !== ``) {
                          const number = Number(
                            product.initial_deposit_amount
                              .trim()
                              .replaceAll(`,`, ``)
                          );
                          product.initial_deposit_amount = isNaN(number)
                            ? `0`
                            : number.toLocaleString();
                        } else {
                          product.initial_deposit_amount =
                            product.initial_deposit_amount.trim();
                        }
                      }
                    "
                  />
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  :rules="{
                    required: false,
                    validateInitialDepositFC : true,
                  }"
                  v-slot="{ errors }"
                >
                  <InputLabel v-if="product.currency_type != 'LKR'"
                    label="Initial Deposit"
                    v-model="product.initial_deposit_amount"
                    styles="mt-5"
                    @input="
                      () => {
                        if (product.initial_deposit_amount.trim() !== ``) {
                          const number = Number(
                            product.initial_deposit_amount
                              .trim()
                              .replaceAll(`,`, ``)
                          );
                          product.initial_deposit_amount = isNaN(number)
                            ? `0`
                            : number.toLocaleString();
                        } else {
                          product.initial_deposit_amount =
                            product.initial_deposit_amount.trim();
                        }
                      }
                    "
                  />
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <!-- <div class="mt-5">
                  <div>
                    <b-field label-position="inside" custom-class="custom-float-label">
                      <template #label
                        >Initial Deposit<div v-if="product.currency_type ==='LKR'"><span class="has-text-danger">*</span></div>
                      </template>

                      <p class="control has-icons-right">
                        <b-input
                          rounded
                          type="text"
                          :value="
                            formatInitialDepositAmount(
                              product.initial_deposit_amount
                            )
                          "
                        />
                      </p>
                    </b-field>
                  </div>
                </div> -->
                <AgentInputButtons
                  :show-buttons="
                    product_selection[mainIndex].initial_deposit_amount
                      .is_changed_by_call_agent
                      ? product_selection[
                          mainIndex
                        ].initial_deposit_amount.call_agent_input
                          .replaceAll(',', '')
                          .toLocaleString() !==
                        product.initial_deposit_amount
                          .replaceAll(`,`, ``)
                          .toLocaleString()
                      : product_selection[
                          mainIndex
                        ].initial_deposit_amount.customer_input
                          .replaceAll(',', '')
                          .toLocaleString() !==
                        product.initial_deposit_amount
                          .replaceAll(`,`, ``)
                          .toLocaleString()
                  "
                  :save="
                    () => {
                      const number = Number(
                        product.initial_deposit_amount
                          .trim()
                          .replaceAll(`,`, ``)
                      );
                      if (product.currency_type === `LKR` && number === 0) {
                        return;
                      }

                      product_selection[
                        mainIndex
                      ].initial_deposit_amount.call_agent_input = isNaN(number)
                        ? `0`
                        : product.initial_deposit_amount.trim();
                      product_selection[
                        mainIndex
                      ].initial_deposit_amount.is_changed_by_call_agent = true;
                      if (
                        product_selection[mainIndex].initial_deposit_amount
                          .call_agent_input ===
                        product_selection[mainIndex].initial_deposit_amount
                          .customer_input
                      ) {
                        product_selection[
                          mainIndex
                        ].initial_deposit_amount.call_agent_input = ``;
                        product_selection[
                          mainIndex
                        ].initial_deposit_amount.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      product.initial_deposit_amount = product_selection[
                        mainIndex
                      ].initial_deposit_amount.is_changed_by_call_agent
                        ? product_selection[mainIndex].initial_deposit_amount
                            .call_agent_input
                        : product_selection[mainIndex].initial_deposit_amount
                            .customer_input;
                    }
                  "
                />
              </div>
              <hr
                style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
              />
            </div>

            <!-- sms -->
            <div class="is-flex is-justify-content-space-between">
              <div>SMS Alerts<span class="has-text-danger">*</span></div>
              <div>
                <div
                  id="sms-toggle-buttons"
                  class="toggle-button toggle-change has-text-centered"
                  :style="{
                    'background-color': '#f9f9f9',
                    opacity: '0.5',
                    cursor: 'not-allowed',
                  }"
                >
                  <div
                    id="sms-yes-button"
                    class="yes yes-active is-size-5 toggle-common"
                    @click="smsYesClick()"
                  >
                    Yes
                  </div>
                  <div
                    id="sms-no-button"
                    class="no is-size-5 toggle-common"
                    @click="
                      () => {
                        // smsNoClick();
                      }
                    "
                  >
                    No
                  </div>
                </div>
              </div>
            </div>
            <!-- ebanking  -->
            <div class="is-flex is-justify-content-space-between mt-5">
              <div>Online-Banking<span class="has-text-danger">*</span></div>
              <div>
                <div
                  id="e-banking-toggle-buttons"
                  class="toggle-button toggle-change has-text-centered"
                  :style="{
                    'background-color': '#f9f9f9',
                    opacity: '0.5',
                    cursor: 'not-allowed',
                  }"
                >
                  <div
                    id="e-banking-yes-button"
                    class="yes yes-active is-size-5 toggle-common"
                    @click="eBankingYesClick()"
                  >
                    Yes
                  </div>
                  <div
                    id="e-banking-no-button"
                    class="no is-size-5 toggle-common"
                    @click="
                      () => {
                        // eBankingNoClick()
                      }
                    "
                  >
                    No
                  </div>
                </div>
              </div>
            </div>
            <!-- Virtual Wallet -->
            <div v-if="ekycFormData.residentship.customer_input !== 'Out of Sri Lanka'">
              <div class="is-flex is-justify-content-space-between mt-5" >
                <div>Virtual Wallet<span class="has-text-danger">*</span></div>
                <div>
                  <div
                    id="virtual-wallet-toggle-buttons"
                    class="toggle-button toggle-change has-text-centered"
                    :style="{
                      'background-color': '#f9f9f9',
                      opacity: '0.5',
                      cursor: 'not-allowed',
                    }"
                  >
                    <div
                      id="virtual-wallet-yes-button"
                      class="yes yes-active is-size-5 toggle-common"
                      @click="virtualWalletYesClick()"
                    >
                      Yes
                    </div>
                    <div
                      id="virtual-wallet-no-button"
                      class="no no-active is-size-5 toggle-common"
                      @click="
                        () => {
                          // virtualWalletNoClick()
                        }
                      "
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- E-statement -->
            <div class="is-flex is-justify-content-space-between mt-5">
              <div>E-Statement<span class="has-text-danger">*</span></div>
              <div>
                <div
                  id="estatement-toggle-buttons"
                  class="toggle-button toggle-change has-text-centered"
                  :style="{
                    'background-color': '#f9f9f9',
                    opacity: '0.5',
                    cursor: 'not-allowed',
                  }"
                >
                  <div
                    id="estatement-yes-button"
                    class="yes yes-active is-size-5 toggle-common"
                    @click="estatementYesClick()"
                  >
                    Yes
                  </div>
                  <div
                    id="estatement-no-button"
                    class="no is-size-5 toggle-common"
                  >
                    No
                  </div>
                </div>
              </div>
            </div>
            <!-- Debit Card* -->

            <div
              v-if="
                ekycFormData.residentship.customer_input !==
                  'Out of Sri Lanka' && isCurrencyLKR()
              "
            >
              <div class="is-flex is-justify-content-space-between mt-5">
                <div>Debit Card<span class="has-text-danger">*</span></div>
                <div>
                  <div
                    id="debit_card-toggle-buttons"
                    class="toggle-button toggle-change has-text-centered"
                  >
                    <div
                      id="debit_card-yes-button"
                      class="yes yes-active is-size-5 toggle-common"
                      @click="debitCardYesClick"
                    >
                      Yes
                    </div>
                    <div
                      id="debit_card-no-button"
                      disabled
                      class="no no-active is-size-5 toggle-common"
                      @click="
                        () => {
                          debitCardNoClick();
                        }
                      "
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
              <AgentInputButtons
                :show-buttons="
                  (ekycFormData.vas_debit_card.is_changed_by_call_agent ===
                    false &&
                    ekycFormData.vas_debit_card.customer_input !==
                      debitcardserviceText) ||
                  (ekycFormData.vas_debit_card.is_changed_by_call_agent ===
                    true &&
                    ekycFormData.vas_debit_card.call_agent_input !==
                      debitcardserviceText)
                "
                :save="
                  () => {
                    ekycFormData.vas_debit_card.call_agent_input =
                      debitcardserviceText;
                    ekycFormData.vas_debit_card.is_changed_by_call_agent = true;

                    if (ekycFormData.vas_debit_card.call_agent_input) {
                      accountToBeLinkedText = ``;
                      ekycFormData.vas_debit_card_pos_account.call_agent_input = ``;
                      ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent = true;

                      facilitydebitcardcustomernameText = ``;
                      ekycFormData.vas_debit_card_name.call_agent_input = ``;
                      ekycFormData.vas_debit_card_name.is_changed_by_call_agent = true;

                      posrequiredText = ``;
                      tempPosRequiredSelection = ``;
                      posrequiredModalText = ``;
                      ekycFormData.vas_debit_card_pos_required.call_agent_input = ``;
                      ekycFormData.vas_debit_card_pos_required.is_changed_by_call_agent = true;

                      pincollectiondetailsText = ``;
                      temppincollection = ``;
                      pincollectiondetailsModalText = ``;
                      ekycFormData.vas_debit_card_pin_collection_details.call_agent_input =
                        null;
                      ekycFormData.vas_debit_card_pin_collection_details.is_changed_by_call_agent = true;

                      collectiondetailsText = ``;
                      tempcollectiondetails = ``;
                      collectiondetailsModalText = ``;
                      ekycFormData.vas_debit_card_collection_details.call_agent_input =
                        null;
                      ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent = true;
                    } else {
                      ekycFormData.vas_debit_card_pos_account.call_agent_input = ``;
                      ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent = false;

                      ekycFormData.vas_debit_card_name.call_agent_input = ``;
                      ekycFormData.vas_debit_card_name.is_changed_by_call_agent = false;

                      ekycFormData.vas_debit_card_pos_required.call_agent_input = ``;
                      ekycFormData.vas_debit_card_pos_required.is_changed_by_call_agent = false;

                      ekycFormData.vas_debit_card_pin_collection_details.call_agent_input =
                        null;
                      ekycFormData.vas_debit_card_pin_collection_details.is_changed_by_call_agent = false;

                      ekycFormData.vas_debit_card_collection_details.call_agent_input =
                        null;
                      ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent = false;
                    }

                    if (
                      ekycFormData.vas_debit_card.call_agent_input ===
                      ekycFormData.vas_debit_card.customer_input
                    ) {
                      ekycFormData.vas_debit_card.call_agent_input = false;
                      ekycFormData.vas_debit_card.is_changed_by_call_agent = false;

                      if (!ekycFormData.vas_debit_card.customer_input) {
                        accountToBeLinkedText = ``;
                        ekycFormData.vas_debit_card_pos_account.call_agent_input = ``;
                        ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent = false;

                        facilitydebitcardcustomernameText = ``;
                        ekycFormData.vas_debit_card_name.call_agent_input = ``;
                        ekycFormData.vas_debit_card_name.is_changed_by_call_agent = false;

                        posrequiredText = ``;
                        posrequiredModalText = ``;
                        ekycFormData.vas_debit_card_pos_required.call_agent_input = ``;
                        ekycFormData.vas_debit_card_pos_required.is_changed_by_call_agent = false;

                        pincollectiondetailsText = ``;
                        pincollectiondetailsModalText = ``;
                        ekycFormData.vas_debit_card_pin_collection_details.call_agent_input =
                          null;
                        ekycFormData.vas_debit_card_pin_collection_details.is_changed_by_call_agent = false;

                        collectiondetailsText = ``;
                        collectiondetailsModalText = ``;
                        ekycFormData.vas_debit_card_collection_details.call_agent_input =
                          null;
                        ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent = false;
                      }
                    }
                  }
                "
                :cancel="
                  () => {
                    if (!debitcardserviceText) {
                      debitCardYesClick();
                    } else if (debitcardserviceText) {
                      debitCardNoClick();
                    }
                    debitcardserviceText =
                      ekycFormData.vas_debit_card.is_changed_by_call_agent ===
                      true
                        ? ekycFormData.vas_debit_card.call_agent_input
                        : ekycFormData.vas_debit_card.customer_input;
                  }
                "
              />

              <div
                class="mt-4"
                v-if="
                  ekycFormData.vas_debit_card.is_changed_by_call_agent
                    ? ekycFormData.vas_debit_card.call_agent_input
                    : ekycFormData.vas_debit_card.customer_input
                "
              >
                <label>POS Required<span class="has-text-danger">*</span></label>

                <div v-if="posrequiredText">
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="isPosrequiredModalActive = true"
                  >
                    Yes
                  </b-radio>
                </div>
                <div v-if="posrequiredText === false">
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="isPosrequiredModalActive = true"
                  >
                    No
                  </b-radio>
                </div>
                <div v-if="posrequiredText === ``">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns">
                      <div class="column is-one-two">
                        <div class="columns">
                          <div class="column is-one-two">
                            <b-field>
                              <b-radio
                                class="en-style"
                                @click.native="isPosrequiredModalActive = true"
                                name="POS Required"
                                :native-value="true"
                                v-model="tempPosRequiredSelection"
                              >
                                Yes
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column is-one-two">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="POS Required"
                                :native-value="false"
                                @click.native="isPosrequiredModalActive = true"
                                v-model="tempPosRequiredSelection"
                              >
                                No
                              </b-radio>
                            </b-field>
                          </div>
                        </div>
                      </div>
                      <div class="column is-one-two"></div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <b-modal
                  v-model="isPosrequiredModalActive"
                  :width="540"
                  :can-cancel="false"
                >
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isPosrequiredModalActive = false;
                            posrequiredModalText = posrequiredText;
                            tempPosRequiredSelection = ``;
                          }
                        "
                      ></button>
                    </div>
                    <div class="mt-5">
                      <label>POS Required<span class="has-text-danger">*</span></label>
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div class="columns">
                          <div class="column is-one-two">
                            <div class="columns">
                              <div class="column is-one-two">
                                <b-field>
                                  <b-radio
                                    class="en-style"
                                    name="POS Required"
                                    :native-value="true"
                                    v-model="posrequiredModalText"
                                  >
                                    Yes
                                  </b-radio>
                                </b-field>
                              </div>
                              <div class="column is-one-two">
                                <b-field>
                                  <b-radio
                                    class="en-style"
                                    name="POS Required"
                                    :native-value="false"
                                    v-model="posrequiredModalText"
                                  >
                                    No
                                  </b-radio>
                                </b-field>
                              </div>
                            </div>
                          </div>
                          <div class="column is-one-two"></div>
                        </div>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        v-if="
                          ekycFormData.vas_debit_card_pos_required
                            .is_changed_by_call_agent
                            ? ekycFormData.vas_debit_card_pos_required
                                .call_agent_input !== posrequiredModalText
                            : ekycFormData.vas_debit_card_pos_required
                                .customer_input !== posrequiredModalText
                        "
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            if (
                              ekycFormData.vas_debit_card_pos_required
                                .customer_input !== posrequiredModalText
                            ) {
                              ekycFormData.vas_debit_card_pos_required.call_agent_input =
                                posrequiredModalText;
                              posrequiredText = posrequiredModalText;
                              ekycFormData.vas_debit_card_pos_required.is_changed_by_call_agent = true;
                            } else {
                              ekycFormData.vas_debit_card_pos_required.call_agent_input = ``;
                              posrequiredText = posrequiredModalText;
                              ekycFormData.vas_debit_card_pos_required.is_changed_by_call_agent = false;
                            }
                            isPosrequiredModalActive = false;
                            tempPosRequiredSelection = ``;
                          }
                        "
                        >Save
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>
              <!-- account_to_be_linked -->

              <div
                class="mt-4"
                v-if="
                  (ekycFormData.vas_debit_card.is_changed_by_call_agent
                    ? ekycFormData.vas_debit_card.call_agent_input
                    : ekycFormData.vas_debit_card.customer_input) &&
                  (ekycFormData.vas_debit_card_pos_required
                    .is_changed_by_call_agent
                    ? ekycFormData.vas_debit_card_pos_required.call_agent_input
                    : ekycFormData.vas_debit_card_pos_required.customer_input)
                "
              >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      accountToBeLinkedText === '' &&
                      !isPosTransactionInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label
                      >Account to be linked for POS transactions<span class="has-text-danger">*</span></template
                    >
                    <b-select
                      expanded
                      rounded
                      v-model="accountToBeLinkedText"
                      :class="
                        accountToBeLinkedText === '' &&
                        !isPosTransactionInputFocus
                          ? 'arrow-up-before'
                          : 'arrow-up'
                      "
                      @focus="
                        () => {
                          isPosTransactionInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isPosTransactionInputFocus = false;
                        }
                      "
                    >
                      <option
                        v-for="(option, posIndex) in pos_options"
                        :key="posIndex"
                        :value="option"
                      >
                        {{ option }}
                      </option></b-select
                    > </b-field
                  ><span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.vas_debit_card_pos_account
                      .is_changed_by_call_agent &&
                      ekycFormData.vas_debit_card_pos_account.customer_input !==
                        accountToBeLinkedText) ||
                    (ekycFormData.vas_debit_card_pos_account
                      .is_changed_by_call_agent &&
                      ekycFormData.vas_debit_card_pos_account
                        .call_agent_input !== accountToBeLinkedText)
                  "
                  :save="
                    () => {
                      ekycFormData.vas_debit_card_pos_account.call_agent_input =
                        accountToBeLinkedText;
                      ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.vas_debit_card_pos_account
                          .call_agent_input ===
                        ekycFormData.vas_debit_card_pos_account.customer_input
                      ) {
                        ekycFormData.vas_debit_card_pos_account.call_agent_input = ``;
                        ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      accountToBeLinkedText = ekycFormData
                        .vas_debit_card_pos_account.call_agent_input
                        ? ekycFormData.vas_debit_card_pos_account
                            .call_agent_input
                        : ekycFormData.vas_debit_card_pos_account
                            .customer_input;
                    }
                  "
                />
              </div>

              <div
                v-if="
                  (ekycFormData.vas_debit_card.is_changed_by_call_agent
                    ? ekycFormData.vas_debit_card.call_agent_input
                    : ekycFormData.vas_debit_card.customer_input) &&
                  (ekycFormData.vas_debit_card_pos_required
                    .is_changed_by_call_agent
                    ? ekycFormData.vas_debit_card_pos_required.call_agent_input
                    : ekycFormData.vas_debit_card_pos_required.customer_input)
                "
              >
                <InputLabel
                  label="Name to be printed on card"
                  required="true"
                  v-model="facilitydebitcardcustomernameText"
                  :validationRules="validateName()"
                  fieldName="This field"
                  styles="mt-5"
                  @getError="
                    (error) => {
                      nameToPrintedValidationProviderError = error;
                    }
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    (!ekycFormData.vas_debit_card_name
                      .is_changed_by_call_agent &&
                      ekycFormData.vas_debit_card_name.customer_input !==
                        facilitydebitcardcustomernameText) ||
                    (ekycFormData.vas_debit_card_name
                      .is_changed_by_call_agent &&
                      ekycFormData.vas_debit_card_name.call_agent_input !==
                        facilitydebitcardcustomernameText)
                  "
                  :save="
                    () => {
                      ekycFormData.vas_debit_card_name.call_agent_input =
                        facilitydebitcardcustomernameText;
                      ekycFormData.vas_debit_card_name.is_changed_by_call_agent = true;
                      if (
                        ekycFormData.vas_debit_card_name.call_agent_input ===
                        ekycFormData.vas_debit_card_name.customer_input
                      ) {
                        ekycFormData.vas_debit_card_name.call_agent_input = ``;
                        ekycFormData.vas_debit_card_name.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      facilitydebitcardcustomernameText = ekycFormData
                        .vas_debit_card_name.call_agent_input
                        ? ekycFormData.vas_debit_card_name.call_agent_input
                        : ekycFormData.vas_debit_card_name.customer_input;
                    }
                  "
                  :disable="nameToPrintedValidationProviderError"
                />
              </div>

              <div
                class="mt-4"
                v-if="
                  ekycFormData.vas_debit_card.is_changed_by_call_agent
                    ? ekycFormData.vas_debit_card.call_agent_input
                    : ekycFormData.vas_debit_card.customer_input
                "
              >
                <label>Card Delivery Details<span class="has-text-danger">*</span></label>

                <div v-if="collectiondetailsText !== ``">
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="isCollectiondetailsModalActive = true"
                  >
                    {{ collectiondetailsText }}
                  </b-radio>
                </div>
                <div v-if="collectiondetailsText === ``">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="Collection Details "
                            @click.native="
                              isCollectiondetailsModalActive = true
                            "
                            native-value="Branch"
                            v-model="tempcollectiondetails"
                          >
                            Branch
                          </b-radio>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="Collection Details "
                            native-value="Residence Address"
                            @click.native="
                              isCollectiondetailsModalActive = true
                            "
                            v-model="tempcollectiondetails"
                          >
                            Residence Address
                          </b-radio>
                        </b-field>
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <b-modal
                  v-model="isCollectiondetailsModalActive"
                  :width="540"
                  :can-cancel="false"
                >
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isCollectiondetailsModalActive = false;
                            collectiondetailsModalText = collectiondetailsText;
                            tempcollectiondetails = ``;
                          }
                        "
                      ></button>
                    </div>
                    <div class="mt-5">
                      <label>Card Delivery Details<span class="has-text-danger">*</span></label>
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div class="columns">
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="Collection Details "
                                native-value="Branch"
                                v-model="collectiondetailsModalText"
                              >
                                Branch
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="Collection Details "
                                native-value="Residence Address"
                                v-model="collectiondetailsModalText"
                              >
                                Residence Address
                              </b-radio>
                            </b-field>
                          </div>
                        </div>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        v-if="
                          ekycFormData.vas_debit_card_collection_details
                            .is_changed_by_call_agent
                            ? ekycFormData.vas_debit_card_collection_details
                                .call_agent_input !== collectiondetailsModalText
                            : ekycFormData.vas_debit_card_collection_details
                                .customer_input !== collectiondetailsModalText
                        "
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            if (
                              ekycFormData.vas_debit_card_collection_details
                                .customer_input !== collectiondetailsModalText
                            ) {
                              ekycFormData.vas_debit_card_collection_details.call_agent_input =
                                collectiondetailsModalText;
                              collectiondetailsText =
                                collectiondetailsModalText;
                              ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent = true;
                            } else {
                              ekycFormData.vas_debit_card_collection_details.call_agent_input =
                                null;
                              collectiondetailsText =
                                collectiondetailsModalText;
                              ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent = false;
                            }
                            isCollectiondetailsModalActive = false;
                            tempcollectiondetails = ``;
                            vasDeliveryaddressCheck();
                          }
                        "
                        >Save
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>

              <div
                class="mt-4"
                v-if="
                  ekycFormData.vas_debit_card.is_changed_by_call_agent
                    ? ekycFormData.vas_debit_card.call_agent_input
                    : ekycFormData.vas_debit_card.customer_input
                "
              >
                <label>PIN Delivery Details<span class="has-text-danger">*</span></label>

                <div v-if="pincollectiondetailsText !== ``">
                  <b-radio
                    class="en-style"
                    checked
                    @click.native="isPinCollectiondetailsModalActive = true"
                  >
                    {{ pincollectiondetailsText }}
                  </b-radio>
                </div>
                <div v-if="pincollectiondetailsText === ``">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="Pin Collection Details"
                            native-value="Branch(for PIN collection)"
                            @click.native="
                              isPinCollectiondetailsModalActive = true
                            "
                            v-model="temppincollection"
                          >
                            Branch(for PIN collection)
                          </b-radio>
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="PIN Collection Details"
                            @click.native="
                              isPinCollectiondetailsModalActive = true
                            "
                            native-value="Residence Address(for PIN collection)"
                            v-model="temppincollection"
                          >
                            Residence Address(for PIN collection)
                          </b-radio>
                        </b-field>
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <b-modal
                  v-model="isPinCollectiondetailsModalActive"
                  :width="540"
                  :can-cancel="false"
                >
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isPinCollectiondetailsModalActive = false;
                            pincollectiondetailsModalText =
                              pincollectiondetailsText;
                            temppincollection = ``;
                          }
                        "
                      ></button>
                    </div>
                    <div class="mt-5">
                      <label>PIN Delivery Details<span class="has-text-danger">*</span></label>
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div class="columns">
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="Pin Collection Details"
                                native-value="Branch(for PIN collection)"
                                v-model="pincollectiondetailsModalText"
                              >
                                Branch(for PIN collection)
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="Pin Collection Details"
                                native-value="Residence Address(for PIN collection)"
                                v-model="pincollectiondetailsModalText"
                              >
                                Residence Address(for PIN collection)
                              </b-radio>
                            </b-field>
                          </div>
                        </div>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        v-if="
                          ekycFormData.vas_debit_card_pin_collection_details
                            .is_changed_by_call_agent
                            ? ekycFormData.vas_debit_card_pin_collection_details
                                .call_agent_input !==
                              pincollectiondetailsModalText
                            : ekycFormData.vas_debit_card_pin_collection_details
                                .customer_input !==
                              pincollectiondetailsModalText
                        "
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            if (
                              ekycFormData.vas_debit_card_pin_collection_details
                                .customer_input !==
                              pincollectiondetailsModalText
                            ) {
                              ekycFormData.vas_debit_card_pin_collection_details.call_agent_input =
                                pincollectiondetailsModalText;
                              pincollectiondetailsText =
                                pincollectiondetailsModalText;
                              ekycFormData.vas_debit_card_pin_collection_details.is_changed_by_call_agent = true;
                            } else {
                              ekycFormData.vas_debit_card_pin_collection_details.call_agent_input =
                                pincollectiondetailsModalText;
                              pincollectiondetailsText =
                                pincollectiondetailsModalText;
                              ekycFormData.vas_debit_card_pin_collection_details.is_changed_by_call_agent = false;
                            }
                            isPinCollectiondetailsModalActive = false;
                            temppincollection = ``;
                            vasDeliveryaddressCheck();
                          }
                        "
                        >Save
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>

            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />

            <div class="columns">
              <div class="column">
                <InputLabel
                  required="true"
                  label="Preferred Date"
                  :value="getFormattedDateTime(ekycFormData.video_call_date)"
                  disabled="true"
                />
              </div>
              <div class="column">
                <InputLabel
                  required="true"
                  label="Preferred Time"
                  disabled="true"
                  v-model="ekycFormData.video_call_time"
                />
              </div>
            </div>
            <hr
              style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
            />
            <p class="mt-4" style="font-size: 18px">
              Upload images of your National Identity Card
            </p>
            <div class="columns">
              <div class="column" v-if="ekycFormData.nic_front_attachment.attachment_url !== ''">
                <ImageBox v-if="ekycFormData.nic_front_attachment.attachment_type !== 'pdf'"
                  label="NIC Front Image"
                  :imageName="ekycFormData.nic_front_attachment.attachment_url"
                  styles="mt-3"
                />
                <div v-else class="image-box-wrapper">
                  <ImageBox 
                    label="NIC Front Image"
                    :imageName="pdfPlaceholder"
                    styles="mt-3"
                  />
                  <button class="download-button"
                    @click="downloadpdf(ekycFormData.nic_front_attachment.attachment_url, 'NIC Front Image')">Download</button>
                </div>
              </div>
              <div class="column" v-if="ekycFormData.nic_back_attachment.attachment_url !== ''">
                <ImageBox v-if="ekycFormData.nic_back_attachment.attachment_type !== 'pdf'"
                  label="NIC Rear Image"
                  :imageName="ekycFormData.nic_back_attachment.attachment_url"
                  styles="mt-3"
                />
                <div v-else class="image-box-wrapper">
                  <ImageBox 
                    label="NIC Rear Image"
                    :imageName="pdfPlaceholder"
                    styles="mt-3"
                  />
                  <button class="download-button"
                    @click="downloadpdf(ekycFormData.nic_back_attachment.attachment_url, 'NIC Rear Image')">Download</button>
                </div>
              </div>
            </div>
            <p
              class="mt-4"
              style="font-size: 18px"
              v-if="ekycFormData.passport_bio_page_attachment.attachment_url !== ``"
            >
              Upload images of your Passport
            </p>

            <div class="columns">
              <div
                class="column"
                v-if="ekycFormData.passport_bio_page_attachment.attachment_url !== ``"
              >
                <ImageBox v-if="ekycFormData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                  label="Passport Bio Page"
                  :imageName="ekycFormData.passport_bio_page_attachment.attachment_url"
                  styles="mt-3"
                />
                <div v-else class="image-box-wrapper">
                  <ImageBox 
                    label="Passport Bio Page"
                    :imageName="pdfPlaceholder"
                    styles="mt-3"
                  />
                  <button class="download-button"
                    @click="downloadpdf(ekycFormData.passport_bio_page_attachment.attachment_url, 'Passport Bio Page')">Download</button>
                </div>
              </div>
              <div
                class="column"
                v-if="
                  ekycFormData.passport_alteration_attachment.attachment_url !== ``
                "
              >
                <ImageBox v-if="ekycFormData.passport_alteration_attachment.attachment_type !== 'pdf'"
                  label="Passport Alterations Page"
                  :imageName="ekycFormData.passport_alteration_attachment.attachment_url"
                  styles="mt-3"
                />
                <div v-else class="image-box-wrapper">
                  <ImageBox 
                    label="Passport Alterations Page"
                    :imageName="pdfPlaceholder"
                    styles="mt-3"
                  />
                  <button class="download-button"
                    @click="downloadpdf(ekycFormData.passport_alteration_attachment.attachment_url, 'Passport Alterations Page')">Download</button>
                </div>
              </div>
            </div>
            <div
              v-if="
                ekycFormData.passport_entry_stamp_attachment.attachment_url !== ``
              "
            >
              <ImageBox v-if="ekycFormData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                label="Passport entry stamp page"
                :imageName="ekycFormData.passport_entry_stamp_attachment.attachment_url"
                styles="mt-3"
              />
              <div v-else class="image-box-wrapper">
                <ImageBox 
                  label="Passport entry stamp page"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
                <button class="download-button"
                  @click="downloadpdf(ekycFormData.passport_entry_stamp_attachment.attachment_url, 'Passport entry stamp')">Download</button>
              </div>
            </div>
            <div v-if="ekycFormData.visa_attachment.attachment_url !== ``">
              <ImageBox v-if="ekycFormData.visa_attachment.attachment_type !== 'pdf'"
                label="Upload a Visa"
                :imageName="ekycFormData.visa_attachment.attachment_url"
                styles="mt-3"
              />
              <div v-else class="image-box-wrapper">
                <ImageBox 
                  label="Upload a Visa"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
                <button class="download-button"
                  @click="downloadpdf(ekycFormData.visa_attachment.attachment_url, 'Visa')">Download</button>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <ImageBox v-if="ekycFormData.selfie_attachment.attachment_type !== `pdf`"
                  label="Upload a Selfie"
                  :imageName="ekycFormData.selfie_attachment.attachment_url"
                  styles="mt-3"
                />
                <div v-else class="image-box-wrapper">
                  <ImageBox 
                    label="Upload a Selfie"
                    :imageName="pdfPlaceholder"
                    styles="mt-3"
                  />
                  <button class="download-button"
                    @click="downloadpdf(ekycFormData.selfie_attachment.attachment_url, 'Selfie')">Download</button>
                </div>
              </div>
              <div class="column">
                <ImageBox
                  label="Upload an image of your signature"
                  :imageName="ekycFormData.signature_image"
                  styles="mt-3"
                />
              </div>
            </div>
            <ImageBox v-if="ekycFormData.address_proof_attachment.attachment_type !== `pdf`"
              label="Upload proof of address"
              :imageName="ekycFormData.address_proof_attachment.attachment_url"
              styles="mt-3"
            />
            <div v-else class="image-box-wrapper">
              <ImageBox 
                label="Upload proof of address"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
              <button class="download-button"
                @click="downloadpdf(ekycFormData.address_proof_attachment.attachment_url, 'Proof of Address')">Download</button>
            </div>
            <div v-if="ekycFormData.hold_application_attachments != null">
              <div
                v-for="(
                  item, index
                ) in ekycFormData.hold_application_attachments"
                :key="item.path"
              >
                <div style="margin-top: 20px">
                  <div
                    class="coer"
                    rounded
                    style="
                      min-width: 28.2vw;
                      border: 2px solid #000000;
                      border-radius: 25px;
                      padding: 20px;
                    "
                  >
                    <label>Image Attachment {{ index + 1 }}</label
                    ><br />
                    <img
                      :src="item.path"
                      style="max-height: 53vh"
                      resposive
                      alt="Attachment"
                    /><br />
                    <label>Comment : </label>
                    <b-input
                      class="rounded-input"
                      rounded
                      readonly="true"
                      type="textarea"
                      v-model="item.comment"
                      placeholder="Add Comment/s"
                      rows="3"
                      style="width: 100%"
                    ></b-input>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div>
              <b-button
                class="py-5 mt-4"
                type="is-primary"
                expanded
                rounded
                :loading="isSubmitBtnLoading"
                @click="submitAgentChangedKycForm()"
                :disabled="
                  invalid || countryError || nameOfPepValidationProviderError || designationOfPepValidationProviderError ||
                  !isVideoCallEkycFormUpdatedByAgent || streetValidationProviderError || taxNumValidationProviderError || townValidationProviderError ||
                  formerror || corTownValidationProviderError || corStreetValidationProviderError || cordistrictValidationProviderError || occupationValidationError || isoccupationTouched || isIndustryTouched ||
                  isAccountNameDuplicated || ((ekycFormData.are_you_a_tax_payer.is_changed_by_call_agent ? ekycFormData.are_you_a_tax_payer.call_agent_input : ekycFormData.are_you_a_tax_payer.customer_input) && (ekycFormData.tax_file_numer.is_changed_by_call_agent ? !ekycFormData.tax_file_numer.call_agent_input.trim() : !ekycFormData.tax_file_numer.customer_input.trim()))
                "
                >Submit</b-button
              >
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import InputLabel from "../components/InputLabel.vue";
import ImageBox from "../components/ImageBox.vue";
import AgentInputButtons from "../components/AgentInputButtons.vue";
import SelectLabel from "../components/SelectLabel.vue";
import PurposeOfAccountOpening from "@/helpers/classes/ProductSelection/PurposeOfOpening";
import NetworkManager from "../network";
import { useVideoKycStore } from "../store/videoKycStore";
import { BuefyHelper } from "../helpers/bufeyHelper";
import { DateTimeManger } from "../helpers/dateTimeManger";

export default {
  name: "VideoCallEkyc",
  components: {
    InputLabel,
    ImageBox,
    AgentInputButtons,
    SelectLabel,
  },
  props: ["ekycFormData", "product_selection"],

  setup() {
    const videoKycStore = useVideoKycStore();
    return { videoKycStore };
  },

  data() {
    return {
      autoCompleteDownArrowImage : require("@/assets/Images/Icons/Stroke-3.png"),
      calendarIcon : require("@/assets/Images/Icons/Calendar.png"),
      pdfPlaceholder : require("@/assets/Images/pdf.png"),
      countryError: false,
      isOfficeNumValid: true,
      isOfficeNumberTouched: false,
      isTelNumberStartsWithZero: false,
      isAccountNameDuplicated: false,
      isresidentinSL: false,
      tempPosRequiredSelection: "",
      temppincollection: "",
      tempcollectiondetails: "",
      tempfreelancer: "",
      tempWorkingPlatform: [],
      isSubmitBtnLoading: false,
      rate: 0,
      isShow: false,
      isEmailShow: false,
      pos_options: [],
      pep_object: {},
      fullNameValidationProviderError: "",
      mobileNumberValidationProviderError: "",
      telNumberValidationProviderError: "",
      idValidationProviderError: "",
      passportNumValidationProviderError: "",
      buldingNumValidationProviderError: "",
      buldingNameValidationProviderError: "",
      streetValidationProviderError: "",
      secStreetValidationProviderError:"",
      townValidationProviderError: "",
      nameOfPepValidationProviderError: "",
      designationOfPepValidationProviderError: "",
      postalCodeValidationProviderError: "",
      districtValidationProviderError: "",
      corbuildingnumberValidationProviderError: "",
      corBuildingNameValidationProviderError: "",
      corStreetValidationProviderError: "",
      corTwoStreetValidationProviderError:"",
      corTownValidationProviderError: "",
      corPostalCodeValidationProviderError: "",
      cordistrictValidationProviderError: "",
      occupationValidationError : false,

      mailValidationProviderError: "",
      mailSecValidationProviderError: "",
      motherNameValidationProviderError: "",
      employerNameValidationProviderError: "",
      taxNumValidationProviderError: "",
      specifiedsourceofincomeValidationProviderError: "",
      specifieworkPlatformValidationProviderError: "",
      reasonValidationProviderError: "",
      nameToPrintedValidationProviderError: "",
      employmentStatusList: [
        {
          value: "Full-Time",
          label: "Full-Time",
        },
        {
          value: "Part Time",
          label: "Part Time",
        },
        {
          value: "Self-Employed",
          label: "Self-Employed",
        },
        {
          value: "Freelance",
          label: "Freelance",
        },
        {
          value: "Probation",
          label: "Probation",
        },
        {
          value: "Unemployed",
          label: "Unemployed",
        },
        {
          value: "Retired",
          label: "Retired",
        },
        {
          value: "Housewife",
          label: "Housewife",
        },
        {
          value: "Student",
          label: "Student",
        },
      ],
      titleList: [
        {
          value: "Dr",
          label: "Dr",
        },
        {
          value: "Master",
          label: "Master",
        },
        {
          value: "Professor",
          label: "Professor",
        },
        {
          value: "Rev",
          label: "Rev",
        },
        {
          value: "Mr",
          label: "Mr",
        },
        {
          value: "Mrs",
          label: "Mrs",
        },
        {
          value: "Ms",
          label: "Ms",
        },
        {
          value: "Miss",
          label: "Miss",
        },
      ],
      nationalityList: [
        {
          value: "Sri Lankan",
          label: "Sri Lankan",
        },
        {
          value: "Sri Lankan with Dual Citizenship",
          label: "Sri Lankan with Dual Citizenship",
        },
        // {
        //   value: "Foreign National",
        //   label: "Foreign National",
        // },
        // {
        //   value: "Foreign National with Dual Citizenship",
        //   label: "Foreign National with Dual Citizenship",
        // },
      ],
      currencyList: [
        {
          value: "AUD",
          label: "AUD",
        },
        {
          value: "CAD",
          label: "CAD",
        },
        {
          value: "CHF",
          label: "CHF",
        },
        {
          value: "CNY",
          label: "CNY",
        },
        {
          value: "EUR",
          label: "EUR",
        },
        {
          value: "GBP",
          label: "GBP",
        },
        {
          value: "JPY",
          label: "JPY",
        },
        {
          value: "LKR",
          label: "LKR",
        },
        {
          value: "SGD",
          label: "SGD",
        },
        {
          value: "USD",
          label: "USD",
        },
        {
          value: "SEK",
          label: "SEK",
        },
        {
          value: "THB",
          label: "THB",
        },
        {
          value: "HKD",
          label: "HKD",
        },
        {
          value: "DKK",
          label: "DKK",
        },
        {
          value: "NOK",
          label: "NOK",
        },
        {
          value: "NZD",
          label: "NZD",
        },
        {
          value: "INR",
          label: "INR",
        },
      ],
      purposeList: [
        {
          key: "purpose_one",
          value: "Salary Remittance",
        },
        { key: "purpose_two", value: "Business" },
        {
          key: "purpose_three",
          value: "Loan Repayment",
        },
        { key: "purpose_four", value: "Savings" },
        {
          key: "purpose_five",
          value: "Investments",
        },
        {
          key: "purpose_six",
          value: "Education",
        },
        {
          key: "purpose_seven",
          value: "Interest Credits",
        },
        {
          key: "purpose_eight",
          value: "Utility Bill Payment",
        },
        { key: "purpose_other", value: "Other" },
      ],
      anticipatedVolumesList: [
        { value: "Less than 100,000" },
        { value: "100,001 - 500,000" },
        { value: "500,001 - 1,000,000" },
        { value: "1,000,001 - 5,000,000" },
        { value: "Above 5,000,001" },
      ],

      workeList: ["Upwork", "Fiverr", "Freelancer", "Other"],
      sourceOfFundList: [
        "Employment Income",
        "Investment Proceeds",
        "Gifts",
        "Inheritance",
        "Commission Income",
        "Sale of Property / Asset",
        "Family Remittance",
        "Donation",
        "Other",
      ],
      regex: {
        old_nic: /^([0-9]{9}[Xx||Vv])$/,
        passport: /^[0-9A-Za-z]{4,18}$/,
        new_nic: /[0-9]{12}/,
      },
      smsToggleButton: "",
      smsYesButton: "",
      smsNoButton: "",
      eBankingToggleButton: "",
      eBankingYesButton: "",
      eBankingNoButton: "",
      virtualWalletToggleButton: "",
      virtualWalletYesButton: "",
      virtualWalletNoButton: "",
      estatementToggleButton: "",
      estatementYesButton: "",
      estatementNoButton: "",
      debitCardToggleButton: "",
      debitCardYesButton: "",
      debitCardNoButton: "",
      maxDate: new Date(),
      minDate: new Date(),
      dob: false,
      pexpiryDate: false,
      pIssueDate: false,
      nicIssueDate: false,
      vediodateFocus: false,
      telFocus: false,
      mobileFocus: false,
      maxDob: new Date(),
      userCodeEdit: "",
      iconStringEdit: "",
      statusofresidenceText: "",
      emailText: "",
      isMobileNumberEditStartsWithZero: false,
      countryCodeArray: [],
      shortNameEdit: "",
      isMobileNumberValidEdit: false,
      mobileNumberEdit: "",
      isCountryCodeTouched: false,
      agentFullMobileNum: "",

      userOfficeCode: "",
      isTelCountryCodeTouched: false,
      teleShortNameEdit: "",
      countThird: 0,
      officeIconString: "",
      teleNumber: "",
      teleCountryCode: "",
      isTeleNumberInputFocus: false,
      agentTeleFullNo: "",

      isMobileNumberInputFocus: false,
      isEmploymentStatusFocus: false,
      isMobileNumberTouched: false,

      isPerAddCountryFocus: false,
      permanentaddresscountry: "",
      permanentaddresscountrycount: 0,
      isPermanentAddressCountryTouched: false,

      isCorrespondenceAddCountryFocus: false,
      isPosTransactionInputFocus: false,
      correspondenceaddresscountry: "",
      correspondenceaddresscountrycount: 0,
      isCorrespondenceAddressCountryTouched: false,

      employeraddresscountry: "",
      isEmployerAddressCountryFocus: false,
      employeraddresscountrycount: 0,
      isEmployerAddressCountryTouched: false,

      isindustryFocus: false,
      industry: "",
      isIndustryTouched: false,
      industries: [],
      industrySelected: "",

      occupation: "",
      occupations: [],
      isoccupationFocus: false,
      isoccupationTouched: false,
      occupationSelected: "",

      isPerAddDistrictFocus: false,
      isPermanentAddressDistrictTouched: false,
      permanentaddressdistrictcount: 0,
      isCorAddDistrictFocus: false,
      isCoressAddressDistrictTouched: false,
      corresaddressdistrictcount: 0,
      isEmplAddDistrictFocus: false,
      isEmplAddressDistrictTouched: false,
      empladdressdistrictcount: 0,

      genderModalText: "",
      accountToBeLinkedText: "",
      maritalstatusModalText: "",
      branchText: "",
      areyouataxpayerModalText: "",
      areyouaFreelancerModalText: "",
      statusofresidenceModalText: "",
      politicallyexposedModalText: "",
      sourceofincomeTextModal: [],
      purposeofopeningMobalText: [],
      secondpurposeofopeningModalText: "",
      thirdpurposeofopeningMobalText: "",
      anticipatedvolumesModalText: "",
      secondanticipatedvolModalText: "",
      thirdanticipatedvolModalText: "",
      posrequiredModalText: "",
      collectiondetailsModalText: "",
      pincollectiondetailsModalText: "",
      pepDeclarationTextName: [],
      pepDeclarationTextDesignation: [],
      titleText: "",
      fullnameText: "",
      genderText: "",
      customernicText: "",
      customerpassportnoText: "",
      nationalityText: "",
      maritalstatusText: "",
      dateOfBirthCustomerInput: new Date(),
      dateOfBirthText: new Date(),
      passportExpiryDateCustomerInput: new Date(),
      passportExpiryDateText: new Date(),
      passportIssueDateCustomerInput: new Date(),
      passportIssueDateText: new Date(),
      nicIssueDateCustomerInput: new Date(),
      nicIssueDateText: new Date(),
      permanentaddressline1Text: "",
      permanentaddressline2Text: "",
      permanentaddressline3Text: "",
      permanentaddressStreetText:"",
      permanentaddresstownText: "",
      permanentaddresspostalcodeText: "",
      permanentaddressdistrict: "",
      permanentaddresscountryText: "",
      correspondenceaddressline1Text: "",
      correspondenceaddressline2Text: "",
      correspondenceaddressline3Text: "",
      correspondenceaddressStreetTwoText:"",
      correspondenceaddresstownText: "",
      correspondenceaddresspostalcodeText: "",
      correspondenceaddressdistrictText: "",
      correspondenceaddresscountryText: "",
      maidennameText: "",
      employmentstatusText: "",
      natureofbusinessText: "",
      areyouafreelancerText: "",
      employernameText: "",
      employeraddressline1Text: "",
      employeraddressline2Text: "",
      employeraddressline3Text: "",
      employeraddresstownText: "",
      employeraddresspostalcodeText: "",
      employeraddressdistrictText: "",
      employeraddresscountryText: "",
      areyouataxpayerText: "",
      taxfilenumText: "",

      sourceofincomeText: [],
      workingPlatformText: [],
      workingPlatformTextModal: [],
      specifiedsourceofincomeText: "",
      reasonforopeninganaccountText: "",
      currencyText: "",
      initialdepositText: "",
      emailSecondText: "",
      specifiedworkplatformText: "",
      secondinitialdepositText: "",
      thirdinitialdepositText: "",
      facilitydebitcardcustomernameText: "",
      politicallyexposedText: "",
      thirdcurrencyText: "",
      secondcurrencyText: "",
      purposeofopeningText: [],
      secondpurposeofopeningText: "",
      thirdpurposeofopeningText: "",
      anticipatedvolumesText: "",
      secondanticipatedvolText: "",
      thirdanticipatedvolText: "",
      posrequiredText: "",
      collectiondetailsText: "",
      debitcardserviceText: "",
      videocalldateText: new Date(),
      videocalldateCustomerInput: new Date(),
      pincollectiondetailsText: "",
      purpose_of_opening_modals: [],
      other_opening_purpose: [],
      anticipated_volumes_modals: [],
      account_modals: [],
      count: 0,

      isGenderModalActive: false,
      isMaritalstatusModalActive: false,
      isStatusOfResidentsModalActive: false,
      isFreelancerModalActive: false,
      isTaxpayerModalActive: false,
      isPoliticallyexposedModalActive: false,
      isSourceofincomeModalActive: false,
      isworkingPlatformModalActive: false,
      isPurposeofopeningModalActive: [],

      isSecondPurposeofopeningModalActive: false,
      isThirdPurposeofopeningModalActive: false,
      isAnticipatedVolModalActive: [],
      isProductModalActive: [],
      issecoundanticipatedvolModalActive: false,
      isthirdanticipatedvolModalActive: false,
      isPosrequiredModalActive: false,
      isCollectiondetailsModalActive: false,
      isPinCollectiondetailsModalActive: false,
      isBranchInputFocus: false,

      product_selection_v_model: "",

      call_agent_comment: "",
      formerror: false,
    };
  },

  methods: {
    downloadpdf(pdfUrl, pdfFileName) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = pdfFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    validateRequiredName: function () {
      const obj = {
        alphaSpaces: true,
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    formatInitialDepositAmount(amount) {
      amount = amount.toLocaleString();
      if (amount !== "") {
        const number = Number(amount.trim().replaceAll(`,`, ``));
        amount = isNaN(number) ? `0` : number.toLocaleString();

        return amount;
      } else {
        return "";
      }
    },
    noEnrolementCountriesNumber(option){
      console.log("selected option = ", option)
      if(option == "MM +95" || option == "KP +850" || option == "IR +98" )
      {
        BuefyHelper.showToastMessage("Sorry! Selected country is not allowed for digital onboarding.","is-danger")
        this.countryError =true;
      }
      else
      {
        this.countryError =false;
      }
    },
    noEnrolementCountries(option){
      console.log("selected option = ", option)
      if(option == "Myanmar" || option == "Korea, Democratic People's Republic of Korea" || option == "Iran, Islamic Republic of Persian Gulf" )
      {
        BuefyHelper.showToastMessage("Sorry! Selected country is not allowed for digital onboarding.","is-danger")
        this.countryError =true;
      }
      else
      {
        this.countryError =false;
      }
    },
    /**
     * This function is used to get T24 industry list
     */
    getT24Industries(callback) {
      const comp = this;

      NetworkManager.apiRequest("api/T24/getIndustriesListForVideoCall", {}, (response) => {
        console.log("T24 industries response");
        console.log(response);
        if (response.statusCode === 200) {
          comp.industries = response.data.industries.sort((a, b) => {
            return a.description.localeCompare(b.description);
          });

          if (typeof callback === "function") {
            callback();
          }
        } else {
          BuefyHelper.showToastMessage(
            "Unable to get Industries list",
            "is-danger"
          );
        }
      },false);
    },

    /**
     * This function is used to get T24 occupations list
     */
    getT24Occupations(callback) {
      const comp = this;

      NetworkManager.apiRequest("api/T24/getOccupationsListForVideoCall",{}, (response) => {
          console.log("T24 industries response");
          console.log(response);
          if (response.statusCode === 200) {
            comp.occupations = response.data.occupations.sort((a, b) => {
              return a.localeCompare(b);
            });

            if (typeof callback === "function") {
              callback();
            }
          } else {
            BuefyHelper.showToastMessage(
              "Unable to get Occupations list",
              "is-danger"
            );
          }
        },false );
    },

    // loadData() {
    //   const comp = this;

    //   comp.getT24Industries();
    //   comp.
    // },

    officeNumValidate: function () {
      if (this.teleNumber.trim()) {
        if (this.teleCountryCode === "+94") {
          const srilankanTelRegEx =
            /^(?:)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

          this.isOfficeNumValid = srilankanTelRegEx.test(this.teleNumber);
        } else {
          const foreignMobileRegEx = /^[0-9]{0,15}$/;
          this.isOfficeNumValid = foreignMobileRegEx.test(this.teleNumber);
        }
      } else {
        this.isOfficeNumValid = true;
      }
    },
    throwAccountSelectionError() {
      const valueArr = this.product_selection.map((item) => {
        return item.account_type.is_changed_by_call_agent
          ? item.account_type.call_agent_input
          : item.account_type.customer_input;
      });
      this.isAccountNameDuplicated = valueArr.some((item, idx) => {
        return valueArr.indexOf(item) != idx;
      });

      if (this.isAccountNameDuplicated) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `You can't select the same Account type more than once`,
          type: "is-danger",
        });
      }
    },
    vasDeliveryaddressCheck() {
      console.log("---------vasDeliveryaddressCheck-------------------")
      const pinCollection = this.ekycFormData.vas_debit_card_pin_collection_details.is_changed_by_call_agent? 
          this.ekycFormData.vas_debit_card_pin_collection_details.call_agent_input : this.ekycFormData.vas_debit_card_pin_collection_details.customer_input;
      const cardCollection = this.ekycFormData.vas_debit_card_collection_details.is_changed_by_call_agent? 
        this.ekycFormData.vas_debit_card_collection_details.call_agent_input : this.ekycFormData.vas_debit_card_collection_details.customer_input;

      console.log("----------------------------",cardCollection,"----------------------------",pinCollection)
      if (
        (pinCollection == "Branch(for PIN collection)" &&
          cardCollection == "Branch") ||
        (pinCollection == "Residence Address(for PIN collection)" &&
          cardCollection == "Residence Address")
      ) {
        console.log("YES YES YES YES")
        this.formerror = true;
        this.$buefy.toast.open({
          duration: 5000,
          message: `PIN delivery details cannot be same as Card delivery details`,
          type: "is-danger",
        });
      } else {
        this.formerror = false;
      }
    },

    preventDefault(e) {
      e.preventDefault();
    },

    getFormattedDateTime(date) {
      return DateTimeManger.formatCustomerInputDates(date);
    },

    isCurrencyLKR() {
      let isValid = false;

      this.product_selection.map((tempProduct) => {
        if (tempProduct.currency_type.customer_input === `LKR`) {
          isValid = true;
        }
      });

      return isValid;
    },
    submitAgentChangedKycForm() {
      const comp = this;

      comp.isSubmitBtnLoading = true;
      comp.videoKycStore.buttonStates.disabled.endCallBtn = true; // disable end call btn

      comp.videoKycStore.answeredVideoKycData.ekycFormData = comp.ekycFormData;
      comp.videoKycStore.answeredVideoKycData.product_details =
        comp.product_selection;
      // comp.videoKycStore.answeredVideoKycData.branchArray = comp.$root.formDataSupport.branchesArray;
      console.log("BRANCH ARRAY", comp.$root.formDataSupport.branchesArray);

      console.log(
        "ekycFormData",
        this.videoKycStore.answeredVideoKycData.ekycFormData
      );
      console.log(
        "product_selection",
        this.videoKycStore.answeredVideoKycData.product_details
      );
      let requestBody = {
        ...comp.ekycFormData,
        product_details: comp.product_selection,
      };

      // debug logs ----------------------------------------
      // console.warn("updated customer Kyc form data")
      // console.log(requestBody)
      // comp.isSubmitBtnLoading = false;

      NetworkManager.apiRequest(
        "api/KycApplication/submitAgnetChangedKycFormData",
        requestBody,
        (response) => {
          if (response.statusCode === 200) {
            console.log("successfully uploaded the updated kyc form");

            comp.isSubmitBtnLoading = false;
            comp.videoKycStore.answeredVideoKycData.pendingActions.isVideoCallKycFormSubmitted = true;
            comp.videoKycStore.buttonStates.disabled.endCallBtn = false;

            BuefyHelper.showToastMessage(
              "Successfully submitted the data",
              "is-success"
            );
          } else {
            comp.isSubmitBtnLoading = false;
            comp.videoKycStore.buttonStates.disabled.endCallBtn = false;
            BuefyHelper.showToastMessage(
              "Sorry! unable to submit the data this time. Try again",
              "is-danger"
            );
          }
        }
      );
    },

    getProductList(callback) {
      const comp = this;

      const requestBody = {
        is_sl_resident:
          comp.ekycFormData.residentship.customer_input === "Sri Lanka",
        age: comp.ekycFormData.age,
        gender: comp.ekycFormData.gender.customer_input.toLowerCase(),
      };

      NetworkManager.apiRequest(
        "api/KycApplication/getProductList",
        requestBody,
        function (response) {
          if (response.statusCode === 200) {
            comp.$root.formDataSupport.productList = response.data.products;
            // comp.videoKycStore.answeredVideoKycData.branchArray = comp.$root.formDataSupport.branchesArray;
            // console.log("BRANCH ARRAY",comp.$root.formDataSupport.branchesArray)
            callback();
          } else {
            BuefyHelper.showToastMessage(
              "Unable to get product list",
              "is-danger"
            );
          }
        }
      );
    },

    saveToPepObject() {
      this.pep_object = {
        ...this.ekycFormData.pep_declaration.customer_input,
      };

      this.pep_object.pep_person_details =
        this.ekycFormData.pep_declaration.customer_input.pep_person_details.map(
          (pep) => {
            return { ...pep };
          }
        );

      if (
        this.politicallyexposedModalText === `Yes, I am politically exposed`
      ) {
        this.pep_object.iam_politically_exposed = true;
        this.pep_object.close_relative_politically_exposed = false;
        this.pep_object.not_politically_exposed = false;
      } else if (
        this.politicallyexposedModalText ===
        `Yes, my Close relative is politically exposed`
      ) {
        this.pep_object.iam_politically_exposed = false;
        this.pep_object.close_relative_politically_exposed = true;
        this.pep_object.not_politically_exposed = false;
      } else if (this.politicallyexposedModalText === `No`) {
        this.pep_object.iam_politically_exposed = false;
        this.pep_object.close_relative_politically_exposed = false;
        this.pep_object.not_politically_exposed = true;
      }
    },
    visible() {
      this.isShow = true;
    },
    visibleHide() {
      this.isShow = false;
      this.ekycFormData.telephone_number.call_agent_input = "";

      this.teleNumber = "";
      this.ekycFormData.telephone_number.is_changed_by_call_agent = false;
      // this.kycFormStore.formData. = "";
      // this.kycFormStore.formData. = "";
    },
    visibleEmail() {
      this.isEmailShow = true;
    },
    emailVisibleHide() {
      this.isEmailShow = false;
      this.emailSecondText = "";
      this.ekycFormData.secondary_email.call_agent_input = "";
      this.ekycFormData.secondary_email.is_changed_by_call_agent = false;
    },
    test(hi) {
      console.log(hi);
    },

    test2(word) {
      console.log(word, "check");
    },
    getPosOptions() {
      const tempArray = [];
      console.log("in getposOptions start");
      for (const item of this.product_selection) {
        console.log(
          "in getposOptions 1=",
          item.currency_type.customer_input === "LKR",
          item.currency_type.customer_input
        );
        if (item.currency_type.customer_input === "LKR") {
          const tempItem = item.account_type.call_agent_input
            ? item.account_type.call_agent_input
            : item.account_type.customer_input;

          tempArray.push(tempItem);
        }
      }

      this.pos_options = [...tempArray];

      if (this.ekycFormData.vas_debit_card_pos_account.call_agent_input) {
        const isExist = this.pos_options.find(
          (option) =>
            option.name ===
            this.ekycFormData.vas_debit_card_pos_account.call_agent_input
        );
        if (!isExist) {
          this.ekycFormData.vas_debit_card_pos_account.call_agent_input = "";
          this.accountToBeLinkedText =
            this.ekycFormData.vas_debit_card_pos_account.customer_input;
          this.ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent = false;
        }
      }
    },
    mobileNumEditValidate: function () {
      if (this.countryCodeEdit === "+94") {
        const srilankanMobileRegEx = /^([7][0||1||2||4||5||6||7||8][0-9]{7})$/;

        this.isMobileNumberValidEdit = srilankanMobileRegEx.test(
          this.mobileNumberEdit
        );
      } else {
        this.isMobileNumberValidEdit = /^\d+$/.test(this.mobileNumberEdit);
      }
    },
    changeSelectionEdit() {
      this.iconStringEdit = `fi fi-${this.shortNameEdit.toLowerCase()}`;
    },
    changeTelSelection() {
      this.officeIconString = `fi fi-${this.teleShortNameEdit.toLowerCase()}`;
    },
    calTelNumber() {
      if (this.teleCountryCode === "+94") {
        console.log("LK Mobile number ---------------");
        const tempTelNumber = this.teleNumber.trim();
        if (tempTelNumber[0] === "0") {
          this.isTelNumberStartsWithZero = true;
        } else {
          this.isTelNumberStartsWithZero = false;
        }
      } else {
        this.isTelNumberStartsWithZero = false;
      }

      if (this.teleNumber && this.teleCountryCode) {
        this.agentTeleFullNo = `${this.teleCountryCode}${this.teleNumber}`;
      } else {
        this.agentTeleFullNo = ``;
      }
      this.officeNumValidate();
    },

    calEditPhoneNumber() {
      if (this.countryCodeEdit === "+94") {
        const tempMobileNumber = this.mobileNumberEdit.trim();
        if (tempMobileNumber[0] === "0") {
          this.isMobileNumberEditStartsWithZero = true;
        } else {
          this.isMobileNumberEditStartsWithZero = false;
        }
      } else {
        this.isMobileNumberEditStartsWithZero = false;
      }

      this.agentFullMobileNum = `${this.countryCodeEdit}${this.mobileNumberEdit}`;

      this.agentTeleFullNo = `${this.teleCountryCode}${this.teleNumber}`;
    },
    validateReason: function () {
      const obj = {
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
        validatePurpose: true,
        min: 3,
      };
      return obj;
    },
    validateName: function () {
      const obj = {
        required: true,
        min: 3,
        alphaSpaces: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateRequired: function () {
      const obj = {
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateTaxRequired: function () {
      const obj = {
        min_value: 1,
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateNonMandatory: function () {
      const obj = {
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    passportValidateObject() {
      let obj = {
        required: true,
        notMatchRegex: [this.regex.old_nic, this.regex.new_nic],
        regex: this.regex.passport,
      };
      return obj;
    },
    debitCardYesClick() {
      if (!this.debitcardserviceText) {
        this.debitCardToggleButton.classList.add("toggle-change");
        this.debitCardYesButton.classList.add("yes");
        this.debitCardYesButton.classList.add("yes-active");
        this.debitCardNoButton.classList.remove("no");
        this.debitCardNoButton.classList.remove("no-active");
        this.debitcardserviceText = true;
      }
    },

    debitCardNoClick() {
      if (this.debitcardserviceText) {
        this.debitCardToggleButton.classList.remove("toggle-change");
        this.debitCardYesButton.classList.remove("yes");
        this.debitCardYesButton.classList.remove("yes-active");
        this.debitCardNoButton.classList.add("no");
        this.debitCardNoButton.classList.add("no-active");
        this.debitcardserviceText = false;
      }
    },

    smsYesClick() {
      if (!this.ekycFormData.vas_sms_alert.customer_input) {
        this.smsToggleButton.classList.add("toggle-change");
        this.smsYesButton.classList.add("yes");
        this.smsYesButton.classList.add("yes-active");
        this.smsNoButton.classList.remove("no");
        this.smsNoButton.classList.remove("no-active");
      }
    },
    smsNoClick() {
      if (this.ekycFormData.vas_sms_alert.customer_input) {
        this.smsToggleButton.classList.remove("toggle-change");
        this.smsYesButton.classList.remove("yes");
        this.smsYesButton.classList.remove("yes-active");
        this.smsNoButton.classList.add("no");
        this.smsNoButton.classList.add("no-active");
      }
    },

    eBankingYesClick() {
      if (!this.ekycFormData.vas_online_banking.customer_input) {
        this.eBankingToggleButton.classList.add("toggle-change");
        this.eBankingYesButton.classList.add("yes");
        this.eBankingYesButton.classList.add("yes-active");
        this.eBankingNoButton.classList.remove("no");
        this.eBankingNoButton.classList.remove("no-active");
      }
    },

    eBankingNoClick() {
      if (this.ekycFormData.vas_online_banking.customer_input) {
        this.eBankingToggleButton.classList.remove("toggle-change");
        this.eBankingYesButton.classList.remove("yes");
        this.eBankingYesButton.classList.remove("yes-active");
        this.eBankingNoButton.classList.add("no");
        this.eBankingNoButton.classList.add("no-active");
      }
    },

    virtualWalletYesClick() {
      if (!this.ekycFormData.vas_virtual_wallet.customer_input) {
        this.virtualWalletToggleButton.classList.add("toggle-change");
        this.virtualWalletYesButton.classList.add("yes");
        this.virtualWalletYesButton.classList.add("yes-active");
        this.virtualWalletNoButton.classList.remove("no");
        this.virtualWalletNoButton.classList.remove("no-active");
      }
    },

    virtualWalletNoClick() {
      if (this.ekycFormData.vas_virtual_wallet.customer_input) {
        this.virtualWalletToggleButton.classList.remove("toggle-change");
        this.virtualWalletYesButton.classList.remove("yes");
        this.virtualWalletYesButton.classList.remove("yes-active");
        this.virtualWalletNoButton.classList.add("no");
        this.virtualWalletNoButton.classList.add("no-active");
      }
    },

    estatementYesClick() {
      if (!this.ekycFormData.vas_estatement.customer_input) {
        this.estatementToggleButton.classList.add("toggle-change");
        this.estatementYesButton.classList.add("yes");
        this.estatementYesButton.classList.add("yes-active");
        this.estatementNoButton.classList.remove("no");
        this.estatementNoButton.classList.remove("no-active");
      }
    },

    estatementNoClick() {
      if (this.ekycFormData.vas_estatement.customer_input) {
        this.estatementToggleButton.classList.remove("toggle-change");
        this.estatementYesButton.classList.remove("yes");
        this.estatementYesButton.classList.remove("yes-active");
        this.estatementNoButton.classList.add("no");
        this.estatementNoButton.classList.add("no-active");
      }
    },

    /***
     * This function can be used ot ge filtered product list based on currency selection
     * @param currency
     */
    getFilteredProductList(currency) {
      const comp = this;
      let filteredProductList = [];

      // check currency is empty
      if (currency !== "") {
        comp.$root.formDataSupport.productList.forEach((product) => {
          if (product.currencies.includes(currency)) {
            filteredProductList.push(product);
          }
        });

        // return filtered product list
        return filteredProductList;
      } else {
        // if currency is not selected, return all products from api
        return comp.$root.formDataSupport.productList;
      }
    },
  },

  computed: {
    isResidentshipDisabled() {
      if (this.ekycFormData.residentship.customer_input == "Sri Lanka") {
        this.isresidentinSL = true;
      }
    },
    isVideoCallEkycFormUpdatedByAgent() {
      let isCurrencyTrue = false;
      let isAccountTypeTrue = false;
      let isPurposeOfOpeningTrue = false;
      let isAnticipatedVolumesTrue = false;
      let initialDepTrue = false;

      const comp = this;

      for (const item of comp.product_selection) {
        if (item.currency_type.is_changed_by_call_agent) {
          isCurrencyTrue = true;
        }
        if (item.account_type.is_changed_by_call_agent) {
          isAccountTypeTrue = true;
        }
        if (item.purpose_of_opening_account.is_changed_by_call_agent) {
          isPurposeOfOpeningTrue = true;
        }
        if (item.anticipated_volume.is_changed_by_call_agent) {
          isAnticipatedVolumesTrue = true;
        }
        if (item.initial_deposit_amount.is_changed_by_call_agent) {
          initialDepTrue = true;
        }
      }

      if (
        this.ekycFormData.title.is_changed_by_call_agent ||
        this.ekycFormData.full_name.is_changed_by_call_agent ||
        this.ekycFormData.nic_eic_number.is_changed_by_call_agent ||
        this.ekycFormData.nic_eic_issue_date.is_changed_by_call_agent ||
        this.ekycFormData.passport_number.is_changed_by_call_agent ||
        this.ekycFormData.passport_issue_date.is_changed_by_call_agent ||
        this.ekycFormData.passport_expire_date.is_changed_by_call_agent ||
        this.ekycFormData.nationality.is_changed_by_call_agent ||
        this.ekycFormData.telephone_number.is_changed_by_call_agent ||
        this.ekycFormData.date_of_birth.is_changed_by_call_agent ||
        this.ekycFormData.maritial_status.is_changed_by_call_agent ||
        this.ekycFormData.building_number.is_changed_by_call_agent ||
        this.ekycFormData.building_name.is_changed_by_call_agent ||
        this.ekycFormData.street.is_changed_by_call_agent ||
        this.ekycFormData.street_2.is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_street_2.is_changed_by_call_agent ||
        this.ekycFormData.town.is_changed_by_call_agent ||
        this.ekycFormData.postal_code.is_changed_by_call_agent ||
        this.ekycFormData.district.is_changed_by_call_agent ||
        this.ekycFormData.country.is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_building_number
          .is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_building_name
          .is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_street
          .is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_town
          .is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_postal_code
          .is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_district
          .is_changed_by_call_agent ||
        this.ekycFormData.correspondence_address_country
          .is_changed_by_call_agent ||
        this.ekycFormData.state_of_residence.is_changed_by_call_agent ||
        this.ekycFormData.email.is_changed_by_call_agent ||
        this.ekycFormData.secondary_email.is_changed_by_call_agent ||
        this.ekycFormData.mothers_maiden_name.is_changed_by_call_agent ||
        this.ekycFormData.profession_nature_of_business
          .is_changed_by_call_agent ||
        this.ekycFormData.other_platforms_working_on_other_description
          .is_changed_by_call_agent ||
        this.ekycFormData.other_platforms_working_on.is_changed_by_call_agent ||
        this.ekycFormData.are_you_work_on_another_platform
          .is_changed_by_call_agent ||
        this.ekycFormData.employment_status.is_changed_by_call_agent ||
        this.ekycFormData.employer.is_changed_by_call_agent ||
        this.ekycFormData.emp_building_number.is_changed_by_call_agent ||
        this.ekycFormData.emp_building_name.is_changed_by_call_agent ||
        this.ekycFormData.emp_street.is_changed_by_call_agent ||
        this.ekycFormData.emp_town.is_changed_by_call_agent ||
        this.ekycFormData.emp_postal_code.is_changed_by_call_agent ||
        this.ekycFormData.emp_district.is_changed_by_call_agent ||
        this.ekycFormData.emp_country.is_changed_by_call_agent ||
        this.ekycFormData.are_you_a_tax_payer.is_changed_by_call_agent ||
        this.ekycFormData.are_you_work_on_another_platform
          .is_changed_by_call_agent ||
        this.ekycFormData.tax_file_numer.is_changed_by_call_agent ||
        this.ekycFormData.pep_declaration.is_changed_by_call_agent ||
        this.ekycFormData.source_of_funds.is_changed_by_call_agent ||
        this.ekycFormData.source_of_funds_other_description
          .is_changed_by_call_agent ||
        this.ekycFormData.source_of_funds_other.is_changed_by_call_agent ||
        this.ekycFormData.branch_id.is_changed_by_call_agent ||
        this.ekycFormData.branch_comment.is_changed_by_call_agent ||
        isCurrencyTrue ||
        isAccountTypeTrue ||
        isAnticipatedVolumesTrue ||
        isPurposeOfOpeningTrue ||
        initialDepTrue ||
        this.ekycFormData.vas_debit_card.is_changed_by_call_agent ||
        this.ekycFormData.vas_debit_card_pos_required
          .is_changed_by_call_agent ||
        this.ekycFormData.vas_debit_card_name.is_changed_by_call_agent ||
        this.ekycFormData.vas_debit_card_collection_details
          .is_changed_by_call_agent ||
        this.ekycFormData.vas_debit_card_pos_account.is_changed_by_call_agent ||
        this.ekycFormData.vas_debit_card_pin_collection_details
          .is_changed_by_call_agent
      ) {
        // update video call ekyc updated state if it is not updated previously
        if (
          !comp.videoKycStore.answeredVideoKycData.pendingActions
            .isVideoCallEkycFormUpdatedByAgent
        ) {
          comp.videoKycStore.answeredVideoKycData.pendingActions.isVideoCallEkycFormUpdatedByAgent = true;
        }
        return true;
      } else {
        return false;
      }
    },

    PurposeOfAccountOpening() {
      return PurposeOfAccountOpening;
    },
    industryFilteredDataArray() {
      return this.industries.filter((option) => {
        return (
          option.description
            .toLowerCase()
            .indexOf(this.industry.toLowerCase()) >= 0
        );
      });
    },
    occupationFilteredDataArray() {
      return this.occupations.filter((option) => {
        return option.toLowerCase().indexOf(this.occupation.toLowerCase()) >= 0;
      });
    },

    filteredDataArrEdit() {
      return this.countryCodeArray.filter((option) => {
        return (
          option.toLowerCase().indexOf(this.userCodeEdit.toLowerCase()) >= 0
        );
      });
    },

    officeFilteredDataArr() {
      this.noEnrolementCountriesNumber(this.userOfficeCode)
      return this.countryCodeArray.filter((option) => {
        return (
          option.toLowerCase().indexOf(this.userOfficeCode.toLowerCase()) >= 0
        );
      });
    },

    countryFilteredDataArray() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.permanentaddresscountry.toLowerCase()) >= 0
        );
      });
    },

    correspondentCountryFilteredDataArray() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.correspondenceaddresscountry.toLowerCase()) >= 0
        );
      });
    },

    empCountryFilteredDataArray() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.employeraddresscountry.toLowerCase()) >= 0
        );
      });
    },

    districtFilteredDataArray() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.permanentaddressdistrict.toLowerCase()) >= 0
        );
      });
    },

    correspondentdistrictFilteredDataArray() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.correspondenceaddressdistrictText.toLowerCase()) >= 0
        );
      });
    },

    employeedistrictFilteredDataArray() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.employeraddressdistrictText.toLowerCase()) >= 0
        );
      });
    },
  },

  mounted() {
    const comp = this;

    setTimeout(() => {
      comp.getT24Industries();
      comp.getT24Occupations();

      // get relevant product list and do other product related tasks
      comp.getProductList(() => {
        this.product_selection_v_model = this.product_selection.map(
          (product) => {
            return {
              id: product.id,
              currency_type: product.currency_type.customer_input,
              account_type: this.$root.formDataSupport.productList.find(
                (tempProduct) =>
                  tempProduct.name === product.account_type.customer_input
              ).name,
              product_id: product.product_id.customer_input,
              purpose_of_opening_account:
                product.purpose_of_opening_account.customer_input,
              anticipated_volume: product.anticipated_volume.customer_input,
              initial_deposit_amount:
                product.initial_deposit_amount.customer_input,
            };
          }
        );

        this.product_selection_v_model.map((temp) => {
          console.log(temp.initial_deposit_amount, "hi");
        });
        this.purpose_of_opening_modals = this.product_selection_v_model.map(
          (product) => {
            this.isPurposeofopeningModalActive.push({ value: false });
            return product.purpose_of_opening_account;
          }
        );
        this.anticipated_volumes_modals = this.product_selection_v_model.map(
          (product) => {
            this.isAnticipatedVolModalActive.push({ value: false });
            return product.anticipated_volume;
          }
        );
        this.account_modals = this.product_selection_v_model.map((product) => {
          this.isProductModalActive.push({ value: false });
          return product.account_type;
        });

        this.other_opening_purpose = this.product_selection_v_model.map(
          (product) => {
            return product.purpose_of_opening_account.find(
              (purposeTest) => purposeTest.key === `purpose_other_specified`
            )
              ? product.purpose_of_opening_account.find(
                  (purposeTest) => purposeTest.key === `purpose_other_specified`
                ).purpose
              : ``;
          }
        );

        console.log(this.other_opening_purpose);
      });

      for (const country of this.$root.formDataSupport.countriesArray) {
        this.countryCodeArray.push(`${country.code} ${country.dial_code}`);
      }
      console.log(
        "mounted country code : " +
          this.ekycFormData.mobile_number_country_code.customer_input
      );
      this.userCodeEdit = this.countryCodeArray.find(
        (countryCode) =>
          countryCode.split(" ")[1] ===
          this.ekycFormData.mobile_number_country_code.customer_input
      );
      this.shortNameEdit = this.userCodeEdit.split(" ")[0];
      this.mobileNumberEdit =
        this.ekycFormData.mobile_number_without_country_code.customer_input;
      this.countryCodeEdit =
        this.ekycFormData.mobile_number_country_code.customer_input;
      this.agentFullMobileNum = this.ekycFormData.mobile_number.customer_input;

      this.teleNumber =
        this.ekycFormData.telephone_number_without_country_code.customer_input;
      this.teleCountryCode =
        this.ekycFormData.telephone_number_country_code.customer_input;
      this.agentTeleFullNo = this.ekycFormData.telephone_number.customer_input;
      this.userOfficeCode = this.ekycFormData.telephone_number_country_code
        .customer_input
        ? this.countryCodeArray.find(
            (countryCode) =>
              countryCode.split(" ")[1] ===
              this.ekycFormData.telephone_number_country_code.customer_input
          )
        : "";
      this.teleShortNameEdit = this.userOfficeCode
        ? this.userOfficeCode.split(" ")[0]
        : "";

      this.changeSelectionEdit();
      this.changeTelSelection();
      // sms
      this.smsToggleButton = document.getElementById("sms-toggle-buttons");
      this.smsYesButton = document.getElementById("sms-yes-button");
      this.smsNoButton = document.getElementById("sms-no-button");
      if (this.ekycFormData.vas_sms_alert.customer_input) {
        this.smsNoButton.classList.remove("no");
        this.smsNoButton.classList.remove("no-active");
      } else {
        this.smsYesButton.classList.remove("yes");
        this.smsYesButton.classList.remove("yes-active");
        this.smsToggleButton.classList.remove("toggle-change");
      }
      // e-banking
      this.eBankingToggleButton = document.getElementById(
        "e-banking-toggle-buttons"
      );
      this.eBankingYesButton = document.getElementById("e-banking-yes-button");
      this.eBankingNoButton = document.getElementById("e-banking-no-button");
      if (this.ekycFormData.vas_online_banking.customer_input) {
        this.eBankingNoButton.classList.remove("no");
        this.eBankingNoButton.classList.remove("no-active");
      } else {
        this.eBankingYesButton.classList.remove("yes");
        this.eBankingYesButton.classList.remove("yes-active");
        this.eBankingToggleButton.classList.remove("toggle-change");
      }
      // virtual-wallet
      this.virtualWalletToggleButton = document.getElementById(
        "virtual-wallet-toggle-buttons"
      );
      this.virtualWalletYesButton = document.getElementById(
        "virtual-wallet-yes-button"
      );
      this.virtualWalletNoButton = document.getElementById(
        "virtual-wallet-no-button"
      );
      if(this.ekycFormData.residentship.customer_input !== 'Out of Sri Lanka')
      {
        if (this.ekycFormData.vas_virtual_wallet.customer_input) {
          this.virtualWalletNoButton.classList.remove("no");
          this.virtualWalletNoButton.classList.remove("no-active");
        } else {
          this.virtualWalletYesButton.classList.remove("yes");
          this.virtualWalletYesButton.classList.remove("yes-active");
          this.virtualWalletToggleButton.classList.remove("toggle-change");
        }
      }
      // estatement
      this.estatementToggleButton = document.getElementById(
        "estatement-toggle-buttons"
      );
      this.estatementYesButton = document.getElementById(
        "estatement-yes-button"
      );
      this.estatementNoButton = document.getElementById("estatement-no-button");
      if (this.ekycFormData.vas_estatement.customer_input) {
        this.estatementNoButton.classList.remove("no");
        this.estatementNoButton.classList.remove("no-active");
      } else {
        this.estatementYesButton.classList.remove("yes");
        this.estatementYesButton.classList.remove("yes-active");
        this.estatementToggleButton.classList.remove("toggle-change");
      }

      // vas_debit_card
      if (
        this.ekycFormData.residentship.customer_input !== "Out of Sri Lanka" &&
        this.isCurrencyLKR()
      ) {
        this.debitCardToggleButton = document.getElementById(
          "debit_card-toggle-buttons"
        );
        this.debitCardYesButton = document.getElementById(
          "debit_card-yes-button"
        );
        this.debitCardNoButton = document.getElementById(
          "debit_card-no-button"
        );
        if (this.ekycFormData.vas_debit_card.customer_input) {
          this.debitCardNoButton.classList.remove("no");
          this.debitCardNoButton.classList.remove("no-active");
        } else {
          this.debitCardYesButton.classList.remove("yes");
          this.debitCardYesButton.classList.remove("yes-active");
          this.debitCardToggleButton.classList.remove("toggle-change");
        }
      }

      this.titleText = this.ekycFormData.title.customer_input;
      this.fullnameText = this.ekycFormData.full_name.customer_input;

      this.dateOfBirthCustomerInput = new Date(
        this.ekycFormData.date_of_birth.customer_input
      );
      this.dateOfBirthText = new Date(
        this.ekycFormData.date_of_birth.customer_input
      );

      this.passportExpiryDateCustomerInput = new Date(
        this.ekycFormData.passport_expire_date.customer_input
      );
      this.passportExpiryDateText = new Date(
        this.ekycFormData.passport_expire_date.customer_input
      );

      this.passportIssueDateCustomerInput = new Date(
        this.ekycFormData.passport_issue_date.customer_input
      );
      this.passportIssueDateText = new Date(
        this.ekycFormData.passport_issue_date.customer_input
      );

      this.nicIssueDateCustomerInput = new Date(
        this.ekycFormData.nic_eic_issue_date.customer_input
      );
      this.nicIssueDateText = new Date(
        this.ekycFormData.nic_eic_issue_date.customer_input
      );
      // this.genderText = this.ekycFormData.gender.customer_input;
      // this.genderModalText = this.ekycFormData.gender.customer_input;

      this.customernicText = this.ekycFormData.nic_eic_number.customer_input;
      this.customerpassportnoText =
        this.ekycFormData.passport_number.customer_input;
      this.maritalstatusText = this.ekycFormData.maritial_status.customer_input;
      this.maritalstatusModalText =
        this.ekycFormData.maritial_status.customer_input;
      this.permanentaddressline1Text =
        this.ekycFormData.building_number.customer_input;
      this.permanentaddressline2Text =
        this.ekycFormData.building_name.customer_input;
      this.permanentaddressline3Text = this.ekycFormData.street.customer_input;
      this.permanentaddressStreetText = this.ekycFormData.street_2.customer_input;
      this.permanentaddresstownText = this.ekycFormData.town.customer_input;
      this.permanentaddresspostalcodeText =
        this.ekycFormData.postal_code.customer_input;
      this.permanentaddressdistrict = this.ekycFormData.district.customer_input;
      this.permanentaddresscountryText =
        this.ekycFormData.country.customer_input;
      this.permanentaddresscountry = this.ekycFormData.country.customer_input;
      this.correspondenceaddressline1Text =
        this.ekycFormData.correspondence_address_building_number.customer_input;
      this.correspondenceaddressline2Text =
        this.ekycFormData.correspondence_address_building_name.customer_input;
      this.correspondenceaddressline3Text =
        this.ekycFormData.correspondence_address_street.customer_input;
      this.correspondenceaddressStreetTwoText =  this.ekycFormData.correspondence_address_street_2.customer_input;
      this.correspondenceaddresstownText =
        this.ekycFormData.correspondence_address_town.customer_input;
      this.correspondenceaddresspostalcodeText =
        this.ekycFormData.correspondence_address_postal_code.customer_input;
      this.correspondenceaddressdistrictText =
        this.ekycFormData.correspondence_address_district.customer_input;
      this.correspondenceaddresscountryText =
        this.ekycFormData.correspondence_address_country.customer_input;
      this.correspondenceaddresscountry =
        this.ekycFormData.correspondence_address_country.customer_input;
      this.statusofresidenceText =
        this.ekycFormData.state_of_residence.customer_input;
      this.statusofresidenceModalText =
        this.ekycFormData.state_of_residence.customer_input;
      this.emailText = this.ekycFormData.email.customer_input;
      this.emailSecondText = this.ekycFormData.secondary_email.customer_input;
      this.maidennameText =
        this.ekycFormData.mothers_maiden_name.customer_input;
      this.employmentstatusText =
        this.ekycFormData.employment_status.customer_input;
      this.natureofbusinessText =
        this.ekycFormData.profession_nature_of_business.customer_input;
      this.areyouafreelancerText =
        this.ekycFormData.are_you_work_on_another_platform.customer_input;
      this.areyouaFreelancerModalText =
        this.ekycFormData.are_you_work_on_another_platform.customer_input;
      this.employernameText = this.ekycFormData.employer.customer_input;
      this.employeraddressline1Text =
        this.ekycFormData.emp_building_number.customer_input;
      this.employeraddressline2Text =
        this.ekycFormData.emp_building_name.customer_input;
      this.employeraddressline3Text =
        this.ekycFormData.emp_street.customer_input;
      this.employeraddresstownText = this.ekycFormData.emp_town.customer_input;
      this.employeraddresspostalcodeText =
        this.ekycFormData.emp_postal_code.customer_input;
      this.employeraddressdistrictText =
        this.ekycFormData.emp_district.customer_input;
      this.employeraddresscountryText =
        this.ekycFormData.emp_country.customer_input;
      this.employeraddresscountry =
        this.ekycFormData.emp_country.customer_input;
      this.taxfilenumText = this.ekycFormData.tax_file_numer.customer_input;

      this.specifiedsourceofincomeText =
        this.ekycFormData.source_of_funds_other_description.customer_input;
      this.reasonforopeninganaccountText =
        this.ekycFormData.branch_comment.customer_input;
      this.facilitydebitcardcustomernameText =
        this.ekycFormData.vas_debit_card_name.customer_input;
      this.areyouataxpayerText =
        this.ekycFormData.are_you_a_tax_payer.customer_input;
      this.areyouataxpayerModalText =
        this.ekycFormData.are_you_a_tax_payer.customer_input;
      this.sourceofincomeText =
        this.ekycFormData.source_of_funds.customer_input;
      this.workingPlatformText =
        this.ekycFormData.other_platforms_working_on.customer_input;
      this.workingPlatformTextModal =
        this.ekycFormData.other_platforms_working_on.customer_input;
      this.specifiedworkplatformText =
        this.ekycFormData.other_platforms_working_on_other_description.customer_input;
      this.sourceofincomeTextModal =
        this.ekycFormData.source_of_funds.customer_input;
      this.posrequiredText =
        this.ekycFormData.vas_debit_card_pos_required.customer_input;
      this.posrequiredModalText =
        this.ekycFormData.vas_debit_card_pos_required.customer_input;
      this.branchText = this.ekycFormData.branch_id.customer_input;
      this.collectiondetailsText =
        this.ekycFormData.vas_debit_card_collection_details.customer_input;
      this.collectiondetailsModalText =
        this.ekycFormData.vas_debit_card_collection_details.customer_input;
      this.pincollectiondetailsText =
        this.ekycFormData.vas_debit_card_pin_collection_details.customer_input;
      this.pincollectiondetailsModalText =
        this.ekycFormData.vas_debit_card_pin_collection_details.customer_input;
      this.nationalityText = this.ekycFormData.nationality.customer_input;
      this.debitcardserviceText =
        this.ekycFormData.vas_debit_card.customer_input;
   
      this.accountToBeLinkedText =
        this.ekycFormData.vas_debit_card_pos_account.customer_input;

      this.politicallyexposedText = this.ekycFormData.pep_declaration
        .customer_input.not_politically_exposed
        ? `No`
        : this.ekycFormData.pep_declaration.customer_input
            .iam_politically_exposed
        ? `Yes, I am politically exposed`
        : `Yes, my close relative is politically exposed`;

      this.politicallyexposedModalText = this.politicallyexposedText;

      this.industry = this.ekycFormData.customer_industry.description;
      this.industrySelected = { ...this.ekycFormData.customer_industry };
      this.occupation = this.ekycFormData.customer_occupation;
      this.occupationSelected = this.ekycFormData.customer_occupation;
      // this.politicallyexposedModalText = this.ekycFormData.pep_declaration
      //   .customer_input.not_politically_exposed
      //   ? `No`
      //   : this.ekycFormData.pep_declaration.customer_input.iam_politically_exposed
      //   ? `Yes, I am politically exposed`
      //   : `Yes, my close relative is politically exposed`;

      this.ekycFormData.pep_declaration.customer_input.pep_person_details.map(
        (pep) => {
          this.pepDeclarationTextName.push(pep.name);
          this.pepDeclarationTextDesignation.push(pep.designation);
        }
      );

      this.pep_object = { ...this.ekycFormData.pep_declaration.customer_input };
      this.pep_object.pep_person_details =
        this.ekycFormData.pep_declaration.customer_input.pep_person_details.map(
          (pep) => {
            return { ...pep };
          }
        );
      this.getPosOptions();
      this.vasDeliveryaddressCheck();
      this.officeNumValidate();
    }, 250);
  },
};
</script>

<style lang="scss" scoped>
.button.delete:hover {
  background-color: #04c3fc !important;
}
.verticalLine {
  border-right: 0.92px solid #1b1b1b;
}

.round-image-box {
  border-radius: 50%;
  border: 2px solid #04c3fc;
  width: 250px;
  height: 180px;
}

.modal-close-button {
  position: absolute;
  right: 5%;
  z-index: 10;
}

.preview_image {
  border-radius: 50%;
  border: 0.1px solid #231f20 !important;
  padding: 1px;
  background-color: #f9f9f9;
}

.toggle-button {
  display: flex;
  border: 1px solid black;
  max-width: 200px;
  justify-content: flex-end;
  border-radius: 500px;
}

.toggle-change {
  justify-content: flex-start;
}

.toggle-button:hover {
  cursor: pointer;
}

.no-active,
.yes-active {
  background-color: #04c3fc;
  color: white;
}

.no,
.yes {
  border-radius: 500px;
}

.toggle-common {
  padding: 0 22px;
}
.button.delete:hover {
  background-color: #04c3fc !important;
}
.delete {
  position: relative;
  right: -10px;
  top: 5px;
}
.custom_float_label_date {
  top: 0.22em !important;
  font-family: "Gotham-Medium";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}

.custom-float-label-initial {
  top: 0.93em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 1rem !important;
  transition: ease 0.3s;
  pointer-events: none;
}

.custom-float-label {
  top: 0.22em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}
.purposes-list,
.source-list {
  flex-wrap: wrap;
  /*padding: 0.5rem;*/
  align-items: baseline;
}

.purpose-list-item {
  width: 180px;
  height: 64px;
  padding: 20px;
}
.source-list-item {
  width: 150px;
  height: 64px;
  padding: 20px;
}
.product-list-item {
  width: 191px !important;
  height: 64px !important;
  padding: 12px !important;
}

.volume-list-item {
  width: 182px !important;
  height: 64px !important;
  padding: 12px !important;
}
.coer img {
  margin: 0 auto;
  display: block;
}
.image-box-wrapper {
    position: relative; /* Make the wrapper a positioning context */
}

.download-button {
    display: none; /* Initially hide the download button */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 93%;
    margin-top :2.5vh;
    border-radius: 10px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
    linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

.image-box-wrapper:hover .download-button {
    display: block; /* Show the download button on hover */
}
</style>
