<template> 
    <div class="container" 
        rounded style="width: 50.5vw;
        background-color: white;
        border: 2px solid #000000;
        border-radius: 25px;
        padding: 20px;
        z-index: 2">
        <ValidationObserver v-slot="{invalid}">
        <div>
          <div class="columns is-7 is-variable" style="width: 100%;">
            <div class="column is-three-fourth" style="text-align: center;">
              <label style="color: #04c3fc; font-size: 26px;">{{Schedule_or_Reschedule}}</label>
            </div> 
            <div class="column is-three-fifth" style="margin-left: 44vw; position: absolute;">
              <b-button @click="emitCloss" type="is-primary" outlined>x</b-button>
            </div>
          </div> 
        </div>
        <hr style="width: 90%; margin-left: auto; margin-right: auto;">
              
        <div class="columns is-7 is-variable">
            <div class="column is-three-fifth">
                <b-field>
                  <ValidationProvider
                    name="Video_Call_Date"
                    :rules="{required:true}"
                    v-slot="{errors}">
                    <b-datepicker class="control has-icons-right"
                        rounded
                        placeholder="Preferred Date for Video Call*"
                        v-model="selectedDateComp"
                        icon="calendar-today"                        
                        @input="emitNewDate"
                        :locale="locale"
                        :min-date="minDate"
                        :max-date="maxDate"
                        :unselectable-dates="unselectableDates"
                        >
                    </b-datepicker>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field> 
            </div>
            <div class="column is-three-fifth">
                <b-field>
                  <ValidationProvider
                    name="Video_Call_Time"
                    :rules="{required:true}"
                    v-slot="{errors}">
                    <b-select 
                      placeholder="Preferred Time" rounded  v-model="videoCallTimeComp" @input="emitNewTime"
                    >
                        <option v-for="timeSlots in timeSlots"
                          :disabled="timeSlots.disable"
                          :key="timeSlots.time"
                          :value="timeSlots.time"
                          >{{ timeSlots.time }}</option>
                    </b-select>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
            </div>
        </div>
        <br>
        <div class="columns is-7 is-variable">

            <div class="column is-three-fifth">
              <ValidationProvider
                name="Send_notifi_Email"
                :rules="{required:true}"
                v-slot="{errors}">
                <b-checkbox required v-model="email"  @input="emitScheduleEmail" disabled default>Send notifications via email</b-checkbox>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>  
            <div class="column is-three-fifth">
              <ValidationProvider
                name="Send_notifi_Sms"
                :rules="{required:true}"
                v-slot="{errors}">
                <b-checkbox v-model="sms"  @input="emitScheduleSms">Send notifications via SMS</b-checkbox>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>  
        </div>
        <br>
         <div class="message-box-body">
            Add Comment/s for Customer
            <b-field grouped position="is-centered">
              <ValidationProvider
                name="Customer Comment"
                :rules="validateComment()"
                v-slot="{errors}">
                <b-input class="rounded-input"
                    rounded 
                    type="textarea"
                    rows="3"
                    maxlength="400" 
                    v-model="Sh_customercomment" 
                    @input="emitShcustomercomment"
                    style="width: 47.5vw;">
                </b-input>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
            
        </div>
        <div>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <label aria-controls="contentIdForA11y1">Add Comment/s for internal use</label>
        </b-collapse>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger="props">
                <label aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"
                      style="color: #04c3fc;"> 
                      <i class="mdi mdi-plus-circle-outline"></i> 
                      Add Comment/s for internal use
                </label>
              </template>
                  <div class="content">
                    <b-field grouped position="is-centered">
                      <ValidationProvider
                        name="Internal Comment"
                        :rules="validateComment()"
                        v-slot="{errors}">
                        <b-input class="rounded-input"
                          rounded 
                          type="textarea" 
                          maxlength="400" 
                          v-model="Sh_internalcomment" 
                          @input="emitShinternalcomment"
                          placeholder="Specify" 
                          rows = 3
                          style="width: 47.5vw;"></b-input>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
              <!-- </div> -->
          </b-collapse>
      </div>
      <br>
      <b-field grouped position="is-centered">
        <b-button type="is-primary" style="width: 47.5vw;" :disabled="invalid || isTimeSlotDisabled(videoCallTimeComp) || !((sms || email) && isDateOkay)" @click="emitsendScheduleMeeting">{{ Schedule_or_Reschedule }}</b-button>
      </b-field>
      </ValidationObserver>
    </div>
</template>
  
  <script>
  import {BuefyHelper} from "../helpers/bufeyHelper";
  import { required } from 'vee-validate/dist/rules';
  import NetworkManager from "@/network";
  export default {
    name: 'ScheduleRescheduleMeeting',
    props: {
      Schedule_or_Reschedule: {
        type: String,
        required: true,
      },
      selectedDate: {
        type: Date,
        required: true,
      },
      videoCallTime: {
        type: String,
        required: true,
      },
      // Sh_Disabled:{
      //   type: Boolean,
      //   required: true,
      // }
    },
    // emits:['close'],
  
    data(){
        const min = new Date()
        min.setDate(min.getDate()-1)
        const max = new Date()
        max.setDate(max.getDate() + 14)
      return{
        Sh_isButtonDisabled: false,
        Sh_showMessageBox: false,
        Sh_customercomment:'',
        Sh_internalcomment:'',
        timeSlots: [],
        minDate: min,
        maxDate: max,
        preferredDateForVideoCall:'',
       // selectedDate: new Date(),
        selectedDateComp : null,
        videoCallTimeComp:'',

        locale: undefined,
        email:true,
        sms:false,
        isDateOkay: false,
        isDisabled :false,
        unselectableDates: [],
        unselectableDatesArray: [],
        isPastTimeSelected: false,
        isAllSlotsDisabled: false,
        isTimeInputFocus :false,
      }
    },
    methods: {
      isTimeSlotDisabled(time){
        const selectedSlot = this.timeSlots.find(timeSlot => timeSlot.time === time);
        return selectedSlot ? selectedSlot.disable : false;
      },
      emitCloss()
      {
          this.$emit('Closs_Sh',this.Sh_showMessageBox)
      },
      emitNewDate()
      {
          this.$emit('Update_Sh_New_Date',this.selectedDateComp)
      },
      emitNewTime()
      {
          this.isPastTimeSelected = false;
          this.$emit('Update_Sh_New_Time',this.videoCallTimeComp)
      },
      emitShcustomercomment() {
          this.$emit('update_Sh_customer_comment', this.Sh_customercomment);
      },
      emitShinternalcomment() {
          this.$emit('update_Sh_internal_comment', this.Sh_internalcomment);
      },
      emitScheduleEmail(){
        this.$emit('update_Sh_email', this.email);
      },
      emitScheduleSms(){
        this.$emit('update_Sh_sms', this.sms);
      },

      clearDate() 
      {
          this.selectedDateComp = null
      },
      validateComment: function () {
        const obj = {
          required: false,
          notStartsWith: " ",
          notEndsWith: " ",
          notConsecutive: "  ",
        };
        return obj;
      },
      // getTimeSlots: function () 
      // {
      //   const comp = this;
      //   const data = {
      //       today: true
      //   }
      //   if (
      //     this.selectedDateComp.toDateString() !=
      //     new Date().toDateString()
      //   ) {
      //     data.today = false;
      //   }
      //   NetworkManager.apiRequest("api/KycApplication/getVideoCallDateAndTime",data,function (response) 
      //     {
      //       if (response.statusCode == 200) {
      //         comp.timeSlots = response.data.time_slots;
      //         console.log("time slots->",comp.timeSlots)
      //         console.log("RESPONCE DATA",response.data)
      //         comp.unselectableDatesArray = response.data.leave_dates;
      //         console.log("unselectableDatesArray",comp.unselectableDatesArray)
      //         comp.unselectableDatesArray.forEach((element) => {
      //           comp.unselectableDates.push(new Date(element));
      //           console.log("Unselectable Date:", new Date(element));
      //         });
      //         comp.isAllSlotsDisabled = response.data.allSlotsDisabled;
            
      //         const today = new Date();
      //         today.setHours(0, 0, 0, 0)
      //         const checkdate = new Date(comp.selectedDateComp);
      //         if (checkdate.toDateString() === today.toDateString()) {
      //           comp.isDisabled = false;
      //           comp.isDateOkay= true;

      //         } else if (checkdate < today) {
      //           comp.isDisabled = true;
      //           comp.isDateOkay= false;
      //         } else if (checkdate > today){
      //           comp.isDisabled = false;
      //           comp.isDateOkay= true;
      //         }
      //         if (comp.isAllSlotsDisabled) {
      //           comp.minDate = new Date(
      //             comp.today.getFullYear(),
      //             comp.today.getMonth(),
      //             comp.today.getDate() + 1
      //           );
      //           //comp.getTimeSlots();
      //           if (
      //             comp.videoCallTimeComp == ""
      //           ) {
      //             comp.selectedDateComp =
      //               comp.minDate;
      //           }
      //           comp.timeSlots.forEach((timeSlots) => {
      //             timeSlots.disable = false;
      //           });
      //         }

      //         // const today = new Date();
      //         // today.setHours(0, 0, 0, 0)

      //         // Check if the selected date is a past date
      //         // if (comp.selectedDateComp < today) {
      //         //   console.log("selected date is past date");
      //         //   // Set today as the selected date
      //         //   comp.selectedDateComp = today;
      //         //   comp.isDateOkay=true
      //         // }
      //         if (
      //           comp.videoCallTimeComp != ""
      //         ){
      //           const startOfToday = new Date();
      //           startOfToday.setHours(0, 0, 0, 0);
      //           console.log("Start- of- Today :", startOfToday);

      //           const todayasdata = new Date().toISOString().split("T")[0] + "T00:00:00";
      //           console.log("today as data--",todayasdata);
      //           // Check if today is an unselectable day
      //           if (comp.unselectableDatesArray.includes(todayasdata)) {
      //             console.log("Today is in unselectable days");
      //             const nextAvailableDate = new Date;
      //             console.log("next Available Date",nextAvailableDate);
      //             // Find the next available day
      //             while (comp.unselectableDatesArray.includes(nextAvailableDate.toISOString().split("T")[0]+ "T00:00:00")) {
      //               nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);
      //             }

      //             // Set the next available day as the selected date
      //             comp.selectedDateComp = nextAvailableDate;   
      //             comp.isDisabled = false;
      //             comp.isDateOkay= true;
      //           }
      //           else {
      //               // Set today as the preferred date for the video call
      //               comp.selectedDateComp = startOfToday;
      //               comp.isDisabled = false;
      //               comp.isDateOkay= true;
      //             }
      //         console.log("Selected Date:", comp.selectedDateComp);

      //         comp.timeSlots.forEach((time_slot) => {
      //           if (
      //             time_slot.time ==
      //               comp.videoCallTimeComp &&
      //             time_slot.disable
      //           ) {
      //             comp.isPastTimeSelected = true;
      //           }
      //         });
      //       }
      //     }
      //       else{
      //         BuefyHelper.showToastMessage(
      //           "Failed to get video call date and time",
      //           "is-danger"
      //         )
      //       }

      //     }
      //   );
      // },
      getTimeSlots: function () 
      {
        const comp = this;
        const data = {
            today: true
        }
        if (
          this.selectedDateComp.toDateString() !=
          new Date().toDateString()
        ) {
          data.today = false;
        }
        NetworkManager.apiRequest("api/KycApplication/getVideoCallDateAndTime",data,function (response) 
          {
            if (response.statusCode == 200) {
            comp.timeSlots = response.data.time_slots;
            console.log("time slots->",comp.timeSlots)
            console.log("RESPONCE DATA",response.data)
            comp.unselectableDatesArray = response.data.leave_dates;
            comp.unselectableDatesArray.forEach((element) => {
              comp.unselectableDates.push(new Date(element));
            });
            comp.isAllSlotsDisabled = response.data.allSlotsDisabled;
            
            const today = new Date();
            const checkdate = new Date(comp.selectedDateComp);
            if (checkdate.toDateString() === today.toDateString()) {
              comp.isDisabled = false;
              comp.isDateOkay= true;

            } else if (checkdate < today) {
              comp.isDisabled = true;
              comp.isDateOkay= false;
            } else if (checkdate > today){
              comp.isDisabled = false;
              comp.isDateOkay= true;
            }
            if (comp.isAllSlotsDisabled) {
              comp.minDate = new Date(
                comp.today.getFullYear(),
                comp.today.getMonth(),
                comp.today.getDate() + 1
              );
              //comp.getTimeSlots();
              if (
                comp.videoCallTimeComp == ""
              ) {
                comp.selectedDateComp = comp.minDate;
              }
              comp.timeSlots.forEach((timeSlots) => {
                timeSlots.disable = false;
              });
            }
            if (
              comp.videoCallTimeComp != ""
            ) {
              comp.timeSlots.forEach((timeSlots) => {
                if (
                  timeSlots.time ==
                    comp.videoCallTimeComp &&
                    timeSlots.disable
                ) {
                  comp.isPastTimeSelected = true;
                }
              });
            }
            }
            else{
              BuefyHelper.showToastMessage(
                "Failed to get video call date and time",
                "is-danger"
              )
            }

          }
        );
      },
      emitsendScheduleMeeting() {
      this.$emit('sendScheduleMeeting',this.Sh_isButtonDisabled,this.Sh_showMessageBox);
    },
         
    },
    watch: {
      selectedDateComp: function (newDate) {
        this.getTimeSlots();
      }
    },
    
    mounted()
    {
      this.videoCallTimeComp = this.videoCallTime
      this.selectedDateComp = this.selectedDate
      this.getTimeSlots();
      //this.GetApplicationData();
    }
  };
  </script>
  
  <style>
.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-item {
  flex: 1;
  padding: 10px;
}
.rounded-input .input,
.rounded-input textarea {
  border-radius: 25px;
}
  </style>
  