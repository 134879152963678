export default class NotificationList {
    array

    constructor(){
        this.array = []
    }

    add(item) {
        this.array.push(item);
    }

    removeByKey(key, value) {
        for (let i = 0; i < this.array.length; i++) {
            let item = this.array[i];
            let isDeleted = false;
            Object.keys(item).map((_key, index) => {
                // @ts-ignore
                if (key === _key && item[_key] === value) {
                    console.log("Removing notification -> key : "+_key+" value : "+value)
                    this.array.splice(i, 1);
                    isDeleted = true;
                }
            });
            if (isDeleted)
                break;
        }
    }
}