<template>
  <div class="wrapper">
      <div id="recorder-icon"></div>
      <div id="timer">REC {{stopwatchTime}}</div>
  </div>
</template>

<script>
export default {
  name: "RecorderStopwatch",

  props : {
    isStart : {
      type : Boolean,
      default : false,
    },
  },

  watch:{
    // this watch method is used to execute start and stop functions of the stopwatch
    isStart : function () {
      console.log("---------------------------------------")
      console.log("Stopwatch component - isStart Value : "+this.isStart)

      if(this.isStart){
        this.startTimer()
      }
      else{
        this.stopTimer()
      }
    }
  },

  data(){
    return{
      stopwatchTime : "00 : 00 : 00",
      hours:0,
      mins:0,
      sec:0,
    }
  },

  methods : {
    startTimer : function() {
      const comp = this
      console.log("Timer started ---------")
      // comp.is_start = true;
      comp.stopwatch();
    },

    stopTimer: function(){
      console.log("Timer stopped ---------")
      const comp = this
      this.isStart = false;
      this.sec = 0;
      this.mins = 0;
      this.hours = 0;
      comp.outputStopwatchTime(comp.hours,comp.mins,comp.sec)
    },

    stopwatch : function(){
      const comp = this

      if(comp.isStart){
        comp.sec ++;
        if(comp.sec === 60){
          comp.mins++;
          comp.sec = 0;
        }

        if (comp.mins === 60){
          comp.hours ++ ;
          comp.mins = 0;
        }

        comp.outputStopwatchTime(comp.hours,comp.mins,comp.sec)
        setTimeout(comp.stopwatch,1000);

      }
    },

    outputStopwatchTime : function (hours,mins,sec){
      let outSec ="";
      let outMins ="";
      let outHours ="";

      sec < 10 ? outSec = "0"+sec : outSec = sec;
      mins < 10 ? outMins = "0"+mins : outMins = mins;
      hours < 10 ? outHours = "0"+hours : outHours = hours;


      this.stopwatchTime = outHours+" : "+outMins+" : "+outSec;

    },
  },

}
</script>

<style scoped>
.wrapper{
  width: 198px;
  height: 10px;
  padding: 20px 5px;
  border-radius: 30px;
  background-color: rgba(19, 21, 23, 0.63);
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 20px;
}

#recorder-icon{
  border: 5px solid #ED1C24;
  background-color: white;
  width: 20px;
  height:20px;
  border-radius: 50px;
  margin-right: 8px;
}
</style>