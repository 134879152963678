<template>

    <b-modal v-model="modelVisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false">
      <div class="model-card">
  
        <header class="modal-card-head" style="border-bottom: 2px solid rgb(199 192 192); background-color: white;">
          <p class="modal-card-title has-text-centered" style="color:red; font-size: 16px">DELETE USER PROFILE (GDPR)</p>
          <button @click="$emit('close-modal')" type="button" class="delete"></button>
        </header>
  
        <div class="modal-card-body" style="max-height: 50vh; overflow-y: auto">
          <div class="content has-text-centered pt-2">
            Your deletion request will be sent to two-
            <br> factor authentication.
          </div>
        </div>
  
        <footer class="modal-card-foot" style="background-color: white;border-top: 0px;">
          <div class=" column mr-2">
            <div class="column">
              <div class="columns" style="justify-items: center;">
                <div class="buttons">
                  <center><b-button
                      type="is-danger" style="margin-left: 75px; width: 250px!important;"  rounded @click="$emit('delete')">Delete</b-button></center>
                 
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
  </template>
  
  <script>
  export default {
    name: "UserProfileDeleteModalAction",
  
    props:['modelVisible']
  
  }
  </script>