export default  class  ProductDetailDTO {
    id
    currency_type
    account_type
    product_id
    purpose_of_opening_account
    anticipated_volume
    initial_deposit_amount
    purpose_of_opening_customer_inputs
    purpose_of_opening_agent_inputs
    purpose_of_opening_other_specify_customer_inputs
    purpose_of_opening_other_specify_agent_inputs
}