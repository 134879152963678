import {ExceptionalApproverBaseTableDataItem} from "../exceptionalApproverBaseTableDataItem";
import {m} from "buefy/dist/esm/chunk-851d1b8f";

export class GrantedExApprovalCallAgentTableDataItem extends  ExceptionalApproverBaseTableDataItem{
    scheduled_time
    granted_date
    received_date
    constructor(id,refNo,nic,name,gender,email,branch,assignedUserName,status,scheduledTime,grantedDate,receivedDate) {
        super(id,refNo,nic,name,gender,email,branch,assignedUserName,status);
        this.scheduled_time = scheduledTime;
        this.granted_date = grantedDate;
        this.received_date = receivedDate;
    }
}