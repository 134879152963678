<template>
  <div style="height: 80.5vh; overflow: auto !important;">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label
                style="
                font-family: Gotham-Medium;
                color: gray;
                font-weight: 400;
                font-size: 23px;
              "
            >Activity Log</label
            >
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                  placeholder="Search..."
                  rounded
                  type="search"
                  icon="magnify"
                  icon-clickable
                  v-model="searchKeyword"
                  style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="mt-5">
      <!--filter component -->
      <ActivityLogFilterComponent @filterData="applyFilteredData"/>

      <!--table component -->
      <ActivityLogTableComponent
          :columns="columnDetails"
          :searchable-fields="searchableFields"
          :search-keyword="searchKeyword"
          :table-data="tableData"
          :custom-text="customTextMsg"
      />

    </section>
  </div>
</template>

<script>
import NetworkManager from "../../network";

import {GlobalAttributes} from "../../helpers/globalAttributes";
import ActivityLogFilterComponent from "../../components/ActivityLog/ActivityLogFilterComponent.vue";
import ActivityLogTableComponent from "../../components/ActivityLog/ActivityLogTableComponent.vue";
import app from "../../App.vue"
import {useAuthStore} from "../../store/authStore";
import {BuefyHelper} from "../../helpers/bufeyHelper";
import ActivityLogTableDataDTO from "../../helpers/classes/activityLog/activityLogTableDataDTO";


export default {
  computed: {
    app() {
      return app;
    },
    GlobalAttributes() {
      return GlobalAttributes;
    },

  },
  components: {
    ActivityLogFilterComponent,
    ActivityLogTableComponent
  },

  setup(){
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {

      searchKeyword: "",
      customTextMsg: "",
      searchableFields: [
        "description",
        "branch",
        "user",
        "timestamp",
      ],

      columnDetails: [
        { id: 1, field: "level", label: "Level", date: false, hide: false },
        { id: 2, field: "description", label: "Description", date: false, hide: false },
        { id: 3, field: "branch", label: "Branch", date: false, hide: false },
        { id: 4, field: "user", label: "User" ,date:false},
        { id: 5, field: "timestamp", label: "Timestamp", date: true, hide: false }
      ],

      // NOTE :: column filed name and table Details field 'key' must be same. refer to above columnDetail and below tableDetail
      tableData: [],
    };
  },

  methods: {

    /**
     * This function is used to load applications based on selected filter parameters
     * @param filterParams
     */
    applyFilteredData(filterParams) {
      console.log("filter params... (receiving end) ");
      console.log(filterParams);

      const comp = this;

      let endDate = null

      if(filterParams.endDate != null){
        let endDateTemp = new Date(filterParams.endDate); // create new date object otherwise may increment the date continuously
        endDate = new Date(endDateTemp.setDate(endDateTemp.getDate()+1)); // pass +1 date for the end date. otherwise it will not returns the correct end date applications due to timestamp filtering in backend
      }

      console.log("end date : "+endDate)
      comp.showFullScreenLoader()

      comp.getActivityLogs(filterParams.startDate,endDate,filterParams.branchId,filterParams.username,(status)=>{
        if (status){
          console.log("filter applied successfully...")
        }
        else {
          console.log("failed to apply the filters ....")
        }
        comp.hideFullScreenLoader()
      })

    },


    /**
     * this function can be used to get the activity logs
     * @param startDate
     * @param endDate
     * @param branchId
     * @param userName
     * @param callback
     */
    getActivityLogs(startDate,endDate,branchId,userName,callback){

      const comp = this

      const requestBody = {
        start_date: startDate ,
        end_date: endDate ,
        branch_id: branchId === null ? 0 : branchId,
        user_name: userName,
        user_type: comp.authStore.loggedInUser.role
      }

      console.log("Get activity logs request body : ",requestBody)

      let successResponse = false;
      NetworkManager.apiRequest("api/ActivityLog/activityLog",requestBody,(response)=>{
        console.log("activity logs response : ",response)


        if(response.statusCode === 200){
          successResponse = true

          // add new table data
          comp.tableData = []

          if(response.data.filtered_activity_log_list !== null){
            // add activity logs to the array
            response.data.filtered_activity_log_list.forEach(log => comp.tableData.push(
                new ActivityLogTableDataDTO(
                    log.activityLevel,
                    log.description,
                    log.branch,
                    log.userName,
                    log.timeStamp,
                ))
            )
          }


          if(typeof callback === "function"){
            callback(successResponse)
          }
        }
        else {
          BuefyHelper.showToastMessage("Failed to apply the filters!","is-danger")
          if(typeof callback === "function"){
            callback(successResponse)
          }
        }
      })
    },



    /**
     * This function can be used to load initial data of the tabs
     */
    loadInitialData() {
      const comp = this;
      comp.showFullScreenLoader()
      comp.getActivityLogs(null,null,null,null,()=>{
        comp.hideFullScreenLoader()
      })
    }


  },

  // life cycle hooks ==================================================================================================

  mounted() {

    this.loadInitialData();

    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
  },
};
</script>
